const heroLottie1152 = {
  v: "5.6.8",
  fr: 25,
  ip: 0,
  op: 333,
  w: 1068,
  h: 368,
  nm: "SUTRA animation FINAL (lottie) 2",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          nm: "NULL CONTROL",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540, 540, 0], ix: 2 },
            a: { a: 0, k: [60, 60, 0], ix: 1 },
            s: { a: 0, k: [539, 539, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 34,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Layer 17",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -4.711],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.03, 0],
                        [0, -0.795],
                        [-1.089, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 4.682],
                        [-6.124, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.795],
                        [-1.089, 0],
                        [0, 0.795],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.124, 0],
                        [0, -4.711],
                        [6.065, 0],
                      ],
                      v: [
                        [-1021.044, 71.566],
                        [-1021.044, 80.075],
                        [-1030.23, 80.075],
                        [-1030.23, 71.566],
                        [-1032.084, 70.094],
                        [-1033.998, 71.566],
                        [-1032.084, 73.038],
                        [-1030.76, 73.038],
                        [-1030.76, 80.075],
                        [-1032.084, 80.075],
                        [-1043.155, 71.566],
                        [-1032.084, 63.058],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1057.23, 73.834],
                        [-1047.809, 73.834],
                        [-1047.809, 80.075],
                        [-1057.23, 80.075],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.265],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.006, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.383, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -4.622],
                        [0, 0],
                      ],
                      v: [
                        [-1043.598, 63.558],
                        [-1043.598, 70.477],
                        [-1047.249, 70.477],
                        [-1047.809, 70.919],
                        [-1047.809, 73.48],
                        [-1057.23, 73.48],
                        [-1057.23, 71.213],
                        [-1047.013, 63.558],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.265, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 5.064],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0.206],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.947, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1058.027, 70.389],
                        [-1064.563, 70.389],
                        [-1064.563, 72.832],
                        [-1064.092, 73.215],
                        [-1058.027, 73.215],
                        [-1058.027, 80.075],
                        [-1063.915, 80.075],
                        [-1073.984, 72.509],
                        [-1073.984, 63.558],
                        [-1058.027, 63.558],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1073.984, 59.436],
                        [-1064.563, 59.436],
                        [-1064.563, 63.117],
                        [-1073.984, 63.117],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0.765],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.476, -0.059],
                      ],
                      o: [
                        [0.766, -0.089],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 5.741],
                        [0, 0],
                      ],
                      v: [
                        [-1086.058, 73.422],
                        [-1084.616, 72.038],
                        [-1084.616, 63.558],
                        [-1075.194, 63.558],
                        [-1075.194, 72.038],
                        [-1086.058, 80.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 5.712],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.736, -0.088],
                      ],
                      o: [
                        [-5.447, -0.088],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.765],
                        [0, 0],
                      ],
                      v: [
                        [-1086.47, 80.664],
                        [-1097.305, 72.038],
                        [-1097.305, 63.558],
                        [-1087.883, 63.558],
                        [-1087.883, 72.038],
                        [-1086.47, 73.392],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.5, 0.147],
                        [0, 0.177],
                        [0.412, 0.177],
                        [1.148, 0.206],
                        [0, 0],
                        [-0.324, -0.088],
                        [0, 0],
                        [0, 0],
                        [-0.471, -0.177],
                        [-0.618, -0.294],
                        [-0.118, -0.029],
                        [-0.354, -0.265],
                        [-0.324, -0.53],
                        [0, -0.442],
                        [1.914, -0.795],
                        [3.062, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.148, 0],
                        [0.353, -0.059],
                        [0, -0.206],
                        [-0.53, -0.177],
                        [0, 0],
                        [0.353, 0.03],
                        [0, 0],
                        [0, 0],
                        [0.913, 0.206],
                        [0.294, 0.118],
                        [0.147, 0.029],
                        [0.648, 0.324],
                        [0.412, 0.353],
                        [0.206, 0.412],
                        [0, 1.59],
                        [-1.973, 0.824],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1108.76, 75.865],
                        [-1108.642, 75.865],
                        [-1106.316, 75.6],
                        [-1105.786, 75.158],
                        [-1106.464, 74.57],
                        [-1108.79, 74.01],
                        [-1108.849, 69.388],
                        [-1107.818, 69.564],
                        [-1106.052, 69.918],
                        [-1104.285, 70.359],
                        [-1102.283, 70.919],
                        [-1101.105, 71.419],
                        [-1100.693, 71.566],
                        [-1099.103, 72.479],
                        [-1098.073, 73.657],
                        [-1097.749, 74.952],
                        [-1100.811, 78.721],
                        [-1108.495, 79.928],
                        [-1108.672, 79.928],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.648, 0.265],
                        [1.178, 0.03],
                        [0, 0],
                        [0, -3.268],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -0.383],
                        [-0.589, -0.236],
                        [0, 0],
                        [7.243, 0.03],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1105.522, 68.858],
                        [-1106.346, 67.916],
                        [-1108.878, 67.503],
                        [-1108.966, 63.499],
                        [-1098.014, 68.799],
                        [-1098.014, 69.918],
                        [-1105.522, 69.918],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.294, 0.059],
                        [0.442, 0.088],
                        [0.412, 0.059],
                        [0.883, 0.353],
                        [0.53, 0.324],
                        [0.442, 0.471],
                        [0, 0.648],
                        [-3.15, 0.03],
                        [0, 0],
                        [0.471, -0.147],
                        [0, -0.235],
                        [-0.5, -0.177],
                        [-0.795, -0.177],
                        [0, 0],
                      ],
                      o: [
                        [-0.471, -0.118],
                        [-0.442, -0.088],
                        [-0.883, -0.206],
                        [-1.03, -0.236],
                        [-0.707, -0.383],
                        [-0.471, -0.56],
                        [0, -4.711],
                        [0, 0],
                        [-0.854, 0],
                        [-0.383, 0.117],
                        [0, 0.206],
                        [0.442, 0.118],
                        [0, 0],
                        [-0.265, -0.03],
                      ],
                      v: [
                        [-1109.908, 73.775],
                        [-1111.145, 73.51],
                        [-1112.499, 73.245],
                        [-1115.12, 72.479],
                        [-1117.475, 71.566],
                        [-1119.124, 70.33],
                        [-1119.83, 68.593],
                        [-1109.261, 63.499],
                        [-1109.261, 67.503],
                        [-1111.175, 67.769],
                        [-1111.734, 68.298],
                        [-1111.086, 68.828],
                        [-1109.231, 69.299],
                        [-1109.113, 73.922],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.031, 0.795],
                        [0, 1.678],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.618, -0.236],
                        [-1.089, -0.059],
                        [0, 0],
                      ],
                      o: [
                        [-2.061, -0.795],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.265],
                        [0.677, 0.236],
                        [0, 0],
                        [-3.003, -0.029],
                      ],
                      v: [
                        [-1116.651, 78.809],
                        [-1120.007, 74.805],
                        [-1120.007, 73.657],
                        [-1112.617, 73.657],
                        [-1112.617, 74.717],
                        [-1111.705, 75.453],
                        [-1109.113, 75.865],
                        [-1109.055, 79.928],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 2,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Layer 16",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.126],
                        [0, 0],
                        [-1.137, 0],
                        [0, 1.137],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 1.148],
                        [1.137, 0],
                        [0, 0],
                        [0, 0],
                        [-1.137, 0],
                      ],
                      v: [
                        [-1033.135, 75.149],
                        [-1033.135, 76.855],
                        [-1031.43, 78.577],
                        [-1029.725, 76.872],
                        [-1029.725, 73.461],
                        [-1031.43, 73.461],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.411],
                        [0, 0],
                        [3.411, 0],
                        [0, 0],
                        [0, 3.411],
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                        [0, 0],
                        [1.137, 0],
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 3.411],
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                        [0, -3.411],
                        [0, 0],
                        [0, 0],
                        [0, -1.137],
                        [0, 0],
                        [0, -2.274],
                        [0, 0],
                        [3.411, 0],
                      ],
                      v: [
                        [-1021.198, 68.345],
                        [-1021.198, 76.872],
                        [-1026.314, 81.988],
                        [-1036.546, 81.988],
                        [-1041.662, 76.872],
                        [-1041.662, 75.166],
                        [-1036.546, 70.05],
                        [-1029.725, 70.05],
                        [-1029.725, 68.345],
                        [-1031.43, 66.639],
                        [-1041.662, 66.639],
                        [-1036.546, 63.229],
                        [-1026.314, 63.229],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 3.411],
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                        [0, -2.274],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                        [0, -3.411],
                        [0, 0],
                        [2.274, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1049.319, 81.988],
                        [-1052.73, 81.988],
                        [-1057.846, 76.872],
                        [-1057.846, 68.362],
                        [-1052.73, 63.246],
                        [-1045.908, 63.229],
                        [-1042.498, 66.639],
                        [-1049.319, 66.639],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.274, 0],
                        [0, 0],
                        [0, 3.411],
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -2.274],
                        [0, 0],
                        [0, 0],
                        [-1.137, 0],
                      ],
                      o: [
                        [0, 2.274],
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                        [0, -3.411],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.274, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.137],
                        [0, 0],
                      ],
                      v: [
                        [-1059.62, 78.577],
                        [-1063.03, 81.988],
                        [-1069.852, 81.988],
                        [-1074.968, 76.872],
                        [-1074.968, 63.229],
                        [-1069.852, 58.113],
                        [-1066.441, 58.113],
                        [-1066.441, 63.229],
                        [-1063.03, 63.229],
                        [-1059.62, 66.639],
                        [-1066.441, 66.639],
                        [-1066.441, 76.872],
                        [-1064.736, 78.577],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 3.411],
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                        [0, 0],
                        [-1.137, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -3.411],
                        [0, 0],
                        [3.411, 0],
                      ],
                      o: [
                        [-3.411, 0],
                        [0, 0],
                        [0, -3.411],
                        [0, 0],
                        [0, 0],
                        [0, 1.08],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.411, 0],
                        [0, 0],
                        [0, 3.411],
                        [0, 0],
                      ],
                      v: [
                        [-1093.556, 81.988],
                        [-1098.672, 76.872],
                        [-1098.672, 68.345],
                        [-1093.556, 63.229],
                        [-1090.146, 63.229],
                        [-1090.146, 76.957],
                        [-1088.44, 78.577],
                        [-1086.735, 78.577],
                        [-1086.735, 63.229],
                        [-1083.324, 63.229],
                        [-1078.208, 68.345],
                        [-1078.208, 76.872],
                        [-1083.324, 81.988],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 3.411],
                        [0, 0],
                        [-3.411, 0],
                        [0, 0],
                        [0, -2.274],
                        [0, 0],
                        [0, -1.137],
                        [0, 0],
                        [-1.137, 0.114],
                        [0, 0],
                        [0, -3.411],
                        [0, 0],
                        [3.411, 0],
                        [0, 0],
                        [0, 2.274],
                        [0, 0],
                        [0, 1.137],
                        [0, 0],
                        [1.137, 0.057],
                      ],
                      o: [
                        [-3.411, 0],
                        [0, 0],
                        [0, -3.411],
                        [0, 0],
                        [2.274, 0],
                        [0, 0],
                        [-1.137, 0],
                        [0, 0],
                        [0, 1.194],
                        [0, 0],
                        [3.411, 0],
                        [0, 0],
                        [0, 3.411],
                        [0, 0],
                        [-2.274, 0],
                        [0, 0],
                        [1.137, 0],
                        [0, 0],
                        [0, -0.944],
                        [0, 0],
                      ],
                      v: [
                        [-1114.737, 74.331],
                        [-1119.853, 69.214],
                        [-1119.853, 68.362],
                        [-1114.737, 63.246],
                        [-1104.505, 63.229],
                        [-1101.094, 66.639],
                        [-1109.621, 66.639],
                        [-1111.326, 68.345],
                        [-1111.326, 69.163],
                        [-1109.621, 70.783],
                        [-1106.21, 70.818],
                        [-1101.094, 75.934],
                        [-1101.094, 76.855],
                        [-1106.21, 81.971],
                        [-1116.442, 81.988],
                        [-1119.853, 78.577],
                        [-1111.326, 78.577],
                        [-1109.621, 76.872],
                        [-1109.621, 75.934],
                        [-1111.326, 74.433],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 2,
          op: 4,
          st: 2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Layer 15",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.908, 0],
                        [0, -2.12],
                        [-1.687, 0],
                        [-0.26, 3.071],
                      ],
                      o: [
                        [-0.909, -0.129],
                        [-1.99, 0],
                        [0, 1.254],
                        [1.946, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1027.569, 74.332],
                        [-1030.381, 74.073],
                        [-1034.101, 76.755],
                        [-1031.809, 79.004],
                        [-1027.569, 74.593],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.201, 0],
                        [0, -4.456],
                        [0, 0],
                        [-0.909, 0],
                        [-0.303, 0.087],
                        [0, 0],
                        [1.211, 0],
                        [0.259, 2.335],
                        [0, 0],
                        [3.158, 0],
                        [0, 4.066],
                        [-4.455, 0],
                        [-0.995, -0.129],
                        [0, 0],
                        [3.158, 0],
                        [0.908, -0.476],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [4.195, 0],
                        [0, 0],
                        [0, 1.125],
                        [0.259, 0],
                        [0, 0],
                        [-1.038, 0.562],
                        [-2.595, 0],
                        [0, 0],
                        [-1.125, 1.99],
                        [-2.811, 0],
                        [0, -4.801],
                        [1.081, 0],
                        [0, 0],
                        [0, -1.989],
                        [-1.168, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.417, -1.385],
                      ],
                      v: [
                        [-1029.732, 62.654],
                        [-1021.99, 69.272],
                        [-1021.99, 77.749],
                        [-1020.649, 79.263],
                        [-1019.827, 79.134],
                        [-1019.784, 82.075],
                        [-1023.287, 82.983],
                        [-1027.569, 79.134],
                        [-1027.569, 79.091],
                        [-1033.842, 82.983],
                        [-1039.854, 77.403],
                        [-1030.771, 71.478],
                        [-1027.569, 71.651],
                        [-1027.569, 71.002],
                        [-1030.771, 66.634],
                        [-1033.755, 67.196],
                        [-1034.49, 69.964],
                        [-1038.946, 69.488],
                        [-1038.08, 64.428],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.341, -0.779],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.519, 0],
                        [0, -1.903],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.639, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.562, -0.346],
                        [-1.254, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.649, -2.25],
                        [1.081, 0],
                      ],
                      v: [
                        [-1042.667, 63.866],
                        [-1041.802, 70.699],
                        [-1046.041, 71.175],
                        [-1046.776, 67.845],
                        [-1048.29, 67.325],
                        [-1050.669, 70.224],
                        [-1050.669, 78.355],
                        [-1047.209, 78.355],
                        [-1047.209, 82.551],
                        [-1058.844, 82.551],
                        [-1058.844, 78.355],
                        [-1056.249, 78.355],
                        [-1056.249, 67.282],
                        [-1059.061, 67.282],
                        [-1059.061, 63.043],
                        [-1051.102, 63.043],
                        [-1051.102, 66.763],
                        [-1046.516, 62.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.066, 0],
                        [0, 4.023],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.384, 0],
                        [-0.346, 2.897],
                        [0, 0],
                      ],
                      o: [
                        [-4.325, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.86],
                        [0.692, 0],
                        [0, 0],
                        [-0.563, 4.499],
                      ],
                      v: [
                        [-1067.021, 82.983],
                        [-1074.288, 76.712],
                        [-1074.288, 67.023],
                        [-1077.057, 67.023],
                        [-1077.057, 63.043],
                        [-1074.288, 63.043],
                        [-1074.288, 57.117],
                        [-1068.708, 57.117],
                        [-1068.708, 63.043],
                        [-1061.701, 63.043],
                        [-1061.701, 67.023],
                        [-1068.708, 67.023],
                        [-1068.708, 75.76],
                        [-1066.372, 78.313],
                        [-1063.821, 75.242],
                        [-1059.755, 76.495],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [2.854, 0],
                        [0, 4.628],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.206, 0],
                        [0, 2.725],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.255, 2.163],
                        [-3.677, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.374],
                        [2.682, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1086.315, 82.551],
                        [-1086.315, 79.566],
                        [-1092.587, 82.94],
                        [-1099.421, 76.192],
                        [-1099.421, 67.24],
                        [-1102.016, 67.24],
                        [-1102.016, 63.043],
                        [-1093.841, 63.043],
                        [-1093.841, 74.029],
                        [-1090.511, 78.701],
                        [-1086.315, 74.073],
                        [-1086.315, 67.24],
                        [-1089.473, 67.24],
                        [-1089.473, 63.043],
                        [-1080.735, 63.043],
                        [-1080.735, 78.355],
                        [-1077.924, 78.355],
                        [-1077.924, 82.551],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.45, 0],
                        [2.595, 1.34],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.605, 0],
                        [0, 1.385],
                        [0, 7.743],
                        [-3.979, 0],
                        [-2.423, -1.254],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.908, 0],
                        [0, -1.297],
                        [0, -7.526],
                      ],
                      o: [
                        [-2.812, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.038, 0.346],
                        [2.119, 0],
                        [0, -3.33],
                        [0, -4.802],
                        [2.595, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.038, -0.346],
                        [-1.427, 0],
                        [0, 3.374],
                        [0, 4.325],
                      ],
                      v: [
                        [-1112.919, 82.983],
                        [-1121.267, 81.081],
                        [-1120.488, 75.63],
                        [-1116.163, 76.106],
                        [-1116.163, 78.529],
                        [-1113.222, 78.918],
                        [-1109.286, 76.841],
                        [-1121.05, 68.71],
                        [-1112.486, 62.654],
                        [-1105.176, 64.428],
                        [-1104.701, 69.402],
                        [-1109.026, 69.878],
                        [-1109.372, 67.153],
                        [-1112.443, 66.676],
                        [-1115.038, 68.536],
                        [-1103.662, 76.582],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 4,
          op: 6,
          st: 4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Layer 14",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.696, 0.507],
                        [0, 0],
                        [0, 0],
                        [0.253, -0.251],
                        [0, -0.379],
                        [0, 0],
                        [-0.253, -0.252],
                        [-0.38, 0],
                      ],
                      o: [
                        [1.341, 0],
                        [0, 0],
                        [0, 0],
                        [-0.38, 0],
                        [-0.253, 0.253],
                        [0, 0],
                        [0, 0.38],
                        [0.253, 0.253],
                        [0, 0],
                      ],
                      v: [
                        [-1032.091, 77.263],
                        [-1027.535, 76.504],
                        [-1027.535, 74.226],
                        [-1033.04, 74.226],
                        [-1033.989, 74.605],
                        [-1034.368, 75.554],
                        [-1034.368, 75.934],
                        [-1033.989, 76.883],
                        [-1033.04, 77.263],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.72, 0],
                        [-1.038, -1.037],
                        [0, -1.72],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.253, -0.367],
                        [0.86, 0],
                        [0, 0],
                        [1.037, 1.038],
                        [0, 1.722],
                        [0, 0],
                        [-1.038, 1.038],
                        [-1.721, 0],
                        [0, 0],
                        [0, 0],
                        [0.253, 0.253],
                        [0.38, 0],
                        [1.986, -0.151],
                        [1.215, -0.076],
                      ],
                      o: [
                        [4.631, -0.506],
                        [1.721, 0],
                        [1.037, 1.038],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.19, 0.785],
                        [-1.253, 0.367],
                        [0, 0],
                        [-1.721, 0],
                        [-1.038, -1.036],
                        [0, 0],
                        [0, -1.722],
                        [1.037, -1.038],
                        [0, 0],
                        [0, 0],
                        [0, -0.38],
                        [-0.253, -0.253],
                        [-1.645, 0],
                        [-1.987, 0.152],
                        [0, 0],
                      ],
                      v: [
                        [-1039.113, 62.458],
                        [-1026.586, 61.699],
                        [-1022.449, 63.255],
                        [-1020.892, 67.392],
                        [-1020.892, 82.198],
                        [-1026.776, 82.198],
                        [-1027.156, 80.299],
                        [-1030.819, 82.027],
                        [-1033.989, 82.577],
                        [-1035.318, 82.577],
                        [-1039.455, 81.02],
                        [-1041.012, 76.883],
                        [-1041.012, 75.175],
                        [-1039.455, 71.038],
                        [-1035.318, 69.481],
                        [-1027.535, 69.481],
                        [-1027.535, 67.963],
                        [-1027.915, 67.013],
                        [-1028.864, 66.634],
                        [-1034.312, 66.861],
                        [-1039.113, 67.203],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.695, -0.759],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.379, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-1.594, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.176, -1.899],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1044.428, 67.583],
                        [-1049.363, 68.722],
                        [-1049.363, 82.198],
                        [-1056.006, 82.198],
                        [-1056.006, 62.079],
                        [-1050.122, 62.079],
                        [-1049.742, 64.546],
                        [-1042.909, 61.699],
                        [-1041.202, 61.699],
                        [-1041.202, 67.583],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.354, 0],
                        [1.037, 1.038],
                        [0, 1.722],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.253, -0.252],
                        [-0.38, 0],
                        [0, 0],
                      ],
                      o: [
                        [-2.455, 0.252],
                        [-1.721, 0],
                        [-1.038, -1.036],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.38],
                        [0.253, 0.253],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1058.663, 82.198],
                        [-1065.876, 82.577],
                        [-1070.013, 81.02],
                        [-1071.57, 76.883],
                        [-1071.57, 67.203],
                        [-1073.847, 67.203],
                        [-1073.847, 62.079],
                        [-1071.57, 62.079],
                        [-1070.81, 57.523],
                        [-1064.926, 57.523],
                        [-1064.926, 62.079],
                        [-1060.181, 62.079],
                        [-1060.181, 67.203],
                        [-1064.926, 67.203],
                        [-1064.926, 75.934],
                        [-1064.547, 76.883],
                        [-1063.598, 77.263],
                        [-1058.663, 77.263],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.696, 0.507],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.253, -0.367],
                        [0.86, 0],
                        [0, 0],
                        [1.037, 1.038],
                        [0, 1.722],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.253, -0.252],
                        [-0.38, 0],
                      ],
                      o: [
                        [1.341, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.19, 0.785],
                        [-1.253, 0.367],
                        [0, 0],
                        [-1.722, 0],
                        [-1.038, -1.036],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.381],
                        [0.253, 0.253],
                        [0, 0],
                      ],
                      v: [
                        [-1087.703, 77.073],
                        [-1083.148, 76.314],
                        [-1083.148, 62.079],
                        [-1076.504, 62.079],
                        [-1076.504, 82.198],
                        [-1082.388, 82.198],
                        [-1082.768, 80.299],
                        [-1086.431, 82.027],
                        [-1089.601, 82.577],
                        [-1091.309, 82.577],
                        [-1095.447, 81.02],
                        [-1097.004, 76.883],
                        [-1097.004, 62.079],
                        [-1090.36, 62.079],
                        [-1090.36, 75.744],
                        [-1089.981, 76.693],
                        [-1089.032, 77.073],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-2.961, 0],
                        [-0.253, 0.253],
                        [0, 0.379],
                        [0, 0],
                        [0, 0],
                        [1.037, 1.038],
                        [0, 1.721],
                        [0, 0],
                        [-1.038, 1.038],
                        [-1.721, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.253, -0.252],
                        [0, -0.379],
                        [-0.253, -0.253],
                        [-0.38, 0],
                        [0, 0],
                        [-1.038, -1.038],
                        [0, -1.721],
                        [0, 0],
                        [1.037, -1.036],
                        [1.721, 0],
                        [5.137, 0.506],
                      ],
                      o: [
                        [5.137, 0.38],
                        [0.38, 0],
                        [0.253, -0.252],
                        [0, 0],
                        [0, 0],
                        [-1.721, 0],
                        [-1.038, -1.038],
                        [0, 0],
                        [0, -1.721],
                        [1.037, -1.037],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.38, 0],
                        [-0.253, 0.253],
                        [0, 0.38],
                        [0.253, 0.253],
                        [0, 0],
                        [1.721, 0],
                        [1.037, 1.038],
                        [0, 0],
                        [0, 1.722],
                        [-1.038, 1.038],
                        [-4.479, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1119.779, 76.883],
                        [-1107.632, 77.453],
                        [-1106.683, 77.073],
                        [-1106.303, 76.125],
                        [-1106.303, 74.795],
                        [-1114.465, 74.795],
                        [-1118.603, 73.239],
                        [-1120.159, 69.102],
                        [-1120.159, 67.772],
                        [-1118.603, 63.635],
                        [-1114.465, 62.079],
                        [-1101.558, 62.079],
                        [-1101.558, 67.013],
                        [-1112.187, 67.013],
                        [-1113.136, 67.392],
                        [-1113.516, 68.342],
                        [-1113.136, 69.291],
                        [-1112.187, 69.67],
                        [-1105.354, 69.67],
                        [-1101.216, 71.227],
                        [-1099.66, 75.365],
                        [-1099.66, 76.883],
                        [-1101.216, 81.02],
                        [-1105.354, 82.577],
                        [-1119.779, 81.818],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 6,
          op: 8,
          st: 6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Layer 13",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.641, -0.37],
                        [0, -2.221],
                        [-1.11, 0],
                        [0, 2.819],
                      ],
                      o: [
                        [-0.484, 0.555],
                        [-1.609, 0.94],
                        [0, 2.847],
                        [1.623, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1030.825, 71.086],
                        [-1032.512, 72.474],
                        [-1034.925, 77.215],
                        [-1033.26, 81.486],
                        [-1030.825, 77.258],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.808, 0],
                        [0.626, 1.666],
                        [2.435, 0],
                        [0, 4.214],
                        [-4.157, 1.296],
                        [0, 1.68],
                        [2.193, 0],
                        [0, -2.136],
                        [-0.399, -1.096],
                        [0, 0],
                        [0, 0.584],
                        [-6.236, 0],
                        [0, -3.189],
                        [0, 0],
                        [-0.868, 0],
                        [-0.527, 0.299],
                      ],
                      o: [
                        [-1.908, 0.74],
                        [-2.677, 0],
                        [-1.296, 1.737],
                        [-4.542, 0],
                        [0, -3.203],
                        [3.816, -1.196],
                        [0, -2.207],
                        [-2.534, 0],
                        [0, 0.669],
                        [0, 0],
                        [-0.1, -0.669],
                        [0, -3.417],
                        [5.923, 0],
                        [0, 0],
                        [0, 1.338],
                        [0.271, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1019.784, 83.259],
                        [-1025.358, 84.369],
                        [-1030.312, 81.871],
                        [-1035.908, 84.476],
                        [-1042.72, 78.155],
                        [-1036.484, 71.406],
                        [-1030.761, 67.092],
                        [-1034.05, 63.782],
                        [-1037.851, 66.986],
                        [-1037.253, 69.634],
                        [-1041.118, 68.886],
                        [-1041.268, 67.007],
                        [-1031.914, 61.882],
                        [-1023.03, 66.665],
                        [-1023.03, 79.735],
                        [-1021.727, 81.742],
                        [-1020.531, 81.294],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.185, -0.769],
                        [0, 0],
                        [-0.142, 0.399],
                        [0, 0.47],
                        [0.655, 0],
                        [0.456, -1.203],
                        [0, -1.338],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.392, 0.384],
                        [0, 0],
                        [-1.837, 0],
                        [0, -2.762],
                        [0.242, -0.94],
                      ],
                      o: [
                        [0, 0],
                        [0.185, -0.84],
                        [0.199, -0.584],
                        [0, -0.911],
                        [-0.498, 0],
                        [-0.456, 1.203],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.794, 0],
                        [0, 0],
                        [1.267, -2.363],
                        [2.506, 0],
                        [0, 0.726],
                        [-0.085, 0.384],
                      ],
                      v: [
                        [-1044.728, 70.317],
                        [-1050.067, 70.317],
                        [-1049.575, 68.459],
                        [-1049.276, 66.879],
                        [-1050.259, 65.512],
                        [-1051.69, 67.317],
                        [-1052.373, 71.129],
                        [-1052.373, 84.028],
                        [-1060.168, 84.028],
                        [-1060.168, 62.501],
                        [-1058.652, 62.522],
                        [-1052.373, 61.946],
                        [-1052.373, 65.491],
                        [-1047.717, 61.946],
                        [-1043.959, 66.089],
                        [-1044.322, 68.587],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.612, 0],
                        [-0.085, 0.641],
                        [0, 0],
                        [3.289, 0],
                        [0, 3.332],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.506, 1.395],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.954],
                        [0.441, 0],
                        [0, 0],
                        [-0.612, 2.249],
                        [-3.915, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.691, -0.74],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1062.688, 62.565],
                        [-1062.688, 64.978],
                        [-1066.147, 64.978],
                        [-1066.147, 80.205],
                        [-1065.229, 81.636],
                        [-1064.439, 80.675],
                        [-1062.218, 80.888],
                        [-1068.07, 84.262],
                        [-1073.942, 79.265],
                        [-1073.942, 64.978],
                        [-1076.548, 64.978],
                        [-1076.548, 62.565],
                        [-1073.942, 62.565],
                        [-1073.942, 58.764],
                        [-1066.147, 55.56],
                        [-1066.147, 62.565],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.591, 0.256],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.819, 0],
                        [0, 3.702],
                        [0, 0],
                        [-2.577, 0.256],
                        [0, 0],
                        [-0.883, 0],
                        [0, 5.012],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.794, 2.563],
                        [-3.801, 0],
                        [0, 0],
                        [2.62, -0.114],
                        [0, 0],
                        [0, 1.452],
                        [2.335, 0],
                        [0, 0],
                        [2.605, -0.114],
                      ],
                      v: [
                        [-1078.705, 61.946],
                        [-1078.705, 84.028],
                        [-1086.5, 84.028],
                        [-1086.5, 80.589],
                        [-1093.419, 84.433],
                        [-1099.121, 78.881],
                        [-1099.121, 62.501],
                        [-1091.326, 61.946],
                        [-1091.326, 78.689],
                        [-1090.002, 80.867],
                        [-1086.5, 73.35],
                        [-1086.5, 62.501],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.484, -4.043],
                        [0, 0],
                        [2.99, 0],
                        [0, -1.509],
                        [-3.161, -1.281],
                        [0, -3.047],
                        [6.165, 0],
                        [0, 4.855],
                        [0, 0],
                        [0, -0.413],
                        [-1.046, -0.925],
                        [-1.424, 0],
                        [0, 1.595],
                        [3.033, 1.296],
                        [0, 3.075],
                        [-1.751, 1.303],
                        [-2.748, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -3.218],
                        [-1.837, 0],
                        [0, 1.111],
                        [4.841, 1.979],
                        [0, 4.855],
                        [-6.521, 0],
                        [0, 0],
                        [-0.057, 0.47],
                        [0, 1.253],
                        [1.046, 0.925],
                        [1.879, 0],
                        [0, -1.068],
                        [-4.57, -1.965],
                        [0, -2.036],
                        [1.751, -1.303],
                        [4.883, 0],
                      ],
                      v: [
                        [-1103.221, 68.032],
                        [-1107.001, 68.694],
                        [-1111.486, 63.868],
                        [-1114.241, 66.131],
                        [-1109.5, 69.719],
                        [-1102.239, 77.258],
                        [-1111.486, 84.54],
                        [-1121.267, 77.258],
                        [-1117.252, 76.446],
                        [-1117.337, 77.77],
                        [-1115.768, 81.038],
                        [-1112.062, 82.426],
                        [-1109.244, 80.034],
                        [-1113.792, 76.489],
                        [-1120.648, 68.929],
                        [-1118.021, 63.921],
                        [-1111.272, 61.967],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 8,
          op: 10,
          st: 8,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Layer 12",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.494, 1.936],
                        [0, 0],
                        [0.569, 0],
                        [0, -1.784],
                        [-0.797, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.569, -0.151],
                        [-2.163, 0],
                        [0, 1.062],
                        [1.366, 0],
                      ],
                      v: [
                        [-1029.536, 74.984],
                        [-1029.309, 74.111],
                        [-1030.903, 73.883],
                        [-1034.28, 76.692],
                        [-1032.876, 78.324],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.566],
                        [-5.844, 0],
                        [-1.556, -0.456],
                        [0, 0],
                        [0, 0.342],
                        [2.163, 0],
                        [1.404, -0.531],
                        [0, 0],
                        [-2.998, 0],
                        [0, -4.137],
                        [0.266, -1.024],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.391, 0],
                      ],
                      o: [
                        [0, -4.364],
                        [1.556, 0],
                        [0, 0],
                        [0.076, -0.266],
                        [0, -1.29],
                        [-1.897, 0],
                        [0, 0],
                        [2.239, -0.683],
                        [6.869, 0],
                        [0, 0.873],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.859, 1.556],
                        [-3.188, 0],
                      ],
                      v: [
                        [-1042.591, 77.565],
                        [-1033.331, 70.354],
                        [-1028.626, 71.037],
                        [-1028.55, 70.81],
                        [-1028.398, 69.785],
                        [-1031.586, 67.774],
                        [-1037.24, 68.684],
                        [-1038.379, 62.84],
                        [-1029.84, 61.74],
                        [-1019.784, 68.494],
                        [-1020.163, 71.378],
                        [-1023.199, 82.763],
                        [-1031.813, 82.763],
                        [-1031.244, 80.676],
                        [-1037.051, 83.219],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.339, -0.151],
                        [0, 0],
                        [0, 0],
                        [1.176, -4.554],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [1.936, -2.809],
                        [0, 0],
                        [0, 0],
                        [-4.061, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1054.281, 62.043],
                        [-1045.667, 62.043],
                        [-1046.767, 66.218],
                        [-1039.102, 61.74],
                        [-1041.531, 70.885],
                        [-1042.441, 70.885],
                        [-1049.841, 77.602],
                        [-1051.207, 82.763],
                        [-1059.822, 82.763],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.794],
                        [-0.266, 1.062],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.417],
                        [-1.214, 0],
                        [-1.214, 0.342],
                        [0, 0],
                        [1.442, 0],
                      ],
                      o: [
                        [0, -0.873],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.189, 0.683],
                        [0, 0.873],
                        [0.683, 0],
                        [0, 0],
                        [-1.48, 0.379],
                        [-4.857, 0],
                      ],
                      v: [
                        [-1073.523, 77.565],
                        [-1073.105, 74.68],
                        [-1071.512, 68.684],
                        [-1073.94, 68.684],
                        [-1072.157, 62.043],
                        [-1069.728, 62.043],
                        [-1068.324, 56.844],
                        [-1059.709, 56.844],
                        [-1061.114, 62.043],
                        [-1056.332, 62.043],
                        [-1058.116, 68.684],
                        [-1062.897, 68.684],
                        [-1064.074, 73.124],
                        [-1064.339, 74.718],
                        [-1062.594, 76.046],
                        [-1060.089, 75.591],
                        [-1061.948, 82.612],
                        [-1066.312, 83.219],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.871],
                        [-0.341, 1.252],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.417],
                        [-1.252, 0],
                        [-0.607, 2.238],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.581, 0],
                      ],
                      o: [
                        [0, -1.062],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.151, 0.531],
                        [0, 1.101],
                        [1.708, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.366, 1.67],
                        [-3.795, 0],
                      ],
                      v: [
                        [-1099.141, 76.919],
                        [-1098.648, 73.428],
                        [-1095.612, 62.043],
                        [-1086.997, 62.043],
                        [-1089.768, 72.366],
                        [-1089.995, 73.921],
                        [-1088.174, 75.743],
                        [-1084.948, 72.366],
                        [-1082.178, 62.043],
                        [-1073.564, 62.043],
                        [-1079.104, 82.763],
                        [-1087.719, 82.763],
                        [-1087.035, 80.183],
                        [-1093.221, 83.256],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.542, 1.936],
                        [0, 0],
                        [-2.353, 0],
                        [0, 0.379],
                        [2.581, 0.949],
                        [0, 3.264],
                        [-6.224, 0],
                        [-2.087, -1.594],
                        [0, 0],
                        [2.049, 0],
                        [0, -0.418],
                        [-2.542, -0.91],
                        [0, -3.643],
                        [6.982, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.859, 1.48],
                        [0.873, 0],
                        [0, -0.684],
                        [-3.34, -1.214],
                        [0, -4.136],
                        [4.174, 0],
                        [0, 0],
                        [-1.594, -1.101],
                        [-0.949, 0],
                        [0, 0.569],
                        [3.301, 1.177],
                        [0, 3.453],
                        [-4.706, 0],
                      ],
                      v: [
                        [-1121.267, 79.803],
                        [-1117.472, 74.869],
                        [-1110.034, 77.678],
                        [-1108.82, 76.957],
                        [-1112.045, 74.984],
                        [-1117.7, 68.988],
                        [-1107.985, 61.55],
                        [-1098.194, 64.396],
                        [-1101.989, 69.329],
                        [-1108.288, 67.128],
                        [-1109.655, 67.812],
                        [-1106.656, 69.519],
                        [-1100.775, 75.781],
                        [-1110.186, 83.256],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 10,
          op: 12,
          st: 10,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Layer 11",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.566, 0.372],
                        [0.981, 0.029],
                        [1.107, -1.928],
                        [0.027, -3.299],
                        [-0.401, -0.643],
                        [-0.802, 0.03],
                        [-0.651, 0.657],
                        [-0.457, 0.743],
                        [-0.027, 0.114],
                      ],
                      o: [
                        [0, -0.029],
                        [-0.567, -0.329],
                        [-1.619, 0.029],
                        [-1.162, 1.943],
                        [0, 1.485],
                        [0.387, 0.657],
                        [0.761, 0],
                        [0.65, -0.543],
                        [0.968, -1.813],
                        [0, 0],
                      ],
                      v: [
                        [-1026.623, 67.045],
                        [-1027.472, 66.445],
                        [-1029.793, 65.91],
                        [-1033.882, 68.844],
                        [-1035.667, 76.707],
                        [-1035.066, 79.898],
                        [-1033.282, 80.84],
                        [-1031.165, 79.855],
                        [-1029.505, 77.927],
                        [-1028.013, 75.035],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.967, -1.34],
                        [0.829, -0.413],
                        [1.188, -0.029],
                        [0.76, 0.285],
                        [0.65, 0.613],
                        [0.028, 2.923],
                        [-2.211, 2.823],
                        [-3.192, 0.029],
                        [-0.843, -0.583],
                        [0, -0.029],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0.086],
                        [-0.498, 0.556],
                        [-0.842, 0.456],
                        [-0.858, 0],
                        [-0.788, -0.285],
                        [-1.341, -1.198],
                        [0.028, -5.061],
                        [2.128, -2.837],
                        [1.727, 0.057],
                        [0.857, 0.67],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1023.741, 84.945],
                        [-1028.404, 84.945],
                        [-1028.529, 81.054],
                        [-1029.979, 83.193],
                        [-1031.969, 84.646],
                        [-1035.015, 85.373],
                        [-1037.441, 84.945],
                        [-1039.598, 83.598],
                        [-1041.651, 77.417],
                        [-1038.293, 65.591],
                        [-1030.313, 61.293],
                        [-1026.458, 62.253],
                        [-1025.173, 63.301],
                        [-1024.032, 61.739],
                        [-1019.784, 61.739],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.513, 0.229],
                        [0.584, 0.029],
                        [0.613, -0.47],
                        [0.442, -0.697],
                        [0, -0.114],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.756, 1.369],
                        [-0.713, 0.413],
                        [-1.041, 0.029],
                        [-0.499, -0.214],
                        [-0.029, 0],
                      ],
                      o: [
                        [-0.029, 0],
                        [-0.556, -0.157],
                        [-0.855, 0.029],
                        [-0.67, 0.499],
                        [-0.898, 1.612],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.029, -0.086],
                        [0.371, -0.613],
                        [0.699, -0.413],
                        [0.827, 0.029],
                        [0.485, 0.285],
                        [0, 0],
                      ],
                      v: [
                        [-1042.551, 67.643],
                        [-1043.364, 67.3],
                        [-1045.075, 67.022],
                        [-1047.277, 67.77],
                        [-1048.945, 69.567],
                        [-1050.293, 72.155],
                        [-1052.517, 84.945],
                        [-1058.654, 84.945],
                        [-1054.591, 61.742],
                        [-1050.293, 61.742],
                        [-1049.715, 65.677],
                        [-1048.625, 63.495],
                        [-1046.999, 61.956],
                        [-1044.39, 61.293],
                        [-1042.401, 61.656],
                        [-1041.632, 62.083],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.166, -0.358],
                        [-0.556, 0],
                        [-1.083, 0.285],
                        [-0.057, 0.03],
                        [0, 0],
                        [1.141, -0.255],
                        [1.461, -0.029],
                        [0.732, 1.313],
                        [-0.373, 2.124],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.138, 0.842],
                        [0.143, 0.371],
                        [0.642, 0],
                        [1.112, -0.214],
                        [0, 0],
                        [-0.029, 0.043],
                        [-1.112, 0.284],
                        [-2.182, -0.029],
                        [-0.773, -1.283],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1060.856, 65.846],
                        [-1065.352, 65.846],
                        [-1067.466, 78.185],
                        [-1067.424, 79.985],
                        [-1066.376, 80.541],
                        [-1063.788, 80.113],
                        [-1062.035, 79.748],
                        [-1062.869, 84.457],
                        [-1064.622, 84.905],
                        [-1068.481, 85.373],
                        [-1072.851, 83.361],
                        [-1073.452, 78.25],
                        [-1071.298, 65.846],
                        [-1075.109, 65.846],
                        [-1074.425, 61.742],
                        [-1070.613, 61.742],
                        [-1069.287, 56.609],
                        [-1063.445, 54.727],
                        [-1064.667, 61.742],
                        [-1059.571, 61.742],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.215, -1.461],
                        [0.898, -0.468],
                        [1.188, -0.029],
                        [-0.898, 5.361],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.086, -0.67],
                        [-0.76, -0.027],
                        [-0.76, 0.643],
                        [-0.539, 0.785],
                        [-0.027, 0.114],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0.113],
                        [-0.594, 0.611],
                        [-0.912, 0.482],
                        [-4.489, -0.029],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.143, 0.8],
                        [0.084, 0.728],
                        [0.883, 0],
                        [0.731, -0.556],
                        [1.105, -1.87],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1082.773, 84.946],
                        [-1087.228, 84.946],
                        [-1087.539, 80.626],
                        [-1089.362, 82.988],
                        [-1091.599, 84.607],
                        [-1094.748, 85.373],
                        [-1100.135, 77.289],
                        [-1097.504, 61.742],
                        [-1091.56, 61.742],
                        [-1094.186, 77.073],
                        [-1094.271, 79.278],
                        [-1093.005, 80.413],
                        [-1090.54, 79.449],
                        [-1088.634, 77.436],
                        [-1086.936, 74.46],
                        [-1084.781, 61.742],
                        [-1078.836, 61.742],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.255, 0.501],
                        [1.341, 0.029],
                        [0.584, -0.258],
                        [0, -0.8],
                        [-0.692, -0.385],
                        [-0.545, -0.242],
                        [0, 0],
                        [-0.513, -0.342],
                        [-0.374, -0.472],
                        [-0.194, -0.643],
                        [0, -0.872],
                        [1.604, -1.143],
                        [2.449, 0],
                        [1.463, 0.512],
                        [0.028, 0.029],
                        [0, 0],
                        [-1.576, -0.558],
                        [-1.306, -0.029],
                        [-0.611, 0.285],
                        [-0.028, 0.785],
                        [0.4, 0.385],
                        [0.697, 0.299],
                        [0, 0],
                        [0.483, 0.358],
                        [0.482, 0.47],
                        [0.317, 0.7],
                        [0, 0.942],
                        [-1.786, 1.143],
                        [-1.069, 0.228],
                        [-1.097, 0],
                        [-0.858, -0.17],
                        [-0.605, -0.226],
                        [-0.028, -0.029],
                      ],
                      o: [
                        [-0.058, -0.029],
                        [-1.227, -0.487],
                        [-0.942, 0],
                        [-0.671, 0.286],
                        [0.029, 0.701],
                        [0.633, 0.372],
                        [0, 0],
                        [0.541, 0.271],
                        [0.471, 0.358],
                        [0.402, 0.472],
                        [0.194, 0.643],
                        [-0.028, 2.872],
                        [-1.618, 1.101],
                        [-2.223, -0.057],
                        [-1.534, -0.554],
                        [0, 0],
                        [0.056, 0.03],
                        [1.476, 0.558],
                        [0.71, 0.03],
                        [0.611, -0.299],
                        [0, -0.67],
                        [-0.43, -0.315],
                        [0, 0],
                        [-0.372, -0.2],
                        [-0.538, -0.314],
                        [-0.469, -0.529],
                        [-0.372, -0.699],
                        [0.071, -2.826],
                        [0.829, -0.613],
                        [0.999, -0.242],
                        [1.097, 0],
                        [0.872, 0.183],
                        [1.266, 0.58],
                        [0, 0],
                      ],
                      v: [
                        [-1104.245, 67.777],
                        [-1106.213, 66.982],
                        [-1110.065, 66.209],
                        [-1112.354, 66.595],
                        [-1113.36, 68.224],
                        [-1112.279, 69.853],
                        [-1110.512, 70.775],
                        [-1108.879, 71.59],
                        [-1107.298, 72.511],
                        [-1106.03, 73.755],
                        [-1105.136, 75.426],
                        [-1104.845, 77.699],
                        [-1107.294, 83.721],
                        [-1113.394, 85.373],
                        [-1118.924, 84.519],
                        [-1121.267, 83.644],
                        [-1120.394, 78.693],
                        [-1117.945, 79.575],
                        [-1113.771, 80.455],
                        [-1111.791, 80.07],
                        [-1110.833, 78.443],
                        [-1111.434, 76.859],
                        [-1113.126, 75.938],
                        [-1114.706, 75.189],
                        [-1115.989, 74.353],
                        [-1117.52, 73.176],
                        [-1118.7, 71.334],
                        [-1119.259, 68.873],
                        [-1116.474, 62.919],
                        [-1113.626, 61.656],
                        [-1110.483, 61.293],
                        [-1107.551, 61.547],
                        [-1105.336, 62.161],
                        [-1103.395, 63.073],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 12,
          op: 14,
          st: 12,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Layer 10",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.238, -0.736],
                        [0, -1.381],
                        [-0.207, -0.46],
                        [-0.614, 0],
                        [-0.176, 0.36],
                        [0, 1.534],
                      ],
                      o: [
                        [-1.488, 1.089],
                        [-0.238, 0.736],
                        [0, 1.58],
                        [0.207, 0.46],
                        [0.583, 0],
                        [0.176, -0.36],
                        [0, 0],
                      ],
                      v: [
                        [-1030.188, 72.558],
                        [-1032.777, 75.296],
                        [-1033.134, 78.472],
                        [-1032.823, 81.532],
                        [-1031.592, 82.223],
                        [-1030.453, 81.681],
                        [-1030.188, 78.84],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.545, 1.281],
                        [-1.641, 0.982],
                        [-2.623, 0],
                        [-1.595, -1.112],
                        [-0.322, -1.618],
                        [0, -5.047],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.897, -0.547],
                        [1.243, 0],
                        [1.358, 0.913],
                        [0, 3.083],
                        [0, 0],
                        [-0.721, 0.828],
                        [-2.853, 1.104],
                        [-0.215, 0.414],
                        [0, 1.273],
                        [0.238, 0.483],
                        [0.552, 0],
                        [0.153, -0.407],
                        [0, -1.703],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, -2.362],
                        [0.545, -1.281],
                        [1.641, -0.982],
                        [3.145, 0],
                        [1.595, 1.112],
                        [0.322, 1.618],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.568, 1.09],
                        [-0.897, 0.545],
                        [-1.626, 0],
                        [-1.358, -0.913],
                        [0, 0],
                        [0, -2.286],
                        [0.721, -0.828],
                        [3.053, -1.197],
                        [0.215, -0.414],
                        [0, -1.595],
                        [-0.238, -0.483],
                        [-0.629, 0],
                        [-0.153, 0.407],
                        [0, 0],
                      ],
                      v: [
                        [-1033.134, 68.37],
                        [-1041.855, 68.37],
                        [-1041.855, 66.322],
                        [-1041.038, 60.856],
                        [-1037.759, 57.463],
                        [-1031.362, 55.991],
                        [-1024.252, 57.658],
                        [-1021.375, 61.754],
                        [-1020.892, 71.753],
                        [-1020.892, 87.078],
                        [-1029.935, 87.078],
                        [-1029.935, 84.356],
                        [-1032.133, 86.812],
                        [-1035.343, 87.63],
                        [-1039.818, 86.26],
                        [-1041.855, 80.267],
                        [-1041.855, 77.759],
                        [-1040.773, 73.087],
                        [-1035.412, 70.188],
                        [-1030.51, 67.772],
                        [-1030.188, 65.241],
                        [-1030.545, 62.122],
                        [-1031.73, 61.398],
                        [-1032.904, 62.007],
                        [-1033.134, 65.172],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-2.562, 0.169],
                        [0, 0],
                        [0.798, -0.46],
                        [0.184, -0.821],
                        [0, -2.961],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.35, -2.875],
                        [0, 0],
                        [-1.703, 0],
                        [-0.798, 0.46],
                        [-0.184, 0.821],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1049.011, 56.543],
                        [-1049.379, 60.557],
                        [-1043.511, 55.991],
                        [-1043.511, 66.737],
                        [-1047.262, 67.427],
                        [-1048.735, 69.347],
                        [-1049.011, 75.02],
                        [-1049.011, 87.078],
                        [-1058.307, 87.078],
                        [-1058.307, 56.543],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.207, -0.23],
                        [-1.519, 0],
                        [0, 0],
                        [0, 0],
                        [0.905, 0.176],
                        [0.69, 0.637],
                        [0.169, 0.821],
                        [0, 3.037],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.01],
                        [0.207, 0.23],
                        [0, 0],
                        [0, 0],
                        [-2.117, 0],
                        [-0.905, -0.176],
                        [-0.69, -0.637],
                        [-0.169, -0.821],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1062.863, 52.47],
                        [-1062.863, 57.279],
                        [-1060.355, 57.279],
                        [-1060.355, 62.112],
                        [-1062.863, 62.112],
                        [-1062.863, 78.449],
                        [-1062.552, 81.808],
                        [-1059.964, 82.154],
                        [-1059.964, 87.078],
                        [-1063.714, 87.078],
                        [-1068.247, 86.812],
                        [-1070.641, 85.592],
                        [-1071.929, 83.406],
                        [-1072.182, 77.621],
                        [-1072.182, 62.112],
                        [-1074.184, 62.112],
                        [-1074.184, 57.279],
                        [-1072.182, 57.279],
                        [-1072.182, 52.47],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.943, -0.516],
                        [1.227, 0],
                        [0.92, 0.491],
                        [0.437, 0.813],
                        [0.107, 0.882],
                        [0, 2.623],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.146, -0.445],
                        [-0.644, 0],
                        [-0.13, 0.46],
                        [0, 2.5],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.644, 1.029],
                        [-0.943, 0.514],
                        [-1.396, 0],
                        [-0.92, -0.491],
                        [-0.437, -0.813],
                        [-0.107, -0.882],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.378],
                        [0.146, 0.445],
                        [0.69, 0],
                        [0.13, -0.46],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1076.002, 56.543],
                        [-1076.002, 87.078],
                        [-1085.459, 87.078],
                        [-1085.298, 84.54],
                        [-1087.68, 86.858],
                        [-1090.936, 87.63],
                        [-1094.41, 86.894],
                        [-1096.447, 84.938],
                        [-1097.263, 82.394],
                        [-1097.425, 77.137],
                        [-1097.425, 56.543],
                        [-1088.128, 56.543],
                        [-1088.128, 77.321],
                        [-1087.91, 81.555],
                        [-1086.725, 82.223],
                        [-1085.494, 81.532],
                        [-1085.298, 77.091],
                        [-1085.298, 56.543],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.161, 0.376],
                        [0.644, 0],
                        [0.261, -0.345],
                        [0, -0.69],
                        [-0.13, -0.437],
                        [-0.652, -0.522],
                        [-2.025, -0.997],
                        [-0.844, -1.166],
                        [0, -2.224],
                        [0.644, -1.266],
                        [1.511, -0.683],
                        [2.132, 0],
                        [1.68, 0.736],
                        [0.629, 1.258],
                        [0, 2.546],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.207, -0.491],
                        [-0.614, 0],
                        [-0.261, 0.33],
                        [0, 1.058],
                        [0.338, 0.368],
                        [3.252, 1.81],
                        [0.598, 1.25],
                        [0, 1.718],
                        [-0.644, 1.158],
                        [-1.542, 0.629],
                        [-2.04, 0],
                        [-1.419, -0.514],
                        [-0.759, -0.844],
                        [-0.161, -0.721],
                        [0, -1.534],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, -1.411],
                        [-0.161, -0.376],
                        [-0.522, 0],
                        [-0.261, 0.345],
                        [0, 0.936],
                        [0.13, 0.437],
                        [0.652, 0.522],
                        [2.7, 1.319],
                        [0.844, 1.166],
                        [0, 2.485],
                        [-0.644, 1.266],
                        [-1.511, 0.683],
                        [-2.362, 0],
                        [-1.68, -0.736],
                        [-0.629, -1.258],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.641],
                        [0.207, 0.491],
                        [0.66, 0],
                        [0.261, -0.33],
                        [0, -1.457],
                        [-0.353, -0.368],
                        [-2.731, -1.534],
                        [-0.598, -1.25],
                        [0, -2.439],
                        [0.644, -1.158],
                        [1.542, -0.629],
                        [2.025, 0],
                        [1.419, 0.514],
                        [0.759, 0.844],
                        [0.161, 0.721],
                        [0, 0],
                      ],
                      v: [
                        [-1100.485, 66.299],
                        [-1108.723, 66.299],
                        [-1108.723, 64.643],
                        [-1108.964, 61.961],
                        [-1110.172, 61.398],
                        [-1111.346, 61.915],
                        [-1111.737, 63.469],
                        [-1111.541, 65.527],
                        [-1110.368, 66.967],
                        [-1106.353, 69.245],
                        [-1101.037, 72.972],
                        [-1099.772, 78.058],
                        [-1100.738, 83.682],
                        [-1103.971, 86.605],
                        [-1109.436, 87.63],
                        [-1115.499, 86.526],
                        [-1118.962, 83.534],
                        [-1119.906, 77.828],
                        [-1119.906, 76.355],
                        [-1111.668, 76.355],
                        [-1111.668, 78.288],
                        [-1111.357, 81.486],
                        [-1110.126, 82.223],
                        [-1108.746, 81.727],
                        [-1108.354, 79.645],
                        [-1108.861, 76.907],
                        [-1114.268, 73.64],
                        [-1119.261, 69.462],
                        [-1120.159, 65.011],
                        [-1119.192, 59.614],
                        [-1115.913, 56.934],
                        [-1110.54, 55.991],
                        [-1105.375, 56.76],
                        [-1102.107, 58.798],
                        [-1100.726, 61.145],
                        [-1100.485, 64.528],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 14,
          op: 16,
          st: 14,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Layer 9",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.611, 0.609],
                        [0.877, 0],
                        [0.626, -0.612],
                        [0, -0.876],
                        [-0.627, -0.626],
                        [-0.909, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.876],
                        [-0.61, -0.612],
                        [-0.909, 0],
                        [-0.627, 0.609],
                        [0, 0.91],
                        [0.626, 0.626],
                        [0, 0],
                      ],
                      v: [
                        [-1028.362, 77.168],
                        [-1028.362, 73.926],
                        [-1029.278, 71.695],
                        [-1031.51, 70.778],
                        [-1033.811, 71.695],
                        [-1034.751, 73.926],
                        [-1033.811, 76.228],
                        [-1031.51, 77.168],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.735, 0.737],
                        [0, 1.097],
                        [-0.736, 0.72],
                        [-1.097, 0],
                        [-0.737, -0.721],
                        [0, -1.063],
                        [0, 0],
                      ],
                      o: [
                        [-1.097, 0],
                        [-0.736, -0.735],
                        [0, -1.063],
                        [0.735, -0.721],
                        [1.065, 0],
                        [0.735, 0.72],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1031.51, 77.779],
                        [-1034.258, 76.674],
                        [-1035.362, 73.926],
                        [-1034.258, 71.249],
                        [-1031.51, 70.169],
                        [-1028.808, 71.249],
                        [-1027.704, 73.926],
                        [-1027.704, 77.779],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.861, 0.845],
                        [1.222, 0],
                        [0.846, -0.846],
                        [0, -1.221],
                        [-0.846, -0.877],
                        [-1.285, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -1.221],
                        [-0.861, -0.846],
                        [-1.285, 0],
                        [-0.846, 0.845],
                        [0, 1.254],
                        [0.846, 0.877],
                        [0, 0],
                      ],
                      v: [
                        [-1027.093, 78.437],
                        [-1027.093, 73.926],
                        [-1028.386, 70.826],
                        [-1031.51, 69.557],
                        [-1034.704, 70.826],
                        [-1035.973, 73.926],
                        [-1034.704, 77.122],
                        [-1031.51, 78.437],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.285, 0],
                        [-1.144, -0.486],
                        [-0.846, -0.845],
                        [-0.486, -1.144],
                        [0, -1.283],
                        [0, 0],
                        [0, 0],
                        [1.143, 0.502],
                        [0.846, 0.845],
                        [0.501, 1.144],
                        [0, 1.315],
                        [-0.501, 1.143],
                        [-0.846, 0.846],
                        [-1.143, 0.487],
                      ],
                      o: [
                        [1.316, 0],
                        [1.143, 0.487],
                        [0.846, 0.846],
                        [0.486, 1.143],
                        [0, 0],
                        [0, 0],
                        [-1.285, 0],
                        [-1.143, -0.5],
                        [-0.846, -0.845],
                        [-0.501, -1.142],
                        [0, -1.283],
                        [0.501, -1.144],
                        [0.846, -0.845],
                        [1.143, -0.486],
                      ],
                      v: [
                        [-1031.51, 64.577],
                        [-1027.822, 65.306],
                        [-1024.838, 67.301],
                        [-1022.842, 70.286],
                        [-1022.114, 73.926],
                        [-1022.114, 83.369],
                        [-1031.51, 83.369],
                        [-1035.151, 82.617],
                        [-1038.134, 80.597],
                        [-1040.154, 77.614],
                        [-1040.905, 73.926],
                        [-1040.154, 70.286],
                        [-1038.134, 67.301],
                        [-1035.151, 65.306],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 4,
                  ty: "sh",
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.378, 0],
                        [1.205, -0.531],
                        [0.908, -0.909],
                        [0.532, -1.204],
                        [0, -1.376],
                        [-0.533, -1.221],
                        [-0.909, -0.909],
                        [-1.206, -0.531],
                        [-1.378, 0],
                        [0, 0],
                        [0, 0],
                        [0.517, 1.207],
                        [0.908, 0.909],
                        [1.222, 0.534],
                      ],
                      o: [
                        [-1.378, 0],
                        [-1.206, 0.534],
                        [-0.909, 0.909],
                        [-0.533, 1.207],
                        [0, 1.379],
                        [0.532, 1.221],
                        [0.908, 0.909],
                        [1.205, 0.534],
                        [0, 0],
                        [0, 0],
                        [0, -1.376],
                        [-0.517, -1.204],
                        [-0.909, -0.909],
                        [-1.222, -0.531],
                      ],
                      v: [
                        [-1031.51, 63.919],
                        [-1035.385, 64.718],
                        [-1038.557, 66.879],
                        [-1040.717, 70.05],
                        [-1041.516, 73.926],
                        [-1040.717, 77.825],
                        [-1038.557, 81.02],
                        [-1035.385, 83.18],
                        [-1031.51, 83.981],
                        [-1021.503, 83.981],
                        [-1021.503, 73.926],
                        [-1022.278, 70.05],
                        [-1024.416, 66.879],
                        [-1027.61, 64.718],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 5",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 5,
                  ty: "sh",
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.473, 0],
                        [-1.3, -0.548],
                        [-0.956, -0.97],
                        [-0.549, -1.283],
                        [0, -1.471],
                        [0, 0],
                        [0, 0],
                        [1.299, 0.548],
                        [0.955, 0.971],
                        [0.564, 1.3],
                        [0, 1.473],
                        [-0.564, 1.285],
                        [-0.956, 0.971],
                        [-1.3, 0.548],
                      ],
                      o: [
                        [1.472, 0],
                        [1.299, 0.548],
                        [0.955, 0.971],
                        [0.547, 1.285],
                        [0, 0],
                        [0, 0],
                        [-1.473, 0],
                        [-1.3, -0.548],
                        [-0.956, -0.971],
                        [-0.564, -1.299],
                        [0, -1.471],
                        [0.564, -1.283],
                        [0.955, -0.97],
                        [1.299, -0.548],
                      ],
                      v: [
                        [-1031.51, 63.309],
                        [-1027.352, 64.131],
                        [-1023.969, 66.409],
                        [-1021.714, 69.791],
                        [-1020.892, 73.926],
                        [-1020.892, 84.59],
                        [-1031.51, 84.59],
                        [-1035.667, 83.769],
                        [-1039.05, 81.49],
                        [-1041.328, 78.084],
                        [-1042.174, 73.926],
                        [-1041.328, 69.791],
                        [-1039.05, 66.409],
                        [-1035.667, 64.131],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 7,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.799, -0.846],
                        [0, -1.221],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.47, 1.143],
                        [-0.798, 0.846],
                        [-1.065, 0.487],
                        [-1.222, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-1.159, 0],
                        [-0.798, 0.845],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.283],
                        [0.47, -1.144],
                        [0.799, -0.845],
                        [1.065, -0.486],
                        [0, 0],
                      ],
                      v: [
                        [-1044.899, 64.577],
                        [-1044.899, 69.557],
                        [-1047.294, 69.557],
                        [-1050.231, 70.826],
                        [-1051.429, 73.926],
                        [-1051.429, 83.369],
                        [-1056.127, 83.369],
                        [-1056.127, 73.926],
                        [-1055.422, 70.286],
                        [-1053.519, 67.301],
                        [-1050.724, 65.306],
                        [-1047.294, 64.577],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.143, -0.531],
                        [0.846, -0.909],
                        [0.501, -1.204],
                        [0, -1.376],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.674, 0.72],
                        [-1.002, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-1.285, 0],
                        [-1.143, 0.534],
                        [-0.846, 0.909],
                        [-0.501, 1.207],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.063],
                        [0.673, -0.721],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1047.294, 63.919],
                        [-1050.935, 64.718],
                        [-1053.918, 66.879],
                        [-1055.939, 70.05],
                        [-1056.69, 73.926],
                        [-1056.69, 83.981],
                        [-1050.818, 83.981],
                        [-1050.818, 73.926],
                        [-1049.808, 71.249],
                        [-1047.294, 70.169],
                        [-1044.335, 70.169],
                        [-1044.335, 63.919],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.579, -0.612],
                        [0, -0.876],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.533, 1.285],
                        [-0.909, 0.971],
                        [-1.206, 0.548],
                        [-1.378, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.814, 0],
                        [-0.58, 0.609],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.471],
                        [0.532, -1.283],
                        [0.908, -0.97],
                        [1.205, -0.548],
                        [0, 0],
                      ],
                      v: [
                        [-1043.771, 63.309],
                        [-1043.771, 70.778],
                        [-1047.294, 70.778],
                        [-1049.385, 71.695],
                        [-1050.254, 73.926],
                        [-1050.254, 84.59],
                        [-1057.301, 84.59],
                        [-1057.301, 73.926],
                        [-1056.502, 69.791],
                        [-1054.341, 66.409],
                        [-1051.17, 64.131],
                        [-1047.294, 63.309],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.064, 0.516],
                        [0.798, 0.878],
                        [0.47, 1.175],
                        [0, 1.348],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.798, -0.892],
                        [-1.191, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-1.222, 0],
                        [-1.065, -0.516],
                        [-0.798, -0.876],
                        [-0.47, -1.175],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.285],
                        [0.799, 0.893],
                        [0, 0],
                      ],
                      v: [
                        [-1060.354, 78.201],
                        [-1060.354, 83.323],
                        [-1062.703, 83.323],
                        [-1066.133, 82.547],
                        [-1068.928, 80.456],
                        [-1070.831, 77.379],
                        [-1071.535, 73.597],
                        [-1071.535, 56.779],
                        [-1066.885, 56.779],
                        [-1066.885, 67.49],
                        [-1060.354, 67.49],
                        [-1060.354, 72.61],
                        [-1066.885, 72.61],
                        [-1066.885, 73.597],
                        [-1065.687, 76.862],
                        [-1062.703, 78.201],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.688, 0.784],
                        [0, 1.097],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.501, -1.253],
                        [-0.846, -0.939],
                        [-1.144, -0.548],
                        [-1.285, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-1.003, 0],
                        [-0.689, -0.782],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.409],
                        [0.501, 1.253],
                        [0.846, 0.941],
                        [1.143, 0.548],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1062.703, 77.591],
                        [-1065.24, 76.416],
                        [-1066.273, 73.597],
                        [-1066.273, 73.268],
                        [-1059.791, 73.268],
                        [-1059.791, 66.879],
                        [-1066.273, 66.879],
                        [-1066.273, 56.168],
                        [-1072.099, 56.168],
                        [-1072.099, 73.597],
                        [-1071.347, 77.591],
                        [-1069.328, 80.879],
                        [-1066.344, 83.111],
                        [-1062.703, 83.932],
                        [-1059.791, 83.932],
                        [-1059.791, 77.591],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.206, 0.581],
                        [0.908, 0.986],
                        [0.533, 1.347],
                        [0, 1.504],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.549, -0.579],
                        [-0.815, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-1.378, 0],
                        [-1.206, -0.579],
                        [-0.909, -0.986],
                        [-0.533, -1.347],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.094, 0.877],
                        [0.547, 0.58],
                        [0, 0],
                      ],
                      v: [
                        [-1059.227, 76.933],
                        [-1059.227, 84.59],
                        [-1062.703, 84.59],
                        [-1066.579, 83.721],
                        [-1069.75, 81.373],
                        [-1071.911, 77.872],
                        [-1072.71, 73.597],
                        [-1072.71, 55.511],
                        [-1065.71, 55.511],
                        [-1065.71, 66.222],
                        [-1059.227, 66.222],
                        [-1059.227, 73.88],
                        [-1065.71, 73.88],
                        [-1064.747, 76.064],
                        [-1062.703, 76.933],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.501, -1.142],
                        [0.861, -0.845],
                        [1.143, -0.5],
                        [1.284, 0],
                        [1.143, 0.502],
                        [0.846, 0.845],
                        [0.486, 1.144],
                        [0, 1.315],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.846, -0.877],
                        [-1.253, 0],
                        [-0.862, 0.877],
                        [0, 1.254],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 1.315],
                        [-0.501, 1.144],
                        [-0.862, 0.845],
                        [-1.144, 0.502],
                        [-1.285, 0],
                        [-1.144, -0.5],
                        [-0.846, -0.845],
                        [-0.486, -1.142],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.254],
                        [0.846, 0.877],
                        [1.252, 0],
                        [0.861, -0.877],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1076.374, 64.577],
                        [-1076.374, 73.926],
                        [-1077.126, 77.614],
                        [-1079.17, 80.597],
                        [-1082.177, 82.617],
                        [-1085.818, 83.369],
                        [-1089.458, 82.617],
                        [-1092.441, 80.597],
                        [-1094.438, 77.614],
                        [-1095.167, 73.926],
                        [-1095.167, 64.577],
                        [-1090.233, 64.577],
                        [-1090.233, 73.926],
                        [-1088.965, 77.122],
                        [-1085.818, 78.437],
                        [-1082.646, 77.122],
                        [-1081.355, 73.926],
                        [-1081.355, 64.577],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.721, -0.735],
                        [1.096, 0],
                        [0.72, 0.737],
                        [0, 1.097],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.533, -1.221],
                        [-0.909, -0.909],
                        [-1.206, -0.531],
                        [-1.378, 0],
                        [-1.222, 0.534],
                        [-0.909, 0.909],
                        [-0.533, 1.221],
                        [0, 1.379],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 1.097],
                        [-0.721, 0.737],
                        [-1.097, 0],
                        [-0.721, -0.735],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.379],
                        [0.533, 1.221],
                        [0.908, 0.909],
                        [1.205, 0.534],
                        [1.378, 0],
                        [1.222, -0.531],
                        [0.908, -0.909],
                        [0.532, -1.221],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1082.012, 63.919],
                        [-1082.012, 73.926],
                        [-1083.093, 76.674],
                        [-1085.818, 77.779],
                        [-1088.542, 76.674],
                        [-1089.623, 73.926],
                        [-1089.623, 63.919],
                        [-1095.824, 63.919],
                        [-1095.824, 73.926],
                        [-1095.026, 77.825],
                        [-1092.864, 81.02],
                        [-1089.693, 83.18],
                        [-1085.818, 83.981],
                        [-1081.918, 83.18],
                        [-1078.724, 81.02],
                        [-1076.562, 77.825],
                        [-1075.764, 73.926],
                        [-1075.764, 63.919],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.564, -1.299],
                        [0.954, -0.971],
                        [1.299, -0.548],
                        [1.472, 0],
                        [1.284, 0.548],
                        [0.97, 0.971],
                        [0.547, 1.3],
                        [0, 1.473],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.61, -0.626],
                        [-0.909, 0],
                        [-0.61, 0.626],
                        [0, 0.91],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 1.473],
                        [-0.563, 1.3],
                        [-0.956, 0.971],
                        [-1.3, 0.548],
                        [-1.473, 0],
                        [-1.285, -0.548],
                        [-0.971, -0.971],
                        [-0.549, -1.299],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.91],
                        [0.611, 0.626],
                        [0.908, 0],
                        [0.61, -0.626],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1075.153, 63.309],
                        [-1075.153, 73.926],
                        [-1075.999, 78.084],
                        [-1078.277, 81.49],
                        [-1081.66, 83.769],
                        [-1085.818, 84.59],
                        [-1089.952, 83.769],
                        [-1093.334, 81.49],
                        [-1095.613, 78.084],
                        [-1096.435, 73.926],
                        [-1096.435, 63.309],
                        [-1089.012, 63.309],
                        [-1089.012, 73.926],
                        [-1088.096, 76.228],
                        [-1085.818, 77.168],
                        [-1083.539, 76.228],
                        [-1082.623, 73.926],
                        [-1082.623, 63.309],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.188, -0.172],
                        [0, -0.283],
                        [-0.188, -0.204],
                        [-0.251, 0],
                        [0, 0],
                        [-0.721, -0.312],
                        [-0.548, -0.533],
                        [-0.313, -0.72],
                        [0, -0.813],
                        [0.313, -0.735],
                        [0.548, -0.533],
                        [0.72, -0.314],
                        [0.814, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.188, 0.204],
                        [0, 0.283],
                        [0.187, 0.204],
                        [0.282, 0],
                        [0, 0],
                        [0.72, 0.298],
                        [0.532, 0.534],
                        [0.298, 0.721],
                        [0, 0.845],
                        [-0.298, 0.721],
                        [-0.533, 0.533],
                        [-0.721, 0.297],
                        [-0.815, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.251, 0],
                        [-0.188, 0.172],
                        [0, 0.25],
                        [0.188, 0.204],
                        [0, 0],
                        [0.814, 0],
                        [0.72, 0.314],
                        [0.548, 0.533],
                        [0.313, 0.721],
                        [0, 0.816],
                        [-0.313, 0.737],
                        [-0.548, 0.533],
                        [-0.721, 0.314],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.282, 0],
                        [0.187, -0.204],
                        [0, -0.25],
                        [-0.188, -0.204],
                        [0, 0],
                        [-0.815, 0],
                        [-0.721, -0.297],
                        [-0.533, -0.531],
                        [-0.298, -0.719],
                        [0, -0.846],
                        [0.298, -0.72],
                        [0.532, -0.533],
                        [0.72, -0.297],
                        [0, 0],
                      ],
                      v: [
                        [-1101.79, 64.577],
                        [-1101.79, 69.557],
                        [-1113.018, 69.557],
                        [-1113.675, 69.816],
                        [-1113.958, 70.497],
                        [-1113.675, 71.178],
                        [-1113.018, 71.484],
                        [-1106.064, 71.484],
                        [-1103.763, 71.953],
                        [-1101.86, 73.222],
                        [-1100.568, 75.101],
                        [-1100.098, 77.402],
                        [-1100.568, 79.728],
                        [-1101.86, 81.631],
                        [-1103.763, 82.9],
                        [-1106.064, 83.369],
                        [-1117.199, 83.369],
                        [-1117.199, 78.437],
                        [-1106.064, 78.437],
                        [-1105.36, 78.131],
                        [-1105.078, 77.402],
                        [-1105.36, 76.722],
                        [-1106.064, 76.416],
                        [-1113.018, 76.416],
                        [-1115.32, 75.97],
                        [-1117.199, 74.725],
                        [-1118.444, 72.845],
                        [-1118.89, 70.497],
                        [-1118.444, 68.147],
                        [-1117.199, 66.269],
                        [-1115.32, 65.024],
                        [-1113.018, 64.577],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.782, -0.344],
                        [0.594, -0.594],
                        [0.329, -0.798],
                        [0, -0.909],
                        [-0.329, -0.798],
                        [-0.596, -0.595],
                        [-0.784, -0.329],
                        [-0.909, 0],
                        [0, 0],
                        [0, -0.25],
                        [0.25, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.799, 0.344],
                        [-0.596, 0.595],
                        [-0.345, 0.798],
                        [0, 0.91],
                        [0.344, 0.798],
                        [0.594, 0.579],
                        [0.798, 0.344],
                        [0.908, 0],
                        [0, 0],
                        [0, 0.25],
                        [-0.22, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-0.909, 0],
                        [-0.784, 0.344],
                        [-0.596, 0.595],
                        [-0.329, 0.799],
                        [0, 0.907],
                        [0.329, 0.799],
                        [0.594, 0.595],
                        [0.782, 0.329],
                        [0, 0],
                        [0.25, 0],
                        [0, 0.252],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.908, 0],
                        [0.798, -0.344],
                        [0.594, -0.594],
                        [0.344, -0.799],
                        [0, -0.907],
                        [-0.345, -0.8],
                        [-0.596, -0.58],
                        [-0.799, -0.344],
                        [0, 0],
                        [-0.22, 0],
                        [0, -0.219],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1113.018, 63.919],
                        [-1115.554, 64.436],
                        [-1117.621, 65.846],
                        [-1119.007, 67.936],
                        [-1119.501, 70.497],
                        [-1119.007, 73.056],
                        [-1117.621, 75.148],
                        [-1115.554, 76.533],
                        [-1113.018, 77.027],
                        [-1106.064, 77.027],
                        [-1105.689, 77.402],
                        [-1106.064, 77.779],
                        [-1117.809, 77.779],
                        [-1117.809, 83.981],
                        [-1106.064, 83.981],
                        [-1103.504, 83.463],
                        [-1101.414, 82.054],
                        [-1100.004, 79.964],
                        [-1099.487, 77.402],
                        [-1100.004, 74.843],
                        [-1101.414, 72.776],
                        [-1103.504, 71.39],
                        [-1106.064, 70.872],
                        [-1113.018, 70.872],
                        [-1113.346, 70.497],
                        [-1113.018, 70.169],
                        [-1101.179, 70.169],
                        [-1101.179, 63.919],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.815, -1.19],
                        [0, -1.503],
                        [0.376, -0.877],
                        [0.642, -0.641],
                        [0.877, -0.375],
                        [1.002, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.83, 1.192],
                        [0, 1.534],
                        [-0.376, 0.877],
                        [-0.642, 0.642],
                        [-0.861, 0.375],
                        [-1.003, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [1.315, 0.534],
                        [0.814, 1.19],
                        [0, 1.004],
                        [-0.376, 0.877],
                        [-0.642, 0.642],
                        [-0.877, 0.376],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.347, -0.533],
                        [-0.83, -1.189],
                        [0, -1.003],
                        [0.376, -0.877],
                        [0.642, -0.642],
                        [0.861, -0.377],
                        [0, 0],
                      ],
                      v: [
                        [-1100.568, 63.309],
                        [-1100.568, 70.778],
                        [-1103.293, 70.778],
                        [-1100.098, 73.362],
                        [-1098.877, 77.402],
                        [-1099.441, 80.221],
                        [-1100.968, 82.5],
                        [-1103.246, 84.026],
                        [-1106.064, 84.59],
                        [-1118.468, 84.59],
                        [-1118.468, 77.168],
                        [-1115.649, 77.168],
                        [-1118.914, 74.583],
                        [-1120.159, 70.497],
                        [-1119.595, 67.678],
                        [-1118.068, 65.4],
                        [-1115.813, 63.873],
                        [-1113.018, 63.309],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 16,
          op: 18,
          st: 16,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "Layer 8",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.578, 0.599],
                        [0.837, 0],
                        [0.578, -0.598],
                        [0, -0.897],
                        [-0.579, -0.599],
                        [-0.837, 0],
                        [-0.578, 0.598],
                        [0, 0.897],
                      ],
                      o: [
                        [-0.578, -0.598],
                        [-0.837, 0],
                        [-0.579, 0.599],
                        [0, 0.897],
                        [0.578, 0.598],
                        [0.837, 0],
                        [0.578, -0.599],
                        [0, -0.897],
                      ],
                      v: [
                        [-1028.636, 70.2],
                        [-1030.76, 69.303],
                        [-1032.882, 70.2],
                        [-1033.749, 72.442],
                        [-1032.882, 74.685],
                        [-1030.76, 75.582],
                        [-1028.636, 74.685],
                        [-1027.769, 72.442],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.565, 1.605],
                        [0, 2.372],
                        [-1.565, 1.605],
                        [-2.253, 0],
                        [-0.767, -0.349],
                        [-0.299, -0.339],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.07, -0.09],
                        [0.279, -0.239],
                        [0.319, -0.19],
                        [0.548, -0.149],
                        [0.618, 0],
                      ],
                      o: [
                        [-1.565, -1.604],
                        [0, -2.372],
                        [1.565, -1.604],
                        [0.977, 0],
                        [0.767, 0.349],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.04, 0.06],
                        [-0.07, 0.09],
                        [-0.279, 0.239],
                        [-0.319, 0.189],
                        [-0.548, 0.149],
                        [-2.253, 0],
                      ],
                      v: [
                        [-1038.429, 78.407],
                        [-1040.776, 72.442],
                        [-1038.429, 66.477],
                        [-1032.703, 64.07],
                        [-1030.087, 64.594],
                        [-1028.487, 65.625],
                        [-1028.068, 66.164],
                        [-1027.769, 66.164],
                        [-1027.769, 64.668],
                        [-1020.892, 64.668],
                        [-1020.892, 80.217],
                        [-1027.769, 80.217],
                        [-1027.769, 78.721],
                        [-1028.068, 78.721],
                        [-1028.233, 78.946],
                        [-1028.756, 79.439],
                        [-1029.653, 80.082],
                        [-1030.954, 80.59],
                        [-1032.703, 80.814],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1056.622, 64.668],
                        [-1043.018, 64.668],
                        [-1043.018, 70.051],
                        [-1049.745, 70.051],
                        [-1049.745, 80.217],
                        [-1056.622, 80.217],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1071.124, 64.668],
                        [-1071.124, 59.286],
                        [-1064.247, 59.286],
                        [-1064.247, 64.668],
                        [-1060.36, 64.668],
                        [-1060.36, 70.051],
                        [-1064.247, 70.051],
                        [-1064.247, 80.217],
                        [-1071.124, 80.217],
                        [-1071.124, 70.051],
                        [-1075.011, 70.051],
                        [-1075.011, 64.668],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 1.895],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.448, -0.449],
                        [-0.598, 0],
                        [-0.498, 0.368],
                        [-0.219, 0.379],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.07, -0.09],
                        [0.279, -0.239],
                        [0.319, -0.19],
                        [0.548, -0.149],
                        [0.618, 0],
                        [1.355, 1.296],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.598],
                        [0.448, 0.449],
                        [0.578, 0],
                        [0.498, -0.369],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.04, 0.06],
                        [-0.07, 0.09],
                        [-0.279, 0.239],
                        [-0.319, 0.189],
                        [-0.549, 0.149],
                        [-2.073, 0],
                        [-1.356, -1.294],
                      ],
                      v: [
                        [-1097.734, 74.087],
                        [-1097.734, 64.668],
                        [-1090.857, 64.668],
                        [-1090.857, 73.339],
                        [-1090.185, 74.909],
                        [-1088.615, 75.582],
                        [-1087, 75.029],
                        [-1085.924, 73.907],
                        [-1085.625, 73.339],
                        [-1085.625, 64.668],
                        [-1078.748, 64.668],
                        [-1078.748, 80.217],
                        [-1085.625, 80.217],
                        [-1085.625, 78.721],
                        [-1085.924, 78.721],
                        [-1086.088, 78.946],
                        [-1086.611, 79.439],
                        [-1087.508, 80.082],
                        [-1088.809, 80.59],
                        [-1090.558, 80.814],
                        [-1095.701, 78.87],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1120.159, 80.217],
                        [-1120.159, 74.685],
                        [-1113.581, 74.685],
                        [-1113.581, 75.731],
                        [-1109.395, 75.731],
                        [-1109.395, 74.984],
                        [-1120.159, 72.442],
                        [-1120.159, 64.668],
                        [-1102.518, 64.668],
                        [-1102.518, 70.2],
                        [-1109.096, 70.2],
                        [-1109.096, 69.153],
                        [-1113.282, 69.153],
                        [-1113.282, 69.751],
                        [-1102.518, 72.292],
                        [-1102.518, 80.217],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 18,
          op: 20,
          st: 18,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: "Layer 7",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.244, 0.381],
                        [0.008, 0.108],
                        [4.155, 0],
                        [0, -0.649],
                        [-1.68, 0],
                      ],
                      o: [
                        [0.002, -0.142],
                        [-0.146, -0.14],
                        [-2.779, 0],
                        [0, 0.904],
                        [2.598, 0],
                      ],
                      v: [
                        [-1027.343, 73.995],
                        [-1027.343, 73.736],
                        [-1032.067, 73.082],
                        [-1035.88, 74.044],
                        [-1032.354, 75.088],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.003, 0.417],
                        [2.524, 0],
                        [0, 4.5],
                        [-4.556, 0],
                        [-1.338, -0.487],
                        [3.138, 0],
                        [0.026, -0.009],
                        [0, 0],
                        [-5.232, 0],
                        [-1.533, -3.502],
                        [0, -4.614],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.457],
                        [-1.357, 0.804],
                        [-3.795, 0],
                        [0, -3.16],
                        [2.467, 0],
                        [-0.719, -2.082],
                        [-4.661, 0],
                        [0, 0],
                        [0.129, -0.048],
                        [2.804, 0],
                        [1.031, 2.358],
                        [0, 0],
                      ],
                      v: [
                        [-1021.279, 78.45],
                        [-1026.947, 78.45],
                        [-1026.953, 77.146],
                        [-1033, 78.704],
                        [-1042.103, 74.039],
                        [-1033.107, 69.752],
                        [-1027.571, 70.653],
                        [-1032.942, 68.265],
                        [-1040.252, 69.274],
                        [-1041.55, 65.383],
                        [-1032.601, 63.821],
                        [-1022.383, 67.708],
                        [-1021.279, 78.449],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.001, 0],
                        [0, 0],
                        [2.306, 0.144],
                        [0.793, -0.764],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.439, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-3.181, -0.198],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.844, -1.053],
                        [3.94, 0],
                      ],
                      v: [
                        [-1041.512, 65.712],
                        [-1043.653, 70.075],
                        [-1048.07, 68.883],
                        [-1053.414, 70.936],
                        [-1053.414, 78.434],
                        [-1059.14, 78.434],
                        [-1059.14, 64.163],
                        [-1058.744, 64.163],
                        [-1058.744, 64.155],
                        [-1053.416, 64.155],
                        [-1053.416, 64.163],
                        [-1053.414, 64.163],
                        [-1053.414, 66.142],
                        [-1047.361, 64.17],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.001, 0],
                        [0, 0],
                        [5.72, 0],
                        [0, 6.367],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.151, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-4.513, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.199, 0],
                      ],
                      v: [
                        [-1062.008, 72.898],
                        [-1059.974, 77.33],
                        [-1069.017, 78.507],
                        [-1074.375, 71.24],
                        [-1074.375, 68.381],
                        [-1077.217, 68.381],
                        [-1077.217, 64.105],
                        [-1074.375, 64.105],
                        [-1074.375, 61.126],
                        [-1068.649, 61.126],
                        [-1068.649, 64.105],
                        [-1061.699, 64.105],
                        [-1061.699, 68.381],
                        [-1068.649, 68.381],
                        [-1068.649, 71.24],
                        [-1066.68, 73.762],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.265, 0],
                        [1.264, 2.69],
                        [-0.004, 5.346],
                        [0, 0],
                        [0, 0],
                        [0, -2.114],
                        [-4.237, 0],
                        [-1.015, 0.907],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.236, 1.241],
                        [-4.21, 0],
                        [-0.911, -1.937],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.114],
                        [2.781, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1078.568, 64.155],
                        [-1078.568, 78.434],
                        [-1084.226, 78.434],
                        [-1084.226, 76.25],
                        [-1090.499, 78.418],
                        [-1098.517, 75.017],
                        [-1099.604, 65.277],
                        [-1099.604, 64.155],
                        [-1093.919, 64.155],
                        [-1093.919, 68.86],
                        [-1089.73, 73.673],
                        [-1084.225, 71.448],
                        [-1084.225, 64.155],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.76],
                        [8.572, 0],
                        [1.095, 1.016],
                        [0, 0],
                        [-2.782, 0],
                        [0, 0.486],
                        [3.642, 0],
                        [0, 3.76],
                        [-6.593, 0],
                        [-1.591, -0.865],
                        [0, 0],
                        [2.922, 0],
                        [0, -0.541],
                        [-3.027, 0],
                      ],
                      o: [
                        [0, 3.748],
                        [-4.037, 0],
                        [0, 0],
                        [0.836, 0.546],
                        [3.245, 0],
                        [0, -0.47],
                        [-6.091, 0],
                        [0, -4.325],
                        [6.273, 0],
                        [0, 0],
                        [-1.541, -0.583],
                        [-2.945, 0],
                        [0.011, 0.387],
                        [8.085, 0],
                      ],
                      v: [
                        [-1100.449, 74.083],
                        [-1110.461, 78.975],
                        [-1119.289, 77.738],
                        [-1117.704, 73.654],
                        [-1110.237, 74.567],
                        [-1105.823, 74.151],
                        [-1110.606, 73.339],
                        [-1119.772, 68.797],
                        [-1110.11, 64.038],
                        [-1100.745, 65.461],
                        [-1102.517, 69.377],
                        [-1110.482, 68.344],
                        [-1114.276, 68.845],
                        [-1110.525, 69.548],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 20,
          op: 22,
          st: 20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "Layer 6",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.133],
                        [0, 0],
                        [-1.144, 0],
                        [0, 1.144],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 1.155],
                        [1.144, 0],
                        [0, 0],
                        [0, 0],
                        [-1.144, 0],
                      ],
                      v: [
                        [-1032.904, 75.181],
                        [-1032.904, 76.897],
                        [-1031.188, 78.63],
                        [-1029.472, 76.914],
                        [-1029.472, 73.482],
                        [-1031.188, 73.482],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.432],
                        [0, 0],
                        [3.432, 0],
                        [0, 0],
                        [0, 3.432],
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                        [0, 0],
                        [1.144, 0],
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 3.432],
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                        [0, -3.432],
                        [0, 0],
                        [0, 0],
                        [0, -1.144],
                        [0, 0],
                        [0, -2.288],
                        [0, 0],
                        [3.432, 0],
                      ],
                      v: [
                        [-1020.892, 68.334],
                        [-1020.892, 76.914],
                        [-1026.04, 82.062],
                        [-1036.336, 82.062],
                        [-1041.483, 76.914],
                        [-1041.483, 75.198],
                        [-1036.336, 70.05],
                        [-1029.472, 70.05],
                        [-1029.472, 68.334],
                        [-1031.188, 66.618],
                        [-1041.483, 66.618],
                        [-1036.336, 63.186],
                        [-1026.04, 63.186],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 3.432],
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                        [0, -2.288],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                        [0, -3.432],
                        [0, 0],
                        [2.288, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1049.188, 82.062],
                        [-1052.62, 82.062],
                        [-1057.767, 76.914],
                        [-1057.767, 68.351],
                        [-1052.62, 63.204],
                        [-1045.756, 63.186],
                        [-1042.324, 66.618],
                        [-1049.188, 66.618],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.288, 0],
                        [0, 0],
                        [0, 3.432],
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -2.288],
                        [0, 0],
                        [0, 0],
                        [-1.144, 0],
                      ],
                      o: [
                        [0, 2.288],
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                        [0, -3.432],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.288, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.144],
                        [0, 0],
                      ],
                      v: [
                        [-1059.552, 78.63],
                        [-1062.984, 82.062],
                        [-1069.848, 82.062],
                        [-1074.995, 76.914],
                        [-1074.995, 63.186],
                        [-1069.848, 58.039],
                        [-1066.416, 58.039],
                        [-1066.416, 63.186],
                        [-1062.984, 63.186],
                        [-1059.552, 66.618],
                        [-1066.416, 66.618],
                        [-1066.416, 76.914],
                        [-1064.7, 78.63],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 3.432],
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                        [0, 0],
                        [-1.144, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -3.432],
                        [0, 0],
                        [3.432, 0],
                      ],
                      o: [
                        [-3.432, 0],
                        [0, 0],
                        [0, -3.432],
                        [0, 0],
                        [0, 0],
                        [0, 1.087],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.432, 0],
                        [0, 0],
                        [0, 3.432],
                        [0, 0],
                      ],
                      v: [
                        [-1093.699, 82.062],
                        [-1098.847, 76.914],
                        [-1098.847, 68.334],
                        [-1093.699, 63.186],
                        [-1090.267, 63.186],
                        [-1090.267, 77],
                        [-1088.551, 78.63],
                        [-1086.835, 78.63],
                        [-1086.835, 63.186],
                        [-1083.403, 63.186],
                        [-1078.256, 68.334],
                        [-1078.256, 76.914],
                        [-1083.403, 82.062],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 3.432],
                        [0, 0],
                        [-3.432, 0],
                        [0, 0],
                        [0, -2.288],
                        [0, 0],
                        [0, -1.144],
                        [0, 0],
                        [-1.144, 0.114],
                        [0, 0],
                        [0, -3.432],
                        [0, 0],
                        [3.432, 0],
                        [0, 0],
                        [0, 2.288],
                        [0, 0],
                        [0, 1.144],
                        [0, 0],
                        [1.144, 0.057],
                      ],
                      o: [
                        [-3.432, 0],
                        [0, 0],
                        [0, -3.432],
                        [0, 0],
                        [2.288, 0],
                        [0, 0],
                        [-1.144, 0],
                        [0, 0],
                        [0, 1.201],
                        [0, 0],
                        [3.432, 0],
                        [0, 0],
                        [0, 3.432],
                        [0, 0],
                        [-2.288, 0],
                        [0, 0],
                        [1.144, 0],
                        [0, 0],
                        [0, -0.949],
                        [0, 0],
                      ],
                      v: [
                        [-1115.011, 74.357],
                        [-1120.159, 69.209],
                        [-1120.159, 68.351],
                        [-1115.011, 63.204],
                        [-1104.715, 63.186],
                        [-1101.283, 66.618],
                        [-1109.863, 66.618],
                        [-1111.579, 68.334],
                        [-1111.579, 69.158],
                        [-1109.863, 70.788],
                        [-1106.431, 70.822],
                        [-1101.283, 75.97],
                        [-1101.283, 76.897],
                        [-1106.431, 82.044],
                        [-1116.727, 82.062],
                        [-1120.159, 78.63],
                        [-1111.579, 78.63],
                        [-1109.863, 76.914],
                        [-1109.863, 75.97],
                        [-1111.579, 74.46],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 22,
          op: 24,
          st: 22,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: "Layer 5",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.443, 0],
                        [-0.264, 0.056],
                        [-0.25, 0.069],
                        [-0.235, 0.069],
                        [-0.153, 0.069],
                        [-0.083, 0.028],
                        [0, 0],
                        [0, 0],
                        [0.457, -0.373],
                        [0, -0.692],
                        [0, 0],
                        [-0.194, -0.291],
                        [-0.346, -0.11],
                      ],
                      o: [
                        [0.193, 0.028],
                        [0.262, -0.055],
                        [0.249, -0.069],
                        [0.235, -0.069],
                        [0.152, -0.069],
                        [0, 0],
                        [0, 0],
                        [-0.914, 0.111],
                        [-0.457, 0.374],
                        [0, 0],
                        [0, 0.471],
                        [0.193, 0.291],
                        [0.346, 0.111],
                      ],
                      v: [
                        [-1031.344, 79.54],
                        [-1030.658, 79.498],
                        [-1029.89, 79.311],
                        [-1029.163, 79.104],
                        [-1028.582, 78.896],
                        [-1028.229, 78.751],
                        [-1028.229, 74.183],
                        [-1030.887, 74.432],
                        [-1032.942, 75.158],
                        [-1033.628, 76.757],
                        [-1033.628, 77.629],
                        [-1033.337, 78.772],
                        [-1032.527, 79.374],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.886, 0],
                        [1.052, 1.011],
                        [0, 1.744],
                        [0, 0],
                        [-1.204, 1.107],
                        [-2.575, 0],
                        [0, 0],
                        [0, 0],
                        [0.221, 0.388],
                        [0.636, 0.166],
                        [1.329, 0],
                        [0, 0],
                        [0, 0],
                        [-1.481, 0.264],
                        [-2.104, 0.028],
                        [-1.356, -0.443],
                        [-0.734, -1.093],
                        [0, -2.02],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.429, -0.291],
                        [0.623, -0.276],
                        [0.775, -0.166],
                      ],
                      o: [
                        [-1.717, 0],
                        [-1.053, -1.01],
                        [0, 0],
                        [0, -1.689],
                        [1.204, -1.107],
                        [0, 0],
                        [0, 0],
                        [0, -0.609],
                        [-0.222, -0.388],
                        [-0.637, -0.166],
                        [0, 0],
                        [0, 0],
                        [1.107, -0.416],
                        [1.481, -0.262],
                        [1.854, 0],
                        [1.356, 0.443],
                        [0.733, 1.094],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.249, 0.222],
                        [-0.43, 0.291],
                        [-0.623, 0.277],
                        [-0.776, 0.166],
                      ],
                      v: [
                        [-1035.04, 84.482],
                        [-1039.192, 82.966],
                        [-1040.771, 78.834],
                        [-1040.771, 76.675],
                        [-1038.964, 72.48],
                        [-1033.295, 70.819],
                        [-1028.229, 70.819],
                        [-1028.229, 69.739],
                        [-1028.561, 68.244],
                        [-1029.849, 67.413],
                        [-1032.797, 67.164],
                        [-1039.358, 67.164],
                        [-1039.358, 63.053],
                        [-1035.476, 62.035],
                        [-1030.098, 61.599],
                        [-1025.28, 62.264],
                        [-1022.145, 64.569],
                        [-1021.044, 69.24],
                        [-1021.044, 84.233],
                        [-1026.734, 84.233],
                        [-1027.855, 81.948],
                        [-1028.872, 82.717],
                        [-1030.451, 83.568],
                        [-1032.548, 84.233],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.942, 0.706],
                        [-1.302, 0],
                        [-0.318, -0.027],
                        [-0.277, -0.083],
                        [0, 0],
                        [0.47, 0.028],
                        [0.416, 0],
                        [0.595, -0.152],
                        [0.484, -0.291],
                        [0.388, -0.47],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.72, -0.969],
                        [0.941, -0.706],
                        [0.249, 0],
                        [0.318, 0.028],
                        [0, 0],
                        [-0.443, -0.055],
                        [-0.471, -0.028],
                        [-0.72, 0],
                        [-0.595, 0.152],
                        [-0.485, 0.291],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1056.842, 84.233],
                        [-1056.842, 61.89],
                        [-1051.277, 61.89],
                        [-1049.658, 65.129],
                        [-1047.166, 62.617],
                        [-1043.802, 61.558],
                        [-1042.951, 61.599],
                        [-1042.057, 61.765],
                        [-1042.057, 69.116],
                        [-1043.428, 68.991],
                        [-1044.757, 68.95],
                        [-1046.73, 69.178],
                        [-1048.349, 69.843],
                        [-1049.658, 70.985],
                        [-1049.658, 84.233],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.121, 0.513],
                        [0.47, 1.191],
                        [-0.056, 2.049],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.083, -0.318],
                        [-0.166, -0.208],
                        [-0.222, -0.097],
                        [-0.277, -0.028],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-1.8, 0],
                        [-1.121, -0.512],
                        [-0.471, -1.19],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.471],
                        [0.083, 0.319],
                        [0.166, 0.208],
                        [0.221, 0.097],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1064.4, 84.233],
                        [-1068.781, 83.464],
                        [-1071.169, 80.91],
                        [-1071.792, 76.052],
                        [-1071.585, 67.579],
                        [-1075.032, 67.579],
                        [-1075.032, 63.011],
                        [-1071.294, 61.89],
                        [-1070.381, 55.619],
                        [-1064.691, 55.619],
                        [-1064.691, 61.89],
                        [-1059.832, 61.89],
                        [-1059.832, 67.579],
                        [-1064.691, 67.579],
                        [-1064.691, 76.01],
                        [-1064.567, 77.193],
                        [-1064.193, 77.982],
                        [-1063.611, 78.439],
                        [-1062.864, 78.626],
                        [-1060.04, 78.917],
                        [-1060.04, 84.233],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.384, 0],
                        [1.121, 1.204],
                        [0, 2.077],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.499, -0.359],
                        [-0.803, 0],
                        [-0.734, 0.277],
                        [-0.471, 0.416],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.26, -0.512],
                      ],
                      o: [
                        [-1.966, 0],
                        [-1.121, -1.204],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.053],
                        [0.499, 0.36],
                        [0.554, 0],
                        [0.733, -0.276],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.969, 0.609],
                        [-1.261, 0.513],
                      ],
                      v: [
                        [-1091.892, 84.482],
                        [-1096.523, 82.675],
                        [-1098.205, 77.754],
                        [-1098.205, 61.89],
                        [-1091.02, 61.89],
                        [-1091.02, 75.511],
                        [-1090.273, 77.629],
                        [-1088.321, 78.169],
                        [-1086.39, 77.754],
                        [-1084.583, 76.716],
                        [-1084.583, 61.89],
                        [-1077.44, 61.89],
                        [-1077.44, 84.233],
                        [-1083.379, 84.233],
                        [-1084.583, 82.031],
                        [-1087.926, 83.713],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.077, 0],
                        [0.803, 0.055],
                        [0.775, 0.083],
                        [0.678, 0.152],
                        [0.499, 0.194],
                        [0, 0],
                        [0, 0],
                        [-0.277, 0.083],
                        [-0.111, 0.18],
                        [0, 0.332],
                        [0, 0],
                        [0.262, 0.235],
                        [0.803, 0],
                        [0, 0],
                        [1.038, 0.388],
                        [0.608, 0.928],
                        [0, 1.523],
                        [0, 0],
                        [-0.554, 0.983],
                        [-1.37, 0.526],
                        [-2.409, 0],
                        [-1.053, -0.11],
                        [-0.914, -0.18],
                        [-0.498, -0.193],
                        [0, 0],
                        [0, 0],
                        [0.291, -0.194],
                        [0, -0.499],
                        [0, 0],
                        [-0.139, -0.193],
                        [-0.332, -0.069],
                        [-0.499, 0],
                        [0, 0],
                        [-0.996, -1.065],
                        [0, -1.882],
                        [0, 0],
                        [0.706, -0.9],
                        [1.398, -0.388],
                      ],
                      o: [
                        [-0.747, 0],
                        [-0.804, -0.056],
                        [-0.776, -0.083],
                        [-0.679, -0.152],
                        [0, 0],
                        [0, 0],
                        [0.471, 0],
                        [0.277, -0.083],
                        [0.11, -0.18],
                        [0, 0],
                        [0, -0.388],
                        [-0.264, -0.235],
                        [0, 0],
                        [-1.246, 0],
                        [-1.039, -0.388],
                        [-0.609, -0.927],
                        [0, 0],
                        [0, -1.439],
                        [0.554, -0.982],
                        [1.371, -0.526],
                        [0.969, 0],
                        [1.052, 0.111],
                        [0.914, 0.18],
                        [0, 0],
                        [0, 0],
                        [-0.609, 0],
                        [-0.291, 0.194],
                        [0, 0],
                        [0, 0.332],
                        [0.139, 0.194],
                        [0.332, 0.07],
                        [0, 0],
                        [2.38, 0],
                        [0.997, 1.066],
                        [0, 0],
                        [0, 1.495],
                        [-0.706, 0.9],
                        [-1.398, 0.388],
                      ],
                      v: [
                        [-1110.912, 84.482],
                        [-1113.237, 84.399],
                        [-1115.605, 84.191],
                        [-1117.785, 83.838],
                        [-1119.55, 83.319],
                        [-1119.55, 79.124],
                        [-1109.417, 79.124],
                        [-1108.296, 79],
                        [-1107.715, 78.605],
                        [-1107.548, 77.837],
                        [-1107.548, 77.214],
                        [-1107.943, 76.28],
                        [-1109.542, 75.926],
                        [-1113.196, 75.926],
                        [-1116.622, 75.345],
                        [-1119.093, 73.373],
                        [-1120.007, 69.697],
                        [-1120.007, 68.368],
                        [-1119.177, 64.735],
                        [-1116.29, 62.472],
                        [-1110.622, 61.682],
                        [-1107.589, 61.849],
                        [-1104.641, 62.285],
                        [-1102.523, 62.845],
                        [-1102.523, 67.04],
                        [-1112.117, 67.04],
                        [-1113.466, 67.33],
                        [-1113.902, 68.368],
                        [-1113.902, 68.95],
                        [-1113.695, 69.739],
                        [-1112.989, 70.133],
                        [-1111.743, 70.237],
                        [-1108.046, 70.237],
                        [-1102.98, 71.836],
                        [-1101.485, 76.259],
                        [-1101.485, 78.377],
                        [-1102.544, 81.969],
                        [-1105.7, 83.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 24,
          op: 26,
          st: 24,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: "Layer 4",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.053, 1.149],
                        [0.573, 0.17],
                        [0.835, 0],
                        [0.522, -0.404],
                        [0, -0.626],
                        [-0.509, -0.392],
                        [-0.836, 0],
                        [-0.731, 0.562],
                      ],
                      o: [
                        [-0.522, -0.26],
                        [-0.574, -0.169],
                        [-0.81, 0],
                        [-0.522, 0.405],
                        [0, 0.652],
                        [0.509, 0.391],
                        [0.991, 0],
                        [0.73, -0.561],
                      ],
                      v: [
                        [-1029.073, 75.354],
                        [-1030.717, 74.708],
                        [-1032.831, 74.454],
                        [-1034.828, 75.06],
                        [-1035.61, 76.607],
                        [-1034.847, 78.173],
                        [-1032.831, 78.76],
                        [-1030.247, 77.918],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.509, -0.131],
                        [0.678, 0],
                        [1.018, 1.462],
                        [0.913, -0.391],
                        [1.278, 0],
                        [1.031, 0.405],
                        [0.717, 0.665],
                        [0.365, 0.874],
                        [0, 0.939],
                        [-0.379, 0.822],
                        [-0.718, 0.6],
                        [-1.031, 0.327],
                        [-1.305, 0],
                        [-0.757, -0.209],
                        [-0.652, -0.313],
                        [0, 0],
                        [0.77, 0.757],
                        [1.435, 0],
                        [0.613, -0.47],
                        [0.209, -0.548],
                        [0.639, 0.509],
                        [0, 0.888],
                        [-1.357, 0.926],
                        [-2.636, 0],
                        [-1.292, -0.404],
                        [-0.953, -0.795],
                        [-0.535, -1.226],
                        [0, -1.644],
                        [0, 0],
                        [-0.861, -0.548],
                      ],
                      o: [
                        [-0.235, 0.131],
                        [-0.509, 0.13],
                        [-1.775, 0],
                        [-0.652, 0.679],
                        [-0.914, 0.392],
                        [-1.331, 0],
                        [-1.032, -0.404],
                        [-0.718, -0.666],
                        [-0.366, -0.874],
                        [0, -0.965],
                        [0.378, -0.822],
                        [0.717, -0.6],
                        [1.031, -0.326],
                        [0.861, 0],
                        [0.757, 0.209],
                        [0, 0],
                        [0, -1.331],
                        [-0.77, -0.757],
                        [-1.252, 0],
                        [-0.614, 0.47],
                        [-0.887, 0],
                        [-0.64, -0.509],
                        [0, -1.409],
                        [1.356, -0.926],
                        [1.488, 0],
                        [1.292, 0.405],
                        [0.952, 0.796],
                        [0.535, 1.227],
                        [0, 0],
                        [0, 2.505],
                        [0, 0],
                      ],
                      v: [
                        [-1020.892, 83.417],
                        [-1022.008, 83.809],
                        [-1023.789, 84.005],
                        [-1027.977, 81.812],
                        [-1030.326, 83.417],
                        [-1033.614, 84.005],
                        [-1037.156, 83.398],
                        [-1039.779, 81.793],
                        [-1041.403, 79.484],
                        [-1041.951, 76.763],
                        [-1041.384, 74.082],
                        [-1039.74, 71.949],
                        [-1037.117, 70.559],
                        [-1033.614, 70.07],
                        [-1031.187, 70.383],
                        [-1029.073, 71.166],
                        [-1029.073, 71.048],
                        [-1030.228, 67.917],
                        [-1033.536, 66.782],
                        [-1036.334, 67.486],
                        [-1037.568, 69.013],
                        [-1039.857, 68.25],
                        [-1040.816, 66.155],
                        [-1038.781, 62.652],
                        [-1032.792, 61.262],
                        [-1028.623, 61.869],
                        [-1025.257, 63.67],
                        [-1023.026, 66.703],
                        [-1022.223, 71.009],
                        [-1022.223, 78.407],
                        [-1020.931, 82.987],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.235, 0.666],
                        [0, 1.174],
                        [0, 0],
                        [0.247, 0.678],
                        [0.417, 0.287],
                        [0, 0],
                        [-0.405, 0.117],
                        [-0.574, 0],
                        [-0.887, -0.548],
                        [-0.34, -1.07],
                        [-0.861, 0.587],
                        [-1.044, 0],
                        [-0.574, -0.287],
                        [-0.391, -0.443],
                        [-0.195, -0.573],
                        [0, -0.548],
                        [0.169, -0.521],
                        [0.352, -0.417],
                        [0.561, -0.248],
                        [0.757, 0],
                        [0.274, 0.366],
                        [0.626, 0],
                        [0.483, -0.573],
                        [0, -1.096],
                        [0, 0],
                        [-0.195, -0.639],
                        [-0.522, -0.365],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.443, -0.313],
                        [0.235, -0.665],
                        [0, 0],
                        [0, -1.174],
                        [-0.248, -0.678],
                        [0, 0],
                        [0.339, -0.131],
                        [0.404, -0.117],
                        [1.043, 0],
                        [0.887, 0.548],
                        [0.548, -0.992],
                        [0.861, -0.587],
                        [0.731, 0],
                        [0.573, 0.288],
                        [0.392, 0.444],
                        [0.196, 0.574],
                        [0, 0.548],
                        [-0.17, 0.522],
                        [-0.353, 0.417],
                        [-0.561, 0.248],
                        [0, -0.522],
                        [-0.274, -0.365],
                        [-0.704, 0],
                        [-0.483, 0.574],
                        [0, 0],
                        [0, 1.174],
                        [0.196, 0.64],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1059.213, 83.457],
                        [-1059.213, 83.026],
                        [-1058.196, 81.558],
                        [-1057.844, 78.798],
                        [-1057.844, 66.586],
                        [-1058.215, 63.807],
                        [-1059.213, 62.359],
                        [-1059.213, 61.928],
                        [-1058.098, 61.556],
                        [-1056.63, 61.38],
                        [-1053.734, 62.202],
                        [-1051.894, 64.629],
                        [-1049.78, 62.261],
                        [-1046.923, 61.38],
                        [-1044.965, 61.81],
                        [-1043.517, 62.907],
                        [-1042.637, 64.433],
                        [-1042.343, 66.116],
                        [-1042.597, 67.721],
                        [-1043.38, 69.131],
                        [-1044.75, 70.129],
                        [-1046.727, 70.5],
                        [-1047.138, 69.169],
                        [-1048.488, 68.621],
                        [-1050.27, 69.483],
                        [-1050.994, 71.988],
                        [-1050.994, 78.798],
                        [-1050.7, 81.519],
                        [-1049.624, 83.026],
                        [-1049.624, 83.457],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.288, -0.405],
                        [-0.522, 0],
                        [-0.209, 0.196],
                        [-0.131, 0.288],
                        [-0.444, -0.483],
                        [0, -0.939],
                        [0.235, -0.561],
                        [0.47, -0.431],
                        [0.678, -0.248],
                        [0.861, 0],
                        [1.2, 1.201],
                        [0, 2.245],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.796, 0.313],
                        [-0.757, 0.548],
                        [-0.692, 0.705],
                        [-0.548, 0.783],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0.548],
                        [0.287, 0.405],
                        [0.365, 0],
                        [0.209, -0.195],
                        [0.573, 0.027],
                        [0.443, 0.483],
                        [0, 0.626],
                        [-0.235, 0.561],
                        [-0.469, 0.431],
                        [-0.678, 0.247],
                        [-2.01, 0],
                        [-1.201, -1.2],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.757, 0],
                        [0.796, -0.313],
                        [0.757, -0.548],
                        [0.691, -0.705],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1065.594, 67.408],
                        [-1065.594, 75.471],
                        [-1065.163, 76.9],
                        [-1063.95, 77.507],
                        [-1063.089, 77.213],
                        [-1062.58, 76.489],
                        [-1061.053, 77.252],
                        [-1060.388, 79.386],
                        [-1060.74, 81.167],
                        [-1061.797, 82.654],
                        [-1063.52, 83.672],
                        [-1065.829, 84.044],
                        [-1070.643, 82.243],
                        [-1072.444, 77.076],
                        [-1072.444, 67.408],
                        [-1075.028, 67.408],
                        [-1075.028, 61.928],
                        [-1072.698, 61.458],
                        [-1070.369, 60.167],
                        [-1068.197, 58.288],
                        [-1066.338, 56.056],
                        [-1065.555, 56.056],
                        [-1065.555, 61.928],
                        [-1060.74, 61.928],
                        [-1060.74, 67.408],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.509, -0.131],
                        [0.678, 0],
                        [0.796, 0.499],
                        [0.443, 0.919],
                        [1.005, -0.538],
                        [1.2, 0],
                        [1.044, 0.456],
                        [0.717, 0.822],
                        [0.365, 1.162],
                        [0, 1.384],
                        [0, 0.874],
                        [-0.014, 0.757],
                        [0, 0.535],
                        [0, 0.157],
                        [0.196, 0.64],
                        [0.522, 0.366],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.248, -0.676],
                        [0, -1.171],
                        [0, 0],
                        [-0.652, -0.637],
                        [-0.939, 0],
                        [-0.679, 0.664],
                        [0, 1.224],
                        [0, 0],
                        [0.196, 0.638],
                        [0.521, 0.365],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.235, -0.665],
                        [0, -1.174],
                        [0, 0],
                        [-0.861, -0.548],
                      ],
                      o: [
                        [-0.235, 0.131],
                        [-0.509, 0.13],
                        [-0.992, 0],
                        [-0.796, -0.499],
                        [-0.757, 0.84],
                        [-1.005, 0.538],
                        [-1.357, 0],
                        [-1.044, -0.456],
                        [-0.718, -0.822],
                        [-0.366, -1.161],
                        [0, -0.861],
                        [0, -0.874],
                        [0.013, -0.757],
                        [0, -0.535],
                        [0, -1.174],
                        [-0.195, -0.639],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.418, 0.287],
                        [-0.248, 0.677],
                        [0, 0],
                        [0, 1.172],
                        [0.652, 0.638],
                        [0.887, 0],
                        [0.678, -0.664],
                        [0, 0],
                        [0, -1.171],
                        [-0.196, -0.637],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.444, 0.313],
                        [-0.235, 0.666],
                        [0, 0],
                        [0, 2.505],
                        [0, 0],
                      ],
                      v: [
                        [-1076.241, 83.417],
                        [-1077.356, 83.809],
                        [-1079.137, 84.005],
                        [-1081.818, 83.257],
                        [-1083.678, 81.13],
                        [-1086.32, 83.198],
                        [-1089.628, 84.005],
                        [-1093.229, 83.32],
                        [-1095.871, 81.402],
                        [-1097.495, 78.427],
                        [-1098.043, 74.61],
                        [-1098.043, 72.008],
                        [-1098.023, 69.561],
                        [-1098.004, 67.623],
                        [-1098.004, 66.586],
                        [-1098.298, 63.866],
                        [-1099.374, 62.359],
                        [-1099.374, 61.928],
                        [-1089.784, 61.928],
                        [-1089.784, 62.357],
                        [-1090.782, 63.802],
                        [-1091.154, 66.574],
                        [-1091.154, 74.072],
                        [-1090.176, 76.785],
                        [-1087.788, 77.742],
                        [-1085.439, 76.746],
                        [-1084.422, 73.915],
                        [-1084.422, 66.574],
                        [-1084.715, 63.861],
                        [-1085.791, 62.357],
                        [-1085.791, 61.928],
                        [-1076.201, 61.928],
                        [-1076.201, 62.359],
                        [-1077.219, 63.826],
                        [-1077.572, 66.586],
                        [-1077.572, 78.407],
                        [-1076.28, 82.987],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.535, 0.3],
                        [0.718, 0.261],
                        [0.861, 0.183],
                        [0.965, 0],
                        [0.652, -0.183],
                        [0, -0.574],
                        [-0.183, -0.156],
                        [-0.457, -0.118],
                        [-0.744, -0.117],
                        [-1.149, -0.182],
                        [-0.953, -0.444],
                        [-0.548, -0.587],
                        [-0.196, -0.705],
                        [0, -0.757],
                        [0.404, -0.9],
                        [0.808, -0.717],
                        [1.2, -0.431],
                        [1.591, 0],
                        [1.252, 0.404],
                        [0.822, 0.626],
                        [0.391, 0.783],
                        [0, 0.758],
                        [-0.222, 0.431],
                        [-0.287, 0.235],
                        [-0.418, 0.131],
                        [-0.457, -0.535],
                        [-0.692, -0.417],
                        [-0.888, -0.248],
                        [-1.018, 0],
                        [-0.6, 0.235],
                        [0, 0.601],
                        [0.195, 0.195],
                        [0.483, 0.17],
                        [0.809, 0.17],
                        [1.226, 0.209],
                        [0.887, 0.431],
                        [0.522, 0.587],
                        [0.221, 0.705],
                        [0, 0.757],
                        [-0.444, 0.77],
                        [-0.835, 0.601],
                        [-1.2, 0.353],
                        [-1.54, 0],
                        [-0.626, -0.091],
                        [-0.483, -0.117],
                        [-0.366, -0.091],
                        [-0.287, 0],
                        [-0.261, 0.131],
                        [-0.105, 0.078],
                        [0, 0],
                      ],
                      o: [
                        [-0.313, -0.235],
                        [-0.535, -0.299],
                        [-0.718, -0.261],
                        [-0.861, -0.183],
                        [-1.252, 0],
                        [-0.652, 0.183],
                        [0, 0.261],
                        [0.182, 0.157],
                        [0.456, 0.117],
                        [0.743, 0.117],
                        [1.513, 0.261],
                        [0.952, 0.444],
                        [0.548, 0.587],
                        [0.195, 0.705],
                        [0, 0.94],
                        [-0.405, 0.9],
                        [-0.81, 0.718],
                        [-1.2, 0.431],
                        [-1.722, 0],
                        [-1.253, -0.405],
                        [-0.822, -0.626],
                        [-0.391, -0.783],
                        [0, -0.678],
                        [0.221, -0.431],
                        [0.313, -0.287],
                        [0.182, 0.574],
                        [0.456, 0.535],
                        [0.691, 0.418],
                        [0.887, 0.248],
                        [1.122, 0],
                        [0.6, -0.235],
                        [0, -0.313],
                        [-0.196, -0.196],
                        [-0.483, -0.17],
                        [-0.809, -0.169],
                        [-1.33, -0.235],
                        [-0.887, -0.431],
                        [-0.522, -0.587],
                        [-0.222, -0.705],
                        [0, -0.835],
                        [0.443, -0.769],
                        [0.835, -0.6],
                        [1.2, -0.352],
                        [0.809, 0],
                        [0.626, 0.092],
                        [0.483, 0.117],
                        [0.365, 0.091],
                        [0.548, 0],
                        [0.13, -0.078],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1101.683, 68.974],
                        [-1102.956, 68.171],
                        [-1104.834, 67.33],
                        [-1107.202, 66.665],
                        [-1109.942, 66.391],
                        [-1112.8, 66.665],
                        [-1113.778, 67.8],
                        [-1113.504, 68.426],
                        [-1112.545, 68.837],
                        [-1110.745, 69.189],
                        [-1107.907, 69.639],
                        [-1104.208, 70.696],
                        [-1101.958, 72.242],
                        [-1100.842, 74.18],
                        [-1100.548, 76.372],
                        [-1101.155, 79.131],
                        [-1102.975, 81.558],
                        [-1105.989, 83.28],
                        [-1110.177, 83.927],
                        [-1114.639, 83.32],
                        [-1117.752, 81.774],
                        [-1119.572, 79.66],
                        [-1120.159, 77.35],
                        [-1119.826, 75.687],
                        [-1119.063, 74.689],
                        [-1117.967, 74.062],
                        [-1117.008, 75.726],
                        [-1115.285, 77.155],
                        [-1112.917, 78.153],
                        [-1110.06, 78.525],
                        [-1107.476, 78.173],
                        [-1106.576, 76.92],
                        [-1106.87, 76.157],
                        [-1107.887, 75.609],
                        [-1109.825, 75.1],
                        [-1112.878, 74.532],
                        [-1116.206, 73.534],
                        [-1118.319, 72.008],
                        [-1119.435, 70.07],
                        [-1119.767, 67.878],
                        [-1119.102, 65.47],
                        [-1117.184, 63.415],
                        [-1114.131, 61.987],
                        [-1110.021, 61.458],
                        [-1107.868, 61.595],
                        [-1106.204, 61.909],
                        [-1104.932, 62.222],
                        [-1103.954, 62.359],
                        [-1102.74, 62.163],
                        [-1102.388, 61.928],
                        [-1101.331, 68.739],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 26,
          op: 28,
          st: 26,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: "Layer 3",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.917],
                        [0, 0],
                        [0, 0],
                        [0, -1.056],
                        [-0.375, -0.292],
                        [-0.667, 0],
                        [-0.584, 0.556],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-1.278, 0],
                        [0, 0.5],
                        [0.375, 0.292],
                        [0.944, 0],
                        [0.583, -0.555],
                      ],
                      v: [
                        [-1028.211, 75.092],
                        [-1028.211, 74.925],
                        [-1031.586, 74.925],
                        [-1033.503, 76.509],
                        [-1032.94, 77.696],
                        [-1031.378, 78.134],
                        [-1029.086, 77.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.972],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.444, 0],
                        [1.292, 1.236],
                        [0, 1.833],
                        [-1.459, 1.098],
                        [-2.529, 0],
                        [0, 0],
                        [0, 0],
                        [2.11, 0],
                        [0.764, -0.389],
                        [1.027, -0.917],
                        [0, 0],
                        [-3.528, 0],
                        [-1.681, -1.597],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.556, 1.473],
                        [-2.195, 0],
                        [-1.291, -1.236],
                        [0, -1.944],
                        [1.458, -1.097],
                        [0, 0],
                        [0, 0],
                        [0, -1.777],
                        [-0.917, 0],
                        [-0.764, 0.389],
                        [0, 0],
                        [2.75, -2.777],
                        [2.944, 0],
                        [1.68, 1.598],
                      ],
                      v: [
                        [-1021.044, 70.633],
                        [-1021.044, 82.717],
                        [-1027.586, 82.717],
                        [-1027.586, 81.009],
                        [-1033.586, 83.218],
                        [-1038.816, 81.363],
                        [-1040.753, 76.759],
                        [-1038.565, 72.196],
                        [-1032.586, 70.55],
                        [-1028.211, 70.55],
                        [-1028.211, 70.217],
                        [-1031.378, 67.55],
                        [-1033.899, 68.133],
                        [-1036.586, 70.091],
                        [-1039.92, 65.55],
                        [-1030.503, 61.383],
                        [-1023.565, 63.779],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.666, -0.222],
                        [0, 0],
                        [0, 0],
                        [0.819, -0.805],
                        [0, -1.722],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.167, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-1.667, 0],
                        [-0.819, 0.806],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.333, -1.694],
                        [0.694, 0],
                      ],
                      v: [
                        [-1042.295, 61.883],
                        [-1042.295, 68.217],
                        [-1044.003, 68.217],
                        [-1047.733, 69.425],
                        [-1048.962, 73.217],
                        [-1048.962, 82.717],
                        [-1056.129, 82.717],
                        [-1056.129, 61.883],
                        [-1049.587, 61.883],
                        [-1049.587, 64.092],
                        [-1044.336, 61.549],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.306, 0],
                        [0, 0],
                        [0, 0],
                        [1.444, 0],
                        [1.569, 1.431],
                        [0, 2.778],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.542, -0.417],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-1.361, 0.333],
                        [-2.973, 0],
                        [-1.569, -1.43],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1],
                        [0.542, 0.417],
                      ],
                      v: [
                        [-1062.421, 76.801],
                        [-1059.796, 76.717],
                        [-1059.796, 82.717],
                        [-1064.005, 83.218],
                        [-1070.818, 81.072],
                        [-1073.172, 74.759],
                        [-1073.172, 67.883],
                        [-1076.339, 67.883],
                        [-1076.339, 61.883],
                        [-1073.172, 61.883],
                        [-1073.172, 56.883],
                        [-1066.005, 56.883],
                        [-1066.005, 61.883],
                        [-1060.421, 61.883],
                        [-1060.421, 67.883],
                        [-1066.005, 67.883],
                        [-1066.005, 74.05],
                        [-1065.192, 76.175],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.028],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.57, -0.569],
                        [-0.889, 0],
                        [-0.57, 0.57],
                        [0, 0.889],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.903, -1.889],
                        [2.972, 0],
                        [1.917, 1.889],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.889],
                        [0.569, 0.57],
                        [0.861, 0],
                        [0.569, -0.569],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.028],
                        [-1.903, 1.889],
                        [-2.973, 0],
                        [-1.917, -1.889],
                      ],
                      v: [
                        [-1099.297, 73.008],
                        [-1099.297, 61.883],
                        [-1092.13, 61.883],
                        [-1092.13, 73.592],
                        [-1091.276, 75.779],
                        [-1089.089, 76.634],
                        [-1086.943, 75.779],
                        [-1086.088, 73.592],
                        [-1086.088, 61.883],
                        [-1078.921, 61.883],
                        [-1078.921, 73.008],
                        [-1081.776, 80.384],
                        [-1089.089, 83.218],
                        [-1096.422, 80.384],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.972, 1.278],
                        [0, 0],
                        [-2.028, 0],
                        [0, 0.611],
                        [0.805, 0.333],
                        [0, 0],
                        [0.944, 1],
                        [0, 1.611],
                        [-1.458, 1.167],
                        [-2.473, 0],
                        [-1.945, -2.027],
                        [0, 0],
                        [1.555, 0],
                        [0, -0.527],
                        [-0.945, -0.361],
                        [0, 0],
                        [-0.931, -0.959],
                        [0, -1.584],
                        [1.611, -1.25],
                        [2.5, 0],
                        [1.569, 0.694],
                      ],
                      o: [
                        [0, 0],
                        [1.806, 1.723],
                        [1.222, 0],
                        [0, -0.361],
                        [0, 0],
                        [-2.14, -0.861],
                        [-0.944, -1],
                        [0, -2.028],
                        [1.459, -1.167],
                        [3.277, 0],
                        [0, 0],
                        [-1.389, -1.194],
                        [-0.973, 0],
                        [0, 0.389],
                        [0, 0],
                        [2.194, 0.861],
                        [0.931, 0.959],
                        [0, 2.139],
                        [-1.64, 1.222],
                        [-1.862, 0],
                        [-1.569, -0.694],
                      ],
                      v: [
                        [-1120.007, 79.217],
                        [-1116.673, 74.967],
                        [-1110.923, 77.551],
                        [-1109.089, 76.634],
                        [-1110.298, 75.592],
                        [-1112.631, 74.634],
                        [-1117.257, 71.842],
                        [-1118.674, 67.925],
                        [-1116.486, 63.133],
                        [-1110.589, 61.383],
                        [-1102.756, 64.424],
                        [-1106.131, 68.8],
                        [-1110.548, 67.008],
                        [-1112.006, 67.8],
                        [-1110.589, 68.925],
                        [-1108.506, 69.758],
                        [-1103.819, 72.488],
                        [-1102.423, 76.3],
                        [-1104.839, 81.384],
                        [-1111.048, 83.218],
                        [-1116.194, 82.176],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 28,
          op: 30,
          st: 28,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: "Layer 2",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.402, 0.301],
                        [-0.402, 0.47],
                        [-0.402, 0.537],
                        [-0.235, 0.47],
                        [0, 0],
                        [0.637, -1.006],
                        [0.838, -0.838],
                        [0.938, -0.535],
                        [0.905, 0],
                        [0.335, 0.402],
                        [0, 0.637],
                        [-0.235, 1.006],
                        [-0.37, 1.005],
                        [-0.469, 1.441],
                        [-0.402, 1.207],
                        [0, 0],
                        [0.369, 0],
                        [0.335, -0.101],
                        [0.302, -0.167],
                        [0, 0],
                        [-0.436, 0.269],
                        [-0.503, 0.235],
                        [-0.503, 0.168],
                        [-0.436, 0.068],
                        [-0.537, 0.402],
                        [-0.771, 0],
                        [0, -0.536],
                        [0.033, -0.234],
                        [0.033, -0.1],
                        [0.402, -1.173],
                        [0.469, -1.475],
                        [0.503, -1.509],
                        [0.335, -1.106],
                        [0.101, -0.369],
                        [0, -0.201],
                        [-0.1, -0.067],
                        [-0.134, 0],
                      ],
                      o: [
                        [0.368, -0.301],
                        [0.402, -0.435],
                        [0.369, -0.535],
                        [0, 0],
                        [-0.302, 0.973],
                        [-0.67, 1.005],
                        [-0.872, 0.872],
                        [-0.938, 0.536],
                        [-0.737, 0],
                        [-0.369, -0.402],
                        [0, -0.436],
                        [0.201, -1.005],
                        [0.402, -1.274],
                        [0.469, -1.408],
                        [0, 0],
                        [-0.201, -0.101],
                        [-0.369, -0.033],
                        [-0.336, 0.134],
                        [0, 0],
                        [0.268, -0.201],
                        [0.402, -0.235],
                        [0.469, -0.234],
                        [0.503, -0.134],
                        [0.369, -0.535],
                        [0.536, -0.402],
                        [0.838, 0],
                        [0, 0.235],
                        [-0.068, 0.235],
                        [-0.168, 0.437],
                        [-0.402, 1.207],
                        [-0.503, 1.475],
                        [-0.503, 1.509],
                        [-0.134, 0.436],
                        [-0.1, 0.369],
                        [0, 0.269],
                        [0.101, 0.068],
                        [0.235, 0],
                      ],
                      v: [
                        [-1025.218, 79.454],
                        [-1024.061, 78.297],
                        [-1022.855, 76.839],
                        [-1021.95, 75.33],
                        [-1021.648, 75.481],
                        [-1023.056, 78.448],
                        [-1025.318, 81.214],
                        [-1028.034, 83.326],
                        [-1030.8, 84.13],
                        [-1032.409, 83.527],
                        [-1032.962, 81.968],
                        [-1032.61, 79.806],
                        [-1031.755, 76.789],
                        [-1030.448, 72.716],
                        [-1029.141, 68.793],
                        [-1027.732, 64.569],
                        [-1028.588, 64.418],
                        [-1029.643, 64.519],
                        [-1030.599, 64.971],
                        [-1030.9, 64.72],
                        [-1029.845, 64.016],
                        [-1028.487, 63.311],
                        [-1027.028, 62.708],
                        [-1025.621, 62.406],
                        [-1024.263, 60.999],
                        [-1022.302, 60.395],
                        [-1021.044, 61.2],
                        [-1021.094, 61.904],
                        [-1021.245, 62.406],
                        [-1022.101, 64.82],
                        [-1023.408, 68.844],
                        [-1024.916, 73.319],
                        [-1026.174, 77.241],
                        [-1026.526, 78.448],
                        [-1026.676, 79.303],
                        [-1026.526, 79.806],
                        [-1026.174, 79.907],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.705, -1.173],
                        [0.637, -1.307],
                        [0.469, -1.207],
                        [0, -0.67],
                        [-0.57, 0],
                        [-0.302, 0.201],
                        [-0.302, 0.269],
                        [-0.302, 0.336],
                        [-0.235, 0.269],
                        [0, 0],
                        [0.57, -0.737],
                        [0.704, -0.67],
                        [0.805, -0.435],
                        [0.737, 0],
                        [0.503, 0.503],
                        [0, 0.973],
                        [-0.737, 1.442],
                        [-1.14, 1.408],
                        [-1.408, 1.275],
                        [-1.375, 0.872],
                        [0, 0],
                      ],
                      o: [
                        [-0.737, 1.207],
                        [-0.67, 1.307],
                        [-0.47, 1.241],
                        [0, 0.738],
                        [0.234, 0],
                        [0.302, -0.167],
                        [0.302, -0.268],
                        [0.268, -0.301],
                        [0, 0],
                        [-0.336, 0.671],
                        [-0.571, 0.771],
                        [-0.704, 0.671],
                        [-0.804, 0.436],
                        [-0.671, 0],
                        [-0.503, -0.503],
                        [0, -1.207],
                        [0.705, -1.441],
                        [1.14, -1.408],
                        [1.374, -1.273],
                        [0, 0],
                        [-0.47, 0.571],
                      ],
                      v: [
                        [-1033.566, 68.541],
                        [-1035.628, 72.313],
                        [-1037.337, 76.084],
                        [-1038.041, 78.951],
                        [-1037.186, 80.057],
                        [-1036.381, 79.755],
                        [-1035.476, 79.102],
                        [-1034.571, 78.197],
                        [-1033.817, 77.342],
                        [-1033.566, 77.543],
                        [-1034.923, 79.655],
                        [-1036.834, 81.817],
                        [-1039.097, 83.476],
                        [-1041.41, 84.13],
                        [-1043.171, 83.376],
                        [-1043.925, 81.164],
                        [-1042.819, 77.191],
                        [-1040.053, 72.916],
                        [-1036.231, 68.893],
                        [-1032.108, 65.675],
                        [-1031.805, 65.926],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.235, 0.637],
                        [0, 0.436],
                        [0.067, 0.168],
                        [0.235, 0],
                        [0.67, -1.039],
                        [0.67, -1.508],
                        [0, 0],
                        [-0.503, 1.14],
                        [-0.603, 0.872],
                        [-0.704, 0.503],
                        [-0.804, 0],
                        [-0.402, -0.402],
                        [0, -0.704],
                        [0.302, -0.636],
                        [0.503, -0.535],
                        [0.636, -0.402],
                        [0.604, -0.167],
                        [0, 0],
                      ],
                      o: [
                        [0.201, -0.636],
                        [0, -0.168],
                        [-0.068, -0.133],
                        [-0.47, 0],
                        [-0.671, 1.039],
                        [0, 0],
                        [0.402, -1.24],
                        [0.469, -1.106],
                        [0.604, -0.838],
                        [0.704, -0.503],
                        [0.57, 0],
                        [0.368, 0.436],
                        [0, 0.704],
                        [-0.336, 0.637],
                        [-0.503, 0.537],
                        [-0.637, 0.402],
                        [0, 0],
                        [0.268, -0.435],
                      ],
                      v: [
                        [-1043.924, 67.636],
                        [-1043.623, 66.027],
                        [-1043.723, 65.524],
                        [-1044.176, 65.323],
                        [-1045.885, 66.882],
                        [-1047.897, 70.704],
                        [-1048.249, 70.603],
                        [-1046.891, 67.033],
                        [-1045.282, 64.066],
                        [-1043.321, 62.054],
                        [-1041.058, 61.3],
                        [-1039.599, 61.904],
                        [-1039.046, 63.614],
                        [-1039.499, 65.625],
                        [-1040.756, 67.385],
                        [-1042.466, 68.793],
                        [-1044.327, 69.648],
                        [-1044.679, 69.245],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.402, 0.973],
                        [0, 0.671],
                        [0.402, 0],
                        [0.57, -0.67],
                        [0.402, -0.636],
                        [0.302, -0.838],
                        [0, 0],
                        [-0.503, 1.006],
                        [-0.671, 0.771],
                        [-0.838, 0.436],
                        [-0.973, 0],
                        [-0.57, -0.503],
                        [0, -0.871],
                        [0.368, -1.173],
                        [0.536, -1.441],
                        [0.368, -1.039],
                        [0.368, -0.972],
                        [0.302, -0.805],
                        [0.168, -0.368],
                        [0.604, -0.503],
                        [1.039, 0],
                        [0.402, 0.469],
                        [-0.269, 0.57],
                        [-0.235, 0.604],
                        [-0.302, 0.737],
                        [-0.336, 0.805],
                        [-0.302, 0.737],
                        [-0.235, 0.603],
                        [-0.134, 0.301],
                      ],
                      o: [
                        [0.402, -0.972],
                        [0, -0.435],
                        [-0.57, 0],
                        [-0.369, 0.437],
                        [-0.402, 0.637],
                        [0, 0],
                        [0.235, -1.073],
                        [0.469, -0.972],
                        [0.636, -0.737],
                        [0.805, -0.435],
                        [0.972, 0],
                        [0.536, 0.536],
                        [0, 1.006],
                        [-0.37, 1.207],
                        [-0.302, 0.838],
                        [-0.402, 1.039],
                        [-0.37, 0.973],
                        [-0.336, 0.805],
                        [-0.269, 0.671],
                        [-0.637, 0.503],
                        [-1.14, 0],
                        [-0.436, -0.436],
                        [0.1, -0.302],
                        [0.234, -0.57],
                        [0.302, -0.737],
                        [0.302, -0.804],
                        [0.302, -0.737],
                        [0.235, -0.604],
                        [0.369, -0.872],
                      ],
                      v: [
                        [-1054.484, 67.838],
                        [-1053.881, 65.373],
                        [-1054.484, 64.72],
                        [-1056.194, 65.725],
                        [-1057.35, 67.335],
                        [-1058.407, 69.547],
                        [-1058.759, 69.397],
                        [-1057.653, 66.278],
                        [-1055.943, 63.664],
                        [-1053.73, 61.904],
                        [-1051.065, 61.25],
                        [-1048.752, 62.005],
                        [-1047.947, 64.116],
                        [-1048.5, 67.385],
                        [-1049.858, 71.358],
                        [-1050.864, 74.174],
                        [-1052.02, 77.191],
                        [-1053.026, 79.856],
                        [-1053.781, 81.616],
                        [-1055.088, 83.376],
                        [-1057.602, 84.13],
                        [-1059.916, 83.427],
                        [-1060.167, 81.918],
                        [-1059.664, 80.56],
                        [-1058.859, 78.599],
                        [-1057.904, 76.285],
                        [-1056.999, 73.973],
                        [-1056.194, 71.961],
                        [-1055.641, 70.603],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.571, 1.509],
                        [-0.537, 1.375],
                        [-0.638, 1.743],
                        [0, 0],
                        [0, 0],
                        [-0.603, 0.235],
                        [0, 0],
                        [-0.168, 0.134],
                        [0, 0],
                        [0, 0],
                        [-0.269, 0.301],
                        [0, 0],
                        [-0.369, 0.201],
                        [-0.235, 0],
                        [-0.168, -0.067],
                        [-0.1, -0.134],
                        [0.067, -0.201],
                        [0.167, -0.503],
                        [0.201, -0.57],
                        [0.302, -0.805],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.207, 0.973],
                        [-0.737, 1.006],
                        [0, 0],
                        [0.838, -1.207],
                        [1.106, -1.006],
                        [1.006, -0.535],
                        [0.938, 0],
                        [0.469, 0.436],
                        [0.033, 0.704],
                        [-0.201, 0.704],
                        [-0.436, 1.106],
                      ],
                      o: [
                        [0.57, -1.475],
                        [0.604, -1.61],
                        [0, 0],
                        [0, 0],
                        [0.704, -0.268],
                        [0, 0],
                        [0.469, -0.201],
                        [0, 0],
                        [0, 0],
                        [0.302, -0.302],
                        [0, 0],
                        [0.469, -0.469],
                        [0.168, -0.1],
                        [0.201, 0.034],
                        [0.134, 0.068],
                        [0.067, 0.168],
                        [-0.068, 0.168],
                        [-0.201, 0.537],
                        [-0.235, 0.638],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.072, -0.469],
                        [1.207, -0.972],
                        [0, 0],
                        [-0.402, 0.939],
                        [-0.872, 1.241],
                        [-1.006, 0.872],
                        [-1.005, 0.57],
                        [-0.738, 0],
                        [-0.47, -0.435],
                        [0, -0.436],
                        [0.168, -0.67],
                        [0.369, -1.005],
                      ],
                      v: [
                        [-1072.99, 73.621],
                        [-1071.331, 69.346],
                        [-1069.47, 64.317],
                        [-1072.739, 64.317],
                        [-1072.286, 63.262],
                        [-1070.325, 62.507],
                        [-1068.867, 61.904],
                        [-1067.911, 61.401],
                        [-1066.956, 60.445],
                        [-1066.051, 59.54],
                        [-1065.196, 58.635],
                        [-1063.788, 57.127],
                        [-1062.531, 56.12],
                        [-1061.927, 55.97],
                        [-1061.374, 56.12],
                        [-1061.022, 56.423],
                        [-1061.022, 56.976],
                        [-1061.374, 57.981],
                        [-1061.978, 59.641],
                        [-1062.782, 61.803],
                        [-1057.2, 61.803],
                        [-1058.155, 64.317],
                        [-1063.637, 64.317],
                        [-1069.068, 79.454],
                        [-1068.917, 79.555],
                        [-1065.498, 77.392],
                        [-1062.581, 74.425],
                        [-1062.279, 74.576],
                        [-1064.14, 77.794],
                        [-1067.107, 81.164],
                        [-1070.124, 83.275],
                        [-1073.041, 84.13],
                        [-1074.851, 83.476],
                        [-1075.605, 81.767],
                        [-1075.304, 80.057],
                        [-1074.398, 77.392],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.571, 0.402],
                        [-0.537, 0.436],
                        [-0.503, 0.437],
                        [-0.335, 0.336],
                        [0, 0],
                        [0.872, -0.972],
                        [1.039, -0.872],
                        [1.073, -0.604],
                        [0.804, 0],
                        [0.402, 0.302],
                        [0, 0.704],
                        [-0.302, 0.838],
                        [-0.402, 0.973],
                        [-0.436, 1.006],
                        [-0.302, 0.738],
                        [0, 0],
                        [-0.336, 0.771],
                        [-0.269, 0.604],
                        [-0.134, 0.301],
                        [-0.603, 0.503],
                        [-1.074, 0],
                        [-0.436, -0.402],
                        [0.335, -0.771],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.536, -0.402],
                        [0.503, -0.435],
                        [0.469, -0.402],
                        [0, 0],
                        [-0.437, 0.738],
                        [-0.872, 1.006],
                        [-1.074, 0.872],
                        [-1.073, 0.636],
                        [-0.537, 0],
                        [-0.436, -0.268],
                        [0, -0.402],
                        [0.268, -0.838],
                        [0.402, -0.972],
                        [0.435, -0.972],
                        [0, 0],
                        [0.368, -0.872],
                        [0.302, -0.737],
                        [0.268, -0.57],
                        [0.268, -0.67],
                        [0.604, -0.503],
                        [1.106, 0],
                        [0.435, 0.437],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.469, -0.234],
                      ],
                      v: [
                        [-1081.336, 79.051],
                        [-1079.727, 77.794],
                        [-1078.219, 76.487],
                        [-1077.012, 75.38],
                        [-1076.71, 75.582],
                        [-1078.672, 78.146],
                        [-1081.538, 80.962],
                        [-1084.756, 83.175],
                        [-1087.572, 84.13],
                        [-1088.98, 83.678],
                        [-1089.634, 82.219],
                        [-1089.181, 80.359],
                        [-1088.175, 77.643],
                        [-1086.918, 74.677],
                        [-1085.812, 72.112],
                        [-1084.706, 69.547],
                        [-1083.65, 67.083],
                        [-1082.795, 65.072],
                        [-1082.192, 63.764],
                        [-1080.884, 62.005],
                        [-1078.369, 61.25],
                        [-1076.057, 61.853],
                        [-1075.906, 63.664],
                        [-1079.677, 72.212],
                        [-1083.046, 79.907],
                        [-1082.896, 80.007],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.604, -1.173],
                        [0, -0.301],
                        [-0.369, 0],
                        [-1.074, 0.872],
                        [-1.275, 1.676],
                        [0, 0],
                        [0.938, -1.073],
                        [0.972, -0.804],
                        [0.938, -0.435],
                        [0.838, 0],
                        [0, 1.576],
                        [-0.134, 0.503],
                        [-0.269, 0.671],
                        [-0.537, 1.074],
                        [-0.704, 1.342],
                        [-0.336, 0.671],
                        [-0.302, 0.704],
                        [-0.235, 0.571],
                        [0, 0.235],
                        [0.536, 0],
                        [0.569, -0.67],
                        [0.838, -1.743],
                        [0, 0],
                        [-0.571, 1.006],
                        [-0.772, 0.771],
                        [-0.905, 0.436],
                        [-0.973, 0],
                        [-0.503, -0.503],
                        [0, -0.905],
                        [0.67, -1.509],
                        [0.771, -1.576],
                      ],
                      o: [
                        [-0.603, 1.207],
                        [0, 0.402],
                        [0.503, 0],
                        [1.072, -0.872],
                        [0, 0],
                        [-0.872, 1.307],
                        [-0.973, 1.106],
                        [-1.005, 0.805],
                        [-0.938, 0.436],
                        [-1.509, 0],
                        [0, -0.268],
                        [0.134, -0.503],
                        [0.369, -0.972],
                        [0.503, -1.039],
                        [0.201, -0.435],
                        [0.335, -0.636],
                        [0.302, -0.67],
                        [0.235, -0.535],
                        [0, -0.535],
                        [-0.537, 0],
                        [-0.738, 0.805],
                        [0, 0],
                        [0.302, -1.073],
                        [0.57, -0.972],
                        [0.771, -0.737],
                        [0.872, -0.435],
                        [0.872, 0],
                        [0.469, 0.503],
                        [0, 1.174],
                        [-0.705, 1.543],
                        [-0.872, 1.743],
                      ],
                      v: [
                        [-1094.21, 77.191],
                        [-1095.115, 79.454],
                        [-1094.562, 80.057],
                        [-1092.198, 78.75],
                        [-1088.678, 74.928],
                        [-1088.377, 75.179],
                        [-1091.092, 78.75],
                        [-1094.009, 81.616],
                        [-1096.925, 83.476],
                        [-1099.59, 84.13],
                        [-1101.853, 81.767],
                        [-1101.652, 80.61],
                        [-1101.049, 78.85],
                        [-1099.691, 75.783],
                        [-1097.881, 72.212],
                        [-1097.076, 70.553],
                        [-1096.121, 68.541],
                        [-1095.316, 66.681],
                        [-1094.964, 65.524],
                        [-1095.769, 64.72],
                        [-1097.428, 65.725],
                        [-1099.792, 69.547],
                        [-1100.143, 69.397],
                        [-1098.836, 66.278],
                        [-1096.824, 63.664],
                        [-1094.31, 61.904],
                        [-1091.545, 61.25],
                        [-1089.483, 62.005],
                        [-1088.779, 64.116],
                        [-1089.784, 68.139],
                        [-1091.997, 72.816],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.47, 0.503],
                        [-0.704, 0],
                        [-0.503, -0.469],
                        [0, -0.704],
                        [0.469, -0.469],
                        [0.67, 0],
                        [0.469, 0.47],
                        [0, 0.671],
                      ],
                      o: [
                        [0.469, -0.469],
                        [0.67, 0],
                        [0.469, 0.503],
                        [0, 0.671],
                        [-0.503, 0.47],
                        [-0.704, 0],
                        [-0.47, -0.469],
                        [0, -0.704],
                      ],
                      v: [
                        [-1104.87, 62.306],
                        [-1103.11, 61.602],
                        [-1101.35, 62.306],
                        [-1100.646, 64.116],
                        [-1101.35, 65.826],
                        [-1103.11, 66.53],
                        [-1104.87, 65.826],
                        [-1105.575, 64.116],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.603, -0.535],
                        [0, -1.073],
                        [-0.235, -0.905],
                        [-0.47, -1.509],
                        [-0.269, -0.872],
                        [0, -0.737],
                        [0.368, -0.838],
                        [0.705, -0.67],
                        [1.106, -0.435],
                        [1.509, -0.101],
                        [0, 0],
                        [-0.57, 0.503],
                        [0, 1.074],
                        [0.302, 1.073],
                        [0.503, 1.275],
                        [0, 1.576],
                        [-0.37, 0.804],
                        [-0.738, 0.704],
                        [-1.106, 0.47],
                        [-1.475, 0.1],
                        [0, 0],
                      ],
                      o: [
                        [-0.604, 0.571],
                        [0, 0.704],
                        [0.234, 0.905],
                        [0.469, 1.375],
                        [0.268, 0.872],
                        [0, 0.805],
                        [-0.37, 0.838],
                        [-0.737, 0.671],
                        [-1.14, 0.469],
                        [0, 0],
                        [1.039, -0.168],
                        [0.569, -0.503],
                        [0, -0.737],
                        [-0.336, -1.039],
                        [-0.872, -2.279],
                        [0, -0.805],
                        [0.368, -0.805],
                        [0.737, -0.67],
                        [1.106, -0.435],
                        [0, 0],
                        [-0.872, 0.134],
                      ],
                      v: [
                        [-1107.636, 62.608],
                        [-1108.542, 65.072],
                        [-1108.189, 67.486],
                        [-1107.133, 71.106],
                        [-1106.027, 74.475],
                        [-1105.624, 76.889],
                        [-1106.177, 79.353],
                        [-1107.787, 81.616],
                        [-1110.553, 83.275],
                        [-1114.525, 84.13],
                        [-1114.425, 83.779],
                        [-1112.011, 82.773],
                        [-1111.156, 80.409],
                        [-1111.609, 77.694],
                        [-1112.866, 74.224],
                        [-1114.173, 68.441],
                        [-1113.62, 66.027],
                        [-1111.961, 63.764],
                        [-1109.195, 62.054],
                        [-1105.323, 61.25],
                        [-1105.423, 61.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.536, 0.537],
                        [-0.771, 0],
                        [-0.537, -0.503],
                        [0, -0.771],
                        [0.503, -0.503],
                        [0.771, 0],
                        [0.503, 0.537],
                        [0, 0.772],
                      ],
                      o: [
                        [0.503, -0.503],
                        [0.771, 0],
                        [0.503, 0.537],
                        [0, 0.772],
                        [-0.537, 0.537],
                        [-0.771, 0],
                        [-0.536, -0.503],
                        [0, -0.771],
                      ],
                      v: [
                        [-1119.202, 78.347],
                        [-1117.291, 77.593],
                        [-1115.33, 78.347],
                        [-1114.576, 80.308],
                        [-1115.33, 82.219],
                        [-1117.291, 83.024],
                        [-1119.202, 82.219],
                        [-1120.007, 80.308],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 30,
          op: 32,
          st: 30,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: "Layer 1",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [-1070.525, 70.05, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.456, 0.312],
                        [-0.216, 0.625],
                        [0, 0],
                        [0, 0],
                        [0, -1.104],
                        [-0.348, -0.3],
                        [-0.6, 0],
                      ],
                      o: [
                        [0.456, -0.312],
                        [0, 0],
                        [0, 0],
                        [-1.656, 0],
                        [0, 0.457],
                        [0.348, 0.3],
                        [0.576, 0],
                      ],
                      v: [
                        [-1030.188, 76.926],
                        [-1029.18, 75.522],
                        [-1029.18, 74.154],
                        [-1031.196, 74.154],
                        [-1033.68, 75.81],
                        [-1033.158, 76.944],
                        [-1031.736, 77.394],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.824, -1.548],
                        [0, -3.216],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.784, 0],
                        [1.092, 0.54],
                        [0.564, 0.924],
                        [0, 1.152],
                        [-1.488, 1.008],
                        [-3.024, 0],
                        [0, 0],
                        [2.208, 0],
                        [0.912, -0.276],
                        [0.648, -0.48],
                        [0, 0],
                        [-1.597, 0.396],
                        [-1.584, 0],
                      ],
                      o: [
                        [1.824, 1.548],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.936, 1.968],
                        [-1.561, 0],
                        [-1.093, -0.54],
                        [-0.564, -0.924],
                        [0, -1.944],
                        [1.488, -1.008],
                        [0, 0],
                        [-0.264, -1.536],
                        [-0.888, 0],
                        [-0.912, 0.276],
                        [0, 0],
                        [1.127, -0.696],
                        [1.596, -0.396],
                        [3.456, 0],
                      ],
                      v: [
                        [-1023.78, 64.2],
                        [-1021.044, 71.346],
                        [-1021.044, 81.93],
                        [-1028.604, 81.93],
                        [-1028.604, 79.338],
                        [-1034.184, 82.29],
                        [-1038.162, 81.48],
                        [-1040.646, 79.284],
                        [-1041.492, 76.17],
                        [-1039.26, 71.742],
                        [-1032.492, 70.23],
                        [-1029.216, 70.23],
                        [-1032.924, 67.926],
                        [-1035.624, 68.34],
                        [-1037.964, 69.474],
                        [-1040.556, 64.11],
                        [-1036.47, 62.472],
                        [-1031.7, 61.878],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.808, 0],
                        [0, 0],
                        [0.552, 0],
                        [0, -2.832],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.648, -0.096],
                        [-2.784, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.44, -1.656],
                      ],
                      v: [
                        [-1042.644, 61.878],
                        [-1042.644, 69.078],
                        [-1044.444, 68.934],
                        [-1048.62, 73.182],
                        [-1048.62, 81.93],
                        [-1056.756, 81.93],
                        [-1056.756, 62.238],
                        [-1049.016, 62.238],
                        [-1049.016, 64.362],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.111, 0],
                        [1.488, 1.332],
                        [0, 2.664],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.312, -0.336],
                        [-0.504, 0],
                        [-0.528, 0.36],
                      ],
                      o: [
                        [-1.224, 0.696],
                        [-2.76, 0],
                        [-1.488, -1.332],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.6],
                        [0.312, 0.336],
                        [0.696, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1058.808, 81.246],
                        [-1063.812, 82.29],
                        [-1070.184, 80.292],
                        [-1072.416, 74.298],
                        [-1072.416, 69.258],
                        [-1075.116, 69.258],
                        [-1075.116, 63.318],
                        [-1072.416, 63.318],
                        [-1072.416, 57.81],
                        [-1064.28, 57.81],
                        [-1064.28, 63.318],
                        [-1060.248, 63.318],
                        [-1060.248, 69.258],
                        [-1064.28, 69.258],
                        [-1064.28, 74.226],
                        [-1063.812, 75.63],
                        [-1062.588, 76.134],
                        [-1060.752, 75.594],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.96, -0.372],
                        [1.08, 0],
                        [1.512, 1.524],
                        [0, 3.072],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.432, -0.576],
                        [-0.84, 0],
                        [-0.528, 0.636],
                        [0, 1.368],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.72, 0.744],
                        [-0.96, 0.372],
                        [-2.496, 0],
                        [-1.512, -1.524],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.345],
                        [0.432, 0.576],
                        [0.792, 0],
                        [0.528, -0.636],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1076.916, 62.238],
                        [-1076.916, 81.93],
                        [-1084.656, 81.93],
                        [-1084.656, 80.058],
                        [-1087.176, 81.732],
                        [-1090.236, 82.29],
                        [-1096.248, 80.004],
                        [-1098.516, 73.11],
                        [-1098.516, 62.238],
                        [-1090.38, 62.238],
                        [-1090.38, 71.706],
                        [-1089.731, 74.586],
                        [-1087.824, 75.45],
                        [-1085.844, 74.496],
                        [-1085.052, 71.49],
                        [-1085.052, 62.238],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.632, 0.36],
                        [1.008, 0.576],
                        [0, 0],
                        [-1.248, -0.312],
                        [-1.176, 0],
                        [-0.373, 0.156],
                        [0, 0.312],
                        [0.468, 0.121],
                        [1.056, 0.12],
                        [1.128, 0.301],
                        [0.852, 0.864],
                        [0, 1.608],
                        [-0.769, 1.008],
                        [-1.524, 0.6],
                        [-2.136, 0],
                        [-1.428, -0.275],
                        [-1.008, -0.527],
                        [0, 0],
                        [2.064, 0],
                        [0, -0.624],
                        [-0.48, -0.132],
                        [-1.032, -0.12],
                        [-1.116, -0.312],
                        [-0.852, -0.864],
                        [0, -1.608],
                        [0.768, -1.008],
                        [1.536, -0.6],
                        [2.184, 0],
                      ],
                      o: [
                        [-1.632, -0.36],
                        [0, 0],
                        [0.912, 0.553],
                        [1.248, 0.312],
                        [0.936, 0],
                        [0.372, -0.156],
                        [0, -0.312],
                        [-0.468, -0.12],
                        [-1.608, -0.192],
                        [-1.128, -0.3],
                        [-0.852, -0.864],
                        [0, -1.272],
                        [0.768, -1.008],
                        [1.524, -0.6],
                        [1.488, 0],
                        [1.428, 0.276],
                        [0, 0],
                        [-1.728, -0.96],
                        [-1.776, 0],
                        [0, 0.312],
                        [0.48, 0.132],
                        [1.607, 0.192],
                        [1.116, 0.312],
                        [0.852, 0.864],
                        [0, 1.224],
                        [-0.769, 1.008],
                        [-1.536, 0.6],
                        [-1.68, 0],
                      ],
                      v: [
                        [-1116.047, 81.75],
                        [-1120.007, 80.346],
                        [-1117.775, 74.982],
                        [-1114.535, 76.278],
                        [-1110.899, 76.746],
                        [-1108.937, 76.512],
                        [-1108.379, 75.81],
                        [-1109.081, 75.162],
                        [-1111.367, 74.802],
                        [-1115.471, 74.064],
                        [-1118.441, 72.318],
                        [-1119.719, 68.61],
                        [-1118.567, 65.19],
                        [-1115.129, 62.778],
                        [-1109.639, 61.878],
                        [-1105.265, 62.292],
                        [-1101.611, 63.498],
                        [-1103.843, 68.862],
                        [-1109.531, 67.422],
                        [-1112.195, 68.358],
                        [-1111.475, 69.024],
                        [-1109.207, 69.402],
                        [-1105.121, 70.158],
                        [-1102.169, 71.922],
                        [-1100.891, 75.63],
                        [-1102.043, 78.978],
                        [-1105.499, 81.39],
                        [-1111.079, 82.29],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 32,
          op: 34,
          st: 32,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          nm: "NULL CONTROL",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [541.079, 536.072, 0], ix: 2 },
            a: { a: 0, k: [60, 60, 0], ix: 1 },
            s: { a: 0, k: [539, 539, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 23,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Layer 12",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [620.478, 705.907],
                        [618.945, 712.485],
                        [622.047, 712.485],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [625.784, 722.277],
                        [624.626, 719.25],
                        [616.329, 719.25],
                        [615.208, 722.277],
                        [604.631, 722.277],
                        [604.631, 721.268],
                        [614.721, 695.816],
                        [626.271, 695.816],
                        [636.362, 721.268],
                        [636.362, 722.277],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.386, 0.249],
                        [0, 0.623],
                        [1.645, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.872, -0.025],
                        [0.386, -0.249],
                        [0, -1.196],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [589.53, 707.477],
                        [590.95, 707.477],
                        [592.837, 707.066],
                        [593.417, 705.757],
                        [590.95, 703.963],
                        [589.53, 703.963],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -6.378],
                        [2.566, -1.57],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [8.546, 0.05],
                        [0, 3.514],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [590.352, 715.326],
                        [589.641, 715.326],
                        [589.641, 722.277],
                        [579.438, 722.277],
                        [579.438, 696.114],
                        [590.726, 696.114],
                        [603.546, 705.757],
                        [599.696, 713.382],
                        [603.957, 721.455],
                        [603.957, 722.277],
                        [593.679, 722.277],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [572.412, 722.277],
                        [562.209, 722.277],
                        [562.209, 704.3],
                        [556.64, 704.3],
                        [556.64, 696.114],
                        [577.982, 696.114],
                        [577.982, 704.3],
                        [572.412, 704.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.361, -0.299],
                        [-0.473, 0],
                        [-0.35, 0.287],
                        [-0.024, 0.523],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.183, -1.545],
                        [1.77, -0.685],
                        [2.117, 0],
                        [1.769, 0.685],
                        [1.196, 1.545],
                        [0, 2.168],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0.499],
                        [0.361, 0.299],
                        [0.523, 0],
                        [0.349, -0.286],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.168],
                        [-1.183, 1.545],
                        [-1.768, 0.685],
                        [-2.119, 0],
                        [-1.769, -0.685],
                        [-1.196, -1.545],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [541.391, 712.821],
                        [541.932, 714.017],
                        [543.184, 714.466],
                        [544.492, 714.036],
                        [545.053, 712.821],
                        [545.053, 696.114],
                        [555.257, 696.114],
                        [555.257, 712.859],
                        [553.481, 718.428],
                        [549.052, 721.773],
                        [543.222, 722.801],
                        [537.391, 721.773],
                        [532.943, 718.428],
                        [531.149, 712.859],
                        [531.149, 696.114],
                        [541.391, 696.114],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.075, -2.89],
                        [0, 0],
                        [0.397, 0.212],
                        [0.549, 0],
                        [0.424, -0.237],
                        [0, -0.498],
                        [-1.071, -0.099],
                        [-0.598, -0.062],
                        [-1.034, -0.174],
                        [-0.61, -0.224],
                        [-0.773, -0.411],
                        [-0.436, -0.535],
                        [-0.298, -0.797],
                        [0, -0.972],
                        [0.498, -1.058],
                        [0.809, -0.66],
                        [1.121, -0.435],
                        [1.133, -0.175],
                        [1.247, 0],
                        [2.342, 1.645],
                        [0.024, 3.139],
                        [0, 0],
                        [-1.545, 0],
                        [0, 0.847],
                        [0.373, 0.224],
                        [0.923, 0.1],
                        [0.947, 0.137],
                        [1.159, 0.374],
                        [0.697, 0.573],
                        [0.486, 0.984],
                        [0, 1.346],
                        [-2.367, 1.545],
                        [-3.388, 0],
                        [-2.242, -1.52],
                      ],
                      o: [
                        [0, 0],
                        [-0.024, -0.398],
                        [-0.399, -0.211],
                        [-0.523, 0],
                        [-0.424, 0.237],
                        [0, 0.822],
                        [1.047, 0.075],
                        [0.598, 0.062],
                        [1.034, 0.174],
                        [0.61, 0.225],
                        [0.772, 0.411],
                        [0.436, 0.536],
                        [0.3, 0.798],
                        [0, 1.321],
                        [-0.499, 1.06],
                        [-0.809, 0.661],
                        [-1.121, 0.436],
                        [-1.135, 0.174],
                        [-3.512, 0],
                        [-2.342, -1.645],
                        [0, 0],
                        [0, 1.072],
                        [1.395, 0],
                        [0, -0.424],
                        [-0.374, -0.225],
                        [-1.371, -0.124],
                        [-0.947, -0.137],
                        [-1.159, -0.374],
                        [-0.699, -0.573],
                        [-0.487, -0.984],
                        [0, -2.566],
                        [2.367, -1.545],
                        [3.538, 0],
                        [2.243, 1.52],
                      ],
                      v: [
                        [528.796, 704.225],
                        [518.891, 704.225],
                        [518.256, 703.309],
                        [516.836, 702.991],
                        [515.415, 703.347],
                        [514.781, 704.449],
                        [516.386, 705.832],
                        [518.854, 706.038],
                        [521.302, 706.393],
                        [523.769, 706.991],
                        [525.843, 707.944],
                        [527.656, 709.364],
                        [528.758, 711.363],
                        [529.207, 714.017],
                        [528.46, 717.587],
                        [526.497, 720.165],
                        [523.601, 721.81],
                        [520.218, 722.726],
                        [516.648, 722.988],
                        [507.866, 720.521],
                        [504.315, 713.345],
                        [514.48, 713.345],
                        [516.798, 714.952],
                        [518.891, 713.681],
                        [518.331, 712.709],
                        [516.386, 712.223],
                        [512.911, 711.831],
                        [509.753, 711.065],
                        [506.969, 709.644],
                        [505.193, 707.308],
                        [504.464, 703.814],
                        [508.015, 697.647],
                        [516.648, 695.329],
                        [525.32, 697.61],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 2,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Layer 11",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [624.84, 714.897],
                        [622.453, 708.488],
                        [620.023, 714.897],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [628.402, 722.983],
                        [627.396, 720.679],
                        [617.467, 720.679],
                        [616.463, 722.983],
                        [608.712, 722.983],
                        [621.28, 694.831],
                        [623.583, 694.831],
                        [636.152, 722.983],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.6, 0.601],
                        [0, 0.978],
                        [0.6, 0.586],
                        [1.007, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [1.007, 0],
                        [0.6, -0.6],
                        [0, -0.977],
                        [-0.6, -0.586],
                        [0, 0],
                      ],
                      v: [
                        [591.284, 701.618],
                        [591.284, 708.111],
                        [594.971, 708.111],
                        [597.38, 707.21],
                        [598.281, 704.844],
                        [597.38, 702.497],
                        [594.971, 701.618],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.011, -1.746],
                        [0, -2.849],
                        [2.905, -1.787],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.1, 0],
                        [2.011, 1.746],
                        [0, 3.463],
                        [0, 0],
                      ],
                      v: [
                        [608.544, 722.983],
                        [600.5, 722.983],
                        [594.259, 714.395],
                        [591.284, 714.395],
                        [591.284, 722.983],
                        [584.079, 722.983],
                        [584.079, 695.334],
                        [594.93, 695.334],
                        [602.596, 697.952],
                        [605.612, 704.844],
                        [601.255, 712.719],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [565.394, 722.983],
                        [565.394, 701.827],
                        [557.101, 701.827],
                        [557.101, 695.334],
                        [580.895, 695.334],
                        [580.895, 701.827],
                        [572.601, 701.827],
                        [572.601, 722.983],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.659],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.921, -0.907],
                        [-1.508, 0],
                        [-0.922, 0.907],
                        [0, 1.564],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.318, -2.276],
                        [3.518, 0],
                        [2.291, 2.248],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.564],
                        [0.923, 0.907],
                        [1.508, 0],
                        [0.922, -0.907],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.603],
                        [-2.318, 2.277],
                        [-3.575, 0],
                        [-2.29, -2.248],
                      ],
                      v: [
                        [529.62, 711.253],
                        [529.62, 695.334],
                        [536.825, 695.334],
                        [536.825, 711.798],
                        [538.206, 715.505],
                        [541.852, 716.867],
                        [545.497, 715.505],
                        [546.88, 711.798],
                        [546.88, 695.334],
                        [554.084, 695.334],
                        [554.084, 711.253],
                        [550.607, 720.072],
                        [541.852, 723.486],
                        [533.054, 720.114],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.005, 1.927],
                        [0, 0],
                        [-0.949, -0.601],
                        [-1.313, 0],
                        [0, 1.34],
                        [0.364, 0.307],
                        [1.146, 0.503],
                        [0, 0],
                        [0, 3.492],
                        [-1.8, 1.508],
                        [-2.848, 0],
                        [-1.843, -3.714],
                        [0, 0],
                        [0.698, 0.461],
                        [0.95, 0],
                        [0, -1.173],
                        [-0.35, -0.32],
                        [-0.893, -0.363],
                        [0, 0],
                        [-1.131, -1.298],
                        [0, -1.927],
                        [1.913, -1.564],
                        [2.933, 0],
                        [1.941, 1.104],
                      ],
                      o: [
                        [0, 0],
                        [0.893, 1.173],
                        [0.949, 0.601],
                        [1.899, 0],
                        [0, -0.586],
                        [-0.363, -0.307],
                        [0, 0],
                        [-4.665, -2.067],
                        [0, -2.43],
                        [1.802, -1.508],
                        [4.468, 0],
                        [0, 0],
                        [-0.698, -1.033],
                        [-0.699, -0.461],
                        [-1.648, 0],
                        [0, 0.503],
                        [0.349, 0.322],
                        [0, 0],
                        [2.457, 1.034],
                        [1.131, 1.299],
                        [0, 2.625],
                        [-1.913, 1.564],
                        [-2.458, 0],
                        [-1.942, -1.103],
                      ],
                      v: [
                        [504.525, 717.286],
                        [509.385, 713.599],
                        [512.149, 716.259],
                        [515.543, 717.16],
                        [518.392, 715.149],
                        [517.846, 713.809],
                        [515.585, 712.593],
                        [512.736, 711.337],
                        [505.74, 703],
                        [508.441, 697.093],
                        [515.417, 694.831],
                        [524.885, 700.403],
                        [520.067, 704.09],
                        [517.972, 701.848],
                        [515.501, 701.157],
                        [513.03, 702.916],
                        [513.553, 704.152],
                        [515.417, 705.179],
                        [518.601, 706.519],
                        [523.984, 710.017],
                        [525.681, 714.856],
                        [522.811, 721.14],
                        [515.543, 723.486],
                        [508.945, 721.831],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 2,
          op: 4,
          st: 2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Layer 10",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [604.708, 718.788],
                        [614.629, 699.334],
                        [625.968, 699.334],
                        [636.056, 718.788],
                        [615.991, 718.788],
                        [618.743, 711.84],
                        [622.716, 711.84],
                        [620.299, 706.699],
                        [615.296, 718.788],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.667],
                        [1.39, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -0.695],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.39, 0],
                      ],
                      v: [
                        [595.006, 707.394],
                        [592.977, 706.032],
                        [590.226, 706.032],
                        [590.226, 708.756],
                        [592.977, 708.756],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.112, -4.085],
                        [2.834, -0.723],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [10.088, 0],
                        [-0.027, 2.029],
                        [0, 0],
                      ],
                      v: [
                        [605.289, 718.788],
                        [595.478, 718.788],
                        [590.226, 712.035],
                        [590.226, 718.788],
                        [585.752, 718.788],
                        [585.863, 701.391],
                        [585.363, 701.391],
                        [585.334, 718.788],
                        [580.804, 718.788],
                        [580.804, 699.334],
                        [591.199, 699.334],
                        [604.399, 706.921],
                        [600.342, 711.785],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [563.46, 706.616],
                        [572.799, 706.616],
                        [572.799, 718.788],
                        [563.46, 718.788],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [556.262, 699.334],
                        [579.996, 699.334],
                        [579.996, 706.282],
                        [556.262, 706.282],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 1.5],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.002, -0.055],
                      ],
                      o: [
                        [1.307, -0.055],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 6.364],
                        [0, 0],
                      ],
                      v: [
                        [543.531, 712.063],
                        [546.004, 709.728],
                        [546.004, 699.334],
                        [555.481, 699.334],
                        [555.481, 709.728],
                        [543.559, 719.344],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 6.309],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.223, -0.111],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.445],
                        [0, 0],
                        [-5.92, -0.111],
                      ],
                      v: [
                        [531.358, 709.728],
                        [531.358, 699.334],
                        [540.751, 699.334],
                        [540.751, 709.728],
                        [543.114, 712.063],
                        [543.114, 719.344],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.584, 0.195],
                        [0, 0.222],
                        [0.528, 0.195],
                        [1.307, 0.278],
                        [0, 0],
                        [-0.444, -0.111],
                        [0, 0],
                        [0, 0],
                        [-0.472, -0.195],
                        [-0.722, -0.334],
                        [-0.195, -0.056],
                        [-0.389, -0.361],
                        [-0.361, -0.639],
                        [0, -0.556],
                        [2.224, -1.001],
                        [3.557, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.307, 0],
                        [0.362, -0.111],
                        [0, -0.278],
                        [-0.556, -0.222],
                        [0, 0],
                        [0.36, 0.056],
                        [0, 0],
                        [0, 0],
                        [1.001, 0.278],
                        [0.417, 0.167],
                        [0.139, 0.083],
                        [0.723, 0.389],
                        [0.472, 0.389],
                        [0.306, 0.528],
                        [0, 1.973],
                        [-2.279, 1.001],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [517.71, 714.23],
                        [517.849, 714.23],
                        [520.545, 713.925],
                        [521.128, 713.369],
                        [520.323, 712.646],
                        [517.655, 711.924],
                        [517.6, 706.227],
                        [518.822, 706.477],
                        [520.795, 706.893],
                        [522.935, 707.449],
                        [525.131, 708.116],
                        [526.548, 708.728],
                        [527.104, 708.95],
                        [528.855, 710.089],
                        [530.05, 711.507],
                        [530.494, 713.119],
                        [526.881, 717.788],
                        [517.96, 719.26],
                        [517.766, 719.26],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.694, 0.306],
                        [1.389, 0.028],
                        [0, 0],
                        [0, -4.03],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -0.445],
                        [-0.695, -0.306],
                        [0, 0],
                        [8.337, 0.028],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [521.49, 705.588],
                        [520.518, 704.448],
                        [517.544, 703.92],
                        [517.488, 698.973],
                        [530.077, 705.504],
                        [530.077, 706.921],
                        [521.49, 706.921],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.472, 0.111],
                        [0.418, 0.055],
                        [1.028, 0.389],
                        [0.611, 0.361],
                        [0.5, 0.584],
                        [0, 0.778],
                        [-3.612, 0.028],
                        [0, 0],
                        [0.528, -0.195],
                        [0, -0.278],
                        [-0.556, -0.222],
                        [-0.973, -0.222],
                        [0, 0],
                      ],
                      o: [
                        [-0.584, -0.111],
                        [-0.584, -0.139],
                        [-1.056, -0.25],
                        [-1.139, -0.334],
                        [-0.806, -0.473],
                        [-0.584, -0.695],
                        [0, -5.809],
                        [0, 0],
                        [-1.001, 0],
                        [-0.501, 0.195],
                        [0, 0.278],
                        [0.528, 0.195],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [516.404, 711.646],
                        [514.959, 711.34],
                        [513.375, 711.007],
                        [510.318, 710.089],
                        [507.595, 708.95],
                        [505.705, 707.394],
                        [504.898, 705.282],
                        [517.126, 698.973],
                        [517.155, 703.92],
                        [514.904, 704.225],
                        [514.237, 704.893],
                        [515.015, 705.532],
                        [517.182, 706.143],
                        [517.266, 711.84],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.307, 0.973],
                        [0, 2.057],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.723, -0.278],
                        [-1.278, -0.055],
                        [0, 0],
                      ],
                      o: [
                        [-2.39, -1.001],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.306],
                        [0.778, 0.306],
                        [0, 0],
                        [-3.474, -0.028],
                      ],
                      v: [
                        [508.567, 717.899],
                        [504.649, 712.924],
                        [504.649, 712.73],
                        [504.621, 711.534],
                        [513.209, 711.534],
                        [513.209, 712.841],
                        [514.292, 713.73],
                        [517.266, 714.23],
                        [517.35, 719.26],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 4,
          op: 6,
          st: 4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Layer 9",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [623.078, 711.246],
                        [620.631, 704.91],
                        [618.181, 711.246],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [625.418, 717.366],
                        [615.842, 717.366],
                        [614.15, 721.758],
                        [605.51, 721.758],
                        [616.526, 696.558],
                        [624.879, 696.558],
                        [635.894, 721.758],
                        [627.11, 721.758],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.576, 0.517],
                        [1.151, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.575, 0.517],
                        [0, 0.936],
                      ],
                      o: [
                        [-0.575, -0.516],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.151, 0],
                        [0.576, -0.516],
                        [0, -0.936],
                      ],
                      v: [
                        [595.61, 703.884],
                        [593.018, 703.11],
                        [589.922, 703.11],
                        [589.922, 709.014],
                        [593.018, 709.014],
                        [595.61, 708.24],
                        [596.474, 706.063],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.729, -0.768],
                        [-0.936, -1.44],
                        [0, -1.92],
                        [0.816, -1.344],
                        [1.535, -0.792],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.328, 0],
                        [1.729, 0.768],
                        [0.936, 1.44],
                        [0, 1.8],
                        [-0.816, 1.344],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [592.226, 715.422],
                        [589.922, 715.422],
                        [589.922, 721.758],
                        [581.426, 721.758],
                        [581.426, 696.558],
                        [593.558, 696.558],
                        [599.642, 697.711],
                        [603.638, 701.023],
                        [605.043, 706.063],
                        [603.818, 710.778],
                        [600.291, 713.982],
                        [605.582, 721.758],
                        [596.511, 721.758],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [563.355, 703.147],
                        [555.974, 703.147],
                        [555.974, 696.558],
                        [579.23, 696.558],
                        [579.23, 703.147],
                        [571.851, 703.147],
                        [571.851, 721.758],
                        [563.355, 721.758],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.16, 2.088],
                        [0, 3.768],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.66, -0.84],
                        [-1.248, 0],
                        [-0.66, 0.84],
                        [0, 1.848],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.16, -2.088],
                        [3.864, 0],
                      ],
                      o: [
                        [-2.16, -2.088],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.848],
                        [0.66, 0.84],
                        [1.248, 0],
                        [0.66, -0.84],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.768],
                        [-2.16, 2.088],
                        [-3.863, 0],
                      ],
                      v: [
                        [533.043, 719.202],
                        [529.803, 710.419],
                        [529.803, 696.558],
                        [538.299, 696.558],
                        [538.299, 710.167],
                        [539.289, 714.198],
                        [542.15, 715.459],
                        [545.013, 714.198],
                        [546.003, 710.167],
                        [546.003, 696.558],
                        [554.355, 696.558],
                        [554.355, 710.419],
                        [551.115, 719.202],
                        [542.078, 722.335],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.979, 0.468],
                        [1.271, 0.792],
                        [0, 0],
                        [-1.488, -0.419],
                        [-1.368, 0],
                        [-0.504, 0.229],
                        [0, 0.456],
                        [0.66, 0.264],
                        [1.512, 0.312],
                        [1.296, 0.468],
                        [0.959, 1.068],
                        [0, 1.824],
                        [-0.888, 1.296],
                        [-1.764, 0.744],
                        [-2.521, 0],
                        [-1.669, -0.372],
                        [-1.272, -0.72],
                        [0, 0],
                        [2.208, 0],
                        [0, -1.056],
                        [-0.648, -0.252],
                        [-1.489, -0.288],
                        [-1.32, -0.468],
                        [-0.973, -1.056],
                        [0, -1.824],
                        [0.888, -1.284],
                        [1.764, -0.756],
                        [2.52, 0],
                      ],
                      o: [
                        [-1.979, -0.468],
                        [0, 0],
                        [1.199, 0.696],
                        [1.488, 0.42],
                        [1.176, 0],
                        [0.504, -0.228],
                        [0, -0.528],
                        [-0.66, -0.264],
                        [-1.944, -0.408],
                        [-1.296, -0.468],
                        [-0.961, -1.068],
                        [0, -1.584],
                        [0.888, -1.296],
                        [1.765, -0.744],
                        [1.728, 0],
                        [1.668, 0.372],
                        [0, 0],
                        [-2.473, -1.248],
                        [-2.185, 0],
                        [0, 0.504],
                        [0.648, 0.252],
                        [1.919, 0.36],
                        [1.318, 0.468],
                        [0.973, 1.057],
                        [0, 1.584],
                        [-0.888, 1.284],
                        [-1.765, 0.756],
                        [-2.089, 0],
                      ],
                      v: [
                        [509.661, 721.632],
                        [504.783, 719.742],
                        [507.519, 713.55],
                        [511.551, 715.224],
                        [515.835, 715.855],
                        [518.355, 715.512],
                        [519.111, 714.486],
                        [518.121, 713.298],
                        [514.863, 712.434],
                        [510.003, 711.12],
                        [506.619, 708.816],
                        [505.179, 704.479],
                        [506.511, 700.158],
                        [510.488, 697.098],
                        [516.915, 695.982],
                        [522.009, 696.541],
                        [526.419, 698.178],
                        [523.863, 704.335],
                        [516.843, 702.462],
                        [513.566, 704.046],
                        [514.539, 705.18],
                        [517.743, 705.99],
                        [522.603, 707.232],
                        [526.041, 709.518],
                        [527.499, 713.838],
                        [526.167, 718.14],
                        [522.189, 721.2],
                        [515.763, 722.335],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 6,
          op: 8,
          st: 6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Layer 8",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [624.83, 710.488],
                        [622.614, 704.876],
                        [622.319, 704.876],
                        [620.104, 710.488],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [618.036, 715.803],
                        [616.707, 719.053],
                        [608.732, 719.053],
                        [617.888, 698.378],
                        [627.044, 698.378],
                        [636.2, 719.053],
                        [628.226, 719.053],
                        [626.897, 715.803],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [590.422, 703.99],
                        [590.422, 706.648],
                        [598.396, 706.648],
                        [598.396, 703.99],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [598.692, 719.053],
                        [595.148, 711.964],
                        [590.422, 711.964],
                        [590.422, 719.053],
                        [583.186, 719.053],
                        [583.186, 698.378],
                        [605.632, 698.378],
                        [605.632, 711.964],
                        [602.826, 711.964],
                        [606.371, 719.053],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [579.199, 704.286],
                        [571.815, 704.286],
                        [571.815, 719.053],
                        [564.58, 719.053],
                        [564.58, 704.286],
                        [557.196, 704.286],
                        [557.196, 698.378],
                        [579.199, 698.378],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.768, -0.787],
                        [-1.123, 0],
                        [-0.768, 0.788],
                        [0, 1.181],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.077, -1.92],
                        [3.326, 0],
                        [2.077, 1.92],
                        [0, 2.855],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.768, 0.788],
                        [1.122, 0],
                        [0.768, -0.787],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.855],
                        [-2.077, 1.92],
                        [-3.328, 0],
                        [-2.077, -1.92],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.181],
                      ],
                      v: [
                        [539.445, 712.85],
                        [542.282, 714.031],
                        [545.116, 712.85],
                        [546.268, 709.897],
                        [546.268, 698.378],
                        [553.504, 698.378],
                        [553.504, 709.897],
                        [550.388, 717.059],
                        [542.282, 719.939],
                        [534.174, 717.059],
                        [531.058, 709.897],
                        [531.058, 698.378],
                        [538.294, 698.378],
                        [538.294, 709.897],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [504.478, 708.864],
                        [504.478, 698.378],
                        [525.742, 698.378],
                        [525.742, 705.467],
                        [518.801, 705.467],
                        [518.801, 703.99],
                        [511.713, 703.99],
                        [511.713, 705.024],
                        [525.742, 708.42],
                        [525.742, 719.053],
                        [504.478, 719.053],
                        [504.478, 711.964],
                        [511.417, 711.964],
                        [511.417, 713.442],
                        [518.506, 713.442],
                        [518.506, 712.26],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 8,
          op: 10,
          st: 8,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Layer 7",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [624.733, 710.02],
                        [622.197, 704.881],
                        [619.659, 710.02],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [636.113, 719.006],
                        [628.542, 719.006],
                        [627.062, 715.709],
                        [617.333, 715.709],
                        [615.851, 719.006],
                        [608.322, 719.006],
                        [617.138, 699.379],
                        [627.296, 699.379],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 1.64],
                        [0.863, 0],
                        [0, 0],
                        [0, 0],
                        [-0.943, 0],
                      ],
                      o: [
                        [0, -1.641],
                        [-0.863, 0],
                        [0, 0],
                        [0, 0],
                        [0.943, 0],
                      ],
                      v: [
                        [601.791, 706.762],
                        [599.249, 705.062],
                        [592.039, 705.062],
                        [592.039, 708.594],
                        [599.354, 708.594],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -6.983],
                        [1.912, -1.046],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.115, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [1.861, 0],
                        [0, 3.647],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.875, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [599.967, 699.311],
                        [608.447, 706.545],
                        [604.835, 713.195],
                        [608.857, 719.005],
                        [601.309, 718.971],
                        [597.967, 714.346],
                        [592.039, 714.346],
                        [592.039, 719.006],
                        [585.52, 719.006],
                        [585.52, 699.311],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [583.805, 699.311],
                        [583.805, 705.83],
                        [574.07, 705.83],
                        [574.07, 719.006],
                        [567.55, 719.006],
                        [567.55, 705.83],
                        [557.815, 705.83],
                        [557.815, 699.311],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.591, 3.062],
                        [-0.005, 6.087],
                        [0, 0],
                        [0, 0],
                        [0, -2.408],
                        [-5.334, 0],
                        [0, 2.407],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.146, -2.207],
                        [5.3, 0],
                      ],
                      o: [
                        [-1.147, -2.207],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.407],
                        [5.335, 0],
                        [0, -2.408],
                        [0, 0],
                        [0, 0],
                        [0.005, 6.087],
                        [-1.591, 3.062],
                        [-5.299, 0],
                      ],
                      v: [
                        [532.236, 715.134],
                        [530.867, 704.044],
                        [530.867, 699.311],
                        [537.768, 699.311],
                        [537.768, 708.124],
                        [543.646, 713.604],
                        [549.368, 708.124],
                        [549.368, 699.311],
                        [556.267, 699.311],
                        [556.269, 704.044],
                        [554.9, 715.134],
                        [543.646, 719.006],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -5.691],
                        [1.104, 0],
                        [5.153, 0],
                        [0, -0.727],
                        [-4.944, -0.404],
                        [0, -5.06],
                        [11.279, 0],
                        [0, 5.732],
                        [-0.832, 0],
                        [-5.025, 0],
                        [0, 0.901],
                        [5.151, 0.424],
                        [0, 5.062],
                        [-8.674, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -1.035],
                        [-4.988, 0],
                        [0.046, 0.518],
                        [10.921, 0.892],
                        [0, 5.046],
                        [-10.387, 0],
                        [0, 0],
                        [0, 1.172],
                        [4.723, 0],
                        [0, -0.631],
                        [-7.99, -0.656],
                        [0, -5.822],
                        [10.293, 0],
                      ],
                      v: [
                        [529.3, 706.498],
                        [522.344, 706.498],
                        [516.791, 704.885],
                        [511.389, 705.851],
                        [516.732, 706.798],
                        [529.989, 712.901],
                        [516.815, 719.006],
                        [504.838, 712.247],
                        [511.593, 712.247],
                        [517.109, 713.686],
                        [522.356, 712.833],
                        [516.624, 711.898],
                        [504.564, 705.787],
                        [516.624, 699.372],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 10,
          op: 12,
          st: 10,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Layer 6",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [1.163, 0],
                        [0, 0],
                        [0, -1.163],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -1.163],
                        [0, 0],
                        [-1.163, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [627.571, 707.414],
                        [627.571, 702.182],
                        [625.827, 700.438],
                        [624.083, 700.438],
                        [622.338, 702.182],
                        [622.338, 707.414],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.163, 0],
                        [0, 0],
                        [0, -1.163],
                        [0, 0],
                        [0, 0],
                        [0, 3.488],
                        [0, 0],
                        [-3.488, 0],
                        [0, 0],
                        [0, -3.488],
                        [0, 0],
                        [2.325, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -1.163],
                        [0, 0],
                        [-1.163, 0],
                        [0, 0],
                        [0, 0],
                        [-3.488, 0],
                        [0, 0],
                        [0, -3.488],
                        [0, 0],
                        [3.488, 0],
                        [0, 0],
                        [0, 3.488],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [627.571, 712.647],
                        [625.827, 710.903],
                        [624.083, 710.903],
                        [622.338, 712.647],
                        [622.338, 721.367],
                        [618.85, 721.367],
                        [613.618, 716.135],
                        [613.618, 702.182],
                        [618.85, 696.95],
                        [631.059, 696.95],
                        [636.291, 702.182],
                        [636.291, 716.135],
                        [632.803, 721.367],
                        [627.571, 721.367],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 1.163],
                        [0, 0],
                        [1.163, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -1.163],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.163, 0],
                      ],
                      v: [
                        [601.253, 705.67],
                        [601.253, 702.182],
                        [599.508, 700.438],
                        [596.02, 700.438],
                        [596.02, 707.414],
                        [599.508, 707.414],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 3.488],
                        [0, 0],
                        [-3.418, 0],
                        [0, 0],
                        [-0.07, -3.488],
                        [0, 0],
                        [1.837, -1.163],
                        [0, -1.163],
                        [0, 0],
                        [2.325, 0],
                        [0, 0],
                        [0, 0],
                        [1.163, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.418, 0],
                        [0, 0],
                        [0, -3.488],
                        [0, 0],
                        [3.418, 0],
                        [0, 0],
                        [0, 1.163],
                        [1.837, 1.163],
                        [0, 0],
                        [0, 2.325],
                        [0, 0],
                        [0, 0],
                        [0, -1.151],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [596.02, 721.367],
                        [592.427, 721.367],
                        [587.3, 716.135],
                        [587.3, 702.182],
                        [592.427, 696.95],
                        [604.741, 696.95],
                        [609.973, 702.182],
                        [609.973, 705.67],
                        [607.217, 709.159],
                        [609.973, 712.647],
                        [609.973, 717.879],
                        [606.485, 721.367],
                        [601.253, 721.367],
                        [601.253, 712.629],
                        [599.508, 710.903],
                        [596.02, 710.903],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.163, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.325],
                        [0, 0],
                        [0, -1.163],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.488, 0.081],
                        [0, 0],
                        [0, -3.488],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-2.337, 0],
                        [0, 0],
                        [-1.163, -0.081],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -3.407],
                        [0, 0],
                        [3.488, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.163],
                      ],
                      v: [
                        [574.655, 700.438],
                        [574.655, 721.367],
                        [569.44, 721.367],
                        [565.935, 717.879],
                        [565.935, 700.56],
                        [564.191, 702.182],
                        [564.191, 705.792],
                        [555.47, 705.792],
                        [555.47, 702.182],
                        [560.703, 696.95],
                        [579.888, 696.95],
                        [585.12, 702.182],
                        [585.12, 705.67],
                        [576.399, 705.67],
                        [576.399, 702.182],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, -4.651],
                        [0, 0],
                        [4.651, 0],
                        [0, 0],
                        [0, 4.651],
                        [0, 0],
                        [-4.651, 0],
                        [0, 0],
                        [0, 0],
                        [-1.174, 0],
                        [0, 0],
                        [0, 1.163],
                      ],
                      o: [
                        [0, 0],
                        [4.651, 0],
                        [0, 0],
                        [0, 4.651],
                        [0, 0],
                        [-4.651, 0],
                        [0, 0],
                        [0, -4.628],
                        [0, 0],
                        [0, 0],
                        [0, 1.163],
                        [0, 0],
                        [1.163, 0],
                        [0, 0],
                      ],
                      v: [
                        [544.343, 696.95],
                        [546.087, 696.95],
                        [553.064, 703.926],
                        [553.064, 714.391],
                        [546.087, 721.367],
                        [537.367, 721.367],
                        [530.391, 714.391],
                        [530.391, 703.891],
                        [537.367, 696.95],
                        [539.111, 696.95],
                        [539.111, 716.135],
                        [540.872, 717.879],
                        [542.599, 717.879],
                        [544.343, 716.135],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.163, 0],
                        [0, 0],
                        [0, 1.244],
                        [0, 0],
                        [1.07, 0],
                        [0, 0],
                        [0, 3.488],
                        [0, 0],
                        [-3.488, 0],
                        [0, 0],
                        [0, -3.488],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.163, 0],
                        [0, 0],
                        [0, -1.139],
                        [0, 0],
                        [-1.163, 0],
                        [0, 0],
                        [0, -3.488],
                        [0, 0],
                        [3.488, 0],
                        [0, 0],
                        [0, 3.488],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.163, 0],
                        [0, 0],
                        [0, -1.163],
                        [0, 0],
                        [-3.488, 0],
                        [0, 0],
                        [0, -3.488],
                        [0, 0],
                        [3.488, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.139],
                        [0, 0],
                        [-1.163, 0],
                        [0, 0],
                        [0, 1.256],
                        [0, 0],
                        [3.488, 0],
                        [0, 0],
                        [0, 3.488],
                        [0, 0],
                        [-3.488, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.128],
                      ],
                      v: [
                        [514.851, 717.879],
                        [516.595, 717.879],
                        [518.339, 716.013],
                        [518.339, 712.647],
                        [516.734, 710.903],
                        [509.619, 710.903],
                        [504.386, 705.67],
                        [504.386, 702.182],
                        [509.619, 696.95],
                        [521.827, 696.95],
                        [527.059, 702.182],
                        [527.059, 705.67],
                        [518.339, 705.67],
                        [518.339, 702.147],
                        [516.595, 700.438],
                        [514.851, 700.438],
                        [513.107, 702.147],
                        [513.107, 705.531],
                        [514.851, 707.414],
                        [521.827, 707.414],
                        [527.059, 712.647],
                        [527.059, 716.135],
                        [521.827, 721.367],
                        [509.619, 721.367],
                        [504.386, 716.135],
                        [504.386, 712.647],
                        [513.107, 712.647],
                        [513.107, 716.187],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 12,
          op: 14,
          st: 12,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Layer 5",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [619.11, 712.561],
                        [625.662, 712.561],
                        [622.386, 699.791],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [608.397, 724.744],
                        [617.89, 693.574],
                        [626.964, 693.574],
                        [636.458, 724.744],
                        [628.939, 724.744],
                        [627.343, 719.073],
                        [617.429, 719.073],
                        [615.874, 724.744],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.392, 0.126],
                        [-0.28, 0.323],
                        [-0.14, 0.504],
                        [0, 0.756],
                        [0.14, 0.491],
                        [0.279, 0.266],
                        [0.392, 0.127],
                        [0.504, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.504, 0],
                        [0.392, -0.126],
                        [0.279, -0.322],
                        [0.14, -0.504],
                        [0, -0.756],
                        [-0.14, -0.489],
                        [-0.28, -0.266],
                        [-0.392, -0.126],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [591.091, 707.856],
                        [595.627, 707.856],
                        [596.971, 707.668],
                        [597.979, 706.995],
                        [598.609, 705.756],
                        [598.819, 703.866],
                        [598.609, 701.996],
                        [597.979, 700.862],
                        [596.971, 700.274],
                        [595.627, 700.085],
                        [591.091, 700.085],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.107, -0.322],
                        [-0.827, -0.77],
                        [-0.463, -1.316],
                        [0, -2.016],
                        [0.253, -0.966],
                        [0.419, -0.658],
                        [0.546, -0.42],
                        [0.588, -0.308],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.35, 0.014],
                        [0.336, 0],
                        [0.35, 0],
                        [0.308, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [1.315, 0],
                        [1.107, 0.322],
                        [0.825, 0.771],
                        [0.462, 1.317],
                        [0, 1.373],
                        [-0.252, 0.966],
                        [-0.422, 0.658],
                        [-0.547, 0.42],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.28, 0],
                        [-0.351, -0.014],
                        [-0.336, 0],
                        [-0.351, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [583.823, 724.744],
                        [583.823, 693.574],
                        [596.93, 693.574],
                        [600.562, 694.057],
                        [603.461, 695.695],
                        [605.394, 698.825],
                        [606.087, 703.823],
                        [605.709, 707.332],
                        [604.701, 709.768],
                        [603.252, 711.385],
                        [601.549, 712.477],
                        [607.138, 724.744],
                        [599.744, 724.744],
                        [595.081, 713.654],
                        [594.136, 713.633],
                        [593.107, 713.612],
                        [592.078, 713.612],
                        [591.091, 713.612],
                        [591.091, 724.744],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [565.255, 724.744],
                        [565.255, 700.085],
                        [557.064, 700.085],
                        [557.064, 693.574],
                        [580.673, 693.574],
                        [580.673, 700.085],
                        [572.481, 700.085],
                        [572.481, 724.744],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.735, 0],
                        [1.497, 0.28],
                        [1.148, 0.757],
                        [0.63, 1.359],
                        [0, 2.129],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.407, -0.574],
                        [-0.77, -0.266],
                        [-1.093, 0],
                        [-0.77, 0.267],
                        [-0.406, 0.574],
                        [0, 0.924],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.631, -1.358],
                        [1.148, -0.756],
                        [1.485, -0.279],
                      ],
                      o: [
                        [-1.737, 0],
                        [-1.5, -0.279],
                        [-1.149, -0.756],
                        [-0.63, -1.358],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.924],
                        [0.406, 0.574],
                        [0.77, 0.267],
                        [1.064, 0],
                        [0.77, -0.266],
                        [0.406, -0.574],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.129],
                        [-0.629, 1.359],
                        [-1.149, 0.757],
                        [-1.484, 0.28],
                      ],
                      v: [
                        [541.942, 724.954],
                        [537.091, 724.533],
                        [533.12, 722.979],
                        [530.453, 719.808],
                        [529.508, 714.578],
                        [529.508, 693.574],
                        [536.775, 693.574],
                        [536.775, 714.536],
                        [537.385, 716.783],
                        [539.148, 718.043],
                        [541.942, 718.443],
                        [544.693, 718.043],
                        [546.457, 716.783],
                        [547.066, 714.536],
                        [547.066, 693.574],
                        [554.334, 693.574],
                        [554.334, 714.578],
                        [553.388, 719.808],
                        [550.722, 722.979],
                        [546.772, 724.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.269, 0],
                        [0.882, 0.056],
                        [0.852, 0.098],
                        [0.798, 0.14],
                        [0.672, 0.168],
                        [0, 0],
                        [-1.092, -0.056],
                        [-1.134, -0.028],
                        [-1.008, 0],
                        [-0.686, 0.154],
                        [-0.365, 0.378],
                        [0, 0.672],
                        [0, 0],
                        [0.489, 0.393],
                        [0.757, 0],
                        [0, 0],
                        [1.623, 1.373],
                        [0, 3.249],
                        [0, 0],
                        [-1.791, 1.471],
                        [-3.362, 0],
                        [-1.149, -0.126],
                        [-1.036, -0.168],
                        [-0.784, -0.195],
                        [0, 0],
                        [1.595, 0.084],
                        [1.315, 0],
                        [0.672, -0.154],
                        [0.365, -0.406],
                        [0, -0.784],
                        [0, 0],
                        [-0.518, -0.42],
                        [-1.064, 0],
                        [0, 0],
                        [-1.26, -0.714],
                        [-0.629, -1.245],
                        [0, -1.568],
                        [0, 0],
                        [0.909, -1.345],
                        [1.666, -0.476],
                      ],
                      o: [
                        [-0.868, 0],
                        [-0.883, -0.056],
                        [-0.855, -0.097],
                        [-0.798, -0.14],
                        [0, 0],
                        [0.896, 0.084],
                        [1.092, 0.056],
                        [1.134, 0.028],
                        [0.98, 0],
                        [0.686, -0.154],
                        [0.364, -0.378],
                        [0, 0],
                        [0, -0.757],
                        [-0.491, -0.392],
                        [0, 0],
                        [-3.136, 0],
                        [-1.625, -1.372],
                        [0, 0],
                        [0, -3.052],
                        [1.792, -1.47],
                        [1.232, 0],
                        [1.148, 0.126],
                        [1.037, 0.168],
                        [0, 0],
                        [-1.288, -0.084],
                        [-1.596, -0.084],
                        [-0.868, 0],
                        [-0.672, 0.154],
                        [-0.365, 0.406],
                        [0, 0],
                        [0, 0.897],
                        [0.518, 0.42],
                        [0, 0],
                        [1.933, 0],
                        [1.26, 0.714],
                        [0.631, 1.247],
                        [0, 0],
                        [0, 2.577],
                        [-0.911, 1.344],
                        [-1.666, 0.476],
                      ],
                      v: [
                        [514.595, 724.995],
                        [511.969, 724.912],
                        [509.366, 724.68],
                        [506.886, 724.324],
                        [504.681, 723.862],
                        [504.681, 718.106],
                        [507.663, 718.317],
                        [511.003, 718.443],
                        [514.217, 718.485],
                        [516.716, 718.253],
                        [518.292, 717.455],
                        [518.837, 715.88],
                        [518.837, 715.082],
                        [518.102, 713.36],
                        [516.232, 712.772],
                        [513.796, 712.772],
                        [506.656, 710.713],
                        [504.22, 703.782],
                        [504.22, 702.311],
                        [506.907, 695.527],
                        [514.637, 693.322],
                        [518.207, 693.511],
                        [521.483, 693.952],
                        [524.214, 694.498],
                        [524.214, 700.211],
                        [519.888, 699.959],
                        [515.519, 699.833],
                        [513.208, 700.064],
                        [511.654, 700.904],
                        [511.108, 702.69],
                        [511.108, 703.278],
                        [511.885, 705.252],
                        [514.259, 705.882],
                        [517.158, 705.882],
                        [521.946, 706.953],
                        [524.781, 709.894],
                        [525.727, 714.116],
                        [525.727, 715.67],
                        [524.362, 721.551],
                        [520.497, 724.282],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 14,
          op: 16,
          st: 14,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Layer 4",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.102, -0.23],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.114, 0.102],
                        [0.154, 0],
                        [0, 0],
                        [0.114, -0.102],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.103, -0.23],
                        [-0.115, -0.102],
                        [0, 0],
                        [-0.153, 0],
                        [-0.114, 0.102],
                      ],
                      v: [
                        [622.519, 703.133],
                        [620.721, 708.948],
                        [626.728, 708.948],
                        [624.93, 703.133],
                        [624.605, 702.636],
                        [624.202, 702.483],
                        [623.246, 702.483],
                        [622.845, 702.636],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.114, 0.587],
                        [-0.178, 0.511],
                        [0, 0],
                        [-0.305, 0.446],
                        [-0.765, 0],
                        [0, 0],
                        [-0.306, -0.446],
                        [-0.281, -0.663],
                        [0, 0],
                        [-0.115, -0.586],
                        [0, -0.689],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -0.689],
                        [0.115, -0.586],
                        [0, 0],
                        [0.254, -0.637],
                        [0.307, -0.446],
                        [0, 0],
                        [0.713, 0],
                        [0.306, 0.446],
                        [0, 0],
                        [0.177, 0.511],
                        [0.114, 0.587],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [616.398, 711.052],
                        [616.57, 709.139],
                        [617.01, 707.494],
                        [619.459, 701.22],
                        [620.3, 699.594],
                        [621.908, 698.925],
                        [625.657, 698.925],
                        [627.187, 699.594],
                        [628.067, 701.258],
                        [630.707, 707.494],
                        [631.147, 709.139],
                        [631.319, 711.052],
                        [631.319, 712.238],
                        [616.398, 712.238],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.114, 0.6],
                        [0.203, 0.561],
                        [0, 0],
                        [0.345, 0.485],
                        [0.841, 0],
                        [0, 0],
                        [0.356, -0.484],
                        [0.28, -0.714],
                        [0, 0],
                        [0.114, -0.599],
                        [0, -0.713],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, -0.713],
                        [-0.114, -0.599],
                        [0, 0],
                        [-0.306, -0.739],
                        [-0.345, -0.484],
                        [0, 0],
                        [-0.868, 0],
                        [-0.358, 0.485],
                        [0, 0],
                        [-0.203, 0.561],
                        [-0.115, 0.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [631.319, 719.201],
                        [631.701, 719.201],
                        [631.701, 711.052],
                        [631.529, 709.082],
                        [631.051, 707.341],
                        [628.411, 701.105],
                        [627.436, 699.269],
                        [625.657, 698.542],
                        [621.908, 698.542],
                        [620.072, 699.269],
                        [619.115, 701.067],
                        [616.666, 707.341],
                        [616.188, 709.082],
                        [616.016, 711.052],
                        [616.016, 719.201],
                        [616.398, 719.201],
                        [616.398, 712.621],
                        [631.319, 712.621],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.216, -0.216],
                        [0.485, 0],
                        [0, 0],
                        [0.216, 0.217],
                        [0, 0.485],
                        [0, 0],
                        [-0.243, 0.995],
                        [-0.511, 1.326],
                        [0, 0],
                        [-0.319, 0.204],
                        [-0.511, 0],
                        [0, 0],
                        [-0.319, -0.204],
                        [-0.153, -0.433],
                        [0, 0],
                        [-0.243, -0.995],
                        [0, -0.765],
                        [0, 0],
                        [0.216, -0.216],
                        [0.485, 0],
                        [0, 0],
                        [0.243, 0.217],
                        [0, 0.485],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0.485],
                        [-0.217, 0.217],
                        [0, 0],
                        [-0.486, 0],
                        [-0.218, -0.216],
                        [0, 0],
                        [0, -0.765],
                        [0.243, -0.995],
                        [0, 0],
                        [0.153, -0.433],
                        [0.319, -0.204],
                        [0, 0],
                        [0.484, 0],
                        [0.32, 0.204],
                        [0, 0],
                        [0.51, 1.326],
                        [0.241, 0.995],
                        [0, 0],
                        [0, 0.485],
                        [-0.217, 0.217],
                        [0, 0],
                        [-0.511, 0],
                        [-0.243, -0.216],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [620.34, 721.267],
                        [620.014, 722.318],
                        [618.961, 722.644],
                        [613.339, 722.644],
                        [612.286, 722.318],
                        [611.961, 721.267],
                        [611.961, 711.167],
                        [612.324, 708.527],
                        [613.453, 705.046],
                        [616.781, 696.362],
                        [617.489, 695.405],
                        [618.732, 695.099],
                        [629.024, 695.099],
                        [630.227, 695.405],
                        [630.935, 696.362],
                        [634.264, 705.046],
                        [635.393, 708.527],
                        [635.757, 711.167],
                        [635.757, 721.267],
                        [635.431, 722.318],
                        [634.378, 722.644],
                        [628.602, 722.644],
                        [627.473, 722.318],
                        [627.11, 721.267],
                        [627.11, 715.911],
                        [620.34, 715.911],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.23, 0.344],
                        [0, 0.511],
                        [0, 0],
                        [0.229, 0.345],
                        [0.662, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0.662, 0],
                        [0.229, -0.345],
                        [0, 0],
                        [0, -0.51],
                        [-0.23, -0.344],
                        [0, 0],
                      ],
                      v: [
                        [592.757, 701.985],
                        [592.757, 706.882],
                        [595.626, 706.882],
                        [596.965, 706.366],
                        [597.309, 705.084],
                        [597.309, 703.783],
                        [596.965, 702.502],
                        [595.626, 701.985],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.651, -0.739],
                        [0, -1.173],
                        [0, 0],
                        [0.536, -0.765],
                        [1.582, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [1.351, 0],
                        [0.65, 0.74],
                        [0, 0],
                        [0, 1.403],
                        [-0.536, 0.765],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [596.965, 698.925],
                        [599.968, 700.034],
                        [600.944, 702.903],
                        [600.944, 705.581],
                        [600.14, 708.833],
                        [596.965, 709.981],
                        [588.625, 709.981],
                        [588.625, 698.925],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.688, -0.625],
                        [0, -1.25],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.766, 0.702],
                        [1.556, 0],
                        [0, 0],
                        [-0.306, 0.752],
                        [0, 1.173],
                        [0, 0],
                        [0.714, 0.829],
                        [1.479, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [1.454, 0],
                        [0.689, 0.625],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.326],
                        [-0.764, -0.702],
                        [0, 0],
                        [0.919, -0.28],
                        [0.306, -0.752],
                        [0, 0],
                        [0, -1.249],
                        [-0.714, -0.829],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [588.625, 719.201],
                        [588.625, 710.326],
                        [599.451, 710.326],
                        [602.664, 711.263],
                        [603.698, 714.074],
                        [603.698, 719.201],
                        [604.08, 719.201],
                        [604.08, 714.074],
                        [602.932, 711.033],
                        [599.451, 709.981],
                        [599.03, 709.981],
                        [600.867, 708.432],
                        [601.326, 705.543],
                        [601.326, 702.903],
                        [600.255, 699.786],
                        [596.965, 698.542],
                        [588.242, 698.542],
                        [588.242, 719.201],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.484, 0],
                        [0, 0],
                        [0.217, 0.217],
                        [0, 0.485],
                        [0, 0],
                        [-0.217, 0.217],
                        [-0.484, 0],
                        [0, 0],
                        [-1.084, -1.186],
                        [0, -1.887],
                        [0, 0],
                        [0.319, -0.752],
                        [0.791, -0.331],
                        [-0.855, -0.969],
                        [0, -1.606],
                        [0, 0],
                        [0.216, -0.216],
                        [0.484, 0],
                        [0, 0],
                        [0.216, 0.217],
                        [0, 0.485],
                        [0, 0],
                        [0.216, 0.268],
                        [0.536, 0],
                        [0, 0],
                        [0, 0],
                        [0.216, -0.216],
                      ],
                      o: [
                        [0, 0],
                        [-0.484, 0],
                        [-0.217, -0.216],
                        [0, 0],
                        [0, -0.484],
                        [0.217, -0.216],
                        [0, 0],
                        [2.856, 0],
                        [1.083, 1.186],
                        [0, 0],
                        [0, 1.071],
                        [-0.32, 0.753],
                        [1.378, 0.153],
                        [0.854, 0.969],
                        [0, 0],
                        [0, 0.485],
                        [-0.218, 0.217],
                        [0, 0],
                        [-0.486, 0],
                        [-0.217, -0.216],
                        [0, 0],
                        [0, -0.56],
                        [-0.218, -0.268],
                        [0, 0],
                        [0, 0],
                        [0, 0.485],
                        [-0.218, 0.217],
                      ],
                      v: [
                        [591.38, 722.644],
                        [585.487, 722.644],
                        [584.436, 722.318],
                        [584.111, 721.267],
                        [584.111, 696.476],
                        [584.436, 695.425],
                        [585.487, 695.099],
                        [598.227, 695.099],
                        [604.138, 696.878],
                        [605.763, 701.488],
                        [605.763, 703.248],
                        [605.286, 705.983],
                        [603.621, 707.609],
                        [606.969, 709.292],
                        [608.25, 713.156],
                        [608.25, 721.267],
                        [607.926, 722.318],
                        [606.873, 722.644],
                        [600.944, 722.644],
                        [599.891, 722.318],
                        [599.565, 721.267],
                        [599.565, 715.413],
                        [599.242, 714.17],
                        [598.113, 713.768],
                        [592.757, 713.768],
                        [592.757, 721.267],
                        [592.432, 722.318],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [568.731, 719.201],
                        [569.114, 719.201],
                        [569.114, 698.925],
                        [576.957, 698.925],
                        [576.957, 698.542],
                        [560.89, 698.542],
                        [560.89, 698.925],
                        [568.731, 698.925],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.485, 0],
                        [0, 0],
                        [0.217, 0.217],
                        [0, 0.485],
                        [0, 0],
                        [0, 0],
                        [0.217, 0.217],
                        [0, 0.485],
                        [0, 0],
                        [-0.217, 0.217],
                        [-0.485, 0],
                        [0, 0],
                        [-0.216, -0.216],
                        [0, -0.484],
                        [0, 0],
                        [0.218, -0.216],
                        [0.485, 0],
                        [0, 0],
                        [0, 0],
                        [0.217, -0.216],
                      ],
                      o: [
                        [0, 0],
                        [-0.485, 0],
                        [-0.217, -0.216],
                        [0, 0],
                        [0, 0],
                        [-0.485, 0],
                        [-0.217, -0.216],
                        [0, 0],
                        [0, -0.484],
                        [0.217, -0.216],
                        [0, 0],
                        [0.485, 0],
                        [0.218, 0.217],
                        [0, 0],
                        [0, 0.485],
                        [-0.216, 0.217],
                        [0, 0],
                        [0, 0],
                        [0, 0.485],
                        [-0.216, 0.217],
                      ],
                      v: [
                        [572.022, 722.644],
                        [565.824, 722.644],
                        [564.772, 722.318],
                        [564.447, 721.267],
                        [564.447, 702.483],
                        [558.824, 702.483],
                        [557.771, 702.158],
                        [557.446, 701.105],
                        [557.446, 696.476],
                        [557.771, 695.425],
                        [558.824, 695.099],
                        [579.023, 695.099],
                        [580.074, 695.425],
                        [580.4, 696.476],
                        [580.4, 701.105],
                        [580.074, 702.158],
                        [579.023, 702.483],
                        [573.399, 702.483],
                        [573.399, 721.267],
                        [573.073, 722.318],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.059, -0.956],
                        [-2.984, 0],
                        [-1.071, 0.957],
                        [0, 1.53],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.02, -0.88],
                        [2.831, 0],
                        [1.008, 0.88],
                        [0, 1.428],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 1.53],
                        [1.057, 0.957],
                        [2.985, 0],
                        [1.071, -0.956],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.428],
                        [-1.02, 0.88],
                        [-2.831, 0],
                        [-1.007, -0.88],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [534.493, 698.542],
                        [534.493, 714.495],
                        [536.081, 718.225],
                        [542.143, 719.66],
                        [548.227, 718.225],
                        [549.833, 714.495],
                        [549.833, 698.542],
                        [549.451, 698.542],
                        [549.451, 714.495],
                        [547.921, 717.957],
                        [542.143, 719.277],
                        [536.386, 717.957],
                        [534.875, 714.495],
                        [534.875, 698.542],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.345, -0.957],
                        [0.88, -0.675],
                        [1.53, -0.382],
                        [2.372, 0],
                        [1.53, 0.382],
                        [0.88, 0.676],
                        [0.345, 0.956],
                        [0, 1.174],
                        [0, 0],
                        [-0.217, 0.217],
                        [-0.485, 0],
                        [0, 0],
                        [-0.218, -0.216],
                        [0, -0.484],
                        [0, 0],
                        [-0.383, -0.345],
                        [-1.326, 0],
                        [-0.382, 0.344],
                        [0, 0.511],
                        [0, 0],
                        [-0.216, 0.217],
                        [-0.485, 0],
                        [0, 0],
                        [-0.218, -0.216],
                        [0, -0.484],
                      ],
                      o: [
                        [0, 1.174],
                        [-0.344, 0.956],
                        [-0.88, 0.676],
                        [-1.53, 0.382],
                        [-2.372, 0],
                        [-1.531, -0.382],
                        [-0.88, -0.675],
                        [-0.345, -0.957],
                        [0, 0],
                        [0, -0.484],
                        [0.216, -0.216],
                        [0, 0],
                        [0.484, 0],
                        [0.216, 0.217],
                        [0, 0],
                        [0, 0.511],
                        [0.382, 0.344],
                        [1.351, 0],
                        [0.383, -0.345],
                        [0, 0],
                        [0, -0.484],
                        [0.217, -0.216],
                        [0, 0],
                        [0.484, 0],
                        [0.216, 0.217],
                        [0, 0],
                      ],
                      v: [
                        [553.926, 715.413],
                        [553.411, 718.608],
                        [551.575, 721.056],
                        [547.959, 722.644],
                        [542.106, 723.218],
                        [536.253, 722.644],
                        [532.638, 721.056],
                        [530.801, 718.608],
                        [530.285, 715.413],
                        [530.285, 696.476],
                        [530.61, 695.425],
                        [531.662, 695.099],
                        [537.669, 695.099],
                        [538.721, 695.425],
                        [539.045, 696.476],
                        [539.045, 713.921],
                        [539.62, 715.203],
                        [542.183, 715.719],
                        [544.784, 715.203],
                        [545.357, 713.921],
                        [545.357, 696.476],
                        [545.682, 695.425],
                        [546.735, 695.099],
                        [552.55, 695.099],
                        [553.603, 695.425],
                        [553.926, 696.476],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.714, -0.65],
                        [0, -1.02],
                        [0, 0],
                        [-0.42, -0.395],
                        [-0.663, -0.127],
                        [0, 0],
                        [-0.421, -0.446],
                        [0, -1.199],
                        [0, 0],
                        [0.702, -0.65],
                        [1.173, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.765, 0.727],
                        [0, 1.122],
                        [0, 0],
                        [0.497, 0.511],
                        [0.765, 0.153],
                        [0, 0],
                        [0.357, 0.357],
                        [0, 0.893],
                        [0, 0],
                        [-0.637, 0.587],
                        [-1.071, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.173, 0],
                        [-0.714, 0.651],
                        [0, 0],
                        [0, 1.046],
                        [0.421, 0.396],
                        [0, 0],
                        [0.714, 0.153],
                        [0.421, 0.447],
                        [0, 0],
                        [0, 1.02],
                        [-0.702, 0.651],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.3, 0],
                        [0.765, -0.727],
                        [0, 0],
                        [0, -1.326],
                        [-0.497, -0.51],
                        [0, 0],
                        [-0.586, -0.102],
                        [-0.358, -0.357],
                        [0, 0],
                        [0, -0.893],
                        [0.637, -0.586],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [521.486, 698.542],
                        [512.992, 698.542],
                        [510.161, 699.518],
                        [509.09, 702.024],
                        [509.09, 705.161],
                        [509.721, 707.322],
                        [511.348, 708.106],
                        [519.42, 709.752],
                        [521.122, 710.65],
                        [521.753, 713.118],
                        [521.753, 715.337],
                        [520.701, 717.843],
                        [517.89, 718.818],
                        [508.364, 718.818],
                        [508.364, 719.201],
                        [517.89, 719.201],
                        [520.989, 718.11],
                        [522.136, 715.337],
                        [522.136, 713.118],
                        [521.39, 710.363],
                        [519.496, 709.369],
                        [511.424, 707.724],
                        [510.009, 707.035],
                        [509.473, 705.161],
                        [509.473, 702.024],
                        [510.429, 699.805],
                        [512.992, 698.925],
                        [521.486, 698.925],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.449, 0],
                        [0, 0],
                        [0.216, 0.217],
                        [0, 0.485],
                        [0, 0],
                        [-0.218, 0.217],
                        [-0.485, 0],
                        [0, 0],
                        [-0.166, 0.217],
                        [0, 0.306],
                        [0.166, 0.204],
                        [0.33, 0.025],
                        [0, 0],
                        [1.083, 0.918],
                        [0, 2.142],
                        [0, 0],
                        [-1.403, 1.161],
                        [-2.474, 0],
                        [0, 0],
                        [-0.216, -0.216],
                        [0, -0.484],
                        [0, 0],
                        [0.217, -0.216],
                        [0.485, 0],
                        [0, 0],
                        [0.178, -0.216],
                        [0, -0.331],
                        [-0.178, -0.23],
                        [-0.332, -0.025],
                        [0, 0],
                        [-1.097, -0.918],
                        [0, -2.142],
                        [0, 0],
                        [1.416, -1.16],
                      ],
                      o: [
                        [0, 0],
                        [-0.485, 0],
                        [-0.218, -0.216],
                        [0, 0],
                        [0, -0.485],
                        [0.216, -0.216],
                        [0, 0],
                        [0.33, 0],
                        [0.166, -0.216],
                        [0, -0.433],
                        [-0.166, -0.204],
                        [0, 0],
                        [-1.785, -0.254],
                        [-1.084, -0.918],
                        [0, 0],
                        [0, -2.167],
                        [1.402, -1.16],
                        [0, 0],
                        [0.485, 0],
                        [0.217, 0.217],
                        [0, 0],
                        [0, 0.485],
                        [-0.216, 0.217],
                        [0, 0],
                        [-0.332, 0],
                        [-0.178, 0.217],
                        [0, 0.332],
                        [0.178, 0.229],
                        [0, 0],
                        [1.785, 0.255],
                        [1.096, 0.918],
                        [0, 0],
                        [0, 2.168],
                        [-1.416, 1.161],
                      ],
                      v: [
                        [518.387, 722.644],
                        [506.298, 722.644],
                        [505.247, 722.318],
                        [504.921, 721.267],
                        [504.921, 716.791],
                        [505.247, 715.739],
                        [506.298, 715.413],
                        [516.589, 715.413],
                        [517.335, 715.088],
                        [517.584, 714.304],
                        [517.335, 713.347],
                        [516.589, 713.003],
                        [510.851, 712.2],
                        [506.547, 710.44],
                        [504.921, 705.849],
                        [504.921, 701.832],
                        [507.025, 696.84],
                        [512.84, 695.099],
                        [523.552, 695.099],
                        [524.603, 695.425],
                        [524.929, 696.476],
                        [524.929, 701.029],
                        [524.603, 702.081],
                        [523.552, 702.406],
                        [514.638, 702.406],
                        [513.873, 702.732],
                        [513.605, 703.554],
                        [513.873, 704.396],
                        [514.638, 704.778],
                        [520.338, 705.543],
                        [524.661, 707.303],
                        [526.306, 711.894],
                        [526.306, 715.911],
                        [524.183, 720.903],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 16,
          op: 18,
          st: 16,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 5,
          nm: "SUTRA",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [68.187, -12.712, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 37.2609596252441,
                    f: "HoeflerText-BlackItalic",
                    t: "SUTRA",
                    j: 0,
                    tr: 0,
                    lh: 44.713151550293,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 18,
          op: 20,
          st: 18,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 5,
          nm: "SUTRA",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [64.572, -14.974, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 41.5951995849609,
                    f: "ClementePDap-HeavyItalic",
                    t: "SUTRA",
                    j: 0,
                    tr: 0,
                    lh: 49.9142395019531,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 20,
          op: 22,
          st: 20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 5,
          nm: "SUTRA",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [68.248, -18.456, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 51.9145317077637,
                    f: "AkzidenzGroteskBQ-XBdCndIt",
                    t: "SUTRA",
                    j: 0,
                    tr: 0,
                    lh: 62.2974380493164,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 22,
          op: 24,
          st: 22,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 5,
          nm: "SUTRA",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [62.607, -16.923, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 39.1662902832031,
                    f: "KomikaAxis",
                    t: "SUTRA",
                    j: 0,
                    tr: 0,
                    lh: 46.9995483398437,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 24,
          op: 26,
          st: 24,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: "Layer 3",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [623.799, 703.531],
                        [623.547, 703.531],
                        [620.482, 711.217],
                        [626.655, 711.217],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [626.991, 722.808],
                        [626.991, 718.021],
                        [629.174, 718.021],
                        [628.167, 715.249],
                        [618.885, 715.249],
                        [617.752, 718.021],
                        [620.314, 718.021],
                        [620.314, 722.808],
                        [609.562, 722.808],
                        [609.562, 718.021],
                        [612.04, 718.021],
                        [619.851, 700.297],
                        [616.072, 700.297],
                        [616.072, 695.51],
                        [625.437, 695.51],
                        [635.264, 718.021],
                        [637.868, 718.021],
                        [637.868, 722.808],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.116, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.604],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.284, 0],
                        [0, -2.73],
                      ],
                      v: [
                        [595.2, 700.297],
                        [592.429, 700.297],
                        [592.429, 707.94],
                        [595.117, 707.94],
                        [600.703, 703.909],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -5.502],
                        [3.107, -1.218],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.761, 0],
                        [0, 3.696],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [601.543, 722.808],
                        [596.125, 712.392],
                        [592.429, 712.392],
                        [592.429, 718.021],
                        [595.369, 718.021],
                        [595.369, 722.808],
                        [583.862, 722.808],
                        [583.862, 718.021],
                        [586.801, 718.021],
                        [586.801, 700.297],
                        [583.862, 700.297],
                        [583.862, 695.51],
                        [596.209, 695.51],
                        [606.75, 703.783],
                        [601.543, 711.217],
                        [605.239, 718.021],
                        [608.514, 718.021],
                        [608.514, 722.808],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [576.512, 705.169],
                        [575.672, 700.297],
                        [571.346, 700.297],
                        [571.346, 718.021],
                        [575.798, 718.021],
                        [575.798, 722.808],
                        [561.519, 722.808],
                        [561.519, 718.021],
                        [565.719, 718.021],
                        [565.719, 700.297],
                        [561.393, 700.297],
                        [560.553, 705.169],
                        [555.766, 704.749],
                        [556.606, 695.51],
                        [580.461, 695.51],
                        [581.3, 704.749],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [8.735, 0],
                        [0, 5.879],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.368, 0],
                        [0, 4.074],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 5.796],
                        [-8.316, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.738],
                        [4.284, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [551.315, 700.297],
                        [551.315, 711.51],
                        [539.64, 723.227],
                        [528.3, 711.973],
                        [528.3, 700.297],
                        [525.864, 700.297],
                        [525.864, 695.51],
                        [537.12, 695.51],
                        [537.12, 700.297],
                        [533.928, 700.297],
                        [533.928, 711.805],
                        [539.933, 718.272],
                        [546.065, 711.51],
                        [546.065, 700.297],
                        [542.873, 700.297],
                        [542.873, 695.51],
                        [553.75, 695.51],
                        [553.75, 700.297],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [6.635, 0],
                        [2.141, 1.89],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.966, 0],
                        [0, 2.772],
                        [3.023, 0.545],
                        [0, 5.88],
                        [-4.913, 0],
                        [-1.764, -1.848],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.344, 0],
                        [0, -1.975],
                        [-2.478, -0.545],
                        [0, -6.972],
                      ],
                      o: [
                        [-3.696, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.89, 1.008],
                        [2.941, 0],
                        [0, -3.024],
                        [-3.948, -0.714],
                        [0, -6.089],
                        [3.57, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.428, -0.966],
                        [-2.225, 0],
                        [0, 2.309],
                        [3.36, 0.714],
                        [0, 5.627],
                      ],
                      v: [
                        [512.679, 723.227],
                        [502.809, 720.372],
                        [503.649, 713.611],
                        [508.143, 714.03],
                        [508.101, 717.348],
                        [512.258, 718.398],
                        [517.34, 715.207],
                        [511.713, 710.965],
                        [503.061, 703.405],
                        [512.972, 695.09],
                        [521.834, 698.113],
                        [522.086, 704.035],
                        [517.592, 704.456],
                        [517.214, 701.263],
                        [513.014, 700.046],
                        [509.109, 702.692],
                        [513.812, 706.177],
                        [523.388, 714.535],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 26,
          op: 28,
          st: 26,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 5,
          nm: "SUTRA",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [67.357, -11.806, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 35.5577697753906,
                    f: "CooperBlack",
                    t: "SUTRA",
                    j: 0,
                    tr: 0,
                    lh: 42.6693237304687,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 28,
          op: 30,
          st: 28,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 5,
          nm: "SUTRA",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [68.772, -13.799, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 40.5858497619629,
                    f: "PlantagenetCherokee",
                    t: "SUTRA",
                    j: 0,
                    tr: 0,
                    lh: 48.7030197143555,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 30,
          op: 32,
          st: 30,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: "Layer 2",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [620.415, 709.908],
                        [624.917, 709.908],
                        [622.854, 704.282],
                        [622.478, 704.282],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [1.951, 0],
                        [0.774, 0.726],
                        [0, 1.05],
                        [-0.15, 0.563],
                        [-0.151, 0.4],
                        [0, 0],
                        [0, 0],
                        [-1, 0.749],
                        [-1.776, 0],
                        [-1.013, -0.751],
                        [-0.576, -1.501],
                        [0, 0],
                        [0, -0.751],
                        [0.774, -0.726],
                        [1.2, 0],
                        [1.05, 2.501],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.05, 2.501],
                        [-1.2, 0],
                        [-0.776, -0.726],
                        [0, -0.326],
                        [0.15, -0.563],
                        [0, 0],
                        [0, 0],
                        [0.6, -1.501],
                        [1, -0.751],
                        [1.776, 0],
                        [1.011, 0.749],
                        [0, 0],
                        [0.625, 1.5],
                        [0, 1.05],
                        [-0.776, 0.726],
                        [-1.951, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [618.727, 716.66],
                        [617.789, 718.911],
                        [613.288, 722.662],
                        [610.325, 721.574],
                        [609.162, 718.911],
                        [609.387, 717.579],
                        [609.838, 716.135],
                        [610.1, 715.535],
                        [616.102, 699.78],
                        [618.502, 696.405],
                        [622.666, 695.28],
                        [626.849, 696.405],
                        [629.23, 699.78],
                        [635.232, 715.535],
                        [636.17, 718.911],
                        [635.008, 721.574],
                        [632.044, 722.662],
                        [627.543, 718.911],
                        [626.605, 716.66],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.462, 0.4],
                        [0, 0.575],
                        [0.463, 0.4],
                        [0.825, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.825, 0],
                        [0.463, -0.4],
                        [0, -0.575],
                        [-0.462, -0.401],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [592.47, 706.532],
                        [596.221, 706.532],
                        [598.152, 705.932],
                        [598.846, 704.469],
                        [598.152, 703.007],
                        [596.221, 702.406],
                        [592.47, 702.406],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.726, -0.675],
                        [1.3, 0],
                        [0.726, 0.675],
                        [0, 1.15],
                        [0, 0],
                        [-0.724, 0.676],
                        [-1.3, 0],
                        [0, 0],
                        [-1.852, -1.488],
                        [0, -2.651],
                        [0.287, -0.751],
                        [0.399, -0.438],
                        [0.4, -0.312],
                        [0.299, -0.126],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1],
                        [0.762, -0.675],
                        [1.475, 0],
                        [0.563, 0.311],
                        [0.299, 0.325],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 1.15],
                        [-0.724, 0.675],
                        [-1.3, 0],
                        [-0.724, -0.675],
                        [0, 0],
                        [0, -1.15],
                        [0.726, -0.676],
                        [0, 0],
                        [3.802, 0],
                        [1.85, 1.488],
                        [0, 0.876],
                        [-0.289, 0.749],
                        [-0.401, 0.438],
                        [-0.401, 0.313],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.001, 1.251],
                        [0, 1.15],
                        [-0.763, 0.675],
                        [-0.552, 0],
                        [-0.563, -0.313],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [594.721, 712.91],
                        [592.47, 712.91],
                        [592.47, 718.911],
                        [591.381, 721.65],
                        [588.343, 722.662],
                        [585.304, 721.65],
                        [584.218, 718.911],
                        [584.218, 699.78],
                        [585.304, 697.042],
                        [588.343, 696.029],
                        [596.221, 696.029],
                        [604.699, 698.261],
                        [607.475, 704.469],
                        [607.043, 706.908],
                        [606.012, 708.689],
                        [604.811, 709.814],
                        [603.761, 710.472],
                        [603.348, 710.659],
                        [603.348, 711.033],
                        [607.098, 715.535],
                        [608.6, 718.911],
                        [607.456, 721.65],
                        [604.099, 722.662],
                        [602.429, 722.194],
                        [601.135, 721.237],
                        [600.722, 720.787],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.726, 0.676],
                        [0, 1.151],
                        [-0.726, 0.676],
                        [-1.3, 0],
                        [0, 0],
                        [-0.726, -0.676],
                        [0, -1.15],
                        [0.726, -0.676],
                        [1.3, 0],
                        [0, 0],
                        [0, 0],
                        [0.726, -0.675],
                        [1.299, 0],
                        [0.726, 0.675],
                        [0, 1.15],
                      ],
                      o: [
                        [0, 0],
                        [-1.3, 0],
                        [-0.726, -0.676],
                        [0, -1.15],
                        [0.726, -0.676],
                        [0, 0],
                        [1.3, 0],
                        [0.726, 0.676],
                        [0, 1.151],
                        [-0.726, 0.676],
                        [0, 0],
                        [0, 0],
                        [0, 1.15],
                        [-0.726, 0.675],
                        [-1.3, 0],
                        [-0.726, -0.675],
                        [0, 0],
                      ],
                      v: [
                        [565.275, 703.532],
                        [561.149, 703.532],
                        [558.11, 702.518],
                        [557.022, 699.78],
                        [558.11, 697.042],
                        [561.149, 696.029],
                        [577.653, 696.029],
                        [580.692, 697.042],
                        [581.78, 699.78],
                        [580.692, 702.518],
                        [577.653, 703.532],
                        [573.527, 703.532],
                        [573.527, 718.911],
                        [572.439, 721.65],
                        [569.401, 722.662],
                        [566.363, 721.65],
                        [565.275, 718.911],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.15, -2.001],
                        [3.7, 0],
                        [2.15, 2.001],
                        [0, 3.252],
                        [0, 0],
                        [-0.726, 0.675],
                        [-1.3, 0],
                        [-0.726, -0.676],
                        [0, -1.15],
                        [0, 0],
                        [-0.675, -0.724],
                        [-1.151, 0],
                        [-0.675, 0.727],
                        [0, 1.3],
                        [0, 0],
                        [-0.726, 0.675],
                        [-1.3, 0],
                        [-0.726, -0.676],
                        [0, -1.15],
                        [0, 0],
                      ],
                      o: [
                        [-2.151, 2.001],
                        [-3.702, 0],
                        [-2.151, -2.001],
                        [0, 0],
                        [0, -1.15],
                        [0.724, -0.676],
                        [1.3, 0],
                        [0.724, 0.675],
                        [0, 0],
                        [0, 1.3],
                        [0.676, 0.727],
                        [1.15, 0],
                        [0.676, -0.724],
                        [0, 0],
                        [0, -1.15],
                        [0.726, -0.676],
                        [1.299, 0],
                        [0.726, 0.675],
                        [0, 0],
                        [0, 3.252],
                      ],
                      v: [
                        [551.359, 720.037],
                        [542.581, 723.037],
                        [533.804, 720.037],
                        [530.577, 712.159],
                        [530.577, 699.405],
                        [531.665, 696.667],
                        [534.703, 695.654],
                        [537.742, 696.667],
                        [538.83, 699.405],
                        [538.83, 712.159],
                        [539.842, 715.197],
                        [542.581, 716.286],
                        [545.318, 715.197],
                        [546.332, 712.159],
                        [546.332, 699.405],
                        [547.42, 696.667],
                        [550.458, 695.654],
                        [553.497, 696.667],
                        [554.584, 699.405],
                        [554.584, 712.159],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.525],
                        [0.688, 0.263],
                        [1.026, 0.201],
                        [1.201, 0.412],
                        [1.025, 0.562],
                        [0.688, 1.063],
                        [0, 1.401],
                        [-1.938, 1.602],
                        [-3.626, 0],
                        [-1.913, -1.375],
                        [0, -1.5],
                        [0.687, -0.6],
                        [1.375, 0],
                        [1.089, 0.875],
                        [0.925, 0],
                        [0.438, -0.287],
                        [0, -0.425],
                        [-0.688, -0.312],
                        [-1.026, -0.226],
                        [-1.2, -0.413],
                        [-1.026, -0.525],
                        [-0.688, -1.026],
                        [0, -1.351],
                        [2.151, -1.714],
                        [3.951, 0],
                        [1.963, 1.374],
                        [0, 1.5],
                        [-0.688, 0.599],
                        [-1.376, 0],
                        [-1.163, -0.875],
                        [-1.076, 0],
                        [-0.6, 0.362],
                      ],
                      o: [
                        [0, -0.35],
                        [-0.688, -0.263],
                        [-1.026, -0.199],
                        [-1.201, -0.412],
                        [-1.026, -0.564],
                        [-0.688, -1.062],
                        [0, -2.55],
                        [1.937, -1.6],
                        [3.051, 0],
                        [1.913, 1.376],
                        [0, 1.05],
                        [-0.689, 0.599],
                        [-1.026, 0],
                        [-1.087, -0.875],
                        [-0.876, 0],
                        [-0.438, 0.287],
                        [0, 0.401],
                        [0.688, 0.312],
                        [1.025, 0.224],
                        [1.201, 0.412],
                        [1.025, 0.525],
                        [0.688, 1.026],
                        [0, 2.575],
                        [-2.151, 1.713],
                        [-3.201, 0],
                        [-1.964, -1.376],
                        [0, -1.05],
                        [0.688, -0.6],
                        [0.975, 0],
                        [1.162, 0.876],
                        [1.3, 0],
                        [0.599, -0.362],
                      ],
                      v: [
                        [518.95, 714.035],
                        [517.918, 713.115],
                        [515.347, 712.421],
                        [512.01, 711.502],
                        [508.671, 710.04],
                        [506.102, 707.601],
                        [505.07, 703.906],
                        [507.977, 697.679],
                        [516.323, 695.28],
                        [523.769, 697.342],
                        [526.638, 701.656],
                        [525.607, 704.132],
                        [522.513, 705.032],
                        [519.342, 703.719],
                        [516.323, 702.406],
                        [514.354, 702.838],
                        [513.697, 703.906],
                        [514.729, 704.976],
                        [517.299, 705.783],
                        [520.637, 706.739],
                        [523.976, 708.146],
                        [526.545, 710.472],
                        [527.576, 714.035],
                        [524.351, 720.468],
                        [515.198, 723.037],
                        [507.452, 720.975],
                        [504.507, 716.66],
                        [505.538, 714.185],
                        [508.634, 713.284],
                        [511.841, 714.597],
                        [515.198, 715.909],
                        [518.049, 715.366],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 32,
          op: 34,
          st: 32,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: "Layer 1",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [570.339, 709.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [622.055, 708.807],
                        [619.2, 715.439],
                        [624.994, 715.439],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.126, -0.294],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0.126, -0.294],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.042, 0],
                        [0, 0],
                      ],
                      v: [
                        [615.718, 723.582],
                        [608.245, 723.582],
                        [622.139, 694.536],
                        [622.642, 694.536],
                        [636.494, 723.582],
                        [628.602, 723.582],
                        [627.511, 721.147],
                        [616.766, 721.147],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 2.772],
                        [3.441, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [3.441, 0],
                        [0, -2.769],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [594.601, 710.948],
                        [599.302, 706.246],
                        [594.601, 701.504],
                        [591.788, 701.504],
                        [591.788, 710.948],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -6.337],
                        [3.358, -1.847],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [7.681, 0],
                        [0, 4.114],
                        [0, 0],
                      ],
                      v: [
                        [606.899, 723.582],
                        [598.63, 723.582],
                        [594.643, 717.034],
                        [591.788, 717.034],
                        [591.788, 723.582],
                        [584.15, 723.582],
                        [584.15, 695.04],
                        [594.979, 695.04],
                        [606.984, 706.246],
                        [601.821, 715.481],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [565.511, 701.461],
                        [557.957, 701.461],
                        [557.957, 695.04],
                        [580.705, 695.04],
                        [580.705, 701.461],
                        [573.15, 701.461],
                        [573.15, 723.582],
                        [565.511, 723.582],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 7.68],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.232, 0],
                        [0, 3.232],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [7.136, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.232],
                        [3.233, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 7.68],
                        [-7.135, 0],
                      ],
                      v: [
                        [530.041, 712.543],
                        [530.041, 695.04],
                        [537.68, 695.04],
                        [537.68, 712.459],
                        [542.422, 717.538],
                        [547.166, 712.459],
                        [547.166, 695.04],
                        [554.805, 695.04],
                        [554.805, 712.543],
                        [542.422, 724.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.763, 2.35],
                        [0, 0],
                        [-2.855, 0],
                        [0, 1.594],
                        [1.889, 0.63],
                        [0, 0],
                        [0, 4.702],
                        [-5.079, 0],
                        [-1.68, -1.973],
                        [0, 0],
                        [2.267, 0],
                        [0, -1.134],
                        [-1.385, -0.461],
                        [0, 0],
                        [0, -4.03],
                        [7.554, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.385, 1.722],
                        [2.267, 0],
                        [0, -1.217],
                        [0, 0],
                        [-4.154, -1.386],
                        [0, -5.623],
                        [5.038, 0],
                        [0, 0],
                        [-1.175, -1.218],
                        [-1.846, 0],
                        [0, 1.091],
                        [0, 0],
                        [5.708, 1.889],
                        [0, 5.245],
                        [-6.422, 0],
                      ],
                      v: [
                        [504.184, 718.713],
                        [509.724, 714.556],
                        [515.684, 717.663],
                        [519.293, 715.397],
                        [516.481, 712.962],
                        [513.123, 711.829],
                        [506.03, 703.224],
                        [515.767, 694.704],
                        [525.254, 698.482],
                        [520.889, 703.057],
                        [516.187, 701],
                        [513.416, 702.973],
                        [515.432, 705.071],
                        [519.209, 706.33],
                        [526.849, 714.978],
                        [516.02, 724.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 34,
          op: 36,
          st: 34,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 5,
          nm: "SUTRA",
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60, 60, 0], ix: 2 },
            a: { a: 0, k: [69.032, -13.929, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 38.2665710449219,
                    f: "NarzissProCyrillic-ExtraBold",
                    t: "SUTRA",
                    j: 0,
                    tr: 0,
                    lh: 45.9198852539063,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 36,
          op: 38,
          st: 36,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: "NarzissProCyrillic-ExtraBold",
        fFamily: "Narziss Pro Cyrillic",
        fStyle: "ExtraBold",
        ascent: 69.9996948242188,
      },
      {
        fName: "PlantagenetCherokee",
        fFamily: "Plantagenet Cherokee",
        fStyle: "Regular",
        ascent: 70.1670532580465,
      },
      {
        fName: "CooperBlack",
        fFamily: "Cooper Black",
        fStyle: "Regular",
        ascent: 67.7734375,
      },
      {
        fName: "KomikaAxis",
        fFamily: "Komika Axis",
        fStyle: "Regular",
        ascent: 88.8332519680262,
      },
      {
        fName: "AkzidenzGroteskBQ-XBdCndIt",
        fFamily: "Akzidenz-Grotesk BQ Extra Conde",
        fStyle: "Bold Italic",
        ascent: 70.599365234375,
      },
      {
        fName: "ClementePDap-HeavyItalic",
        fFamily: "ClementePDap",
        fStyle: "HeavyItalic",
        ascent: 72.9990845080465,
      },
      {
        fName: "HoeflerText-BlackItalic",
        fFamily: "Hoefler Text",
        fStyle: "Black Italic",
        ascent: 74.6997926384211,
      },
      {
        fName: "Ubuntu-Light",
        fFamily: "Ubuntu",
        fStyle: "Light",
        ascent: 77.5985717773438,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "promjena slova brza 2fps (mala slova)",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [536, 155.2, 0], ix: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1 },
        s: { a: 0, k: [144.6, 144.6, 100], ix: 6 },
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 300,
      op: 333,
      st: 300,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: "FULL SERVICE CREATIVE AGENCY",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [531.399, 365, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 56,
                f: "Ubuntu-Light",
                t: "FULL SERVICE CREATIVE AGENCY",
                j: 2,
                tr: 138,
                lh: 67.2,
                ls: 0,
                fc: [1, 1, 1],
              },
              t: 0,
            },
          ],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 333,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "sutra Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [535.2, 251, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [126.6, 126.6, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 225,
                    s: [
                      {
                        i: [
                          [-8.804, 7.478],
                          [0, 12.142],
                          [7.397, 6.151],
                          [16.161, 3.699],
                          [3.456, 1.688],
                          [1.165, 1.93],
                          [0, 2.252],
                          [-3.298, 2.493],
                          [-6.192, 0],
                          [-2.936, -0.803],
                          [-2.171, -1.607],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [8.16, 1.89],
                          [9.809, 0],
                          [7.517, -3.618],
                          [3.658, -5.949],
                          [0, -7.236],
                          [-7.317, -6.754],
                          [-15.438, -3.215],
                          [-3.418, -2.774],
                          [0, -3.618],
                          [3.215, -2.572],
                          [7.879, 0],
                          [3.256, 0.805],
                          [2.732, 1.368],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.881, -0.37],
                          [-7.453, -1.64],
                          [-9.729, 0],
                        ],
                        o: [
                          [8.804, -7.478],
                          [0, -11.096],
                          [-7.398, -6.151],
                          [-6.594, -1.526],
                          [-3.458, -1.688],
                          [-1.166, -1.93],
                          [0, -3.939],
                          [3.296, -2.491],
                          [3.697, 0],
                          [2.934, 0.805],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-5.548, -3.055],
                          [-8.162, -1.888],
                          [-11.74, 0],
                          [-7.519, 3.618],
                          [-3.66, 5.951],
                          [0, 10.534],
                          [7.315, 6.754],
                          [10.21, 2.092],
                          [3.417, 2.774],
                          [0, 3.859],
                          [-3.217, 2.574],
                          [-4.182, 0],
                          [-3.256, -0.803],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0.872, 0.391],
                          [6.915, 2.906],
                          [8.401, 1.849],
                          [17.527, 0],
                        ],
                        v: [
                          [-202.316, -8.684],
                          [-189.109, -38.111],
                          [-200.205, -63.981],
                          [-235.542, -78.755],
                          [-250.618, -83.58],
                          [-257.553, -89.007],
                          [-259.302, -95.278],
                          [-254.357, -104.927],
                          [-240.125, -108.666],
                          [-230.176, -107.459],
                          [-222.517, -103.841],
                          [-218.778, -86.715],
                          [-194.416, -86.715],
                          [-194.778, -122.415],
                          [-215.341, -129.832],
                          [-242.296, -132.666],
                          [-271.181, -127.239],
                          [-287.946, -112.887],
                          [-293.433, -93.107],
                          [-282.458, -67.177],
                          [-248.327, -52.222],
                          [-227.884, -44.926],
                          [-222.758, -35.337],
                          [-227.583, -25.689],
                          [-244.226, -21.83],
                          [-255.382, -23.036],
                          [-264.367, -26.292],
                          [-269.071, -45.106],
                          [-293.554, -45.106],
                          [-293.192, -8.201],
                          [-290.562, -7.06],
                          [-269.01, -0.241],
                          [-241.814, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 299,
                    s: [
                      {
                        i: [
                          [-8.804, 7.478],
                          [0, 12.142],
                          [7.397, 6.151],
                          [16.161, 3.699],
                          [3.456, 1.688],
                          [1.165, 1.93],
                          [0, 2.252],
                          [-3.298, 2.493],
                          [-6.192, 0],
                          [-2.936, -0.803],
                          [-2.171, -1.607],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [8.16, 1.89],
                          [9.809, 0],
                          [7.517, -3.618],
                          [3.658, -5.949],
                          [0, -7.236],
                          [-7.317, -6.754],
                          [-15.438, -3.215],
                          [-3.418, -2.774],
                          [0, -3.618],
                          [3.215, -2.572],
                          [7.879, 0],
                          [5.224, 2.499],
                          [2.732, 1.368],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.881, -0.37],
                          [-7.453, -1.64],
                          [-9.729, 0],
                        ],
                        o: [
                          [8.804, -7.478],
                          [0, -11.096],
                          [-7.398, -6.151],
                          [-6.594, -1.526],
                          [-3.458, -1.688],
                          [-1.166, -1.93],
                          [0, -3.939],
                          [3.296, -2.491],
                          [3.697, 0],
                          [2.934, 0.805],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-5.548, -3.055],
                          [-8.162, -1.888],
                          [-11.74, 0],
                          [-7.519, 3.618],
                          [-3.66, 5.951],
                          [0, 10.534],
                          [7.315, 6.754],
                          [10.21, 2.092],
                          [3.417, 2.774],
                          [0, 3.859],
                          [-3.217, 2.574],
                          [-3.961, 0],
                          [-3.979, -1.903],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0.872, 0.391],
                          [6.915, 2.906],
                          [8.401, 1.849],
                          [17.527, 0],
                        ],
                        v: [
                          [-202.316, -8.684],
                          [-189.109, -38.111],
                          [-200.205, -63.981],
                          [-235.542, -78.755],
                          [-250.618, -83.58],
                          [-257.553, -89.007],
                          [-259.302, -95.278],
                          [-254.357, -104.927],
                          [-240.125, -108.666],
                          [-230.176, -107.459],
                          [-222.517, -103.841],
                          [-219.431, -79.346],
                          [-195.069, -79.346],
                          [-194.778, -122.415],
                          [-215.341, -129.832],
                          [-242.296, -132.666],
                          [-271.181, -127.239],
                          [-287.946, -112.887],
                          [-293.433, -93.107],
                          [-282.458, -67.177],
                          [-248.327, -52.222],
                          [-227.884, -44.926],
                          [-222.758, -35.337],
                          [-227.583, -25.689],
                          [-243.436, -23.804],
                          [-256.567, -27.775],
                          [-267.132, -36.956],
                          [-284.915, -63.775],
                          [-293.008, -63.93],
                          [-293.192, -8.201],
                          [-290.562, -7.06],
                          [-269.01, -0.241],
                          [-241.814, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "s",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "s",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 225,
                    s: [
                      {
                        i: [
                          [-6.192, 3.296],
                          [-4.101, 6.111],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [4.02, -2.05],
                          [5.226, 0],
                          [3.256, 4.061],
                          [0, 9.328],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-7.839, -8.723],
                          [-13.749, 0],
                        ],
                        o: [
                          [6.19, -3.296],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-2.574, 3.78],
                          [-4.021, 2.05],
                          [-6.594, 0],
                          [-3.256, -4.059],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 18.413],
                          [7.839, 8.725],
                          [7.798, 0],
                        ],
                        v: [
                          [-96.726, -2.412],
                          [-81.288, -16.523],
                          [-78.876, 0],
                          [-32.322, 0],
                          [-32.322, -21.106],
                          [-48.001, -24.724],
                          [-48.001, -130.495],
                          [-83.218, -130.495],
                          [-98.776, -130.495],
                          [-98.776, -109.269],
                          [-83.218, -105.65],
                          [-83.218, -36.664],
                          [-93.107, -27.92],
                          [-106.977, -24.845],
                          [-121.751, -30.935],
                          [-126.636, -51.016],
                          [-126.636, -130.495],
                          [-161.853, -130.495],
                          [-176.205, -130.495],
                          [-176.205, -109.269],
                          [-161.853, -105.65],
                          [-161.853, -51.257],
                          [-150.094, -10.553],
                          [-117.711, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 299,
                    s: [
                      {
                        i: [
                          [-6.192, 3.296],
                          [-4.101, 6.111],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [4.02, -2.05],
                          [5.226, 0],
                          [3.256, 4.061],
                          [0, 9.328],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-7.839, -8.723],
                          [-13.749, 0],
                        ],
                        o: [
                          [6.19, -3.296],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-2.574, 3.78],
                          [-4.021, 2.05],
                          [-6.594, 0],
                          [-3.256, -4.059],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 18.413],
                          [7.839, 8.725],
                          [7.798, 0],
                        ],
                        v: [
                          [-96.726, -2.412],
                          [-81.288, -16.523],
                          [-78.876, 0],
                          [-13.322, 0],
                          [-13.322, -21.106],
                          [-48.001, -24.724],
                          [-48.001, -130.495],
                          [-83.218, -130.495],
                          [-111.465, -130.506],
                          [-111.465, -109.28],
                          [-83.218, -105.65],
                          [-83.218, -36.664],
                          [-93.107, -27.92],
                          [-106.977, -24.845],
                          [-121.751, -30.935],
                          [-126.636, -51.016],
                          [-126.636, -130.495],
                          [-161.853, -130.495],
                          [-181.205, -130.99],
                          [-181.205, -109.764],
                          [-161.853, -105.65],
                          [-161.853, -51.257],
                          [-150.094, -10.553],
                          [-117.711, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "u",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "u",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 225,
                    s: [
                      {
                        i: [
                          [-4.221, 0.682],
                          [-3.618, 1.046],
                          [0, 0],
                          [2.25, -0.281],
                          [1.93, 0],
                          [2.129, 2.171],
                          [0, 5.065],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-6.554, -6.673],
                          [-12.142, 0],
                        ],
                        o: [
                          [4.221, -0.684],
                          [0, 0],
                          [-1.609, 0.322],
                          [-2.252, 0.283],
                          [-3.699, 0],
                          [-2.131, -2.171],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 14.473],
                          [6.552, 6.675],
                          [4.101, 0],
                        ],
                        v: [
                          [44.443, 1.508],
                          [56.202, -1.085],
                          [53.187, -26.533],
                          [47.398, -25.629],
                          [41.126, -25.207],
                          [32.383, -28.463],
                          [29.187, -39.317],
                          [29.187, -105.771],
                          [53.187, -105.771],
                          [53.187, -130.495],
                          [29.187, -130.495],
                          [29.187, -162.456],
                          [-5.91, -162.456],
                          [-5.91, -130.495],
                          [-23.88, -130.495],
                          [-23.88, -105.771],
                          [-5.91, -105.771],
                          [-5.91, -39.197],
                          [3.92, -7.478],
                          [31.96, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 299,
                    s: [
                      {
                        i: [
                          [-4.221, 0.682],
                          [-3.618, 1.046],
                          [0, 0],
                          [2.25, -0.281],
                          [1.93, 0],
                          [2.129, 2.171],
                          [0, 5.065],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-6.554, -6.673],
                          [-12.142, 0],
                        ],
                        o: [
                          [4.221, -0.684],
                          [0, 0],
                          [-1.609, 0.322],
                          [-2.252, 0.283],
                          [-3.699, 0],
                          [-2.131, -2.171],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 14.473],
                          [6.552, 6.675],
                          [4.101, 0],
                        ],
                        v: [
                          [44.443, 1.508],
                          [56.202, -1.085],
                          [53.187, -26.533],
                          [47.398, -25.629],
                          [41.126, -25.207],
                          [32.383, -28.463],
                          [29.187, -39.317],
                          [29.187, -105.771],
                          [46.187, -106.256],
                          [46.187, -130.98],
                          [29.187, -130.495],
                          [29.373, -204.456],
                          [-5.723, -204.456],
                          [-5.91, -130.495],
                          [-38.88, -130.266],
                          [-38.88, -105.542],
                          [-5.91, -105.771],
                          [-5.91, -39.197],
                          [3.92, -7.478],
                          [31.96, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "t",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "t",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 225,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-3.096, 1.93],
                          [-4.986, -0.241],
                          [0, 0],
                          [0, 0],
                          [2.491, 0.362],
                          [2.09, 0],
                          [4.743, -3.618],
                          [3.215, -7.074],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [2.412, -3.618],
                          [3.094, -1.93],
                          [0, 0],
                          [0, 0],
                          [-1.93, -0.562],
                          [-2.493, -0.362],
                          [-6.594, 0],
                          [-4.745, 3.618],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [140.988, 0],
                          [140.988, -21.106],
                          [124.103, -24.724],
                          [124.103, -88.645],
                          [132.365, -96.967],
                          [144.485, -99.5],
                          [161.973, -98.535],
                          [165.712, -130.978],
                          [159.079, -132.365],
                          [152.204, -132.907],
                          [135.199, -127.48],
                          [123.259, -111.439],
                          [123.018, -114.334],
                          [121.691, -130.495],
                          [70.192, -130.495],
                          [70.192, -109.269],
                          [88.886, -105.65],
                          [88.886, -24.724],
                          [72.001, -21.106],
                          [72.001, 0],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 299,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-3.096, 1.93],
                          [-4.986, -0.241],
                          [0, 0],
                          [0, 0],
                          [2.491, 0.362],
                          [2.09, 0],
                          [4.743, -3.618],
                          [3.215, -7.074],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [2.412, -3.618],
                          [3.094, -1.93],
                          [0, 0],
                          [0, 0],
                          [-1.93, -0.562],
                          [-2.493, -0.362],
                          [-6.594, 0],
                          [-4.745, 3.618],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [152.046, -0.569],
                          [152.046, -21.675],
                          [124.103, -24.724],
                          [124.103, -88.645],
                          [132.365, -96.967],
                          [144.485, -99.5],
                          [161.973, -98.535],
                          [165.712, -130.978],
                          [159.079, -132.365],
                          [152.204, -132.907],
                          [135.199, -127.48],
                          [123.259, -111.439],
                          [123.018, -114.334],
                          [121.691, -130.495],
                          [70.192, -130.495],
                          [70.385, -105.269],
                          [89.079, -101.65],
                          [88.886, -24.724],
                          [72.001, -21.106],
                          [72.001, 0],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "r",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "r",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 225,
                    s: [
                      {
                        i: [
                          [-5.668, 3.175],
                          [-4.182, 5.307],
                          [-0.886, -2.572],
                          [-0.886, -2.25],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0.04, 1.73],
                          [0, 1.77],
                          [0, 0],
                          [9.89, 7.92],
                          [17.045, 0],
                          [7.96, -2.412],
                          [6.833, -3.859],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-3.015, 0.925],
                          [-3.78, 0],
                          [-3.66, -3.899],
                          [0, -6.271],
                          [0, 0],
                          [0, 0],
                          [8.723, -3.537],
                          [4.462, -6.19],
                          [0, -8.201],
                          [-7.64, -6.954],
                          [-12.946, 0],
                        ],
                        o: [
                          [5.668, -3.175],
                          [0.401, 2.574],
                          [0.884, 2.574],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.241, -1.607],
                          [-0.041, -1.728],
                          [0, 0],
                          [0, -14.714],
                          [-9.89, -7.919],
                          [-9.811, 0],
                          [-7.96, 2.412],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [2.412, -1.688],
                          [3.015, -0.923],
                          [7.315, 0],
                          [3.658, 3.901],
                          [0, 0],
                          [0, 0],
                          [-12.784, 0],
                          [-8.725, 3.539],
                          [-4.462, 6.192],
                          [0, 11.981],
                          [7.638, 6.956],
                          [7.638, 0],
                        ],
                        v: [
                          [237.412, -2.231],
                          [252.186, -14.955],
                          [254.116, -7.236],
                          [256.769, 0],
                          [300.428, 0],
                          [300.428, -21.106],
                          [286.8, -23.036],
                          [286.378, -28.041],
                          [286.317, -33.287],
                          [286.317, -87.077],
                          [271.483, -121.028],
                          [231.08, -132.907],
                          [204.426, -129.289],
                          [182.235, -119.882],
                          [182.717, -85.992],
                          [208.044, -85.992],
                          [210.818, -103.238],
                          [218.959, -107.158],
                          [229.15, -108.545],
                          [245.613, -102.696],
                          [251.101, -87.439],
                          [251.101, -77.791],
                          [233.854, -77.791],
                          [201.592, -72.484],
                          [181.813, -57.891],
                          [175.119, -36.302],
                          [186.577, -7.9],
                          [217.452, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 299,
                    s: [
                      {
                        i: [
                          [-5.668, 3.175],
                          [-4.182, 5.307],
                          [-0.886, -2.572],
                          [-0.886, -2.25],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0.04, 1.73],
                          [0, 1.77],
                          [0, 0],
                          [9.89, 7.92],
                          [17.045, 0],
                          [7.96, -2.412],
                          [6.833, -3.859],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-3.015, 0.925],
                          [-3.78, 0],
                          [-3.66, -3.899],
                          [0, -6.271],
                          [0, 0],
                          [0, 0],
                          [8.723, -3.537],
                          [4.462, -6.19],
                          [0, -8.201],
                          [-7.64, -6.954],
                          [-12.946, 0],
                        ],
                        o: [
                          [5.668, -3.175],
                          [0.401, 2.574],
                          [0.884, 2.574],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.241, -1.607],
                          [-0.041, -1.728],
                          [0, 0],
                          [0, -14.714],
                          [-9.89, -7.919],
                          [-9.811, 0],
                          [-7.96, 2.412],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [2.412, -1.688],
                          [3.015, -0.923],
                          [7.315, 0],
                          [3.658, 3.901],
                          [0, 0],
                          [0, 0],
                          [-12.784, 0],
                          [-8.725, 3.539],
                          [-4.462, 6.192],
                          [0, 11.981],
                          [7.638, 6.956],
                          [7.638, 0],
                        ],
                        v: [
                          [237.412, -2.231],
                          [252.186, -14.955],
                          [254.116, -7.236],
                          [256.769, 0],
                          [358.428, 0],
                          [358.428, -21.106],
                          [286.8, -23.036],
                          [286.378, -28.041],
                          [286.317, -33.287],
                          [286.317, -87.077],
                          [271.483, -121.028],
                          [231.08, -132.907],
                          [204.426, -129.289],
                          [182.235, -119.882],
                          [182.435, -92.992],
                          [207.762, -92.992],
                          [210.818, -103.238],
                          [218.959, -107.158],
                          [229.15, -108.545],
                          [245.613, -102.696],
                          [251.101, -87.439],
                          [251.101, -77.791],
                          [233.854, -77.791],
                          [201.592, -72.484],
                          [181.813, -57.891],
                          [175.119, -36.302],
                          [186.577, -7.9],
                          [217.452, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "a",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.934, 2.533],
                    [0, 4.664],
                    [-3.98, 3.539],
                    [-7.719, 0],
                    [0, 0],
                    [0, 0],
                    [4.421, -2.21],
                    [4.903, 0],
                  ],
                  o: [
                    [-2.936, -2.533],
                    [0, -4.984],
                    [3.98, -3.537],
                    [0, 0],
                    [0, 0],
                    [-2.412, 3.618],
                    [-4.423, 2.212],
                    [-5.227, 0],
                  ],
                  v: [
                    [214.617, -27.317],
                    [210.215, -38.111],
                    [216.185, -50.896],
                    [233.733, -56.202],
                    [251.101, -56.202],
                    [251.101, -35.579],
                    [240.849, -26.835],
                    [226.859, -23.518],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "a",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "a",
          np: 5,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 226,
      op: 300,
      st: 226,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: "promjena slova brza 2fps (velika slova)",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [534, 171, 0], ix: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 188,
      op: 226,
      st: 188,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Merged Shape Layer 2",
      parent: 10,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-4.877, 47.01, 0], ix: 2 },
        a: { a: 0, k: [960.056, 339.39, 0], ix: 1 },
        s: { a: 0, k: [30.288, 30.288, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [807.708, -173.565],
                        [806.901, -173.565],
                        [797.08, -148.939],
                        [816.86, -148.939],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 113,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [817.936, -111.8],
                              [817.936, -127.139],
                              [824.931, -127.139],
                              [821.702, -136.02],
                              [791.966, -136.02],
                              [788.333, -127.139],
                              [796.542, -127.139],
                              [796.542, -111.8],
                              [762.094, -111.8],
                              [762.094, -127.139],
                              [770.031, -127.139],
                              [795.06, -183.924],
                              [782.95, -183.924],
                              [782.95, -199.263],
                              [812.956, -199.263],
                              [844.443, -127.139],
                              [852.786, -127.139],
                              [852.786, -111.8],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 186,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [817.936, -111.8],
                              [817.936, -127.139],
                              [824.931, -127.139],
                              [821.702, -136.02],
                              [791.966, -136.02],
                              [788.333, -127.139],
                              [796.542, -127.139],
                              [796.542, -111.8],
                              [762.094, -111.8],
                              [762.094, -127.139],
                              [770.031, -127.139],
                              [795.06, -183.924],
                              [762.844, -183.747],
                              [762.844, -199.085],
                              [812.956, -199.263],
                              [844.443, -127.139],
                              [852.786, -127.139],
                              [852.786, -111.8],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1347.44, 384.468], ix: 2 },
              a: { a: 0, k: [807.44, -155.532], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 16",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [13.188, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 8.343],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [13.726, 0],
                        [0, -8.746],
                      ],
                      v: [
                        [716.078, -183.924],
                        [707.198, -183.924],
                        [707.198, -159.436],
                        [715.809, -159.436],
                        [733.708, -172.352],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 113,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, -17.627],
                              [9.956, -3.901],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [21.662, 0],
                              [0, 11.841],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [736.4, -111.8],
                              [719.042, -145.173],
                              [707.198, -145.173],
                              [707.198, -127.139],
                              [716.619, -127.139],
                              [716.619, -111.8],
                              [679.749, -111.8],
                              [679.749, -127.139],
                              [689.167, -127.139],
                              [689.167, -183.924],
                              [679.749, -183.924],
                              [679.749, -199.263],
                              [719.31, -199.263],
                              [753.083, -172.755],
                              [736.4, -148.939],
                              [748.241, -127.139],
                              [758.734, -127.139],
                              [758.734, -111.8],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 186,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, -17.627],
                              [9.956, -3.901],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [21.662, 0],
                              [0, 11.841],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [736.4, -111.8],
                              [719.042, -145.173],
                              [707.198, -145.173],
                              [707.198, -127.139],
                              [716.619, -127.139],
                              [716.619, -111.8],
                              [664.934, -111.991],
                              [664.934, -127.329],
                              [689.167, -127.139],
                              [689.167, -183.924],
                              [679.749, -183.924],
                              [679.749, -199.263],
                              [719.31, -199.263],
                              [753.083, -172.755],
                              [736.4, -148.939],
                              [748.241, -127.139],
                              [758.734, -127.139],
                              [758.734, -111.8],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1259.242, 384.468], ix: 2 },
              a: { a: 0, k: [719.242, -155.532], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 15",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 113,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [656.202, -168.317],
                              [653.51, -183.924],
                              [639.65, -183.924],
                              [639.65, -127.139],
                              [653.914, -127.139],
                              [653.914, -111.8],
                              [608.163, -111.8],
                              [608.163, -127.139],
                              [621.62, -127.139],
                              [621.62, -183.924],
                              [607.76, -183.924],
                              [605.068, -168.317],
                              [589.729, -169.661],
                              [592.421, -199.263],
                              [668.852, -199.263],
                              [671.54, -169.661],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 186,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [655.823, -154.031],
                              [653.51, -183.924],
                              [639.65, -183.924],
                              [639.65, -127.139],
                              [653.914, -127.139],
                              [653.914, -111.8],
                              [608.163, -111.8],
                              [608.163, -127.139],
                              [621.62, -127.139],
                              [621.62, -183.924],
                              [607.76, -183.924],
                              [605.289, -164.084],
                              [589.95, -165.428],
                              [592.421, -199.263],
                              [668.852, -199.263],
                              [671.162, -155.375],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1170.635, 384.468], ix: 2 },
              a: { a: 0, k: [630.635, -155.532], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 14",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 113,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [27.986, 0],
                              [0, 18.837],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-13.995, 0],
                              [0, 13.054],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 18.571],
                              [-26.645, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 11.975],
                              [13.726, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [575.469, -183.924],
                              [575.469, -147.998],
                              [538.062, -110.456],
                              [501.729, -146.517],
                              [501.729, -183.924],
                              [493.924, -183.924],
                              [493.924, -199.263],
                              [529.987, -199.263],
                              [529.987, -183.924],
                              [519.759, -183.924],
                              [519.759, -147.054],
                              [539.002, -126.333],
                              [558.649, -147.998],
                              [558.649, -183.924],
                              [548.421, -183.924],
                              [548.421, -199.263],
                              [583.271, -199.263],
                              [583.271, -183.924],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 186,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [27.986, 0],
                              [0, 18.837],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-13.995, 0],
                              [0, 13.054],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 18.571],
                              [-26.645, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 11.975],
                              [13.726, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [575.469, -183.924],
                              [575.469, -147.998],
                              [538.062, -110.456],
                              [501.729, -146.517],
                              [501.729, -183.924],
                              [493.924, -183.924],
                              [493.924, -199.263],
                              [533.691, -199.085],
                              [533.691, -183.747],
                              [519.759, -183.924],
                              [519.759, -147.054],
                              [539.002, -126.333],
                              [558.649, -147.998],
                              [558.649, -183.924],
                              [548.421, -183.924],
                              [548.421, -199.263],
                              [583.271, -199.263],
                              [583.271, -183.924],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1078.598, 385.14], ix: 2 },
              a: { a: 0, k: [538.598, -154.86], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 13",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 113,
                        s: [
                          {
                            i: [
                              [21.259, 0],
                              [6.861, 6.055],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-3.095, 0],
                              [0, 8.881],
                              [9.687, 1.747],
                              [0, 18.84],
                              [-15.742, 0],
                              [-5.652, -5.92],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [4.308, 0],
                              [0, -6.327],
                              [-7.94, -1.747],
                              [0, -22.338],
                            ],
                            o: [
                              [-11.841, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [6.055, 3.229],
                              [9.422, 0],
                              [0, -9.69],
                              [-12.651, -2.288],
                              [0, -19.509],
                              [11.438, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-4.576, -3.095],
                              [-7.13, 0],
                              [0, 7.399],
                              [10.766, 2.288],
                              [0, 18.03],
                            ],
                            v: [
                              [451.677, -110.456],
                              [420.056, -119.606],
                              [422.747, -141.268],
                              [437.145, -139.924],
                              [437.011, -129.293],
                              [450.33, -125.93],
                              [466.613, -136.154],
                              [448.583, -149.746],
                              [420.862, -173.968],
                              [452.618, -200.61],
                              [481.011, -190.923],
                              [481.817, -171.949],
                              [467.419, -170.602],
                              [466.21, -180.83],
                              [452.753, -184.731],
                              [440.24, -176.253],
                              [455.31, -165.088],
                              [485.99, -138.308],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 186,
                        s: [
                          {
                            i: [
                              [21.259, 0],
                              [6.861, 6.055],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-3.095, 0],
                              [0, 8.881],
                              [9.687, 1.747],
                              [0, 18.84],
                              [-15.742, 0],
                              [-5.652, -5.92],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [4.308, 0],
                              [0, -6.327],
                              [-7.94, -1.747],
                              [0, -22.338],
                            ],
                            o: [
                              [-11.841, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [6.055, 3.229],
                              [9.422, 0],
                              [0, -9.69],
                              [-12.651, -2.288],
                              [0, -19.509],
                              [11.438, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-4.576, -3.095],
                              [-7.13, 0],
                              [0, 7.399],
                              [10.766, 2.288],
                              [0, 18.03],
                            ],
                            v: [
                              [451.677, -110.456],
                              [420.056, -119.606],
                              [422.505, -144.443],
                              [436.903, -143.099],
                              [437.011, -129.293],
                              [450.33, -125.93],
                              [466.613, -136.154],
                              [448.583, -149.746],
                              [420.862, -173.968],
                              [452.618, -200.61],
                              [481.011, -190.923],
                              [481.915, -169.304],
                              [467.517, -167.956],
                              [466.21, -180.83],
                              [452.753, -184.731],
                              [440.24, -176.253],
                              [455.31, -165.088],
                              [485.99, -138.308],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [993.023, 384.467], ix: 2 },
              a: { a: 0, k: [453.023, -155.533], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Layer 12",
          np: 1,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 113,
      op: 188,
      st: 113,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "SUTRA Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [540, 251, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [-9.729, 3.939],
                          [-5.388, 7.278],
                          [0, 9.971],
                          [10.01, 8.846],
                          [19.376, 5.388],
                          [5.105, 2.614],
                          [2.171, 2.936],
                          [0, 3.94],
                          [-2.493, 3.217],
                          [-4.302, 1.688],
                          [-5.629, 0],
                          [-5.186, -1.447],
                          [-3.057, -1.93],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [10.412, 3.377],
                          [13.508, -0.16],
                          [9.326, -4.18],
                          [5.265, -7.276],
                          [0, -9.486],
                          [-4.543, -6.793],
                          [-9.126, -4.864],
                          [-13.911, -4.101],
                          [-4.704, -2.693],
                          [-2.05, -3.256],
                          [0, -4.18],
                          [2.292, -3.256],
                          [4.381, -1.768],
                          [6.351, 0],
                          [6.351, 1.89],
                          [3.618, 3.057],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.613, -2.895],
                          [-12.946, 0],
                        ],
                        o: [
                          [9.728, -3.939],
                          [5.386, -7.276],
                          [0, -14.392],
                          [-10.01, -8.844],
                          [-9.006, -2.974],
                          [-5.107, -2.612],
                          [-2.171, -2.934],
                          [0, -4.583],
                          [2.491, -3.215],
                          [4.3, -1.688],
                          [6.592, 0],
                          [5.186, 1.447],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-7.559, -6.03],
                          [-10.414, -3.377],
                          [-12.223, 0.162],
                          [-9.328, 4.182],
                          [-5.267, 7.278],
                          [0, 9.569],
                          [4.542, 6.795],
                          [9.125, 4.866],
                          [8.039, 2.493],
                          [4.704, 2.695],
                          [2.05, 3.256],
                          [0, 4.423],
                          [-2.292, 3.256],
                          [-4.383, 1.77],
                          [-7.478, 0],
                          [-6.353, -1.888],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [10.291, 7.157],
                          [10.613, 2.895],
                          [13.025, 0],
                        ],
                        v: [
                          [-316.288, -3.377],
                          [-293.614, -20.201],
                          [-285.533, -46.071],
                          [-300.549, -80.926],
                          [-344.63, -102.273],
                          [-365.796, -110.656],
                          [-376.711, -118.977],
                          [-379.968, -129.289],
                          [-376.229, -140.988],
                          [-366.038, -148.345],
                          [-351.143, -150.877],
                          [-333.474, -148.707],
                          [-321.112, -143.641],
                          [-316.529, -119.52],
                          [-290.116, -119.52],
                          [-290.116, -159.079],
                          [-317.072, -173.189],
                          [-352.952, -178.014],
                          [-385.274, -171.501],
                          [-407.164, -154.315],
                          [-415.064, -129.168],
                          [-408.25, -104.625],
                          [-387.747, -87.137],
                          [-353.193, -73.69],
                          [-334.077, -65.911],
                          [-323.946, -56.986],
                          [-320.871, -45.83],
                          [-324.308, -34.312],
                          [-334.318, -26.774],
                          [-350.419, -24.121],
                          [-371.163, -26.955],
                          [-386.118, -34.373],
                          [-389.857, -59.338],
                          [-417.114, -59.338],
                          [-417.114, -16.885],
                          [-385.757, -1.809],
                          [-350.419, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 72,
                    s: [
                      {
                        i: [
                          [-9.729, 3.939],
                          [-5.388, 7.278],
                          [0, 9.971],
                          [10.01, 8.846],
                          [19.376, 5.388],
                          [5.105, 2.614],
                          [2.171, 2.936],
                          [0, 3.94],
                          [-2.493, 3.217],
                          [-4.302, 1.688],
                          [-5.629, 0],
                          [-5.186, -1.447],
                          [-3.057, -1.93],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [10.412, 3.377],
                          [13.508, -0.16],
                          [9.326, -4.18],
                          [5.265, -7.276],
                          [0, -9.486],
                          [-4.543, -6.793],
                          [-9.126, -4.864],
                          [-13.911, -4.101],
                          [-4.704, -2.693],
                          [-2.05, -3.256],
                          [0, -4.18],
                          [2.292, -3.256],
                          [4.381, -1.768],
                          [6.351, 0],
                          [6.351, 1.89],
                          [3.618, 3.057],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-10.613, -2.895],
                          [-12.946, 0],
                        ],
                        o: [
                          [9.728, -3.939],
                          [5.386, -7.276],
                          [0, -14.392],
                          [-10.01, -8.844],
                          [-9.006, -2.974],
                          [-5.107, -2.612],
                          [-2.171, -2.934],
                          [0, -4.583],
                          [2.491, -3.215],
                          [4.3, -1.688],
                          [6.592, 0],
                          [5.186, 1.447],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-7.559, -6.03],
                          [-10.414, -3.377],
                          [-12.223, 0.162],
                          [-9.328, 4.182],
                          [-5.267, 7.278],
                          [0, 9.569],
                          [4.542, 6.795],
                          [9.125, 4.866],
                          [8.039, 2.493],
                          [4.704, 2.695],
                          [2.05, 3.256],
                          [0, 4.423],
                          [-2.292, 3.256],
                          [-4.383, 1.77],
                          [-7.478, 0],
                          [-6.353, -1.888],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [10.291, 7.157],
                          [10.613, 2.895],
                          [13.025, 0],
                        ],
                        v: [
                          [-316.288, -3.377],
                          [-293.614, -20.201],
                          [-285.533, -46.071],
                          [-300.549, -80.926],
                          [-344.63, -102.273],
                          [-365.796, -110.656],
                          [-376.711, -118.977],
                          [-379.968, -129.289],
                          [-376.229, -140.988],
                          [-366.038, -148.345],
                          [-351.143, -150.877],
                          [-333.474, -148.707],
                          [-321.112, -143.641],
                          [-316.058, -101.52],
                          [-289.646, -101.52],
                          [-290.116, -159.079],
                          [-317.072, -173.189],
                          [-352.952, -178.014],
                          [-385.274, -171.501],
                          [-407.164, -154.315],
                          [-415.064, -129.168],
                          [-408.25, -104.625],
                          [-387.747, -87.137],
                          [-353.193, -73.69],
                          [-334.077, -65.911],
                          [-323.946, -56.986],
                          [-320.871, -45.83],
                          [-324.308, -34.312],
                          [-334.318, -26.774],
                          [-350.419, -24.121],
                          [-371.163, -26.955],
                          [-386.118, -34.373],
                          [-370.857, -63.338],
                          [-398.114, -63.338],
                          [-417.114, -16.885],
                          [-385.757, -1.809],
                          [-350.419, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "S",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "S",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [-10.574, 4.783],
                          [-5.91, 9.207],
                          [0, 13.106],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [2.814, -5.145],
                          [5.265, -2.533],
                          [7.397, 0],
                          [5.226, 2.533],
                          [2.814, 5.146],
                          [0, 7.719],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-5.87, -9.166],
                          [-10.574, -4.824],
                          [-13.911, 0],
                        ],
                        o: [
                          [10.572, -4.783],
                          [5.91, -9.206],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 7.719],
                          [-2.815, 5.146],
                          [-5.267, 2.533],
                          [-7.236, 0],
                          [-5.227, -2.533],
                          [-2.815, -5.145],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 13.106],
                          [5.868, 9.166],
                          [10.572, 4.824],
                          [14.069, 0],
                        ],
                        v: [
                          [-147.862, -4.643],
                          [-123.138, -25.629],
                          [-114.274, -59.097],
                          [-114.274, -150.757],
                          [-95.459, -154.375],
                          [-95.459, -175.602],
                          [-168.184, -175.602],
                          [-168.184, -154.375],
                          [-149.49, -150.757],
                          [-149.49, -59.097],
                          [-153.712, -39.8],
                          [-165.833, -28.282],
                          [-184.828, -24.483],
                          [-203.522, -28.282],
                          [-215.582, -39.8],
                          [-219.803, -59.097],
                          [-219.803, -150.757],
                          [-200.989, -154.375],
                          [-200.989, -175.602],
                          [-273.714, -175.602],
                          [-273.714, -154.375],
                          [-255.02, -150.757],
                          [-255.02, -59.097],
                          [-246.216, -25.689],
                          [-221.552, -4.704],
                          [-184.828, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 72,
                    s: [
                      {
                        i: [
                          [-10.574, 4.783],
                          [-5.91, 9.207],
                          [0, 13.106],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [2.814, -5.145],
                          [5.265, -2.533],
                          [7.397, 0],
                          [5.226, 2.533],
                          [2.814, 5.146],
                          [0, 7.719],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-5.87, -9.166],
                          [-10.574, -4.824],
                          [-13.911, 0],
                        ],
                        o: [
                          [10.572, -4.783],
                          [5.91, -9.206],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 7.719],
                          [-2.815, 5.146],
                          [-5.267, 2.533],
                          [-7.236, 0],
                          [-5.227, -2.533],
                          [-2.815, -5.145],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 13.106],
                          [5.868, 9.166],
                          [10.572, 4.824],
                          [14.069, 0],
                        ],
                        v: [
                          [-147.862, -4.643],
                          [-123.138, -25.629],
                          [-114.274, -59.097],
                          [-114.733, -124.757],
                          [-95.918, -128.375],
                          [-95.459, -175.602],
                          [-168.184, -175.602],
                          [-168.644, -128.375],
                          [-149.95, -124.757],
                          [-149.49, -59.097],
                          [-153.712, -39.8],
                          [-165.833, -28.282],
                          [-184.828, -24.483],
                          [-203.522, -28.282],
                          [-215.582, -39.8],
                          [-219.803, -59.097],
                          [-219.803, -150.757],
                          [-200.989, -154.375],
                          [-200.989, -175.602],
                          [-273.714, -175.602],
                          [-273.714, -154.375],
                          [-255.02, -150.757],
                          [-255.02, -59.097],
                          [-246.216, -25.689],
                          [-221.552, -4.704],
                          [-184.828, 2.533],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "U",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "U",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [29.247, 0],
                          [29.247, -21.106],
                          [10.312, -24.724],
                          [10.312, -148.465],
                          [42.513, -148.465],
                          [44.443, -127.48],
                          [71.218, -127.48],
                          [71.218, -175.602],
                          [-85.449, -175.602],
                          [-85.449, -127.48],
                          [-58.795, -127.48],
                          [-56.865, -148.465],
                          [-24.905, -148.465],
                          [-24.905, -24.724],
                          [-43.719, -21.106],
                          [-43.719, 0],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 72,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [29.247, 0],
                          [29.247, -21.106],
                          [10.312, -24.724],
                          [10.312, -148.465],
                          [42.513, -148.465],
                          [44.886, -83.48],
                          [71.661, -83.48],
                          [71.218, -175.602],
                          [-85.449, -175.602],
                          [-85.449, -127.48],
                          [-58.795, -127.48],
                          [-56.865, -148.465],
                          [-24.905, -148.465],
                          [-24.905, -24.724],
                          [-43.719, -21.106],
                          [-43.719, 0],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "T",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "T",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-9.166, 4.664],
                          [-5.146, 8.122],
                          [0, 10.293],
                          [5.186, 7.8],
                          [9.648, 4.182],
                          [13.508, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [11.98, -0.079],
                          [9.166, -4.662],
                          [5.145, -8.12],
                          [0, -10.934],
                          [-5.186, -7.798],
                          [-9.648, -4.18],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.124, 0],
                          [156.124, -21.106],
                          [137.309, -24.724],
                          [137.309, -69.348],
                          [170.476, -69.348],
                          [202.195, -76.464],
                          [223.663, -95.64],
                          [231.382, -123.259],
                          [223.603, -151.36],
                          [201.351, -169.33],
                          [166.616, -175.602],
                          [83.399, -175.602],
                          [83.399, -154.375],
                          [102.093, -150.757],
                          [102.093, -24.724],
                          [83.399, -21.106],
                          [83.399, 0],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 72,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-9.166, 4.664],
                          [-5.146, 8.122],
                          [0, 10.293],
                          [5.186, 7.8],
                          [9.648, 4.182],
                          [13.508, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [11.98, -0.079],
                          [9.166, -4.662],
                          [5.145, -8.12],
                          [0, -10.934],
                          [-5.186, -7.798],
                          [-9.648, -4.18],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.124, 0],
                          [156.124, -21.106],
                          [137.309, -24.724],
                          [137.309, -69.348],
                          [170.476, -69.348],
                          [202.195, -76.464],
                          [223.663, -95.64],
                          [231.382, -123.259],
                          [223.603, -151.36],
                          [201.351, -169.33],
                          [166.616, -175.602],
                          [83.399, -175.602],
                          [83.399, -154.375],
                          [102.093, -150.757],
                          [102.093, -24.724],
                          [59.399, -21.106],
                          [59.399, 0],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "R",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [239.1, 0],
                    [239.1, -21.106],
                    [224.145, -24.604],
                    [198.577, -83.7],
                    [166.858, -75.861],
                    [196.406, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "R",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-4.986, -4.864],
                    [0, -8.12],
                    [5.024, -4.3],
                    [10.131, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [9.809, 0],
                    [4.984, 4.866],
                    [0, 8.201],
                    [-5.026, 4.302],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [137.309, -148.465],
                    [166.616, -148.465],
                    [188.808, -141.169],
                    [196.285, -121.691],
                    [188.748, -102.937],
                    [166.013, -96.484],
                    [137.309, -96.484],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "R",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "R",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [311.222, 0],
                          [311.222, -21.106],
                          [299.041, -23.277],
                          [306.76, -47.398],
                          [368.51, -47.398],
                          [376.229, -23.277],
                          [364.048, -21.106],
                          [364.048, 0],
                          [423.868, 0],
                          [423.868, -21.106],
                          [411.325, -22.915],
                          [355.846, -175.602],
                          [319.785, -175.602],
                          [263.945, -22.915],
                          [251.402, -21.106],
                          [251.402, 0],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 72,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [337.722, 0],
                          [337.722, -21.106],
                          [299.041, -23.277],
                          [306.76, -47.398],
                          [368.51, -47.398],
                          [376.229, -23.277],
                          [364.048, -21.106],
                          [364.048, 0],
                          [465.868, 0],
                          [465.868, -21.106],
                          [411.325, -22.915],
                          [355.846, -175.602],
                          [319.785, -175.602],
                          [263.945, -22.915],
                          [251.402, -21.106],
                          [251.402, 0],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "A",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [335.464, -133.148],
                    [337.273, -138.817],
                    [337.997, -138.817],
                    [339.685, -133.51],
                    [360.188, -73.328],
                    [315.082, -73.328],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "A",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "A",
          np: 5,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [534.889, 159.448, 0], ix: 2 },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [624, 624, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 333,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: "promjena slova brza 2fps (velika slova)",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [534, 171, 0], ix: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1 },
        s: { a: 0, k: [107.7, 107.7, 100], ix: 6 },
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 75,
      op: 113,
      st: 75,
      bm: 0,
    },
  ],
  markers: [],
  chars: [
    {
      ch: "S",
      size: 38.2665710449219,
      style: "ExtraBold",
      w: 63.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [2.133, 0],
                      [4.133, 1.2],
                      [3.533, 0],
                      [4.866, -4],
                      [0, -6.133],
                      [-1.8, -2.966],
                      [-2.867, -1.533],
                      [-3.534, -1.533],
                      [-0.934, -0.333],
                      [-1.1, -0.466],
                      [-1.8, -0.966],
                      [-0.834, -0.866],
                      [-0.667, -1.366],
                      [0, -1.666],
                      [2.6, -2.433],
                      [4.866, 0],
                      [3.933, 4.334],
                      [0.933, 6.934],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.067, 0],
                      [-4.4, -1.733],
                      [-4, 0],
                      [-5.134, 4.134],
                      [0, 6.267],
                      [5.066, 3.267],
                      [6.666, 2.067],
                      [2.533, 2.034],
                      [0, 3.8],
                      [-2.4, 2.267],
                      [-4.467, 0],
                      [-3.567, -3.766],
                      [-1, -6.866],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-0.267, 2.467],
                      [-1.267, 0],
                      [-3.867, -1.266],
                      [-9.534, 0],
                      [-4.867, 4],
                      [0, 3.867],
                      [1.8, 2.967],
                      [2.466, 1.334],
                      [0.466, 0.2],
                      [2.333, 0.867],
                      [1.1, 0.467],
                      [1.8, 0.967],
                      [0.833, 0.867],
                      [0.666, 1.367],
                      [0, 4],
                      [-2.6, 2.434],
                      [-6.467, 0],
                      [-3.8, -4.333],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.333, -2.533],
                      [1.133, 0],
                      [4.4, 1.733],
                      [10, 0],
                      [5.133, -4.2],
                      [0, -7.266],
                      [-3.667, -2.4],
                      [-4.867, -1.666],
                      [-2.534, -2.033],
                      [0, -3.933],
                      [2.4, -2.266],
                      [6, 0],
                      [3.566, 3.767],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [56.7, -71],
                      [53.1, -67.3],
                      [45, -69.1],
                      [33.9, -71],
                      [12.3, -65],
                      [5, -49.8],
                      [7.7, -39.55],
                      [14.7, -32.8],
                      [23.7, -28.5],
                      [25.8, -27.7],
                      [30.95, -25.7],
                      [35.3, -23.55],
                      [39.25, -20.8],
                      [41.5, -17.45],
                      [42.5, -12.9],
                      [38.6, -3.25],
                      [27.4, 0.4],
                      [11.8, -6.1],
                      [4.7, -23],
                      [4.2, -23],
                      [4.2, -0.3],
                      [4.6, -0.3],
                      [8.2, -4.1],
                      [16.5, -1.5],
                      [29.1, 1.1],
                      [51.8, -5.1],
                      [59.5, -20.8],
                      [51.9, -36.6],
                      [36.4, -43.3],
                      [25.3, -48.85],
                      [21.5, -57.6],
                      [25.1, -66.9],
                      [35.4, -70.3],
                      [49.75, -64.65],
                      [56.6, -48.7],
                      [57, -48.7],
                      [57, -71],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Narziss Pro Cyrillic",
    },
    {
      ch: "U",
      size: 38.2665710449219,
      style: "ExtraBold",
      w: 72.2,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.467, -2.066],
                      [0, -4],
                      [0, 0],
                      [3.2, -3.3],
                      [5.266, 0],
                      [3.266, 3.334],
                      [0, 5.4],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.134, -4.733],
                      [-8, 0],
                      [-5.267, 4.8],
                      [0, 7.534],
                      [0, 0],
                      [-1.367, 2.067],
                      [-2.267, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.4, 0],
                      [1.466, 2.067],
                      [0, 0],
                      [0, 5.734],
                      [-3.2, 3.3],
                      [-5.2, 0],
                      [-3.267, -3.333],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 7.2],
                      [5.266, 4.734],
                      [7.866, 0],
                      [5.333, -4.933],
                      [0, 0],
                      [0, -4],
                      [1.366, -2.066],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [54, -70],
                      [54, -69.7],
                      [54.6, -69.7],
                      [60.4, -66.6],
                      [62.6, -57.5],
                      [62.6, -25],
                      [57.8, -11.45],
                      [45.1, -6.5],
                      [32.4, -11.5],
                      [27.5, -24.6],
                      [27.5, -69.7],
                      [33.9, -69.7],
                      [33.9, -70],
                      [1.2, -70],
                      [1.2, -69.7],
                      [7.7, -69.7],
                      [7.7, -24.1],
                      [15.4, -6.2],
                      [35.3, 0.9],
                      [55, -6.3],
                      [63, -25],
                      [63, -57.5],
                      [65.05, -66.6],
                      [70.5, -69.7],
                      [71.1, -69.7],
                      [71.1, -70],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Narziss Pro Cyrillic",
    },
    {
      ch: "T",
      size: 38.2665710449219,
      style: "ExtraBold",
      w: 72.1,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [2.333, 0],
                      [0, 0],
                      [0.2, 2.6],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-11.134, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.4, -12.933],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-0.067, 2.6],
                      [0, 0],
                      [-2.267, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.333, -12.933],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [11.066, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [67.9, -73.9],
                      [64.3, -70],
                      [7.9, -70],
                      [4.2, -73.9],
                      [3.9, -73.9],
                      [3.9, -50.3],
                      [4.3, -50.3],
                      [23, -69.7],
                      [26.2, -69.7],
                      [26.2, -0.3],
                      [19.6, -0.3],
                      [19.6, 0],
                      [52.4, 0],
                      [52.4, -0.3],
                      [46, -0.3],
                      [46, -69.7],
                      [49.1, -69.7],
                      [67.8, -50.3],
                      [68.2, -50.3],
                      [68.2, -73.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Narziss Pro Cyrillic",
    },
    {
      ch: "R",
      size: 38.2665710449219,
      style: "ExtraBold",
      w: 72.5,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.534, -3.8],
                      [0, -6.066],
                      [-2.267, -2.666],
                      [-4.334, 0],
                      [-2.367, 2.133],
                      [0, 3.134],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.466, 0],
                      [0.533, 1.234],
                      [0, 3.6],
                      [3.366, 3.067],
                      [7.266, 0.534],
                      [-4.467, 3.267],
                      [0, 4.8],
                      [4.766, 3],
                      [9.066, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.6, 0],
                      [1.466, 3.334],
                      [0, 4.867],
                      [2.266, 2.667],
                      [3.866, 0],
                      [2.366, -2.133],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 3],
                      [-1.534, 0],
                      [-0.534, -1.233],
                      [0, -6.133],
                      [-3.367, -3.066],
                      [7.133, -0.6],
                      [4.533, -3.333],
                      [0, -5.933],
                      [-4.767, -3],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.1, -69.7],
                      [8.1, -0.3],
                      [1.6, -0.3],
                      [1.6, 0],
                      [34, 0],
                      [34, -0.3],
                      [27.1, -0.3],
                      [27.1, -34],
                      [31.8, -34],
                      [42.5, -28.3],
                      [44.7, -14.2],
                      [48.1, -2.9],
                      [58, 1.1],
                      [67.35, -2.1],
                      [70.9, -10],
                      [70.9, -12.8],
                      [70.6, -12.8],
                      [70.6, -10.3],
                      [66.9, -5.8],
                      [63.8, -7.65],
                      [63, -14.9],
                      [57.95, -28.7],
                      [42, -34.1],
                      [59.4, -39.9],
                      [66.2, -52.1],
                      [59.05, -65.5],
                      [38.3, -70],
                      [1.6, -70],
                      [1.6, -69.7],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.034, -3.166],
                      [0, -5.533],
                      [8.533, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.033, 3.167],
                      [0, 11.667],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.2, 0],
                    ],
                    v: [
                      [44.05, -64.85],
                      [47.1, -51.8],
                      [34.3, -34.3],
                      [27.1, -34.3],
                      [27.1, -69.6],
                      [34.7, -69.6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Narziss Pro Cyrillic",
    },
    {
      ch: "A",
      size: 38.2665710449219,
      style: "ExtraBold",
      w: 79.9,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [4.666, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.233, 1.234],
                      [0, 2],
                      [-0.534, 1.467],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-2.734, 7.2],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.134, -0.066],
                      [-1.234, -1.233],
                      [0, -1.133],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [33.7, -70],
                      [12.1, -11.2],
                      [1, -0.4],
                      [0.4, -0.4],
                      [0.4, 0],
                      [19.7, 0],
                      [19.7, -0.4],
                      [18.7, -0.4],
                      [13.65, -2.35],
                      [11.8, -7.2],
                      [12.6, -11.1],
                      [18.5, -27.3],
                      [42.5, -27.3],
                      [52.7, -0.3],
                      [45.3, -0.3],
                      [45.3, 0],
                      [79.5, 0],
                      [79.5, -0.3],
                      [73.4, -0.3],
                      [46.9, -70],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [42.4, -27.6],
                      [18.6, -27.6],
                      [30.4, -59.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Narziss Pro Cyrillic",
    },
    {
      ch: "S",
      size: 40.5858497619629,
      style: "Regular",
      w: 52.4,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.667, 1.3],
                      [-1.134, 0.867],
                      [-1.5, 0.434],
                      [-1.667, 0],
                      [-1.7, -0.4],
                      [-1.2, -1.066],
                      [-0.8, -1.9],
                      [-0.667, -2.933],
                      [-0.334, -0.133],
                      [-0.367, 0.034],
                      [-0.267, 0.167],
                      [0, 0.2],
                      [0.2, 1.4],
                      [0, 1.734],
                      [-0.1, 1.2],
                      [-0.334, 1.2],
                      [0.266, 0.134],
                      [0.333, 0.034],
                      [0.366, -0.1],
                      [0.133, -0.266],
                      [0.8, 0],
                      [0.566, 0.167],
                      [0.866, 0.267],
                      [1.3, 0.234],
                      [1.866, 0],
                      [2.733, -0.6],
                      [2.133, -1.333],
                      [1.3, -2.166],
                      [0, -3.2],
                      [-1.067, -2.1],
                      [-1.734, -1.5],
                      [-2.2, -1.066],
                      [-2.2, -1],
                      [-1.867, -0.966],
                      [-1.534, -1.3],
                      [-1.034, -1.8],
                      [0, -2.4],
                      [0.733, -1.4],
                      [1.266, -0.966],
                      [1.633, -0.5],
                      [1.8, 0],
                      [2.033, 0.867],
                      [1.6, 1.567],
                      [1.066, 2.234],
                      [0.4, 2.734],
                      [0.366, 0.167],
                      [0.4, -0.033],
                      [0.333, -0.266],
                      [-0.067, -0.4],
                      [-0.1, -1.733],
                      [0, -1.733],
                      [0.166, -1.7],
                      [0.2, -1.466],
                      [-0.234, -0.2],
                      [-0.367, -0.034],
                      [-0.334, 0.133],
                      [-0.2, 0.4],
                      [-0.534, 0.4],
                      [-0.467, 0],
                      [-0.3, -0.133],
                      [-0.334, -0.133],
                      [-2.334, -0.566],
                      [-1.867, 0],
                      [-2.867, 0.666],
                      [-2.3, 1.5],
                      [-1.434, 2.367],
                      [0, 3.467],
                      [1.233, 2.434],
                      [2, 1.767],
                      [2.5, 1.334],
                      [2.533, 1.2],
                      [1.566, 0.7],
                      [1.3, 0.934],
                      [0.833, 1.367],
                      [0, 2],
                    ],
                    o: [
                      [0.666, -1.3],
                      [1.133, -0.866],
                      [1.5, -0.433],
                      [2.4, 0],
                      [1.7, 0.4],
                      [1.2, 1.067],
                      [0.8, 1.9],
                      [0.066, 0.334],
                      [0.333, 0.134],
                      [0.366, -0.033],
                      [0.266, -0.166],
                      [-0.134, -1.933],
                      [-0.2, -1.4],
                      [0, -1.333],
                      [0.1, -1.2],
                      [0.066, -0.266],
                      [-0.267, -0.133],
                      [-0.334, -0.033],
                      [-0.367, 0.1],
                      [-0.334, 0.667],
                      [-0.534, 0],
                      [-0.567, -0.166],
                      [-1.6, -0.533],
                      [-1.3, -0.233],
                      [-2.934, 0],
                      [-2.734, 0.6],
                      [-2.134, 1.334],
                      [-1.3, 2.167],
                      [0, 3.134],
                      [1.066, 2.1],
                      [1.733, 1.5],
                      [2.2, 1.067],
                      [1.666, 0.8],
                      [1.866, 0.967],
                      [1.533, 1.3],
                      [1.033, 1.8],
                      [0, 1.867],
                      [-0.734, 1.4],
                      [-1.267, 0.967],
                      [-1.634, 0.5],
                      [-2.4, 0],
                      [-2.034, -0.866],
                      [-1.6, -1.566],
                      [-1.067, -2.233],
                      [-0.067, -0.4],
                      [-0.367, -0.166],
                      [-0.4, 0.034],
                      [-0.334, 0.267],
                      [0.266, 1.867],
                      [0.1, 1.734],
                      [0, 1.734],
                      [-0.167, 1.7],
                      [0, 0.266],
                      [0.233, 0.2],
                      [0.366, 0.033],
                      [0.333, -0.134],
                      [0.4, -1],
                      [0.533, -0.4],
                      [0.466, 0],
                      [0.3, 0.134],
                      [1.933, 1.134],
                      [2.333, 0.566],
                      [2.866, 0],
                      [2.866, -0.666],
                      [2.3, -1.5],
                      [1.433, -2.366],
                      [0, -3.466],
                      [-1.234, -2.433],
                      [-2, -1.766],
                      [-2.5, -1.333],
                      [-1.467, -0.666],
                      [-1.567, -0.7],
                      [-1.3, -0.933],
                      [-0.834, -1.366],
                      [0, -1.666],
                    ],
                    v: [
                      [15, -58.45],
                      [17.7, -61.7],
                      [21.65, -63.65],
                      [26.4, -64.3],
                      [32.55, -63.7],
                      [36.9, -61.5],
                      [39.9, -57.05],
                      [42.1, -49.8],
                      [42.7, -49.1],
                      [43.75, -48.95],
                      [44.7, -49.25],
                      [45.1, -49.8],
                      [44.6, -54.8],
                      [44.3, -59.5],
                      [44.45, -63.3],
                      [45.1, -66.9],
                      [44.8, -67.5],
                      [43.9, -67.75],
                      [42.85, -67.65],
                      [42.1, -67.1],
                      [40.4, -66.1],
                      [38.75, -66.35],
                      [36.6, -67],
                      [32.25, -68.15],
                      [27.5, -68.5],
                      [19, -67.6],
                      [11.7, -64.7],
                      [6.55, -59.45],
                      [4.6, -51.4],
                      [6.2, -43.55],
                      [10.4, -38.15],
                      [16.3, -34.3],
                      [22.9, -31.2],
                      [28.2, -28.55],
                      [33.3, -25.15],
                      [37.15, -20.5],
                      [38.7, -14.2],
                      [37.6, -9.3],
                      [34.6, -5.75],
                      [30.25, -3.55],
                      [25.1, -2.8],
                      [18.45, -4.1],
                      [13, -7.75],
                      [9, -13.45],
                      [6.8, -20.9],
                      [6.15, -21.75],
                      [5, -21.95],
                      [3.9, -21.5],
                      [3.5, -20.5],
                      [4.05, -15.1],
                      [4.2, -9.9],
                      [3.95, -4.75],
                      [3.4, 0],
                      [3.75, 0.7],
                      [4.65, 1.05],
                      [5.7, 0.9],
                      [6.5, 0.1],
                      [7.9, -2],
                      [9.4, -2.6],
                      [10.55, -2.4],
                      [11.5, -2],
                      [17.9, 0.55],
                      [24.2, 1.4],
                      [32.8, 0.4],
                      [40.55, -2.85],
                      [46.15, -8.65],
                      [48.3, -17.4],
                      [46.45, -26.25],
                      [41.6, -32.55],
                      [34.85, -37.2],
                      [27.3, -41],
                      [22.75, -43.05],
                      [18.45, -45.5],
                      [15.25, -48.95],
                      [14, -54],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Plantagenet Cherokee",
    },
    {
      ch: "U",
      size: 40.5858497619629,
      style: "Regular",
      w: 79.1,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0.033, 1.6],
                      [-0.067, 1.567],
                      [-0.167, 1.4],
                      [-0.334, 0.8],
                      [-0.367, 0.567],
                      [-0.467, 0.267],
                      [-0.7, 0.034],
                      [-1.2, 0.134],
                      [-0.2, 0.367],
                      [0, 0.4],
                      [0.2, 0.334],
                      [0.4, 0],
                      [1.733, -0.233],
                      [1.733, 0],
                      [1.8, 0.234],
                      [1.6, 0],
                      [0.133, -0.333],
                      [0, -0.4],
                      [-0.167, -0.366],
                      [-0.334, 0],
                      [-0.8, -0.033],
                      [-0.534, -0.266],
                      [-0.367, -0.566],
                      [-0.334, -1.133],
                      [-0.134, -1.433],
                      [-0.067, -1.566],
                      [0, -1.6],
                      [0, -1.066],
                      [0, 0],
                      [3.4, -3.566],
                      [6.466, 0],
                      [2.266, 1.134],
                      [1.5, 1.9],
                      [0.733, 2.534],
                      [0, 2.8],
                      [0, 0],
                      [-0.2, 1.467],
                      [-0.667, 0.9],
                      [-1.267, 0.434],
                      [-2.067, 0.134],
                      [-0.2, 0.367],
                      [-0.034, 0.4],
                      [0.166, 0.334],
                      [0.4, 0],
                      [1.2, -0.066],
                      [1.166, -0.1],
                      [1.233, -0.066],
                      [1.4, 0],
                      [2.733, 0.234],
                      [2.133, 0],
                      [0.2, -0.333],
                      [0, -0.4],
                      [-0.2, -0.366],
                      [-0.4, 0],
                      [-1.234, -0.5],
                      [-0.667, -0.933],
                      [-0.234, -1.466],
                      [0, -2.066],
                      [0, 0],
                      [-1.067, -3.233],
                      [-2.234, -2.033],
                      [-3.434, -0.933],
                      [-4.667, 0],
                      [-3.4, 1.266],
                      [-2.1, 2.367],
                      [-0.967, 3.434],
                      [0, 4.334],
                    ],
                    o: [
                      [0, -1.2],
                      [-0.034, -1.6],
                      [0.066, -1.566],
                      [0.166, -1.4],
                      [0.466, -1.133],
                      [0.366, -0.566],
                      [0.466, -0.266],
                      [0.7, -0.033],
                      [0.4, 0],
                      [0.2, -0.366],
                      [0, -0.4],
                      [-0.2, -0.333],
                      [-2.134, 0],
                      [-1.734, 0.234],
                      [-1.867, 0],
                      [-1.8, -0.233],
                      [-0.4, 0],
                      [-0.134, 0.334],
                      [0, 0.4],
                      [0.166, 0.367],
                      [1.2, 0.134],
                      [0.8, 0.034],
                      [0.533, 0.267],
                      [0.366, 0.567],
                      [0.266, 0.8],
                      [0.133, 1.434],
                      [0.066, 1.567],
                      [0, 1.534],
                      [0, 0],
                      [0, 7.734],
                      [-3.4, 3.567],
                      [-3.067, 0],
                      [-2.267, -1.133],
                      [-1.5, -1.9],
                      [-0.734, -2.533],
                      [0, 0],
                      [0, -2.266],
                      [0.2, -1.466],
                      [0.666, -0.9],
                      [1.266, -0.433],
                      [0.333, 0],
                      [0.2, -0.366],
                      [0.033, -0.4],
                      [-0.167, -0.333],
                      [-1.4, 0],
                      [-1.2, 0.067],
                      [-1.167, 0.1],
                      [-1.234, 0.067],
                      [-2.4, 0],
                      [-2.734, -0.233],
                      [-0.4, 0],
                      [-0.2, 0.334],
                      [0, 0.4],
                      [0.2, 0.367],
                      [2.066, 0.134],
                      [1.233, 0.5],
                      [0.666, 0.934],
                      [0.233, 1.467],
                      [0, 0],
                      [0, 4.6],
                      [1.066, 3.234],
                      [2.233, 2.034],
                      [3.433, 0.933],
                      [4.866, 0],
                      [3.4, -1.266],
                      [2.1, -2.366],
                      [0.966, -3.433],
                      [0, 0],
                    ],
                    v: [
                      [67.2, -43],
                      [67.15, -47.2],
                      [67.2, -51.95],
                      [67.55, -56.4],
                      [68.3, -59.7],
                      [69.55, -62.25],
                      [70.8, -63.5],
                      [72.55, -63.95],
                      [75.4, -64.2],
                      [76.3, -64.75],
                      [76.6, -65.9],
                      [76.3, -67],
                      [75.4, -67.5],
                      [69.6, -67.15],
                      [64.4, -66.8],
                      [58.9, -67.15],
                      [53.8, -67.5],
                      [53, -67],
                      [52.8, -65.9],
                      [53.05, -64.75],
                      [53.8, -64.2],
                      [56.8, -63.95],
                      [58.8, -63.5],
                      [60.15, -62.25],
                      [61.2, -59.7],
                      [61.8, -56.35],
                      [62.1, -51.85],
                      [62.2, -47.1],
                      [62.2, -43.2],
                      [62.2, -26.3],
                      [57.1, -9.35],
                      [42.3, -4],
                      [34.3, -5.7],
                      [28.65, -10.25],
                      [25.3, -16.9],
                      [24.2, -24.9],
                      [24.2, -52.2],
                      [24.5, -57.8],
                      [25.8, -61.35],
                      [28.7, -63.35],
                      [33.7, -64.2],
                      [34.5, -64.75],
                      [34.85, -65.9],
                      [34.65, -67],
                      [33.8, -67.5],
                      [29.9, -67.4],
                      [26.35, -67.15],
                      [22.75, -66.9],
                      [18.8, -66.8],
                      [11.1, -67.15],
                      [3.8, -67.5],
                      [2.9, -67],
                      [2.6, -65.9],
                      [2.9, -64.75],
                      [3.8, -64.2],
                      [8.75, -63.25],
                      [11.6, -61.1],
                      [12.95, -57.5],
                      [13.3, -52.2],
                      [13.3, -24.2],
                      [14.9, -12.45],
                      [19.85, -4.55],
                      [28.35, -0.1],
                      [40.5, 1.3],
                      [52.9, -0.6],
                      [61.15, -6.05],
                      [65.75, -14.75],
                      [67.2, -26.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Plantagenet Cherokee",
    },
    {
      ch: "T",
      size: 40.5858497619629,
      style: "Regular",
      w: 64.6,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-1.334, 0.634],
                      [-3, 0],
                      [-1.6, -0.366],
                      [-1.1, -0.966],
                      [-0.667, -1.7],
                      [-0.4, -2.666],
                      [-0.367, -0.133],
                      [-0.4, 0.034],
                      [-0.334, 0.234],
                      [0, 0.267],
                      [0.1, 1.467],
                      [0, 1.4],
                      [-0.1, 1.167],
                      [-0.134, 0.8],
                      [0.833, 0.234],
                      [1.266, 0],
                      [0, 0],
                      [0.933, -0.266],
                      [0, -0.733],
                      [0.033, -2.566],
                      [0.266, -2.466],
                      [-0.3, -0.2],
                      [-0.367, -0.033],
                      [-0.367, 0.134],
                      [-0.067, 0.4],
                      [-0.634, 1.667],
                      [-1.034, 0.967],
                      [-1.6, 0.367],
                      [-2.467, 0],
                      [-1.234, -0.633],
                      [0, -2.466],
                      [0, 0],
                      [0.233, -1.233],
                      [0.7, -0.666],
                      [1.233, -0.3],
                      [2.066, -0.2],
                      [0.2, -0.366],
                      [0, -0.4],
                      [-0.2, -0.333],
                      [-0.4, 0],
                      [-2.7, 0.266],
                      [-2.534, 0],
                      [-2.434, -0.266],
                      [-2.667, 0],
                      [-0.234, 0.333],
                      [0, 0.4],
                      [0.233, 0.367],
                      [0.4, 0.067],
                      [1.266, 0.3],
                      [0.7, 0.667],
                      [0.2, 1.234],
                      [0, 2.2],
                    ],
                    o: [
                      [0, -2.466],
                      [1.333, -0.633],
                      [2.2, 0],
                      [1.6, 0.367],
                      [1.1, 0.967],
                      [0.666, 1.7],
                      [0.066, 0.4],
                      [0.366, 0.134],
                      [0.4, -0.033],
                      [0.333, -0.233],
                      [-0.134, -1.333],
                      [-0.1, -1.466],
                      [0, -1.4],
                      [0.1, -1.166],
                      [0, -0.733],
                      [-0.834, -0.233],
                      [0, 0],
                      [-1.134, 0],
                      [-0.934, 0.267],
                      [0.333, 2.534],
                      [-0.034, 2.567],
                      [0, 0.267],
                      [0.3, 0.2],
                      [0.366, 0.034],
                      [0.366, -0.133],
                      [0.533, -2.666],
                      [0.633, -1.666],
                      [1.033, -0.966],
                      [1.6, -0.366],
                      [2.933, 0],
                      [1.233, 0.634],
                      [0, 0],
                      [0, 2.2],
                      [-0.234, 1.234],
                      [-0.7, 0.667],
                      [-1.234, 0.3],
                      [-0.4, 0.067],
                      [-0.2, 0.367],
                      [0, 0.4],
                      [0.2, 0.333],
                      [2.2, 0],
                      [2.7, -0.266],
                      [2.4, 0],
                      [2.433, 0.266],
                      [0.466, 0],
                      [0.233, -0.333],
                      [0, -0.4],
                      [-0.234, -0.366],
                      [-2.134, -0.2],
                      [-1.267, -0.3],
                      [-0.7, -0.666],
                      [-0.2, -1.233],
                      [0, 0],
                    ],
                    v: [
                      [37.6, -57.4],
                      [39.6, -62.05],
                      [46.1, -63],
                      [51.8, -62.45],
                      [55.85, -60.45],
                      [58.5, -56.45],
                      [60.1, -49.9],
                      [60.75, -49.1],
                      [61.9, -48.95],
                      [63, -49.35],
                      [63.5, -50.1],
                      [63.15, -54.3],
                      [63, -58.6],
                      [63.15, -62.45],
                      [63.5, -65.4],
                      [62.25, -66.85],
                      [59.1, -67.2],
                      [5.6, -67.2],
                      [2.5, -66.8],
                      [1.1, -65.3],
                      [1.55, -57.65],
                      [1.1, -50.1],
                      [1.55, -49.4],
                      [2.55, -49.05],
                      [3.65, -49.2],
                      [4.3, -50],
                      [6.05, -56.5],
                      [8.55, -60.45],
                      [12.5, -62.45],
                      [18.6, -63],
                      [24.85, -62.05],
                      [26.7, -57.4],
                      [26.7, -13],
                      [26.35, -7.85],
                      [24.95, -5],
                      [22.05, -3.55],
                      [17.1, -2.8],
                      [16.2, -2.15],
                      [15.9, -1],
                      [16.2, 0.1],
                      [17.1, 0.6],
                      [24.45, 0.2],
                      [32.3, -0.2],
                      [39.55, 0.2],
                      [47.2, 0.6],
                      [48.25, 0.1],
                      [48.6, -1],
                      [48.25, -2.15],
                      [47.3, -2.8],
                      [42.2, -3.55],
                      [39.25, -5],
                      [37.9, -7.85],
                      [37.6, -13],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Plantagenet Cherokee",
    },
    {
      ch: "R",
      size: 40.5858497619629,
      style: "Regular",
      w: 67.1,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0.233, -1.2],
                      [0.666, -0.733],
                      [1.2, -0.333],
                      [1.866, -0.2],
                      [0.2, -0.366],
                      [-0.034, -0.4],
                      [-0.2, -0.333],
                      [-0.4, 0],
                      [-2.467, 0.266],
                      [-2.534, 0],
                      [-2.534, -0.266],
                      [-3.334, 0],
                      [-0.234, 0.333],
                      [0, 0.4],
                      [0.233, 0.367],
                      [0.4, 0],
                      [1.366, 0.334],
                      [0.933, 0.767],
                      [0.466, 1.367],
                      [0, 2.267],
                      [0, 0],
                      [-0.8, 0.467],
                      [-1.134, 0],
                      [-1.9, -1.866],
                      [-1.2, -4.266],
                      [-0.334, -1.3],
                      [-0.334, -1.366],
                      [-0.367, -1.333],
                      [-0.4, -1.133],
                      [-1.734, -0.933],
                      [-2.134, 0],
                      [-1.3, 0.366],
                      [-1.034, 0.766],
                      [-0.734, 1.2],
                      [-0.2, 1.667],
                      [0.3, 0.3],
                      [0.366, 0.1],
                      [0.4, -0.166],
                      [0.133, -0.533],
                      [0.733, -0.666],
                      [0.8, 0],
                      [0.766, 0.7],
                      [0.333, 1.534],
                      [0.4, 2.1],
                      [0.9, 2],
                      [1.7, 1.867],
                      [3.133, 1.534],
                      [0, 0.267],
                      [-0.467, 0.134],
                      [-1.834, 0.834],
                      [-1.334, 1.334],
                      [-0.767, 1.867],
                      [0, 2.534],
                      [1.233, 2.267],
                      [2.033, 1.4],
                      [2.6, 0.634],
                      [2.666, 0],
                      [2.633, -0.1],
                      [4, -0.066],
                      [1.8, 0.134],
                      [2.266, 0],
                      [0.2, -0.333],
                      [0, -0.4],
                      [-0.2, -0.333],
                      [-0.4, 0],
                      [-0.8, -0.133],
                      [-0.734, -0.2],
                      [-0.7, -0.866],
                      [-0.334, -1.2],
                      [-0.1, -1.4],
                      [0, -1.466],
                    ],
                    o: [
                      [0, 2],
                      [-0.234, 1.2],
                      [-0.667, 0.734],
                      [-1.2, 0.334],
                      [-0.467, 0.067],
                      [-0.2, 0.367],
                      [0.033, 0.4],
                      [0.2, 0.333],
                      [2.333, 0],
                      [2.466, -0.266],
                      [2.4, 0],
                      [2.533, 0.266],
                      [0.466, 0],
                      [0.233, -0.333],
                      [0, -0.466],
                      [-0.234, -0.366],
                      [-1.8, -0.133],
                      [-1.367, -0.333],
                      [-0.934, -0.766],
                      [-0.467, -1.366],
                      [0, 0],
                      [0, -1.4],
                      [0.8, -0.466],
                      [3.4, 0],
                      [1.9, 1.867],
                      [0.266, 1],
                      [0.333, 1.3],
                      [0.333, 1.367],
                      [0.366, 1.334],
                      [0.6, 1.734],
                      [1.733, 0.933],
                      [1.266, 0],
                      [1.3, -0.367],
                      [1.033, -0.766],
                      [0.733, -1.2],
                      [0.066, -0.333],
                      [-0.3, -0.3],
                      [-0.367, -0.1],
                      [-0.4, 0.167],
                      [-0.4, 1.334],
                      [-0.734, 0.667],
                      [-0.8, 0],
                      [-0.767, -0.7],
                      [-0.467, -2],
                      [-0.4, -2.1],
                      [-0.9, -2],
                      [-1.7, -1.866],
                      [-0.467, -0.266],
                      [0, -0.333],
                      [2.133, -0.466],
                      [1.833, -0.833],
                      [1.333, -1.333],
                      [0.766, -1.866],
                      [0, -3.2],
                      [-1.234, -2.266],
                      [-2.034, -1.4],
                      [-2.6, -0.633],
                      [-2.4, 0],
                      [-2.634, 0.1],
                      [-2.4, 0],
                      [-1.8, -0.133],
                      [-0.4, 0],
                      [-0.2, 0.334],
                      [0, 0.4],
                      [0.2, 0.334],
                      [0.733, 0.067],
                      [0.8, 0.134],
                      [1.2, 0.334],
                      [0.7, 0.867],
                      [0.333, 1.2],
                      [0.1, 1.4],
                      [0, 0],
                    ],
                    v: [
                      [13.6, -12.9],
                      [13.25, -8.1],
                      [11.9, -5.2],
                      [9.1, -3.6],
                      [4.5, -2.8],
                      [3.5, -2.15],
                      [3.25, -1],
                      [3.6, 0.1],
                      [4.5, 0.6],
                      [11.7, 0.2],
                      [19.2, -0.2],
                      [26.6, 0.2],
                      [35.4, 0.6],
                      [36.45, 0.1],
                      [36.8, -1],
                      [36.45, -2.25],
                      [35.5, -2.8],
                      [30.75, -3.5],
                      [27.3, -5.15],
                      [25.2, -8.35],
                      [24.5, -13.8],
                      [24.5, -27.3],
                      [25.7, -30.1],
                      [28.6, -30.8],
                      [36.55, -28],
                      [41.2, -18.8],
                      [42.1, -15.35],
                      [43.1, -11.35],
                      [44.15, -7.3],
                      [45.3, -3.6],
                      [48.8, 0.4],
                      [54.6, 1.8],
                      [58.45, 1.25],
                      [61.95, -0.45],
                      [64.6, -3.4],
                      [66, -7.7],
                      [65.65, -8.65],
                      [64.65, -9.25],
                      [63.5, -9.15],
                      [62.7, -8.1],
                      [61, -5.1],
                      [58.7, -4.1],
                      [56.35, -5.15],
                      [54.7, -8.5],
                      [53.4, -14.65],
                      [51.45, -20.8],
                      [47.55, -26.6],
                      [40.3, -31.7],
                      [39.6, -32.5],
                      [40.3, -33.2],
                      [46.25, -35.15],
                      [51, -38.4],
                      [54.15, -43.2],
                      [55.3, -49.8],
                      [53.45, -58],
                      [48.55, -63.5],
                      [41.6, -66.55],
                      [33.7, -67.5],
                      [26.15, -67.35],
                      [16.2, -67.1],
                      [9.9, -67.3],
                      [3.8, -67.5],
                      [2.9, -67],
                      [2.6, -65.9],
                      [2.9, -64.8],
                      [3.8, -64.3],
                      [6.1, -64],
                      [8.4, -63.5],
                      [11.25, -61.7],
                      [12.8, -58.6],
                      [13.45, -54.7],
                      [13.6, -50.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-0.834, 0.667],
                      [-2.6, 0],
                      [-1.667, -0.466],
                      [-1.034, -1.1],
                      [-0.467, -1.766],
                      [0, -2.533],
                      [0.433, -1.833],
                      [1.066, -1.166],
                      [1.766, -0.5],
                      [2.6, 0],
                      [0.966, 0.3],
                      [0, 1.734],
                    ],
                    o: [
                      [0, -2.066],
                      [0.833, -0.666],
                      [2.4, 0],
                      [1.666, 0.467],
                      [1.033, 1.1],
                      [0.466, 1.767],
                      [0, 2.667],
                      [-0.434, 1.834],
                      [-1.067, 1.167],
                      [-1.767, 0.5],
                      [-1.934, 0],
                      [-0.967, -0.3],
                      [0, 0],
                    ],
                    v: [
                      [24.5, -58.2],
                      [25.75, -62.3],
                      [30.9, -63.3],
                      [37, -62.6],
                      [41.05, -60.25],
                      [43.3, -55.95],
                      [44, -49.5],
                      [43.35, -42.75],
                      [41.1, -38.25],
                      [36.85, -35.75],
                      [30.3, -35],
                      [25.95, -35.45],
                      [24.5, -38.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Plantagenet Cherokee",
    },
    {
      ch: "A",
      size: 40.5858497619629,
      style: "Regular",
      w: 73.4,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-0.567, -0.233],
                      [-0.334, -0.866],
                      [-0.6, -1.633],
                      [-0.334, -1.033],
                      [-0.1, -0.6],
                      [0, -0.466],
                      [1.066, -0.533],
                      [2.266, -0.266],
                      [0.2, -0.3],
                      [0, -0.4],
                      [-0.234, -0.366],
                      [-0.467, 0],
                      [-2.467, 0.266],
                      [-2.8, 0],
                      [-1.967, -0.266],
                      [-2.134, 0],
                      [-0.167, 0.333],
                      [0, 0.4],
                      [0.166, 0.367],
                      [0.4, 0.067],
                      [0.8, 0.2],
                      [0.766, 0.534],
                      [0.766, 1.067],
                      [0.8, 1.934],
                      [2, 4.967],
                      [2, 5.034],
                      [1.9, 4.867],
                      [1.666, 4.267],
                      [0.933, 0],
                      [0.2, -0.533],
                      [0, 0],
                      [0.666, -1.1],
                      [0.7, -0.666],
                      [0.833, -0.3],
                      [1, -0.2],
                      [0, 0],
                      [0.166, -0.366],
                      [-0.034, -0.4],
                      [-0.234, -0.333],
                      [-0.334, 0],
                      [-1.6, 0.266],
                      [-1.734, 0],
                      [-2.067, -0.266],
                      [-1.6, 0],
                      [-0.2, 0.366],
                      [0, 0.4],
                      [0.2, 0.367],
                      [0.466, 0],
                      [0.933, 0.2],
                      [0.733, 0.334],
                      [0.433, 0.567],
                      [0, 0.867],
                      [-0.334, 1],
                      [0, 0],
                      [-0.4, 0.3],
                      [-0.934, 0],
                    ],
                    o: [
                      [1.266, 0],
                      [0.566, 0.234],
                      [1, 2.534],
                      [0.6, 1.634],
                      [0.333, 1.034],
                      [0.1, 0.6],
                      [0, 1.667],
                      [-1.067, 0.534],
                      [-0.467, 0.067],
                      [-0.2, 0.3],
                      [0, 0.467],
                      [0.233, 0.366],
                      [2.266, 0],
                      [2.466, -0.266],
                      [2.466, 0],
                      [1.966, 0.266],
                      [0.333, 0],
                      [0.166, -0.333],
                      [0, -0.4],
                      [-0.167, -0.366],
                      [-0.934, -0.2],
                      [-0.8, -0.2],
                      [-0.767, -0.533],
                      [-0.767, -1.066],
                      [-1.8, -4.4],
                      [-2, -4.966],
                      [-2, -5.033],
                      [-1.9, -4.866],
                      [-0.534, -0.533],
                      [-0.867, 0],
                      [0, 0],
                      [-0.667, 1.734],
                      [-0.667, 1.1],
                      [-0.7, 0.667],
                      [-0.834, 0.3],
                      [0, 0],
                      [-0.467, 0.067],
                      [-0.167, 0.367],
                      [0.033, 0.4],
                      [0.233, 0.333],
                      [1.866, 0],
                      [1.6, -0.266],
                      [1.866, 0],
                      [2.066, 0.266],
                      [0.466, 0],
                      [0.2, -0.366],
                      [0, -0.4],
                      [-0.2, -0.366],
                      [-1, -0.066],
                      [-0.934, -0.2],
                      [-0.734, -0.333],
                      [-0.434, -0.566],
                      [0, -0.533],
                      [0, 0],
                      [0.333, -0.733],
                      [0.4, -0.3],
                      [0, 0],
                    ],
                    v: [
                      [41.3, -23.6],
                      [44.05, -23.25],
                      [45.4, -21.6],
                      [47.8, -15.35],
                      [49.2, -11.35],
                      [49.85, -8.9],
                      [50, -7.3],
                      [48.4, -4],
                      [43.4, -2.8],
                      [42.4, -2.25],
                      [42.1, -1.2],
                      [42.45, 0.05],
                      [43.5, 0.6],
                      [50.6, 0.2],
                      [58.5, -0.2],
                      [65.15, 0.2],
                      [71.3, 0.6],
                      [72.05, 0.1],
                      [72.3, -1],
                      [72.05, -2.15],
                      [71.2, -2.8],
                      [68.6, -3.4],
                      [66.25, -4.5],
                      [63.95, -6.9],
                      [61.6, -11.4],
                      [55.9, -25.45],
                      [49.9, -40.45],
                      [44.05, -55.3],
                      [38.7, -69],
                      [36.5, -69.8],
                      [34.9, -69],
                      [12.3, -12.1],
                      [10.3, -7.85],
                      [8.25, -5.2],
                      [5.95, -3.75],
                      [3.2, -3],
                      [1.7, -2.8],
                      [0.75, -2.15],
                      [0.55, -1],
                      [0.95, 0.1],
                      [1.8, 0.6],
                      [7, 0.2],
                      [12, -0.2],
                      [17.9, 0.2],
                      [23.4, 0.6],
                      [24.4, 0.05],
                      [24.7, -1.1],
                      [24.4, -2.25],
                      [23.4, -2.8],
                      [20.5, -3.2],
                      [18, -4],
                      [16.25, -5.35],
                      [15.6, -7.5],
                      [16.1, -9.8],
                      [20.6, -21.6],
                      [21.7, -23.15],
                      [23.7, -23.6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0.266, 0.267],
                      [-0.334, 0.867],
                      [0, 0],
                      [-0.4, 0],
                      [-0.134, -0.333],
                      [0, 0],
                      [0.333, -0.3],
                      [0.933, 0],
                    ],
                    o: [
                      [-0.734, 0],
                      [-0.267, -0.266],
                      [0, 0],
                      [0.133, -0.333],
                      [0.4, 0],
                      [0, 0],
                      [0.333, 0.8],
                      [-0.334, 0.3],
                      [0, 0],
                    ],
                    v: [
                      [24.8, -27.5],
                      [23.3, -27.9],
                      [23.4, -29.6],
                      [32.5, -53],
                      [33.3, -53.5],
                      [34.1, -53],
                      [42.8, -29.6],
                      [42.8, -27.95],
                      [40.9, -27.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Plantagenet Cherokee",
    },
    {
      ch: "S",
      size: 35.5577697753906,
      style: "Regular",
      w: 64.5,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [5.469, -4.59],
                      [1.334, -2.718],
                      [0, -2.864],
                      [-5.144, -4.557],
                      [-2.441, -1.221],
                      [-4.655, -1.465],
                      [-1.172, -0.944],
                      [0, -1.302],
                      [1.497, -0.895],
                      [2.409, 0],
                      [2.62, 1.205],
                      [1.204, 1.823],
                      [0.684, 0.716],
                      [1.237, 0],
                      [0.813, -1.041],
                      [0, -1.595],
                      [-1.254, -3.499],
                      [-1.562, -1.041],
                      [-4.623, -0.993],
                      [-4.167, 0],
                      [-5.795, 4.492],
                      [0, 6.934],
                      [8.301, 4.037],
                      [6.738, 1.986],
                      [0, 2.832],
                      [-1.091, 0.879],
                      [-1.823, 0],
                      [-3.906, -3.645],
                      [-0.814, -0.52],
                      [-1.302, 0],
                      [-1.091, 1.254],
                      [0, 1.823],
                      [2.425, 3.451],
                      [2.18, 0],
                      [0.52, -0.195],
                      [0.325, 0],
                      [0.651, 0.195],
                      [3.352, 0],
                    ],
                    o: [
                      [-2.441, 1.986],
                      [-1.335, 2.718],
                      [0, 5.795],
                      [2.116, 1.855],
                      [2.441, 1.221],
                      [2.637, 0.814],
                      [1.172, 0.945],
                      [0, 1.4],
                      [-1.498, 0.896],
                      [-2.8, 0],
                      [-2.621, -1.204],
                      [-2.539, -3.873],
                      [-1.14, -1.27],
                      [-1.237, 0],
                      [-0.652, 0.814],
                      [0, 3.32],
                      [1.253, 3.5],
                      [2.051, 1.335],
                      [4.622, 0.993],
                      [9.342, 0],
                      [5.664, -4.395],
                      [0, -7.845],
                      [-1.888, -0.911],
                      [-4.98, -1.432],
                      [0, -1.432],
                      [1.09, -0.879],
                      [3.776, 0],
                      [2.766, 2.572],
                      [0.813, 0.521],
                      [1.562, 0],
                      [1.09, -1.253],
                      [0, -3.125],
                      [-2.425, -3.45],
                      [-0.586, 0],
                      [-1.628, 0.586],
                      [-0.391, 0],
                      [-5.437, -1.66],
                      [-7.878, 0],
                    ],
                    v: [
                      [11.426, -61.133],
                      [5.762, -54.077],
                      [3.76, -45.703],
                      [11.475, -30.176],
                      [18.311, -25.562],
                      [28.955, -21.533],
                      [34.668, -18.896],
                      [36.426, -15.527],
                      [34.18, -12.085],
                      [28.32, -10.742],
                      [20.19, -12.549],
                      [14.453, -17.09],
                      [9.619, -23.975],
                      [6.055, -25.879],
                      [2.979, -24.316],
                      [2.002, -20.703],
                      [3.882, -10.474],
                      [8.105, -3.662],
                      [18.115, -0.171],
                      [31.299, 1.318],
                      [54.004, -5.42],
                      [62.5, -22.412],
                      [50.049, -40.234],
                      [37.109, -44.58],
                      [29.639, -50.977],
                      [31.274, -54.443],
                      [35.645, -55.762],
                      [47.168, -50.293],
                      [52.539, -45.654],
                      [55.713, -44.873],
                      [59.692, -46.753],
                      [61.328, -51.367],
                      [57.69, -61.23],
                      [50.781, -66.406],
                      [49.121, -66.113],
                      [46.191, -65.234],
                      [44.629, -65.527],
                      [31.445, -68.018],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Cooper Black",
    },
    {
      ch: "U",
      size: 35.5577697753906,
      style: "Regular",
      w: 81.98,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.244, 1.123],
                      [-1.726, 0.977],
                      [-0.391, 0.456],
                      [0, 0.945],
                      [2.262, 0.879],
                      [5.045, 0],
                      [2.327, -0.895],
                      [0, -1.953],
                      [-0.619, -0.618],
                      [-1.693, -0.748],
                      [-0.31, -1.416],
                      [-0.163, -8.235],
                      [0, 0],
                      [0.325, -1.562],
                      [0.879, -1.27],
                      [2.116, -0.96],
                      [2.604, 0],
                      [2.604, 2.409],
                      [-0.163, 5.599],
                      [0, 2.832],
                      [-0.375, 1.205],
                      [-1.042, 0.456],
                      [-0.228, 0.195],
                      [0, 1.367],
                      [3.483, 0.781],
                      [5.371, 0],
                      [3.58, -0.716],
                      [1.09, -0.977],
                      [0, -1.27],
                      [-2.148, -1.399],
                      [-0.407, -1.041],
                      [0, -2.702],
                      [0.098, -3.841],
                      [0, -2.376],
                      [-1.172, -3.223],
                      [-3.06, -2.441],
                      [-10.449, 0],
                      [-4.753, 1.758],
                      [-2.312, 6.316],
                      [0, 7.195],
                      [0, 0],
                    ],
                    o: [
                      [0.244, -1.123],
                      [1.66, -0.944],
                      [0.391, -0.455],
                      [0, -1.92],
                      [-2.263, -0.879],
                      [-5.144, 0],
                      [-2.328, 0.896],
                      [0, 0.912],
                      [0.618, 0.619],
                      [1.79, 0.814],
                      [0.309, 1.416],
                      [0, 0],
                      [0, 2.702],
                      [-0.326, 1.562],
                      [-1.107, 1.66],
                      [-2.116, 0.961],
                      [-4.102, 0],
                      [-3.191, -2.93],
                      [0.26, -9.407],
                      [0, -2.766],
                      [0.374, -1.204],
                      [1.725, -0.813],
                      [1.009, -0.813],
                      [0, -2.766],
                      [-3.548, -0.813],
                      [-4.883, 0],
                      [-1.791, 0.359],
                      [-1.091, 0.977],
                      [0, 1.726],
                      [1.367, 0.912],
                      [0.407, 1.042],
                      [0, 1.237],
                      [-0.163, 5.437],
                      [0, 6.087],
                      [1.172, 3.223],
                      [6.282, 4.98],
                      [5.989, 0],
                      [7.845, -2.832],
                      [1.074, -2.995],
                      [0, 0],
                      [0.065, -7.227],
                    ],
                    v: [
                      [73.999, -54.761],
                      [76.953, -57.91],
                      [80.029, -60.01],
                      [80.615, -62.109],
                      [77.222, -66.309],
                      [66.26, -67.627],
                      [55.054, -66.284],
                      [51.562, -62.012],
                      [52.49, -59.717],
                      [55.957, -57.666],
                      [59.106, -54.321],
                      [59.814, -39.844],
                      [59.814, -29.102],
                      [59.326, -22.705],
                      [57.52, -18.457],
                      [52.686, -14.526],
                      [45.605, -13.086],
                      [35.547, -16.699],
                      [31.006, -29.492],
                      [31.396, -47.852],
                      [31.958, -53.809],
                      [34.082, -56.299],
                      [37.012, -57.812],
                      [38.525, -61.084],
                      [33.301, -66.406],
                      [19.922, -67.627],
                      [7.227, -66.553],
                      [2.905, -64.551],
                      [1.27, -61.182],
                      [4.492, -56.494],
                      [7.153, -53.564],
                      [7.764, -47.949],
                      [7.617, -40.332],
                      [7.373, -28.613],
                      [9.131, -14.648],
                      [15.479, -6.152],
                      [40.576, 1.318],
                      [56.689, -1.318],
                      [71.924, -15.039],
                      [73.535, -30.322],
                      [73.535, -42.236],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Cooper Black",
    },
    {
      ch: "T",
      size: 35.5577697753906,
      style: "Regular",
      w: 70.07,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.123, 0.13],
                      [1.758, 0.359],
                      [0.651, 0],
                      [2.473, -3.32],
                      [0.911, -2.425],
                      [0, -2.018],
                      [-1.14, -1.318],
                      [-1.758, 0],
                      [-1.66, 1.27],
                      [-2.181, 3.32],
                      [-1.27, 0],
                      [-0.456, -1.237],
                      [0, -2.93],
                      [0.227, -5.68],
                      [0.26, -2.539],
                      [0.471, -1.009],
                      [1.237, -0.358],
                      [0.293, -0.163],
                      [0, -1.334],
                      [-3.288, -1.106],
                      [-8.073, 0],
                      [-3.174, 1.139],
                      [0, 2.344],
                      [0.635, 0.668],
                      [1.758, 0.749],
                      [0.586, 1.27],
                      [0, 2.8],
                      [0, 0],
                      [-0.521, 1.009],
                      [-1.205, 0],
                      [-0.619, -0.618],
                      [-1.074, -2.018],
                      [-1.27, -0.96],
                      [-1.726, 0],
                      [-1.302, 0.977],
                      [0, 2.832],
                      [2.49, 3.906],
                      [2.637, 0],
                      [0.977, -0.26],
                      [2.766, -0.098],
                      [0, 0],
                    ],
                    o: [
                      [-1.123, -0.13],
                      [-0.847, -0.195],
                      [-2.409, 0],
                      [-1.53, 2.051],
                      [-0.912, 2.425],
                      [0, 2.051],
                      [1.139, 1.318],
                      [1.562, 0],
                      [1.399, -1.074],
                      [1.204, -1.823],
                      [1.041, 0],
                      [0.455, 1.237],
                      [0, 4.167],
                      [-0.228, 5.681],
                      [-0.293, 2.865],
                      [-0.472, 1.009],
                      [-2.019, 0.619],
                      [-1.172, 0.749],
                      [0, 2.474],
                      [3.906, 1.334],
                      [6.575, 0],
                      [3.174, -1.139],
                      [0, -1.172],
                      [-0.635, -0.667],
                      [-1.628, -0.684],
                      [-0.586, -1.27],
                      [0, 0],
                      [0, -2.311],
                      [0.52, -1.009],
                      [0.879, 0],
                      [0.618, 0.619],
                      [1.237, 2.214],
                      [1.27, 0.961],
                      [1.823, 0],
                      [1.725, -1.302],
                      [0, -4.134],
                      [-2.49, -3.906],
                      [-0.749, 0],
                      [-2.279, 0.652],
                      [0, 0],
                      [-1.465, 0],
                    ],
                    v: [
                      [18.921, -66.846],
                      [14.6, -67.578],
                      [12.354, -67.871],
                      [5.029, -62.891],
                      [1.367, -56.177],
                      [0, -49.512],
                      [1.709, -44.458],
                      [6.055, -42.48],
                      [10.889, -44.385],
                      [16.26, -50.977],
                      [19.971, -53.711],
                      [22.217, -51.855],
                      [22.9, -45.605],
                      [22.559, -30.835],
                      [21.826, -18.506],
                      [20.679, -12.695],
                      [18.115, -10.645],
                      [14.648, -9.473],
                      [12.891, -6.348],
                      [17.822, -0.977],
                      [35.791, 1.025],
                      [50.415, -0.684],
                      [55.176, -5.908],
                      [54.224, -8.667],
                      [50.635, -10.791],
                      [47.314, -13.721],
                      [46.436, -19.824],
                      [46.436, -47.168],
                      [47.217, -52.148],
                      [49.805, -53.662],
                      [52.051, -52.734],
                      [54.59, -48.779],
                      [58.35, -44.019],
                      [62.842, -42.578],
                      [67.529, -44.043],
                      [70.117, -50.244],
                      [66.382, -62.305],
                      [58.691, -68.164],
                      [56.104, -67.773],
                      [48.535, -66.65],
                      [22.803, -66.65],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Cooper Black",
    },
    {
      ch: "R",
      size: 35.5577697753906,
      style: "Regular",
      w: 77.64,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.156, -1.27],
                      [-0.749, -3.092],
                      [-0.863, -2.278],
                      [-1.237, -1.497],
                      [-6.348, 0],
                      [-2.734, 1.27],
                      [-0.781, 1.025],
                      [0, 1.009],
                      [0.423, 0.635],
                      [0.618, 0.195],
                      [0.553, 0.635],
                      [0.293, 2.148],
                      [1.595, 2.507],
                      [3.418, 1.237],
                      [0, 0.945],
                      [-1.042, 0.521],
                      [-1.433, 2.458],
                      [0, 2.995],
                      [4.102, 3.027],
                      [6.966, 0],
                      [3.092, -0.227],
                      [2.93, 0],
                      [0.813, 0.066],
                      [1.074, 0],
                      [1.399, -0.358],
                      [0.928, -0.895],
                      [0, -1.204],
                      [-0.488, -0.569],
                      [-1.433, -0.684],
                      [-0.456, -1.627],
                      [0, -4.524],
                      [0.163, -2.311],
                      [0.504, -1.286],
                      [1.432, -0.944],
                      [0.358, -0.471],
                      [0, -0.781],
                      [-12.045, 0],
                      [-2.718, 0.895],
                      [0, 2.148],
                      [1.953, 1.074],
                      [0.325, 0.359],
                      [0.163, 0.652],
                      [0, 4.134],
                      [-0.342, 0.749],
                      [-1.237, 0],
                    ],
                    o: [
                      [1.155, 1.27],
                      [1.172, 4.818],
                      [0.862, 2.279],
                      [2.734, 3.352],
                      [4.752, 0],
                      [1.237, -0.586],
                      [0.781, -1.025],
                      [0, -0.781],
                      [-0.423, -0.635],
                      [-2.019, -0.586],
                      [-0.554, -0.635],
                      [-0.684, -5.176],
                      [-1.595, -2.506],
                      [-1.595, -0.586],
                      [0, -0.716],
                      [2.506, -1.237],
                      [1.432, -2.457],
                      [0, -5.371],
                      [-4.558, -3.352],
                      [-1.237, 0],
                      [-4.883, 0.391],
                      [-1.758, 0],
                      [-4.297, -0.325],
                      [-2.441, 0],
                      [-1.66, 0.423],
                      [-0.928, 0.896],
                      [0, 0.912],
                      [0.488, 0.57],
                      [1.497, 0.749],
                      [0.455, 1.628],
                      [0, 14.258],
                      [-0.195, 3.158],
                      [-0.505, 1.286],
                      [-1.27, 0.847],
                      [-0.359, 0.472],
                      [0, 4.558],
                      [6.477, 0],
                      [2.718, -0.895],
                      [0, -1.758],
                      [-0.912, -0.52],
                      [-0.326, -0.358],
                      [-0.521, -2.311],
                      [0, -2.734],
                      [0.342, -0.748],
                      [2.148, 0.098],
                    ],
                    v: [
                      [38.843, -25.977],
                      [41.699, -19.434],
                      [44.751, -8.789],
                      [47.9, -3.125],
                      [61.523, 1.904],
                      [72.754, 0],
                      [75.781, -2.417],
                      [76.953, -5.469],
                      [76.318, -7.593],
                      [74.756, -8.838],
                      [70.898, -10.669],
                      [69.629, -14.844],
                      [66.211, -26.367],
                      [58.691, -31.982],
                      [56.299, -34.277],
                      [57.861, -36.133],
                      [63.77, -41.675],
                      [65.918, -49.854],
                      [59.766, -62.451],
                      [42.48, -67.48],
                      [35.986, -67.139],
                      [24.268, -66.553],
                      [20.41, -66.65],
                      [12.354, -67.139],
                      [6.592, -66.602],
                      [2.71, -64.624],
                      [1.318, -61.475],
                      [2.051, -59.253],
                      [4.932, -57.373],
                      [7.861, -53.809],
                      [8.545, -44.58],
                      [8.301, -19.727],
                      [7.251, -13.062],
                      [4.346, -9.717],
                      [1.904, -7.739],
                      [1.367, -5.859],
                      [19.434, 0.977],
                      [33.228, -0.366],
                      [37.305, -4.932],
                      [34.375, -9.18],
                      [32.52, -10.498],
                      [31.787, -12.012],
                      [31.006, -21.68],
                      [31.519, -26.904],
                      [33.887, -28.027],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.521, 0.586],
                      [-1.27, 0],
                      [0, -6.282],
                      [1.302, -1.481],
                      [2.864, 0],
                      [0.342, 0.391],
                      [0, 1.172],
                      [0, 0.098],
                      [0, 0],
                    ],
                    o: [
                      [0.52, -0.586],
                      [4.459, 0],
                      [0, 3.255],
                      [-1.302, 1.482],
                      [-1.042, 0],
                      [-0.342, -0.391],
                      [0, -0.195],
                      [0, 0],
                      [0.065, -1.497],
                    ],
                    v: [
                      [31.934, -56.055],
                      [34.619, -56.934],
                      [41.309, -47.51],
                      [39.355, -40.405],
                      [33.105, -38.184],
                      [31.03, -38.77],
                      [30.518, -41.113],
                      [30.518, -41.553],
                      [31.055, -52.93],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Cooper Black",
    },
    {
      ch: "A",
      size: 35.5577697753906,
      style: "Regular",
      w: 82.67,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [4.166, 3.516],
                      [3.288, 0],
                      [2.246, -2.278],
                      [0.309, -1.09],
                      [0.032, -2.799],
                      [0.862, -2.75],
                      [2.539, -5.534],
                      [0, 0],
                      [1.09, -1.35],
                      [2.116, -0.748],
                      [0.504, -0.553],
                      [0, -1.009],
                      [-2.279, -0.684],
                      [-5.273, 0],
                      [0, 3.874],
                      [1.985, 0.586],
                      [0.276, 0.212],
                      [0, 0.716],
                      [-0.391, 0.847],
                      [-0.521, 0.326],
                      [-4.33, 0],
                      [-2.832, -0.391],
                      [0, -2.18],
                      [1.53, -0.52],
                      [0.504, -0.537],
                      [0, -0.977],
                      [-0.961, -0.895],
                      [-1.628, -0.293],
                      [-7.357, 0],
                      [-2.865, 1.041],
                      [0, 2.377],
                      [2.344, 0.359],
                      [0.586, 0.57],
                      [0.879, 1.823],
                      [0, 0],
                    ],
                    o: [
                      [-2.995, -2.571],
                      [-3.484, 0],
                      [-1.074, 1.042],
                      [-0.31, 1.091],
                      [-0.033, 2.637],
                      [-0.863, 2.751],
                      [0, 0],
                      [-2.019, 4.427],
                      [-1.091, 1.351],
                      [-1.302, 0.456],
                      [-0.505, 0.554],
                      [0, 2.312],
                      [2.473, 0.716],
                      [9.277, 0],
                      [0, -1.758],
                      [-1.53, -0.488],
                      [-0.277, -0.211],
                      [0, -0.781],
                      [0.391, -0.846],
                      [0.846, -0.52],
                      [5.143, 0],
                      [2.637, 0.391],
                      [0, 1.237],
                      [-1.367, 0.456],
                      [-0.505, 0.537],
                      [0, 1.27],
                      [0.96, 0.896],
                      [3.548, 0.618],
                      [6.51, 0],
                      [2.864, -1.041],
                      [0, -2.018],
                      [-1.205, -0.195],
                      [-0.586, -0.569],
                      [0, 0],
                      [-7.943, -16.959],
                    ],
                    v: [
                      [46.875, -64.453],
                      [37.451, -68.311],
                      [28.857, -64.893],
                      [26.782, -61.694],
                      [26.27, -55.859],
                      [24.927, -47.778],
                      [19.824, -35.352],
                      [12.939, -20.264],
                      [8.276, -11.597],
                      [3.467, -8.447],
                      [0.757, -6.934],
                      [0, -4.59],
                      [3.418, -0.098],
                      [15.039, 0.977],
                      [28.955, -4.834],
                      [25.977, -8.35],
                      [23.267, -9.399],
                      [22.852, -10.791],
                      [23.438, -13.232],
                      [24.805, -14.99],
                      [32.568, -15.771],
                      [44.531, -15.186],
                      [48.486, -11.328],
                      [46.191, -8.691],
                      [43.384, -7.202],
                      [42.627, -4.932],
                      [44.067, -1.685],
                      [47.949, 0.098],
                      [64.307, 1.025],
                      [78.369, -0.537],
                      [82.666, -5.664],
                      [79.15, -9.229],
                      [76.465, -10.376],
                      [74.268, -13.965],
                      [65.039, -33.74],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0.814],
                      [-1.367, 3.32],
                      [-0.13, 0.293],
                      [-1.172, 0],
                      [-0.586, -0.651],
                      [-1.107, -1.92],
                      [0, -1.074],
                      [1.758, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, -0.651],
                      [0.813, -2.018],
                      [0.781, -2.18],
                      [0.651, 0],
                      [0.586, 0.652],
                      [2.441, 4.199],
                      [0, 0.781],
                      [0, 0],
                      [-1.498, 0],
                    ],
                    v: [
                      [28.809, -28.809],
                      [30.859, -34.766],
                      [32.275, -38.232],
                      [35.205, -41.504],
                      [37.061, -40.527],
                      [39.6, -36.67],
                      [43.262, -28.76],
                      [40.625, -27.588],
                      [31.055, -27.588],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Cooper Black",
    },
    {
      ch: "S",
      size: 39.1662902832031,
      style: "Regular",
      w: 60.1,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.333, 0.234],
                      [-1.366, -0.1],
                      [-1.234, -0.366],
                      [-1, -0.666],
                      [-4.167, -1],
                      [-1.867, 1.467],
                      [0.433, 0.8],
                      [1.233, 0.967],
                      [1.833, 1.167],
                      [2.033, 1.267],
                      [2.033, 1.334],
                      [1.666, 1.4],
                      [1.433, 1.834],
                      [0.8, 2.167],
                      [0.067, 2.467],
                      [-0.866, 2.667],
                      [-1.433, 2.6],
                      [-1.934, 2.467],
                      [-2.334, 2.134],
                      [-2.4, 1.467],
                      [-1.267, 0.567],
                      [-1.567, 0.267],
                      [-1.834, -0.1],
                      [-2, -0.6],
                      [-3, -1.533],
                      [-2, -1.933],
                      [-0.7, -1.233],
                      [-0.3, -1.2],
                      [-0.034, -1.1],
                      [0, -1],
                      [0.466, -2.133],
                      [0.6, -2.166],
                      [0.733, -2],
                      [0.733, -1.466],
                      [0.866, -0.333],
                      [1.533, 0.6],
                      [-0.667, 5.534],
                      [-0.367, 1.5],
                      [-0.367, 1.5],
                      [-0.267, 1.367],
                      [0.1, 0.867],
                      [0.533, 0.134],
                      [1.266, -0.866],
                      [1.333, -1.2],
                      [1.233, -1.533],
                      [0.966, -1.8],
                      [0.333, -1.8],
                      [-0.4, -1.733],
                      [-1.534, -1.333],
                      [-2.367, -1.6],
                      [-2.367, -1.5],
                      [-2.1, -1.466],
                      [-1.367, -1.566],
                      [-0.367, -1.7],
                      [1.066, -1.933],
                      [1.2, -1.6],
                      [2, -1.366],
                      [2.866, -0.933],
                      [4, -0.067],
                      [5.066, 2.033],
                      [5.4, 5],
                      [0.134, 1.2],
                      [-1.266, 0.734],
                    ],
                    o: [
                      [1.334, -0.233],
                      [1.366, 0.1],
                      [1.233, 0.367],
                      [5.6, 3.4],
                      [4.166, 1],
                      [0.733, -0.466],
                      [-0.434, -0.8],
                      [-1.234, -0.966],
                      [-1.834, -1.166],
                      [-2.034, -1.266],
                      [-2.034, -1.333],
                      [-1.8, -1.466],
                      [-1.434, -1.833],
                      [-0.8, -2.166],
                      [-0.066, -2.466],
                      [0.6, -2.266],
                      [1.433, -2.6],
                      [1.933, -2.466],
                      [2.333, -2.133],
                      [0.866, -0.6],
                      [1.266, -0.566],
                      [1.566, -0.266],
                      [1.833, 0.1],
                      [3, 1.067],
                      [3, 1.534],
                      [1.333, 1.2],
                      [0.7, 1.234],
                      [0.3, 1.2],
                      [0.033, 1.1],
                      [-0.2, 1.8],
                      [-0.467, 2.134],
                      [-0.6, 2.167],
                      [-0.734, 2],
                      [-0.734, 1.334],
                      [-0.867, 0.334],
                      [-9.067, -3.2],
                      [0.066, -1.133],
                      [0.366, -1.5],
                      [0.366, -1.5],
                      [0.266, -1.366],
                      [-0.1, -0.866],
                      [-0.534, -0.133],
                      [-1.067, 0.6],
                      [-1.334, 1.2],
                      [-1.234, 1.534],
                      [-0.967, 1.8],
                      [-0.334, 1.8],
                      [0.4, 1.734],
                      [1.933, 1.8],
                      [2.366, 1.6],
                      [2.366, 1.5],
                      [2.1, 1.467],
                      [1.366, 1.567],
                      [0.366, 1.7],
                      [-0.667, 1.4],
                      [-1.2, 1.6],
                      [-2, 1.367],
                      [-2.867, 0.933],
                      [-5.334, 0.133],
                      [-5.067, -2.033],
                      [-2.866, -2.466],
                      [0, -1.266],
                      [1.2, -0.466],
                    ],
                    v: [
                      [-4.5, -20.95],
                      [-0.45, -21.15],
                      [3.45, -20.45],
                      [6.8, -18.9],
                      [21.45, -12.3],
                      [30.5, -13],
                      [30.95, -14.9],
                      [28.45, -17.55],
                      [23.85, -20.75],
                      [18.05, -24.4],
                      [11.95, -28.3],
                      [6.4, -32.4],
                      [1.55, -37.35],
                      [-1.8, -43.35],
                      [-3.1, -50.3],
                      [-1.9, -58],
                      [1.15, -65.3],
                      [6.2, -72.9],
                      [12.6, -79.8],
                      [19.7, -85.2],
                      [22.9, -86.95],
                      [27.15, -88.2],
                      [32.25, -88.45],
                      [38, -87.4],
                      [47, -83.5],
                      [54.5, -78.3],
                      [57.55, -74.65],
                      [59.05, -71],
                      [59.55, -67.55],
                      [59.6, -64.4],
                      [58.6, -58.5],
                      [57, -52.05],
                      [55, -45.8],
                      [52.8, -40.6],
                      [50.4, -38.1],
                      [46.8, -38.5],
                      [34.2, -51.6],
                      [34.85, -55.55],
                      [35.95, -60.05],
                      [36.9, -64.35],
                      [37.15, -67.7],
                      [36.2, -69.2],
                      [33.5, -68.1],
                      [29.9, -65.4],
                      [26.05, -61.3],
                      [22.75, -56.3],
                      [20.8, -50.9],
                      [20.9, -45.6],
                      [23.8, -41],
                      [30.25, -35.9],
                      [37.35, -31.25],
                      [44.05, -26.8],
                      [49.25, -22.25],
                      [51.85, -17.35],
                      [50.8, -11.9],
                      [48, -7.4],
                      [43.2, -2.95],
                      [35.9, 0.5],
                      [25.6, 2],
                      [10, -0.85],
                      [-5.7, -11.4],
                      [-10.2, -16.9],
                      [-8.3, -19.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Komika Axis",
    },
    {
      ch: "U",
      size: 39.1662902832031,
      style: "Regular",
      w: 67.6,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.634, 3.234],
                      [0, 3.867],
                      [-0.533, 4.334],
                      [-0.866, 4.467],
                      [-1.1, 4.5],
                      [-1.2, 4.267],
                      [-1.034, 0.4],
                      [-2.134, -0.733],
                      [-1.834, -0.933],
                      [-1.734, -1.266],
                      [1.133, -4.066],
                      [1.166, -4.033],
                      [1, -4.133],
                      [0.7, -3.933],
                      [0.166, -3.133],
                      [-0.5, -2.066],
                      [-1.467, -0.4],
                      [-1.734, 2.2],
                      [-1.567, 3.9],
                      [-1.434, 5.134],
                      [-1.234, 5.434],
                      [-1, 5.267],
                      [-0.8, 4.134],
                      [-1.334, 0.4],
                      [-1.9, -0.5],
                      [-2.167, -1.1],
                      [-1.8, -1.066],
                      [-0.5, -0.5],
                      [-0.2, -0.533],
                      [0.066, -0.633],
                      [0.2, -0.866],
                      [2.033, -7.966],
                      [2.266, -6.133],
                      [2.666, -4.133],
                      [3.266, -1.866],
                      [2.333, -0.434],
                      [3, 0.2],
                      [3.866, 2.134],
                      [1.466, 1.267],
                      [1.066, 1.867],
                    ],
                    o: [
                      [-0.633, -3.233],
                      [0, -3.866],
                      [0.534, -4.333],
                      [0.867, -4.466],
                      [1.1, -4.5],
                      [0.533, -1.933],
                      [1.033, -0.4],
                      [2, 0.6],
                      [1.833, 0.934],
                      [3.6, 2.6],
                      [-1.067, 3.467],
                      [-1.167, 4.034],
                      [-1, 4.134],
                      [-0.7, 3.934],
                      [-0.167, 3.134],
                      [0.5, 2.067],
                      [1.8, 0.467],
                      [1.733, -2.2],
                      [1.566, -3.9],
                      [1.433, -5.133],
                      [1.233, -5.433],
                      [1, -5.266],
                      [0.133, -2],
                      [1.333, -0.4],
                      [1.9, 0.5],
                      [2.166, 1.1],
                      [0.933, 0.6],
                      [0.5, 0.5],
                      [0.2, 0.534],
                      [-0.067, 0.634],
                      [-2, 9.534],
                      [-2.034, 7.967],
                      [-2.267, 6.134],
                      [-2.667, 4.134],
                      [-2.334, 1.133],
                      [-2.334, 0.433],
                      [-6, -0.4],
                      [-1.667, -0.933],
                      [-1.467, -1.266],
                      [-1.4, -2.333],
                    ],
                    v: [
                      [-3.55, -18.55],
                      [-4.5, -29.2],
                      [-3.7, -41.5],
                      [-1.6, -54.7],
                      [1.35, -68.15],
                      [4.8, -81.3],
                      [7.15, -84.8],
                      [11.9, -84.3],
                      [17.65, -82],
                      [23, -78.7],
                      [26.7, -68.7],
                      [23.35, -57.45],
                      [20.1, -45.2],
                      [17.55, -33.1],
                      [16.25, -22.5],
                      [16.75, -14.7],
                      [19.7, -11],
                      [25, -13.6],
                      [29.95, -22.75],
                      [34.45, -36.3],
                      [38.45, -52.15],
                      [41.8, -68.2],
                      [44.5, -82.3],
                      [46.7, -85.9],
                      [51.55, -85.75],
                      [57.65, -83.35],
                      [63.6, -80.1],
                      [65.75, -78.45],
                      [66.8, -76.9],
                      [67, -75.15],
                      [66.6, -72.9],
                      [60.55, -46.65],
                      [54.1, -25.5],
                      [46.7, -10.1],
                      [37.8, -1.1],
                      [30.8, 1.25],
                      [22.8, 1.6],
                      [8, -2.2],
                      [3.3, -5.5],
                      [-0.5, -10.2],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Komika Axis",
    },
    {
      ch: "T",
      size: 39.1662902832031,
      style: "Regular",
      w: 66.4,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.066, -0.3],
                      [3.266, -0.333],
                      [1.5, -6],
                      [1.4, -5.9],
                      [1.233, -5.6],
                      [0.933, -4.866],
                      [0.5, -0.3],
                      [0.866, 0.033],
                      [1.133, 0.333],
                      [1.4, 0.4],
                      [1.133, 0.467],
                      [0.766, 0.667],
                      [0.333, 0.9],
                      [-0.267, 1.267],
                      [-0.6, 4.667],
                      [-0.834, 5.2],
                      [-1, 5.3],
                      [-1, 4.534],
                      [1.8, -0.233],
                      [1.2, -0.333],
                      [1.066, 0.3],
                      [1.333, 1.4],
                      [1.166, 2.134],
                      [0.734, 1.467],
                      [-0.4, 0.5],
                      [-1.266, 0.334],
                      [-4.967, 0.667],
                      [-5.167, 0.534],
                      [-4.967, 0.367],
                      [-3.934, 0.134],
                      [-0.9, -0.366],
                      [-0.667, -0.866],
                      [-0.867, -1.8],
                      [-0.467, -1.733],
                      [0.366, -0.566],
                      [2.066, -0.133],
                    ],
                    o: [
                      [-3.067, 0.3],
                      [-1.4, 5.6],
                      [-1.5, 6],
                      [-1.4, 5.9],
                      [-1.234, 5.6],
                      [-0.134, 0.933],
                      [-0.5, 0.3],
                      [-0.867, -0.034],
                      [-1.134, -0.334],
                      [-1.4, -0.4],
                      [-1.134, -0.466],
                      [-0.767, -0.666],
                      [-0.334, -0.9],
                      [0.2, -3.2],
                      [0.6, -4.666],
                      [0.833, -5.2],
                      [1, -5.3],
                      [-1.934, 0.334],
                      [-1.8, 0.234],
                      [-1.667, 0.4],
                      [-1.067, -0.3],
                      [-1.4, -1.4],
                      [-1.166, -2.133],
                      [-0.8, -1.533],
                      [0.4, -0.5],
                      [3.933, -0.8],
                      [4.966, -0.666],
                      [5.166, -0.533],
                      [4.966, -0.366],
                      [2.066, 0],
                      [0.9, 0.367],
                      [1.333, 1.534],
                      [0.866, 1.8],
                      [0.4, 1.534],
                      [-0.367, 0.567],
                      [-2.6, 0.267],
                    ],
                    v: [
                      [55.7, -69.35],
                      [46.2, -68.4],
                      [41.85, -51],
                      [37.5, -33.15],
                      [33.55, -15.9],
                      [30.3, -0.2],
                      [29.35, 1.65],
                      [27.3, 2.05],
                      [24.3, 1.5],
                      [20.5, 0.4],
                      [16.7, -0.9],
                      [13.85, -2.6],
                      [12.2, -4.95],
                      [12.1, -8.2],
                      [13.3, -20],
                      [15.45, -34.8],
                      [18.2, -50.55],
                      [21.2, -65.3],
                      [15.6, -64.45],
                      [11.1, -63.6],
                      [7, -63.45],
                      [3.4, -66],
                      [-0.45, -71.3],
                      [-3.3, -76.7],
                      [-3.9, -79.75],
                      [-1.4, -81],
                      [11.95, -83.2],
                      [27.15, -85],
                      [42.35, -86.35],
                      [55.7, -87.1],
                      [60.15, -86.55],
                      [62.5, -84.7],
                      [65.8, -79.7],
                      [67.8, -74.4],
                      [67.85, -71.25],
                      [64.2, -70.2],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Komika Axis",
    },
    {
      ch: "R",
      size: 39.1662902832031,
      style: "Regular",
      w: 72.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.766, -3.733],
                      [0.733, -3.6],
                      [0.566, -0.334],
                      [1.533, 0.333],
                      [1.4, 0.266],
                      [1.266, 0.667],
                      [0.467, 1.034],
                      [-0.133, 1.734],
                      [-0.233, 2.667],
                      [-0.333, 3.367],
                      [-0.433, 3.867],
                      [-0.5, 3.867],
                      [-0.6, 3.634],
                      [-0.667, 2.9],
                      [-0.8, 1.9],
                      [-0.867, 0.467],
                      [-4.5, 1.8],
                      [-4.367, 0.767],
                      [-3.9, -0.633],
                      [-2.934, -2.866],
                      [-1.9, -2.9],
                      [-1.067, -4.2],
                      [1.533, -2.866],
                      [5.366, -3.866],
                      [7.466, -3.333],
                      [-1.834, -1.933],
                      [-1.934, -1.933],
                      [-1.9, -1.8],
                      [-1.667, -1.333],
                      [0.133, -0.666],
                      [1, -0.733],
                      [0.833, -0.166],
                      [1.2, -0.2],
                      [1.766, 0.166],
                      [2.333, 1.4],
                      [2, 1.6],
                      [2.133, 1.934],
                      [2.133, 2.1],
                      [1.733, 2.134],
                    ],
                    o: [
                      [-0.767, 3.734],
                      [-0.334, 1.066],
                      [-0.567, 0.333],
                      [-1.8, -0.467],
                      [-1.4, -0.267],
                      [-1.266, -0.733],
                      [-0.466, -1.033],
                      [0.134, -1.4],
                      [0.234, -2.666],
                      [0.334, -3.366],
                      [0.434, -3.866],
                      [0.5, -3.866],
                      [0.6, -3.633],
                      [0.666, -2.9],
                      [0.8, -1.9],
                      [4.133, -1.866],
                      [4.5, -1.8],
                      [4.366, -0.766],
                      [3.9, 0.634],
                      [2.333, 2.534],
                      [1.9, 2.9],
                      [0.866, 4.467],
                      [-2.334, 4.6],
                      [-5.367, 3.867],
                      [1.533, 1.734],
                      [1.833, 1.934],
                      [1.933, 1.934],
                      [1.9, 1.8],
                      [1.333, 1.134],
                      [-0.134, 0.667],
                      [-1.2, 0.934],
                      [-0.834, 0.167],
                      [-1.467, 0.4],
                      [-1.767, -0.167],
                      [-1.534, -1.066],
                      [-2, -1.6],
                      [-2.134, -1.933],
                      [-2.134, -2.1],
                      [-0.934, 4.067],
                    ],
                    v: [
                      [15.55, -11.7],
                      [13.3, -0.7],
                      [11.95, 1.4],
                      [8.8, 1.4],
                      [4, 0.3],
                      [0, -1.1],
                      [-2.6, -3.75],
                      [-3.1, -7.9],
                      [-2.55, -14],
                      [-1.7, -23.05],
                      [-0.55, -33.9],
                      [0.85, -45.5],
                      [2.5, -56.75],
                      [4.4, -66.55],
                      [6.6, -73.75],
                      [9.1, -77.3],
                      [22.05, -82.8],
                      [35.35, -86.65],
                      [47.75, -86.85],
                      [58, -81.6],
                      [64.35, -73.45],
                      [68.8, -62.8],
                      [67.8, -51.8],
                      [56.25, -39.1],
                      [37, -28.3],
                      [42.05, -22.8],
                      [47.7, -17],
                      [53.45, -11.4],
                      [58.8, -6.7],
                      [60.6, -4],
                      [58.9, -1.9],
                      [55.85, -0.25],
                      [52.8, 0.3],
                      [47.95, 0.65],
                      [41.8, -1.7],
                      [36.5, -5.7],
                      [30.3, -11],
                      [23.9, -17.05],
                      [18.1, -23.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1, -3.866],
                      [1.266, -4.4],
                      [-3.567, 2.234],
                      [-2.4, 2.3],
                      [-1.067, 2.067],
                      [0.4, 1.134],
                      [3.933, 0],
                      [2.333, -0.466],
                      [2.6, -1.266],
                    ],
                    o: [
                      [-1, 3.867],
                      [4.6, -1.533],
                      [3.566, -2.233],
                      [2.4, -2.3],
                      [1.066, -2.066],
                      [-0.8, -1.866],
                      [-1.934, 0],
                      [-2.334, 0.467],
                      [-1.067, 3.4],
                    ],
                    v: [
                      [25.7, -53.1],
                      [22.3, -40.7],
                      [34.55, -46.35],
                      [43.5, -53.15],
                      [48.7, -59.7],
                      [49.7, -64.5],
                      [42.6, -67.3],
                      [36.2, -66.6],
                      [28.8, -64],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Komika Axis",
    },
    {
      ch: "A",
      size: 39.1662902832031,
      style: "Regular",
      w: 71.8,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [4.966, -0.7],
                      [3.8, -1],
                      [1, -2.233],
                      [0.933, -2.133],
                      [0.766, -0.2],
                      [1.533, 0.533],
                      [1.034, 0.433],
                      [1.067, 0.534],
                      [0.6, 0.367],
                      [0.334, 0.534],
                      [0.034, 0.834],
                      [-0.4, 1.4],
                      [-2.566, 6.2],
                      [-2.967, 6.6],
                      [-3.1, 6.434],
                      [-2.8, 5],
                      [-1.6, 1.134],
                      [-3.134, -0.266],
                      [-2.134, -0.3],
                      [-1.767, -0.4],
                      [-1.5, -0.466],
                      [-1.534, -0.4],
                      [-0.567, -1.2],
                      [0.2, -1.933],
                      [0.266, -6.133],
                      [0.133, -6.666],
                      [0.033, -6.7],
                      [-0.067, -5.733],
                      [0.5, -0.467],
                      [1.733, 0],
                      [1.466, 0.1],
                      [1.533, 0.533],
                      [0.633, 0.734],
                      [0.266, 1.867],
                      [0.2, 2.3],
                      [0.2, 2.734],
                    ],
                    o: [
                      [-4.967, 0.7],
                      [-1.334, 2.6],
                      [-1, 2.234],
                      [-0.534, 1.133],
                      [-0.767, 0.2],
                      [-0.867, -0.334],
                      [-1.033, -0.433],
                      [-0.8, -0.533],
                      [-0.6, -0.366],
                      [-0.333, -0.533],
                      [-0.033, -0.833],
                      [1.6, -4.6],
                      [2.566, -6.2],
                      [2.966, -6.6],
                      [3.1, -6.433],
                      [1, -1.8],
                      [1.6, -1.133],
                      [2.733, 0.134],
                      [2.133, 0.3],
                      [1.766, 0.4],
                      [1.5, 0.467],
                      [2.066, 0.734],
                      [0.566, 1.2],
                      [-0.4, 4.734],
                      [-0.267, 6.134],
                      [-0.134, 6.667],
                      [-0.034, 6.7],
                      [0, 1.2],
                      [-0.5, 0.466],
                      [-1.734, 0],
                      [-1.467, -0.1],
                      [-1.6, -0.467],
                      [-0.634, -0.733],
                      [-0.267, -1.933],
                      [-0.2, -2.3],
                      [-5, 0.667],
                    ],
                    v: [
                      [26.75, -16.85],
                      [13.6, -14.3],
                      [10.1, -7.05],
                      [7.2, -0.5],
                      [5.25, 1.5],
                      [1.8, 1],
                      [-1.05, -0.15],
                      [-4.2, -1.6],
                      [-6.3, -2.95],
                      [-7.7, -4.3],
                      [-8.25, -6.35],
                      [-7.7, -9.7],
                      [-1.45, -25.9],
                      [6.85, -45.1],
                      [15.95, -64.65],
                      [24.8, -81.8],
                      [28.7, -86.2],
                      [35.8, -87.5],
                      [43.1, -86.85],
                      [48.95, -85.8],
                      [53.85, -84.5],
                      [58.4, -83.2],
                      [62.35, -80.3],
                      [62.9, -75.6],
                      [61.9, -59.3],
                      [61.3, -40.1],
                      [61.05, -20.05],
                      [61.1, -1.4],
                      [60.35, 1.1],
                      [57, 1.8],
                      [52.2, 1.65],
                      [47.7, 0.7],
                      [44.35, -1.1],
                      [43, -5],
                      [42.3, -11.35],
                      [41.7, -18.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.034, 0.334],
                      [-3.067, 0.2],
                      [0.066, 5.434],
                      [-0.134, 5.334],
                      [3.1, -5.9],
                      [3.133, -6.333],
                    ],
                    o: [
                      [3.033, -0.333],
                      [-0.134, -5.466],
                      [-0.067, -5.433],
                      [-2.667, 4.867],
                      [-3.1, 5.9],
                      [3, -0.466],
                    ],
                    v: [
                      [31.65, -34.1],
                      [40.8, -34.9],
                      [40.5, -51.25],
                      [40.6, -67.4],
                      [31.95, -51.25],
                      [22.6, -32.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Komika Axis",
    },
    {
      ch: "S",
      size: 51.9145317077637,
      style: "Bold Italic",
      w: 52.1,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.525],
                      [9.164, 0],
                      [4.23, -4.935],
                      [0, -5.237],
                      [-1.007, -1.813],
                      [-3.525, -2.719],
                      [0, -3.525],
                      [1.611, -1.712],
                      [1.712, 0],
                      [0, 3.223],
                      [-0.705, 2.417],
                      [0, 0],
                      [0, -4.028],
                      [-9.467, 0],
                      [-4.532, 5.438],
                      [0, 5.136],
                      [1.007, 1.813],
                      [2.417, 1.913],
                      [1.007, 1.611],
                      [0, 1.41],
                      [-0.806, 1.41],
                      [-2.417, 0],
                      [0, -2.719],
                      [0.604, -2.417],
                      [0, 0],
                    ],
                    o: [
                      [0, -11.884],
                      [-6.042, 0],
                      [-3.323, 3.928],
                      [0, 2.115],
                      [1.913, 3.625],
                      [2.518, 1.913],
                      [0, 2.316],
                      [-1.208, 1.309],
                      [-3.021, 0],
                      [0, -2.417],
                      [0, 0],
                      [-1.007, 3.827],
                      [0, 12.085],
                      [6.647, 0],
                      [3.323, -3.928],
                      [0, -2.014],
                      [-1.511, -2.82],
                      [-2.316, -1.913],
                      [-0.806, -1.208],
                      [0, -1.611],
                      [1.208, -2.014],
                      [2.417, 0],
                      [0, 2.618],
                      [0, 0],
                      [0.806, -3.424],
                    ],
                    v: [
                      [58.008, -59.015],
                      [36.658, -72.308],
                      [19.739, -66.769],
                      [12.991, -46.628],
                      [14.401, -40.686],
                      [28.5, -28.601],
                      [33.838, -21.35],
                      [31.018, -12.488],
                      [26.285, -10.574],
                      [21.149, -15.308],
                      [23.163, -24.472],
                      [5.942, -24.472],
                      [3.827, -12.689],
                      [25.882, 1.208],
                      [45.721, -5.338],
                      [51.562, -23.666],
                      [50.354, -29.407],
                      [43.607, -36.356],
                      [31.018, -46.426],
                      [30.011, -49.85],
                      [31.723, -57.101],
                      [36.859, -60.123],
                      [41.391, -56.195],
                      [39.78, -48.541],
                      [56.195, -48.541],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Akzidenz-Grotesk BQ Extra Conde",
    },
    {
      ch: "U",
      size: 51.9145317077637,
      style: "Bold Italic",
      w: 54.2,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.035, 0],
                      [0, 3.122],
                      [-0.302, 1.309],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -5.035],
                      [-10.373, 0],
                      [-4.33, 9.467],
                      [-0.705, 3.223],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-0.906, 4.028],
                      [-2.719, 0],
                      [0, -1.309],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.108, 4.935],
                      [0, 12.186],
                      [9.769, 0],
                      [1.41, -3.021],
                      [0, 0],
                    ],
                    v: [
                      [63.547, -71.1],
                      [46.527, -71.1],
                      [34.744, -20.343],
                      [27.695, -12.186],
                      [22.458, -16.415],
                      [22.961, -20.343],
                      [34.744, -71.1],
                      [17.523, -71.1],
                      [7.755, -29.507],
                      [5.237, -14.502],
                      [26.385, 1.208],
                      [48.944, -10.776],
                      [51.865, -20.343],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Akzidenz-Grotesk BQ Extra Conde",
    },
    {
      ch: "T",
      size: 51.9145317077637,
      style: "Bold Italic",
      w: 41.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [55.188, -71.1],
                      [13.092, -71.1],
                      [10.172, -58.813],
                      [23.163, -58.813],
                      [9.567, 0],
                      [26.889, 0],
                      [40.485, -58.813],
                      [52.368, -58.813],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Akzidenz-Grotesk BQ Extra Conde",
    },
    {
      ch: "R",
      size: 51.9145317077637,
      style: "Bold Italic",
      w: 54.2,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 2.618],
                      [11.783, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.813, 3.223],
                      [-1.208, 5.035],
                    ],
                    o: [
                      [0, -7.352],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.532, -1.41],
                      [1.511, -2.618],
                      [1.007, -4.431],
                    ],
                    v: [
                      [59.821, -60.425],
                      [42.902, -71.1],
                      [18.53, -71.1],
                      [2.115, 0],
                      [19.437, 0],
                      [26.285, -29.91],
                      [28.802, -29.91],
                      [33.536, 0],
                      [51.059, 0],
                      [44.614, -31.219],
                      [54.181, -38.068],
                      [58.31, -49.75],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -2.216],
                      [0.604, -2.618],
                      [0.403, -1.108],
                      [3.625, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 1.208],
                      [-0.504, 2.518],
                      [-1.108, 2.216],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.323, 0],
                    ],
                    v: [
                      [43.405, -55.692],
                      [42.499, -50.253],
                      [41.19, -45.52],
                      [34.644, -42.398],
                      [29.205, -42.398],
                      [33.032, -58.914],
                      [38.672, -58.914],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Akzidenz-Grotesk BQ Extra Conde",
    },
    {
      ch: "A",
      size: 51.9145317077637,
      style: "Bold Italic",
      w: 54.2,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [56.9, -71.1],
                      [29.205, -71.1],
                      [-1.309, 0],
                      [17.12, 0],
                      [23.163, -15.811],
                      [36.356, -15.811],
                      [34.845, 0],
                      [51.764, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [40.686, -64.554],
                      [37.463, -27.997],
                      [27.695, -27.997],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Akzidenz-Grotesk BQ Extra Conde",
    },
    {
      ch: "S",
      size: 41.5951995849609,
      style: "HeavyItalic",
      w: 64.9,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.666, 2.3],
                      [1.566, 1.567],
                      [2.233, 1],
                      [2.466, 0.734],
                      [2.4, 0.567],
                      [1.833, 0.7],
                      [1.033, 0.967],
                      [-0.334, 1.534],
                      [-0.834, 0.834],
                      [-1.1, 0.467],
                      [-1.167, 0.2],
                      [-1, 0],
                      [-1.3, -0.333],
                      [-0.9, -0.766],
                      [-0.334, -1.166],
                      [0.533, -1.6],
                      [0, 0],
                      [0.933, 2.967],
                      [2.233, 1.834],
                      [3.3, 0.8],
                      [3.8, 0],
                      [3.6, -0.9],
                      [3.066, -1.866],
                      [2.266, -2.9],
                      [0.933, -4.066],
                      [-0.9, -2.3],
                      [-1.967, -1.466],
                      [-2.667, -0.9],
                      [-2.667, -0.8],
                      [-1.967, -0.633],
                      [-1.167, -0.633],
                      [-0.367, -0.833],
                      [0.266, -1.266],
                      [0.933, -1.033],
                      [1.2, -0.633],
                      [1.3, -0.266],
                      [1.133, 0],
                      [1.966, 1.767],
                      [-0.934, 4.267],
                      [0, 0],
                      [-0.5, -2.7],
                      [-1.366, -1.9],
                      [-2, -1.2],
                      [-2.134, -0.7],
                      [-2.067, -0.234],
                      [-1.534, 0],
                      [-4, 0.833],
                      [-3.434, 1.9],
                      [-2.6, 3.067],
                      [-1, 4.534],
                    ],
                    o: [
                      [-0.667, -2.3],
                      [-1.567, -1.566],
                      [-2.234, -1],
                      [-2.4, -0.666],
                      [-2.4, -0.566],
                      [-1.834, -0.7],
                      [-1.034, -0.966],
                      [0.266, -1.266],
                      [0.833, -0.833],
                      [1.1, -0.466],
                      [1.166, -0.2],
                      [1.333, 0],
                      [1.3, 0.334],
                      [0.9, 0.767],
                      [0.333, 1.167],
                      [0, 0],
                      [0.866, -4.333],
                      [-0.934, -2.966],
                      [-2.234, -1.833],
                      [-3.3, -0.8],
                      [-3.6, 0],
                      [-3.6, 0.9],
                      [-3.067, 1.867],
                      [-2.267, 2.9],
                      [-0.8, 3.6],
                      [0.9, 2.3],
                      [1.966, 1.467],
                      [2.666, 0.9],
                      [2.866, 0.867],
                      [1.966, 0.634],
                      [1.166, 0.634],
                      [0.366, 0.834],
                      [-0.334, 1.534],
                      [-0.934, 1.034],
                      [-1.2, 0.634],
                      [-1.3, 0.267],
                      [-4.134, 0],
                      [-1.967, -1.766],
                      [0, 0],
                      [-0.8, 3.734],
                      [0.5, 2.7],
                      [1.366, 1.9],
                      [2, 1.2],
                      [2.133, 0.7],
                      [2.066, 0.233],
                      [3.866, 0],
                      [4, -0.833],
                      [3.433, -1.9],
                      [2.6, -3.066],
                      [0.8, -3.333],
                    ],
                    v: [
                      [61.1, -30.95],
                      [57.75, -36.75],
                      [52.05, -40.6],
                      [45, -43.2],
                      [37.8, -45.05],
                      [31.45, -46.95],
                      [27.15, -49.45],
                      [26.1, -53.2],
                      [27.75, -56.35],
                      [30.65, -58.3],
                      [34.05, -59.3],
                      [37.3, -59.6],
                      [41.25, -59.1],
                      [44.55, -57.45],
                      [46.4, -54.55],
                      [46.1, -50.4],
                      [64.5, -50.4],
                      [64.4, -61.35],
                      [59.65, -68.55],
                      [51.35, -72.5],
                      [40.7, -73.7],
                      [29.9, -72.35],
                      [19.9, -68.2],
                      [11.9, -61.05],
                      [7.1, -50.6],
                      [7.25, -41.75],
                      [11.55, -36.1],
                      [18.5, -32.55],
                      [26.5, -30],
                      [33.75, -27.75],
                      [38.45, -25.85],
                      [40.75, -23.65],
                      [40.9, -20.5],
                      [39, -16.65],
                      [35.8, -14.15],
                      [32.05, -12.8],
                      [28.4, -12.4],
                      [19.25, -15.05],
                      [17.7, -24.1],
                      [-1.8, -24.1],
                      [-2.25, -14.45],
                      [0.55, -7.55],
                      [5.6, -2.9],
                      [11.8, -0.05],
                      [18.1, 1.35],
                      [23.5, 1.7],
                      [35.3, 0.45],
                      [46.45, -3.65],
                      [55.5, -11.1],
                      [60.9, -22.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "ClementePDap",
    },
    {
      ch: "U",
      size: 41.5951995849609,
      style: "HeavyItalic",
      w: 62,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.934, 8.467],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.4, -1.303],
                      [0.633, -1.134],
                      [1, -0.769],
                      [1.466, 0],
                      [0.633, 0.769],
                      [0.133, 1.134],
                      [-0.2, 1.303],
                      [-0.267, 1.069],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.267, -4.2],
                      [-8.534, 0],
                      [-5.334, 4.067],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.267, 1.069],
                      [-0.4, 1.303],
                      [-0.634, 1.134],
                      [-1, 0.769],
                      [-1.467, 0],
                      [-0.634, -0.769],
                      [-0.134, -1.134],
                      [0.2, -1.303],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.867, 8.2],
                      [3.266, 4.2],
                      [8.333, 0],
                      [5.333, -4.066],
                    ],
                    v: [
                      [53.7, -23.2],
                      [64.9, -72],
                      [45.4, -72],
                      [34.5, -24.619],
                      [33.5, -21.062],
                      [31.95, -17.406],
                      [29.5, -14.552],
                      [25.8, -13.4],
                      [22.65, -14.552],
                      [21.5, -17.406],
                      [21.6, -21.062],
                      [22.3, -24.619],
                      [33.2, -72],
                      [13.7, -72],
                      [2.5, -23.2],
                      [4.6, -4.6],
                      [22.3, 1.7],
                      [42.8, -4.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "ClementePDap",
    },
    {
      ch: "T",
      size: 41.5951995849609,
      style: "HeavyItalic",
      w: 62,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2, -0.9],
                      [1.266, -1.466],
                      [0.7, -1.833],
                      [0.4, -1.866],
                      [0, 0],
                      [-1.167, 0.8],
                      [-2.467, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-3.134, 0],
                      [-2, 0.9],
                      [-1.267, 1.467],
                      [-0.7, 1.834],
                      [0, 0],
                      [0.533, -2.266],
                      [1.166, -0.8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [66.6, -55.7],
                      [70.3, -72],
                      [22, -72],
                      [14.3, -70.65],
                      [9.4, -67.1],
                      [6.45, -62.15],
                      [4.8, -56.6],
                      [15.8, -49.9],
                      [18.35, -54.5],
                      [23.8, -55.7],
                      [25.8, -55.7],
                      [12.9, 0],
                      [32.4, 0],
                      [45.3, -55.7],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "ClementePDap",
    },
    {
      ch: "R",
      size: 41.5951995849609,
      style: "HeavyItalic",
      w: 62.8,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.933, -1.1],
                      [1.233, -0.6],
                      [1.366, -0.2],
                      [1.2, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.4, -1.433],
                      [0.6, -2.666],
                    ],
                    o: [
                      [-0.934, 1.1],
                      [-1.234, 0.6],
                      [-1.367, 0.2],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.466, 0],
                      [1.4, 1.434],
                      [-0.4, 1.8],
                    ],
                    v: [
                      [40.5, -44.25],
                      [37.25, -41.7],
                      [33.35, -40.5],
                      [29.5, -40.2],
                      [26.1, -40.2],
                      [30, -56.9],
                      [32.5, -56.9],
                      [41.3, -54.75],
                      [42.5, -48.6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.666, 2.534],
                      [1.7, 1.667],
                      [2.6, 0.834],
                      [3.133, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.267, -0.766],
                      [-0.5, -1.333],
                      [0.133, -1.766],
                      [0.466, -2],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.067, 1.9],
                      [0.5, 1.634],
                      [1.1, 1.267],
                      [1.8, 0.667],
                      [-1.667, 1.2],
                      [-1.3, 1.6],
                      [-0.9, 1.867],
                      [-0.467, 1.934],
                    ],
                    o: [
                      [-0.667, -2.533],
                      [-1.7, -1.666],
                      [-2.6, -0.833],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.333, 0],
                      [1.266, 0.767],
                      [0.5, 1.334],
                      [-0.134, 1.767],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.4, -1.933],
                      [0.066, -1.9],
                      [-0.5, -1.633],
                      [-1.1, -1.266],
                      [2.066, -0.666],
                      [1.666, -1.2],
                      [1.3, -1.6],
                      [0.9, -1.866],
                      [0.8, -3.4],
                    ],
                    v: [
                      [62.9, -60.7],
                      [59.35, -67],
                      [52.9, -70.75],
                      [44.3, -72],
                      [14, -72],
                      [-2.6, 0],
                      [16.9, 0],
                      [23, -26.6],
                      [26.6, -26.6],
                      [32, -25.45],
                      [34.65, -22.3],
                      [35.2, -17.65],
                      [34.3, -12],
                      [31.5, 0],
                      [50.5, 0],
                      [54, -15.6],
                      [54.7, -21.35],
                      [54.05, -26.65],
                      [51.65, -31],
                      [47.3, -33.9],
                      [52.9, -36.7],
                      [57.35, -40.9],
                      [60.65, -46.1],
                      [62.7, -51.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "ClementePDap",
    },
    {
      ch: "A",
      size: 41.5951995849609,
      style: "HeavyItalic",
      w: 62,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.4, 1.317],
                      [-0.634, 1.148],
                      [-1, 0.778],
                      [-1.467, 0],
                      [-0.634, -0.777],
                      [-0.134, -1.148],
                      [0.2, -1.317],
                      [0.266, -1.081],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0.266, -1.081],
                      [0.4, -1.317],
                      [0.633, -1.148],
                      [1, -0.777],
                      [1.466, 0],
                      [0.633, 0.778],
                      [0.133, 1.148],
                      [-0.2, 1.317],
                      [0, 0],
                    ],
                    v: [
                      [38.8, -43.5],
                      [26.6, -43.5],
                      [27.5, -47.25],
                      [28.5, -50.847],
                      [30.05, -54.545],
                      [32.5, -57.434],
                      [36.2, -58.6],
                      [39.35, -57.434],
                      [40.5, -54.545],
                      [40.4, -50.847],
                      [39.7, -47.25],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [3.333, 4.2],
                      [8.533, 0],
                      [5.3, -4.066],
                      [1.933, -8.466],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [1.933, -8.2],
                      [-3.334, -4.2],
                      [-8.334, 0],
                      [-5.3, 4.067],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [48.3, 0],
                      [59.6, -48.8],
                      [57.5, -67.4],
                      [39.7, -73.7],
                      [19.25, -67.6],
                      [8.4, -48.8],
                      [-2.9, 0],
                      [16.6, 0],
                      [23.2, -28.4],
                      [35.4, -28.4],
                      [28.8, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "ClementePDap",
    },
    {
      ch: "S",
      size: 37.2609596252441,
      style: "Black Italic",
      w: 62.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.366, 1],
                      [3.8, 0],
                      [4.133, -4.333],
                      [0, -5.066],
                      [-2.4, -3.2],
                      [-2.934, -2.8],
                      [-2.467, -2.6],
                      [0, -3],
                      [1.833, -1.8],
                      [3.333, 0],
                      [2.866, 3.9],
                      [0.266, 6.667],
                      [0.4, 0.134],
                      [0.466, 0],
                      [0.666, -0.166],
                      [0, -0.2],
                      [0.7, -3.933],
                      [0.533, -3.066],
                      [-3.134, -1.466],
                      [-4.6, 0],
                      [-4.967, 4.367],
                      [0, 5.6],
                      [2.366, 3.367],
                      [2.8, 2.534],
                      [2.566, 2.5],
                      [0, 3.067],
                      [-1.7, 1.634],
                      [-2.534, 0],
                      [-2.334, -3.566],
                      [0.133, -4.933],
                      [-0.467, -0.133],
                      [-0.467, 0],
                      [-0.6, 0.167],
                      [-0.067, 0.2],
                      [-0.4, 3.2],
                      [-0.6, 3.4],
                    ],
                    o: [
                      [-3.367, -1],
                      [-8.8, 0],
                      [-4.134, 4.334],
                      [0, 4.4],
                      [2.4, 3.2],
                      [2.733, 2.6],
                      [2.466, 2.6],
                      [0, 3.534],
                      [-1.834, 1.8],
                      [-3.867, 0],
                      [-2.867, -3.9],
                      [0, -0.266],
                      [-0.4, -0.133],
                      [-0.334, 0],
                      [-0.667, 0.167],
                      [-0.334, 3.067],
                      [-0.7, 3.934],
                      [3.933, 0.734],
                      [3.133, 1.466],
                      [9.066, 0],
                      [4.966, -4.366],
                      [0, -5],
                      [-2.367, -3.366],
                      [-2.4, -2.2],
                      [-2.567, -2.5],
                      [0, -3.133],
                      [1.7, -1.633],
                      [3, 0],
                      [2.333, 3.567],
                      [0, 0.267],
                      [0.466, 0.134],
                      [0.333, 0],
                      [0.6, -0.166],
                      [0.333, -3.2],
                      [0.4, -3.2],
                      [-2.667, -0.266],
                    ],
                    v: [
                      [49.45, -69.1],
                      [38.7, -70.6],
                      [19.3, -64.1],
                      [13.1, -50],
                      [16.7, -38.6],
                      [24.7, -29.6],
                      [32.5, -21.8],
                      [36.2, -13.4],
                      [33.45, -5.4],
                      [25.7, -2.7],
                      [15.6, -8.55],
                      [10.9, -24.4],
                      [10.3, -25],
                      [9, -25.2],
                      [7.5, -24.95],
                      [6.5, -24.4],
                      [4.95, -13.9],
                      [3.1, -3.4],
                      [13.7, -0.1],
                      [25.3, 2.1],
                      [46.35, -4.45],
                      [53.8, -19.4],
                      [50.25, -31.95],
                      [42.5, -40.8],
                      [35.05, -47.85],
                      [31.2, -56.2],
                      [33.75, -63.35],
                      [40.1, -65.8],
                      [48.1, -60.45],
                      [51.4, -47.7],
                      [52.1, -47.1],
                      [53.5, -46.9],
                      [54.9, -47.15],
                      [55.9, -47.7],
                      [57, -57.3],
                      [58.5, -67.2],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Hoefler Text",
    },
    {
      ch: "U",
      size: 37.2609596252441,
      style: "Black Italic",
      w: 78.6,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.133, 0.33],
                      [0.133, 0],
                      [1.766, -0.166],
                      [2.333, 0],
                      [1.772, 0.167],
                      [2.266, 0],
                      [0.233, -0.659],
                      [0, -0.461],
                      [-0.134, -0.428],
                      [-0.134, -0.066],
                      [-0.735, -0.7],
                      [0, -1.467],
                      [0.134, -0.567],
                      [0.134, -0.6],
                      [0, 0],
                      [3.23, -4.337],
                      [6.328, 0],
                      [1.133, 3.87],
                      [-1.4, 5.538],
                      [0, 0],
                      [-1.1, 1.067],
                      [-4.467, 0.467],
                      [-0.234, 0.725],
                      [0, 0.33],
                      [0.1, 0.33],
                      [0.133, 0],
                      [3.499, -0.166],
                      [2.598, 0],
                      [3.531, 0.167],
                      [3.2, 0],
                      [0.266, -0.659],
                      [0, -0.461],
                      [-0.1, -0.428],
                      [-0.134, -0.066],
                      [-0.7, -0.7],
                      [0, -1.467],
                      [0.1, -0.534],
                      [0.2, -0.667],
                      [0, 0],
                      [0.199, -1.502],
                      [0, -0.9],
                      [-4.333, -4.17],
                      [-9.098, 0],
                      [-4.5, 5.438],
                      [-1.933, 7.925],
                      [0, 0],
                      [-1.267, 1.201],
                      [-5, 0.534],
                      [-0.234, 0.725],
                      [0, 0.33],
                    ],
                    o: [
                      [-0.134, -0.33],
                      [-2.4, 0],
                      [-1.767, 0.167],
                      [-2.484, 0],
                      [-1.773, -0.166],
                      [-0.334, 0],
                      [-0.234, 0.659],
                      [0, 0.198],
                      [0.133, 0.428],
                      [3.733, 0.467],
                      [0.734, 0.701],
                      [0, 0.534],
                      [-0.134, 0.567],
                      [0, 0],
                      [-1.266, 5.405],
                      [-3.231, 4.338],
                      [-5.529, 0],
                      [-1.133, -3.87],
                      [0, 0],
                      [0.733, -3.002],
                      [1.1, -1.067],
                      [0.333, 0],
                      [0.233, -0.725],
                      [0, -0.461],
                      [-0.1, -0.33],
                      [-2.934, 0],
                      [-3.499, 0.167],
                      [-2.598, 0],
                      [-3.531, -0.166],
                      [-0.267, 0],
                      [-0.267, 0.659],
                      [0, 0.198],
                      [0.1, 0.428],
                      [3.8, 0.467],
                      [0.7, 0.701],
                      [0, 0.534],
                      [-0.1, 0.534],
                      [0, 0],
                      [-0.299, 1.334],
                      [-0.199, 1.502],
                      [0, 6.972],
                      [4.333, 4.17],
                      [8.598, 0],
                      [4.499, -5.438],
                      [0, 0],
                      [0.666, -2.669],
                      [1.266, -1.201],
                      [0.333, 0],
                      [0.233, -0.725],
                      [0, -0.461],
                    ],
                    v: [
                      [85.743, -68.505],
                      [85.343, -69],
                      [79.093, -68.75],
                      [72.943, -68.5],
                      [66.558, -68.75],
                      [60.499, -69],
                      [59.649, -68.011],
                      [59.299, -66.33],
                      [59.499, -65.391],
                      [59.899, -64.65],
                      [66.602, -62.898],
                      [67.704, -59.646],
                      [67.502, -57.995],
                      [67.099, -56.243],
                      [59.691, -25.017],
                      [52.947, -10.405],
                      [38.608, -3.9],
                      [28.615, -9.705],
                      [29.016, -23.816],
                      [36.709, -56.243],
                      [39.459, -62.348],
                      [47.809, -64.65],
                      [48.659, -65.738],
                      [49.009, -67.32],
                      [48.859, -68.505],
                      [48.509, -69],
                      [38.859, -68.75],
                      [29.714, -68.5],
                      [20.521, -68.75],
                      [10.425, -69],
                      [9.625, -68.011],
                      [9.225, -66.33],
                      [9.375, -65.391],
                      [9.725, -64.65],
                      [16.474, -62.898],
                      [17.524, -59.646],
                      [17.374, -58.045],
                      [16.925, -56.244],
                      [10.396, -28.723],
                      [9.648, -24.47],
                      [9.35, -20.867],
                      [15.849, -4.155],
                      [35.996, 2.1],
                      [55.644, -6.056],
                      [65.292, -26.1],
                      [72.443, -56.244],
                      [75.343, -62.048],
                      [84.743, -64.65],
                      [85.593, -65.738],
                      [85.943, -67.32],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Hoefler Text",
    },
    {
      ch: "T",
      size: 37.2609596252441,
      style: "Black Italic",
      w: 75.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [5.433, -0.266],
                      [9.066, 0],
                      [5.033, 0.267],
                      [2.333, 0.4],
                      [1, -4.233],
                      [1, -3.933],
                      [-0.4, -0.233],
                      [-0.667, 0],
                      [-0.467, 0.234],
                      [-0.067, 0.2],
                      [-3.902, 3.867],
                      [-3.535, 0],
                      [0, 0],
                      [-0.434, -0.467],
                      [0.333, -1.534],
                      [0, 0],
                      [1.133, -0.734],
                      [4.266, -0.467],
                      [0.266, -0.626],
                      [0, -0.461],
                      [-0.134, -0.33],
                      [-0.067, 0],
                      [-3.466, 0.166],
                      [-2.597, 0],
                      [-3.73, -0.167],
                      [-3.267, 0],
                      [-0.234, 0.659],
                      [0, 0.462],
                      [0.066, 0.462],
                      [0.2, 0],
                      [1.066, 0.767],
                      [0, 1.402],
                      [-0.1, 0.701],
                      [-0.134, 0.534],
                      [0, 0],
                      [-0.567, 0.667],
                      [-1.467, 0],
                      [0, 0],
                      [-2.034, -3.866],
                      [-0.267, -4.6],
                      [-0.467, -0.166],
                      [-0.467, 0],
                      [-0.5, 0.167],
                      [-0.067, 0.2],
                      [-0.8, 4.8],
                      [-0.734, 3.667],
                    ],
                    o: [
                      [-5.434, 0.267],
                      [-10.534, 0],
                      [-5.034, -0.266],
                      [-0.6, 3.467],
                      [-1, 4.234],
                      [0, 0.2],
                      [0.4, 0.234],
                      [0.466, 0],
                      [0.466, -0.233],
                      [1.534, -3.933],
                      [3.902, -3.866],
                      [0, 0],
                      [1.734, 0],
                      [0.434, 0.467],
                      [0, 0],
                      [-0.867, 3.698],
                      [-1.134, 0.734],
                      [-0.267, 0.066],
                      [-0.267, 0.627],
                      [0, 0.462],
                      [0.133, 0.329],
                      [3, 0],
                      [3.466, -0.167],
                      [2.662, 0],
                      [3.73, 0.166],
                      [0.333, 0],
                      [0.233, -0.659],
                      [0, -0.197],
                      [-0.067, -0.461],
                      [-3.534, -0.4],
                      [-1.066, -0.767],
                      [0, -0.334],
                      [0.1, -0.7],
                      [0, 0],
                      [0.266, -1.134],
                      [0.567, -0.667],
                      [0, 0],
                      [3.401, 0],
                      [2.034, 3.867],
                      [0, 0.2],
                      [0.466, 0.167],
                      [0.466, 0],
                      [0.5, -0.166],
                      [0.4, -3.266],
                      [0.8, -4.8],
                      [-2.067, 0.4],
                    ],
                    v: [
                      [66.25, -68.9],
                      [44.5, -68.5],
                      [21.15, -68.9],
                      [10.1, -69.9],
                      [7.7, -58.35],
                      [4.7, -46.1],
                      [5.3, -45.45],
                      [6.9, -45.1],
                      [8.3, -45.45],
                      [9.1, -46.1],
                      [17.254, -57.8],
                      [28.409, -63.6],
                      [29.21, -63.6],
                      [32.461, -62.899],
                      [32.612, -59.897],
                      [21.218, -12.3],
                      [18.218, -5.652],
                      [10.118, -3.85],
                      [9.318, -2.812],
                      [8.918, -1.18],
                      [9.118, 0.006],
                      [9.418, 0.5],
                      [19.116, 0.25],
                      [28.209, 0],
                      [37.797, 0.25],
                      [48.291, 0.5],
                      [49.141, -0.488],
                      [49.491, -2.17],
                      [49.391, -3.158],
                      [48.991, -3.85],
                      [42.091, -5.602],
                      [40.492, -8.854],
                      [40.641, -10.405],
                      [40.991, -12.257],
                      [52.395, -59.897],
                      [53.645, -62.599],
                      [56.697, -63.6],
                      [57.397, -63.6],
                      [65.549, -57.8],
                      [69, -45.1],
                      [69.7, -44.55],
                      [71.1, -44.3],
                      [72.55, -44.55],
                      [73.4, -45.1],
                      [75.2, -57.2],
                      [77.5, -69.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Hoefler Text",
    },
    {
      ch: "R",
      size: 37.2609596252441,
      style: "Black Italic",
      w: 79.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0.5],
                      [0.2, 0],
                      [1.866, 1.134],
                      [0.933, 2.467],
                      [0, 0],
                      [-4.1, 3.6],
                      [0, 4.667],
                      [3.464, 3.1],
                      [9.592, 0],
                      [2.964, -0.166],
                      [2.598, 0],
                      [2.931, 0.167],
                      [3.2, 0],
                      [0.266, -0.659],
                      [0, -0.461],
                      [-0.1, -0.428],
                      [-0.134, -0.066],
                      [-0.7, -0.702],
                      [0, -1.469],
                      [0.1, -0.534],
                      [0.2, -0.667],
                      [0, 0],
                      [0.933, -1.202],
                      [5, -0.601],
                      [0.267, -0.626],
                      [0, -0.461],
                      [-0.133, -0.33],
                      [-0.133, 0],
                      [-3.532, 0.166],
                      [-2.597, 0],
                      [-3.262, -0.167],
                      [-3.2, 0],
                      [-0.234, 0.659],
                      [0, 0.462],
                      [0.066, 0.462],
                      [0.2, 0],
                      [0.8, 0.769],
                      [0, 1.536],
                      [-0.1, 0.702],
                      [-0.134, 0.534],
                      [0, 0],
                      [-0.827, -0.4],
                      [-0.397, -1.333],
                      [0, 0],
                      [-5.038, -0.334],
                      [-3.937, -0.534],
                      [-0.4, 0.533],
                      [0, 0.6],
                    ],
                    o: [
                      [0, -0.5],
                      [-2.8, -0.6],
                      [-1.867, -1.133],
                      [0, 0],
                      [4.066, -0.733],
                      [4.1, -3.6],
                      [0, -4.666],
                      [-3.464, -3.1],
                      [-3.198, 0],
                      [-2.964, 0.167],
                      [-2.465, 0],
                      [-2.931, -0.166],
                      [-0.267, 0],
                      [-0.267, 0.659],
                      [0, 0.198],
                      [0.1, 0.428],
                      [3.8, 0.467],
                      [0.7, 0.702],
                      [0, 0.534],
                      [-0.1, 0.534],
                      [0, 0],
                      [-0.6, 2.629],
                      [-0.934, 1.202],
                      [-0.266, 0.066],
                      [-0.266, 0.627],
                      [0, 0.462],
                      [0.134, 0.329],
                      [2.867, 0],
                      [3.532, -0.167],
                      [2.596, 0],
                      [3.263, 0.166],
                      [0.266, 0],
                      [0.233, -0.659],
                      [0, -0.197],
                      [-0.067, -0.461],
                      [-3, -0.267],
                      [-0.8, -0.768],
                      [0, -0.334],
                      [0.1, -0.702],
                      [0, 0],
                      [1.653, 0],
                      [0.827, 0.4],
                      [0, 0],
                      [3.202, 0],
                      [5.037, 0.333],
                      [0.4, 0.066],
                      [0.4, -0.534],
                      [0, -0.2],
                    ],
                    v: [
                      [75.1, -1.15],
                      [74.8, -1.9],
                      [67.8, -4.5],
                      [63.6, -9.9],
                      [53.9, -33.8],
                      [66.15, -40.3],
                      [72.3, -52.7],
                      [67.104, -64.35],
                      [47.52, -69],
                      [38.276, -68.75],
                      [29.933, -68.5],
                      [21.838, -68.75],
                      [12.641, -69],
                      [11.841, -68.011],
                      [11.441, -66.33],
                      [11.591, -65.391],
                      [11.941, -64.65],
                      [18.691, -62.897],
                      [19.741, -59.641],
                      [19.591, -58.039],
                      [19.141, -56.236],
                      [8.718, -12.3],
                      [6.418, -6.554],
                      [-2.482, -3.85],
                      [-3.282, -2.812],
                      [-3.682, -1.18],
                      [-3.482, 0.006],
                      [-3.082, 0.5],
                      [6.516, 0.25],
                      [15.709, 0],
                      [24.497, 0.25],
                      [34.191, 0.5],
                      [34.941, -0.488],
                      [35.291, -2.17],
                      [35.191, -3.158],
                      [34.791, -3.85],
                      [29.091, -5.403],
                      [27.892, -8.859],
                      [28.041, -10.412],
                      [28.391, -12.266],
                      [32.823, -30.3],
                      [36.545, -29.7],
                      [38.38, -27.1],
                      [47.477, 0.5],
                      [59.838, 1],
                      [73.298, 2.3],
                      [74.499, 1.6],
                      [75.1, -0.1],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.031, -3],
                      [4.33, 0],
                      [0, 0],
                      [0, 0],
                      [-0.666, 0.5],
                      [-1.333, 0],
                      [-1.499, -1.733],
                      [0, -3.6],
                    ],
                    o: [
                      [-3.032, 3],
                      [0, 0],
                      [0, 0],
                      [0.333, -1.2],
                      [0.666, -0.5],
                      [2.598, 0],
                      [1.499, 1.734],
                      [0, 6.267],
                    ],
                    v: [
                      [48.352, -39.6],
                      [37.309, -35.1],
                      [33.91, -35.1],
                      [40.008, -60.8],
                      [41.507, -63.35],
                      [44.505, -64.1],
                      [50.651, -61.5],
                      [52.9, -53.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Hoefler Text",
    },
    {
      ch: "A",
      size: 37.2609596252441,
      style: "Black Italic",
      w: 77.4,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.1, 0.462],
                      [0.133, 0],
                      [1.266, 1.167],
                      [-0.067, 3.47],
                      [0, 0],
                      [0.666, 0.2],
                      [0.733, 0],
                      [0.666, -0.166],
                      [0, -0.2],
                      [0, 0],
                      [2.533, -1.667],
                      [4.067, -0.4],
                      [0.234, -0.626],
                      [0, -0.461],
                      [-0.133, -0.33],
                      [-0.066, 0],
                      [-1.566, 0.166],
                      [-2.2, 0],
                      [-1.9, -0.167],
                      [-2.867, 0],
                      [-0.234, 0.659],
                      [0, 0.462],
                      [0.1, 0.462],
                      [0.133, 0],
                      [0.633, 0.602],
                      [0, 1.003],
                      [-0.671, 1.17],
                      [-0.873, 1.059],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.833, -1.337],
                      [4.533, -0.267],
                      [0.266, -0.626],
                      [0, -0.461],
                      [-0.134, -0.33],
                      [-0.067, 0],
                      [-3.667, 0.166],
                      [-3, 0],
                      [-3.167, -0.167],
                      [-3.2, 0],
                      [-0.234, 0.659],
                      [0, 0.462],
                    ],
                    o: [
                      [-0.1, -0.461],
                      [-3.867, -0.4],
                      [-1.267, -1.167],
                      [0, 0],
                      [0, -0.133],
                      [-0.667, -0.2],
                      [-0.6, 0],
                      [-0.667, 0.167],
                      [0, 0],
                      [-2.4, 2.936],
                      [-2.533, 1.668],
                      [-0.266, 0.066],
                      [-0.233, 0.627],
                      [0, 0.462],
                      [0.134, 0.329],
                      [2.334, 0],
                      [1.567, -0.167],
                      [2.733, 0],
                      [1.9, 0.166],
                      [0.333, 0],
                      [0.233, -0.659],
                      [0, -0.197],
                      [-0.1, -0.461],
                      [-2, -0.066],
                      [-0.634, -0.602],
                      [0, -0.735],
                      [0.67, -1.17],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 2.808],
                      [-0.834, 1.338],
                      [-0.267, 0.066],
                      [-0.267, 0.627],
                      [0, 0.462],
                      [0.133, 0.329],
                      [3.2, 0],
                      [3.666, -0.167],
                      [2.733, 0],
                      [3.166, 0.166],
                      [0.333, 0],
                      [0.233, -0.659],
                      [0, -0.197],
                    ],
                    v: [
                      [69.05, -3.158],
                      [68.7, -3.85],
                      [61, -6.202],
                      [59.2, -13.157],
                      [59.9, -69.799],
                      [58.9, -70.299],
                      [56.8, -70.6],
                      [54.9, -70.35],
                      [53.9, -69.799],
                      [7.4, -13.858],
                      [0, -6.952],
                      [-9.9, -3.85],
                      [-10.65, -2.812],
                      [-11, -1.18],
                      [-10.8, 0.006],
                      [-10.5, 0.5],
                      [-4.65, 0.25],
                      [1, 0],
                      [7.95, 0.25],
                      [15.1, 0.5],
                      [15.95, -0.488],
                      [16.3, -2.17],
                      [16.15, -3.158],
                      [15.8, -3.85],
                      [11.85, -4.852],
                      [10.9, -7.26],
                      [11.906, -10.118],
                      [14.221, -13.461],
                      [20.21, -20.9],
                      [39.48, -20.9],
                      [39.3, -12.475],
                      [38.05, -6.257],
                      [30, -3.85],
                      [29.2, -2.812],
                      [28.8, -1.18],
                      [29, 0.006],
                      [29.3, 0.5],
                      [39.6, 0.25],
                      [49.6, 0],
                      [58.45, 0.25],
                      [68, 0.5],
                      [68.85, -0.488],
                      [69.2, -2.17],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [39.583, -25.7],
                      [24.134, -25.7],
                      [40.008, -45.581],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Hoefler Text",
    },
    {
      ch: "F",
      size: 56,
      style: "Light",
      w: 52,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [15.8, 0],
                      [15.8, -33.1],
                      [44.8, -33.1],
                      [44.8, -39],
                      [15.8, -39],
                      [15.8, -63.4],
                      [48.5, -63.4],
                      [48.5, -69.3],
                      [9, -69.3],
                      [9, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "F",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "F",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "U",
      size: 56,
      style: "Light",
      w: 68,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.234, 1.4],
                      [-2, 2.467],
                      [-0.9, 3.3],
                      [0, 3.8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.9, -2.766],
                      [1.6, -1.8],
                      [2.266, -0.866],
                      [2.8, 0],
                      [2.266, 0.867],
                      [1.6, 1.8],
                      [0.9, 2.767],
                      [0, 3.8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.9, -3.3],
                      [-2, -2.466],
                      [-3.234, -1.4],
                      [-4.6, 0],
                    ],
                    o: [
                      [3.233, -1.4],
                      [2, -2.466],
                      [0.9, -3.3],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 3.8],
                      [-0.9, 2.767],
                      [-1.6, 1.8],
                      [-2.267, 0.867],
                      [-2.8, 0],
                      [-2.267, -0.866],
                      [-1.6, -1.8],
                      [-0.9, -2.766],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 3.8],
                      [0.9, 3.3],
                      [2, 2.467],
                      [3.233, 1.4],
                      [4.6, 0],
                    ],
                    v: [
                      [45.75, -0.6],
                      [53.6, -6.4],
                      [57.95, -15.05],
                      [59.3, -25.7],
                      [59.3, -69.3],
                      [52.5, -69.3],
                      [52.5, -26.6],
                      [51.15, -16.75],
                      [47.4, -9.9],
                      [41.6, -5.9],
                      [34, -4.6],
                      [26.4, -5.9],
                      [20.6, -9.9],
                      [16.85, -16.75],
                      [15.5, -26.6],
                      [15.5, -69.3],
                      [8.7, -69.3],
                      [8.7, -25.7],
                      [10.05, -15.05],
                      [14.4, -6.4],
                      [22.25, -0.6],
                      [34, 1.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "L",
      size: 56,
      style: "Light",
      w: 50.2,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [15.8, -5.9],
                      [15.8, -69.3],
                      [9, -69.3],
                      [9, 0],
                      [48.4, 0],
                      [48.4, -5.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "L",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "L",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    { ch: " ", size: 56, style: "Light", w: 22.8, data: {}, fFamily: "Ubuntu" },
    {
      ch: "S",
      size: 56,
      style: "Light",
      w: 51.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.933, 0.334],
                      [1.533, 0.5],
                      [1.133, 0.534],
                      [0.666, 0.4],
                      [0, 0],
                      [-1.2, -0.533],
                      [-1.734, -0.5],
                      [-2.2, -0.334],
                      [-2.667, 0],
                      [-4.367, 3.134],
                      [0, 6.267],
                      [1.066, 2.2],
                      [1.8, 1.534],
                      [2.433, 1.134],
                      [2.733, 1.134],
                      [1.933, 0.867],
                      [1.433, 1.067],
                      [0.8, 1.467],
                      [0, 2.134],
                      [-0.767, 1.467],
                      [-1.334, 0.967],
                      [-1.834, 0.434],
                      [-2.2, 0],
                      [-2.767, -0.8],
                      [-1.934, -1.333],
                      [0, 0],
                      [1.3, 0.534],
                      [1.566, 0.434],
                      [1.8, 0.267],
                      [1.866, 0],
                      [3.933, -3.066],
                      [0, -5.933],
                      [-0.834, -1.933],
                      [-1.6, -1.5],
                      [-2.267, -1.192],
                      [-2.867, -1.125],
                      [-2.067, -0.828],
                      [-1.6, -1.169],
                      [-0.967, -1.703],
                      [0, -2.539],
                      [3, -1.972],
                      [5.4, 0],
                    ],
                    o: [
                      [-1.934, -0.333],
                      [-1.534, -0.5],
                      [-1.134, -0.533],
                      [0, 0],
                      [0.666, 0.4],
                      [1.2, 0.534],
                      [1.733, 0.5],
                      [2.2, 0.333],
                      [7.466, 0],
                      [4.366, -3.133],
                      [0, -3.2],
                      [-1.067, -2.2],
                      [-1.8, -1.533],
                      [-2.434, -1.133],
                      [-2.267, -0.933],
                      [-1.934, -0.866],
                      [-1.434, -1.066],
                      [-0.8, -1.466],
                      [0, -2.066],
                      [0.766, -1.466],
                      [1.333, -0.966],
                      [1.833, -0.433],
                      [3.2, 0],
                      [2.766, 0.8],
                      [0, 0],
                      [-0.867, -0.6],
                      [-1.3, -0.533],
                      [-1.567, -0.433],
                      [-1.8, -0.266],
                      [-7, 0],
                      [-3.934, 3.067],
                      [0, 2.6],
                      [0.833, 1.934],
                      [1.6, 1.5],
                      [2.266, 1.194],
                      [2.266, 0.928],
                      [2.066, 0.828],
                      [1.6, 1.169],
                      [0.966, 1.703],
                      [0, 4.409],
                      [-3, 1.972],
                      [-2.334, 0],
                    ],
                    v: [
                      [17.5, -4.9],
                      [12.3, -6.15],
                      [8.3, -7.7],
                      [5.6, -9.1],
                      [3.4, -3.2],
                      [6.2, -1.8],
                      [10.6, -0.25],
                      [16.5, 1],
                      [23.8, 1.5],
                      [41.55, -3.2],
                      [48.1, -17.3],
                      [46.5, -25.4],
                      [42.2, -31],
                      [35.85, -35],
                      [28.1, -38.4],
                      [21.8, -41.1],
                      [16.75, -44],
                      [13.4, -47.8],
                      [12.2, -53.2],
                      [13.35, -58.5],
                      [16.5, -62.15],
                      [21.25, -64.25],
                      [27.3, -64.9],
                      [36.25, -63.7],
                      [43.3, -60.5],
                      [45.7, -66.2],
                      [42.45, -67.9],
                      [38.15, -69.35],
                      [33.1, -70.4],
                      [27.6, -70.8],
                      [11.2, -66.2],
                      [5.3, -52.7],
                      [6.55, -45.9],
                      [10.2, -40.75],
                      [16, -36.711],
                      [23.7, -33.233],
                      [30.2, -30.598],
                      [35.7, -27.602],
                      [39.55, -23.292],
                      [41, -16.928],
                      [36.5, -7.356],
                      [23.9, -4.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "E",
      size: 56,
      style: "Light",
      w: 55.5,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [51.6, 0],
                      [51.6, -5.9],
                      [15.8, -5.9],
                      [15.8, -33.5],
                      [45.4, -33.5],
                      [45.4, -39.3],
                      [15.8, -39.3],
                      [15.8, -63.4],
                      [49, -63.4],
                      [49, -69.3],
                      [9, -69.3],
                      [9, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "E",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "E",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "R",
      size: 56,
      style: "Light",
      w: 61.8,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [4.833, 3.6],
                      [9.533, 0],
                      [3.233, -0.3],
                      [2.4, -0.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.134, 0.2],
                      [-1.6, -2.133],
                      [-1.7, -2.5],
                      [-1.634, -2.666],
                      [-1.4, -2.666],
                      [0, 0],
                      [1.733, 2.934],
                      [1.8, 2.7],
                      [1.7, 2.267],
                      [1.066, 1.334],
                      [-2.734, 3],
                      [0, 5.2],
                    ],
                    o: [
                      [-4.834, -3.6],
                      [-2.734, 0],
                      [-3.234, 0.3],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.133, 0],
                      [1.2, 1.6],
                      [1.6, 2.134],
                      [1.7, 2.5],
                      [1.633, 2.667],
                      [0, 0],
                      [-1.2, -2.666],
                      [-1.734, -2.933],
                      [-1.8, -2.7],
                      [-1.7, -2.266],
                      [4.466, -1.333],
                      [2.733, -3],
                      [0, -7.066],
                    ],
                    v: [
                      [47.95, -64.5],
                      [26.4, -69.9],
                      [17.45, -69.45],
                      [9, -68],
                      [9, 0],
                      [15.8, 0],
                      [15.8, -28],
                      [27, -28],
                      [33.4, -28.3],
                      [37.6, -22.7],
                      [42.55, -15.75],
                      [47.55, -8],
                      [52.1, 0],
                      [59.4, 0],
                      [55, -8.4],
                      [49.7, -16.85],
                      [44.45, -24.3],
                      [40.3, -29.7],
                      [51.1, -36.2],
                      [55.2, -48.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.5, -0.433],
                      [-1.934, -1.166],
                      [-1.2, -2],
                      [0, -3.066],
                      [1.2, -1.933],
                      [2.066, -1.033],
                      [2.8, -0.3],
                      [3.133, 0],
                      [0, 0],
                      [0, 0],
                      [-1.9, 0.167],
                      [-2.534, 0],
                    ],
                    o: [
                      [2.5, 0.434],
                      [1.933, 1.167],
                      [1.2, 2],
                      [0, 3.334],
                      [-1.2, 1.934],
                      [-2.067, 1.034],
                      [-2.8, 0.3],
                      [0, 0],
                      [0, 0],
                      [1.266, -0.133],
                      [1.9, -0.166],
                      [2.666, 0],
                    ],
                    v: [
                      [34.95, -63.35],
                      [41.6, -60.95],
                      [46.3, -56.2],
                      [48.1, -48.6],
                      [46.3, -40.7],
                      [41.4, -36.25],
                      [34.1, -34.25],
                      [25.2, -33.8],
                      [15.8, -33.8],
                      [15.8, -63.3],
                      [20.55, -63.75],
                      [27.2, -64],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "V",
      size: 56,
      style: "Light",
      w: 65.5,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-2.439, -6.597],
                      [-2.12, -5.325],
                      [-1.939, -4.497],
                      [-2.022, -4.62],
                      [0, 0],
                      [-1.897, 4.502],
                      [-2.081, 5.341],
                      [-2.461, 6.591],
                      [-3.056, 8.764],
                      [0, 0],
                      [1.845, -5.328],
                      [1.847, -4.962],
                      [1.886, -4.772],
                      [2.069, -4.786],
                      [1.85, 4.772],
                      [1.808, 4.964],
                      [1.875, 5.297],
                      [1.997, 5.934],
                    ],
                    o: [
                      [3.059, 8.738],
                      [2.438, 6.598],
                      [2.12, 5.325],
                      [1.938, 4.498],
                      [0, 0],
                      [2.069, -4.573],
                      [1.897, -4.5],
                      [2.081, -5.341],
                      [2.459, -6.589],
                      [0, 0],
                      [-2.006, 5.956],
                      [-1.847, 5.328],
                      [-1.847, 4.963],
                      [-1.886, 4.772],
                      [-2.17, -4.866],
                      [-1.85, -4.772],
                      [-1.808, -4.964],
                      [-1.875, -5.297],
                      [0, 0],
                    ],
                    v: [
                      [1, -69.3],
                      [9.247, -46.298],
                      [16.084, -28.413],
                      [22.173, -13.678],
                      [28.114, 0],
                      [34.497, 0],
                      [40.447, -13.613],
                      [46.413, -28.375],
                      [53.225, -46.27],
                      [61.5, -69.3],
                      [54.295, -69.3],
                      [48.519, -52.375],
                      [42.98, -36.939],
                      [37.381, -22.338],
                      [31.45, -8],
                      [25.419, -22.456],
                      [19.931, -37.061],
                      [14.408, -52.453],
                      [8.6, -69.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "V",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "V",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "I",
      size: 56,
      style: "Light",
      w: 24.8,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9, 0],
                      [15.8, 0],
                      [15.8, -69.3],
                      [9, -69.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "I",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "I",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "C",
      size: 56,
      style: "Light",
      w: 60,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.267, 0.3],
                      [-1.734, 0.466],
                      [-1.134, 0.434],
                      [-0.467, 0.334],
                      [0, 0],
                      [2.966, -0.833],
                      [4.4, 0],
                      [3.1, 1.4],
                      [2.133, 2.6],
                      [1.1, 3.7],
                      [0, 4.6],
                      [-1.1, 3.667],
                      [-2.2, 2.667],
                      [-3.234, 1.467],
                      [-4.334, 0],
                      [-2.567, -0.966],
                      [-1.667, -1],
                      [0, 0],
                      [1.133, 0.5],
                      [1.6, 0.5],
                      [2.133, 0.334],
                      [2.6, 0],
                      [3.866, -1.566],
                      [2.933, -3.066],
                      [1.766, -4.5],
                      [0, -5.8],
                      [-1.634, -4.5],
                      [-2.834, -3.066],
                      [-3.834, -1.6],
                      [-4.467, 0],
                    ],
                    o: [
                      [2.266, -0.3],
                      [1.733, -0.466],
                      [1.133, -0.433],
                      [0, 0],
                      [-1.534, 0.867],
                      [-2.967, 0.834],
                      [-4, 0],
                      [-3.1, -1.4],
                      [-2.134, -2.6],
                      [-1.1, -3.7],
                      [0, -4.466],
                      [1.1, -3.666],
                      [2.2, -2.666],
                      [3.233, -1.466],
                      [3.8, 0],
                      [2.566, 0.967],
                      [0, 0],
                      [-0.6, -0.4],
                      [-1.134, -0.5],
                      [-1.6, -0.5],
                      [-2.134, -0.333],
                      [-4.2, 0],
                      [-3.867, 1.567],
                      [-2.934, 3.067],
                      [-1.767, 4.5],
                      [0, 5.8],
                      [1.633, 4.5],
                      [2.833, 3.067],
                      [3.833, 1.6],
                      [2.8, 0],
                    ],
                    v: [
                      [45.4, 1.05],
                      [51.4, -0.1],
                      [55.7, -1.45],
                      [58.1, -2.6],
                      [56.2, -8.4],
                      [49.45, -5.85],
                      [38.4, -4.6],
                      [27.75, -6.7],
                      [19.9, -12.7],
                      [15.05, -22.15],
                      [13.4, -34.6],
                      [15.05, -46.8],
                      [20, -56.3],
                      [28.15, -62.5],
                      [39.5, -64.7],
                      [49.05, -63.25],
                      [55.4, -60.3],
                      [57.6, -66.2],
                      [55, -67.55],
                      [50.9, -69.05],
                      [45.3, -70.3],
                      [38.2, -70.8],
                      [26.1, -68.45],
                      [15.9, -61.5],
                      [8.85, -50.15],
                      [6.2, -34.7],
                      [8.65, -19.25],
                      [15.35, -7.9],
                      [25.35, -0.9],
                      [37.8, 1.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "C",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "C",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "A",
      size: 56,
      style: "Light",
      w: 67.4,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [2.319, 6.239],
                      [2.258, 5.556],
                      [2.194, 5.136],
                      [2.444, 5.131],
                      [0, 0],
                      [2.219, -5.125],
                      [2.25, -5.559],
                      [2.341, -6.23],
                      [2.631, -7.214],
                      [0, 0],
                      [-1.106, 3.144],
                      [-1.156, 2.994],
                      [0, 0],
                      [-1.111, -3.142],
                      [-1.322, -3.403],
                    ],
                    o: [
                      [-2.784, -7.206],
                      [-2.319, -6.239],
                      [-2.258, -5.556],
                      [-2.194, -5.134],
                      [0, 0],
                      [-2.447, 5.155],
                      [-2.219, 5.127],
                      [-2.25, 5.559],
                      [-2.341, 6.231],
                      [0, 0],
                      [1.236, -3.384],
                      [1.106, -3.144],
                      [0, 0],
                      [1.013, 2.978],
                      [1.111, 3.142],
                      [0, 0],
                    ],
                    v: [
                      [63.2, 0],
                      [55.544, -20.167],
                      [48.678, -37.861],
                      [42, -53.9],
                      [35.042, -69.3],
                      [29.047, -69.3],
                      [22.05, -53.88],
                      [15.345, -37.852],
                      [8.458, -20.167],
                      [1, 0],
                      [8.1, 0],
                      [11.614, -9.794],
                      [15.009, -19],
                      [48.864, -19],
                      [52.048, -9.819],
                      [55.697, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.425, -5.934],
                      [-2.559, -6.867],
                      [0, 0],
                      [-2.377, 5.927],
                      [-2.509, 5.756],
                    ],
                    o: [
                      [2.423, 5.934],
                      [0, 0],
                      [2.544, -6.858],
                      [2.377, -5.925],
                      [2.506, 5.731],
                    ],
                    v: [
                      [39.247, -44.003],
                      [46.722, -24.8],
                      [17.142, -24.8],
                      [24.522, -43.977],
                      [31.85, -61.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "T",
      size: 56,
      style: "Light",
      w: 57.2,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.8, -69.3],
                      [1.8, -63.4],
                      [24, -63.4],
                      [24, 0],
                      [30.8, 0],
                      [30.8, -63.4],
                      [53, -63.4],
                      [53, -69.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "G",
      size: 56,
      style: "Light",
      w: 65.8,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [2.3, -0.366],
                      [3.4, 0],
                      [3.2, 1.4],
                      [2.166, 2.634],
                      [1.066, 3.7],
                      [0, 4.6],
                      [-1.3, 3.734],
                      [-2.267, 2.534],
                      [-3.067, 1.267],
                      [-3.467, 0],
                      [-3.2, -0.833],
                      [-1.867, -1.333],
                      [0, 0],
                      [3.333, 1.1],
                      [5.133, 0],
                      [3.933, -1.6],
                      [2.966, -3.033],
                      [1.733, -4.5],
                      [0, -5.8],
                      [-1.6, -4.5],
                      [-2.834, -3.033],
                      [-3.867, -1.6],
                      [-4.534, 0],
                      [-3.7, 0.833],
                      [-1.267, 0.534],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-0.8, 0.334],
                      [-2.3, 0.367],
                      [-4.2, 0],
                      [-3.2, -1.4],
                      [-2.167, -2.633],
                      [-1.067, -3.7],
                      [0, -4.933],
                      [1.3, -3.733],
                      [2.266, -2.533],
                      [3.066, -1.266],
                      [3.733, 0],
                      [3.2, 0.834],
                      [0, 0],
                      [-1.534, -1],
                      [-3.334, -1.1],
                      [-4.467, 0],
                      [-3.934, 1.6],
                      [-2.967, 3.034],
                      [-1.734, 4.5],
                      [0, 5.8],
                      [1.6, 4.5],
                      [2.833, 3.034],
                      [3.866, 1.6],
                      [5.4, 0],
                      [3.7, -0.833],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [52.2, -6.2],
                      [47.55, -5.15],
                      [39, -4.6],
                      [27.9, -6.7],
                      [19.85, -12.75],
                      [15, -22.25],
                      [13.4, -34.7],
                      [15.35, -47.7],
                      [20.7, -57.1],
                      [28.7, -62.8],
                      [38.5, -64.7],
                      [48.9, -63.45],
                      [56.5, -60.2],
                      [58.8, -66],
                      [51.5, -69.15],
                      [38.8, -70.8],
                      [26.2, -68.4],
                      [15.85, -61.45],
                      [8.8, -50.15],
                      [6.2, -34.7],
                      [8.6, -19.25],
                      [15.25, -7.95],
                      [25.3, -1],
                      [37.9, 1.4],
                      [51.55, 0.15],
                      [59, -1.9],
                      [59, -32.8],
                      [52.2, -32.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "G",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "G",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "N",
      size: 56,
      style: "Light",
      w: 70.9,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.238, 4.931],
                      [3.736, 5.166],
                      [3.887, 4.977],
                      [3.403, 3.797],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.45, -3.122],
                      [-2.559, -3.572],
                      [-2.581, -3.778],
                      [-2.375, -3.538],
                      [-1.942, -3.15],
                      [-1.286, -2.319],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.075, -3.661],
                      [-3.238, -4.93],
                      [-3.736, -5.164],
                      [-3.888, -4.975],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.9, 2.272],
                      [2.45, 3.122],
                      [2.559, 3.572],
                      [2.581, 3.78],
                      [2.373, 3.539],
                      [1.942, 3.15],
                      [0, 0],
                    ],
                    v: [
                      [61.9, 0],
                      [61.9, -69.3],
                      [55.2, -69.3],
                      [55.2, -12.9],
                      [47.233, -25.788],
                      [36.772, -40.93],
                      [25.338, -56.141],
                      [14.4, -69.3],
                      [9, -69.3],
                      [9, 0],
                      [15.7, 0],
                      [15.7, -58.373],
                      [22.223, -50.281],
                      [29.738, -40.241],
                      [37.45, -29.216],
                      [44.884, -18.239],
                      [51.358, -8.205],
                      [56.2, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "N",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "N",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
    {
      ch: "Y",
      size: 56,
      style: "Light",
      w: 60.4,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-3.934, 6.834],
                      [-3.534, 6.334],
                      [0, 0],
                      [3.408, -6.033],
                      [3.542, -5.466],
                      [3.375, 6.034],
                      [2.806, 5.867],
                      [0, 0],
                      [-3.983, -6.766],
                      [-4.753, -7.266],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [4.6, -7.066],
                      [3.933, -6.833],
                      [0, 0],
                      [-2.875, 5.867],
                      [-3.409, 6.034],
                      [-3.677, -5.466],
                      [-3.375, -6.033],
                      [0, 0],
                      [3.414, 6.4],
                      [3.983, 6.767],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [32, 0],
                      [32, -28.7],
                      [44.8, -49.55],
                      [56, -69.3],
                      [48.6, -69.3],
                      [39.177, -51.45],
                      [28.75, -34.2],
                      [18.173, -51.45],
                      [8.9, -69.3],
                      [1, -69.3],
                      [12.095, -49.55],
                      [25.2, -28.5],
                      [25.2, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Y",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "Y",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Ubuntu",
    },
  ],
};

export default heroLottie1152;
